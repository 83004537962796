import { TableCell } from '@mui/material'
import React, { memo, useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ActionsIconTbodyWrapper, DropDown, Icon, Name } from 'components'
import { CMSContext, ProgressContext } from 'context'
import {
  CMSCollection,
  CMSCollectionRecord,
  CMSService,
  RecordStatus,
  getCollectionName,
  getParentCollectionName,
} from 'services/cms'

interface Props {
  currentCollection: CMSCollection
  collectionId: string
  record: CMSCollectionRecord
  setSelectedRecords: React.Dispatch<React.SetStateAction<CMSCollectionRecord[]>>
  refreshRecords: () => void
  setToRemove: React.Dispatch<React.SetStateAction<CMSCollectionRecord | null>>
  openRecord: (recordId: string) => void
}

export const Actions: React.FC<Props> = memo(
  ({ currentCollection, collectionId, record, setSelectedRecords, refreshRecords, setToRemove, openRecord }) => {
    const { startLoader, stopLoader, toast } = useContext(ProgressContext)
    const { allCollections } = useContext(CMSContext)
    const { id } = useParams()
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const subCollections = useMemo(
      () => allCollections.filter(el => getParentCollectionName(el.name) === currentCollection.name),
      [currentCollection, allCollections]
    )

    const publishRecord = (publish: boolean, recordId: string) => {
      startLoader()
      CMSService.publishRecord(id as string, collectionId, recordId, publish)
        .then(() => {
          setSelectedRecords(selectedRecords => selectedRecords.filter(el => el.id !== recordId))
          refreshRecords()
        })
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }

    const dublicateRecord = (record: CMSCollectionRecord) => {
      startLoader()
      CMSService.postRecord(id as string, collectionId, record)
        .then(() => {
          setSelectedRecords(selectedRecords => selectedRecords.filter(el => el.id !== record.id))
          refreshRecords()
        })
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }

    const openSubCollection = (subCollectionId: string) =>
      navigate(`/projects/${id}/collections/${collectionId}/${record.id}/${subCollectionId}`)

    return (
      <TableCell onClick={e => e.stopPropagation()} sx={{ zIndex: open ? '2 !important' : '1' }}>
        <ActionsIconTbodyWrapper>
          <DropDown
            options={[
              {
                icon: <Icon name={Name.PICKERS_EDIT_RECORDS} />,
                text: 'View/edit',
                onClick: () => openRecord(record.id),
              },
              {
                icon: <Icon name={Name.GLOBAL_FILE_TRANSPARENT} />,
                text:
                  record.recordStatus === RecordStatus.PUBLISHED
                    ? 'Unpublish'
                    : record.recordStatus === RecordStatus.CHANGED
                    ? 'Publish changes'
                    : 'Publish',
                onClick: () => publishRecord(record.recordStatus !== RecordStatus.PUBLISHED, record.id),
              },
              {
                icon: <Icon name={Name.RIGHT_SIDEBAR_COPY} />,
                text: 'Dublicate',
                onClick: () => dublicateRecord(record),
              },
              ...subCollections.map(el => {
                const subCollectionId = getCollectionName(el.name)
                return {
                  icon: <Icon name={Name.ADDITIONAL_COLLECTION} />,
                  text: subCollectionId,
                  onClick: () => openSubCollection(subCollectionId),
                }
              }),
              {
                icon: <Icon name={Name.PICKERS_DELETE} />,
                text: record.recordStatus === RecordStatus.CHANGED ? 'Discard' : 'Delete',
                onClick: () => setToRemove(record),
                red: true,
              },
            ]}
            top="25px"
            labelElement={<Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />}
            checkIfOpen={setOpen}
          />
        </ActionsIconTbodyWrapper>
      </TableCell>
    )
  }
)
