import React from 'react'

import { Icon, ImagePicker, Name } from 'components'
import { SettingField } from 'partials/RightSidebar/components'
import { LocalVariable, Resource, Screen, VariableSource } from 'utils'

interface Props {
  big?: boolean
  label?: string
  subLabel?: string
  value?: string
  url?: string
  onChange: (val?: string, resources?: Resource[]) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  sfByDefault?: boolean
  withoutDelete?: boolean
  insidePicker?: boolean
}

export const ImageInput: React.FC<Props> = ({
  big,
  label,
  subLabel,
  value,
  url,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  sfByDefault,
  withoutDelete,
  insidePicker,
}) => (
  <SettingField
    big={big}
    label={label}
    subLabel={subLabel}
    insidePicker={insidePicker}
    value={value}
    leftIcon={
      insidePicker ? undefined : url ? <img src={url} alt="preview" /> : <Icon name={Name.RIGHT_SIDEBAR_ADD_ASSET} />
    }
    optionsContainer={
      <ImagePicker
        close={() => {}}
        url={url}
        name={value}
        onChange={onChange}
        source={source}
        onSourceChange={onSourceChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
        sfByDefault={sfByDefault}
        withoutDelete={withoutDelete}
      />
    }
    actions={
      !withoutDelete && (value || source) ? (
        <Icon
          name={Name.RIGHT_SIDEBAR_DELETE}
          onClick={() => {
            onChange()
            onSourceChange?.()
          }}
        />
      ) : undefined
    }
  />
)
