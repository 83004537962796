import React, { useContext } from 'react'

import { BooleanInput, BrandingColorInput, BrandingFontInput, ElementWithLocalizedContent } from 'components'
import { ProjectContext } from 'context'
import {
  RightSidebarItem,
  SettingNumberField,
  SettingTextAlignment,
  SettingTextField,
  SubWrapper,
} from 'partials/RightSidebar/components'
import {
  LocalVariable,
  Resource,
  Screen,
  ScreenComponent,
  getAppTextAlignment,
  getCSSTextAlignment,
  getTextareaValue,
} from 'utils'
import { Common, Id } from '../common'

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const TextSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { language } = useContext(ProjectContext)
  const { componentType, name, text, font, textAlignment, textColor, numberOfLines, showMoreTextButton } = component
  const { key, value } = getTextareaValue(language, text)

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <SettingTextField
          key={language}
          label="Text"
          value={value}
          values={text?.values || []}
          limit={300}
          onChange={(value, values) =>
            onComponentPropertiesChange([
              { propName: 'text', value: { key, locales: { ...text?.locales, [language]: value }, values } },
            ])
          }
          translation={
            text ? (
              <ElementWithLocalizedContent
                title={`${name || componentType}`}
                value={text}
                onChange={value => onComponentPropertiesChange([{ propName: 'text', value }])}
              />
            ) : undefined
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <BrandingFontInput
          label="Style"
          value={font}
          onChange={value => onComponentPropertiesChange([{ propName: 'font', value }])}
          canOpenStylePickerFromScreen
        />
        <SettingNumberField
          subLabel="Number of lines"
          value={numberOfLines?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'numberOfLines', value: { constant: value } }])}
          source={numberOfLines?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'numberOfLines', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingTextAlignment
          subLabel="Alignment"
          value={getCSSTextAlignment(textAlignment)}
          onChange={value =>
            onComponentPropertiesChange([{ propName: 'textAlignment', value: getAppTextAlignment(value) }])
          }
        />
        <BrandingColorInput
          subLabel="Text color"
          value={textColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { constant: value } }])}
          source={textColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
      <SubWrapper title="Options" canHide childrenFor={componentType}>
        <BooleanInput
          subLabel="Show more text button"
          value={showMoreTextButton?.constant}
          onChange={value =>
            onComponentPropertiesChange([{ propName: 'showMoreTextButton', value: { constant: value } }])
          }
          source={showMoreTextButton?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'showMoreTextButton', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
    </RightSidebarItem>
  )
}
