import React, { useContext } from 'react'

import {
  BooleanInput,
  BrandingColorInput,
  DateInput,
  ElementWithLocalizedContent,
  Icon,
  ImageInput,
  Name,
} from 'components'
import { ProjectContext } from 'context'
import {
  RightSidebarItem,
  SettingField,
  SettingNumberField,
  SettingTextField,
  SubWrapper,
} from 'partials/RightSidebar/components'
import { ComponentType, LocalVariable, Resource, Screen, ScreenComponent, getTextareaValue } from 'utils'
import { Common, Id } from '../common'

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const TextInputSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
}) => {
  const { language } = useContext(ProjectContext)
  const { componentType, name, text, date, placeholderText, textColor } = component
  const { key, value } = getTextareaValue(language, text)
  const { key: placeholderKey, value: placeholderTextareaValue } = getTextareaValue(language, placeholderText)

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        {componentType === ComponentType.datePicker ? (
          <DateInput
            label={placeholderTextareaValue}
            value={date?.constant}
            onChange={value => onComponentPropertiesChange([{ propName: 'date', value: { constant: value } }])}
            source={date?.source}
            onSourceChange={value => onComponentPropertiesChange([{ propName: 'date', value: { source: value } }])}
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        ) : (
          <SettingTextField
            key={language}
            label={placeholderTextareaValue}
            value={value}
            values={text?.values || []}
            limit={300}
            onChange={(value, values) =>
              onComponentPropertiesChange([
                { propName: 'text', value: { key, locales: { ...text?.locales, [language]: value }, values } },
              ])
            }
            translation={
              text ? (
                <ElementWithLocalizedContent
                  title={`${name || componentType}`}
                  value={text}
                  onChange={value => onComponentPropertiesChange([{ propName: 'text', value }])}
                />
              ) : undefined
            }
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        )}
        <SettingField label="Style" value={'Text Input'} leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />} />
        <BrandingColorInput
          subLabel="Text color"
          value={textColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { constant: value } }])}
          source={textColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingField
          label="Content"
          value={'Initial value - form.firstName'}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
        />
        <SettingField value={'Initial value - Names'} leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />} />
        <SettingTextField subLabel="Default value" value={''} onChange={() => {}} placeholder="Enter text" />
        <SettingTextField
          key={language}
          subLabel="Placeholder"
          value={placeholderTextareaValue}
          values={placeholderText?.values || []}
          limit={300}
          onChange={(value, values) =>
            onComponentPropertiesChange([
              {
                propName: 'placeholderText',
                value: {
                  key: placeholderKey,
                  locales: { ...placeholderText?.locales, [language]: value },
                  values,
                },
              },
            ])
          }
          translation={
            placeholderText ? (
              <ElementWithLocalizedContent
                title={`${name || componentType} (placeholder)`}
                value={placeholderText}
                onChange={value => onComponentPropertiesChange([{ propName: 'placeholderText', value }])}
              />
            ) : undefined
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingTextField subLabel="Details" value={''} onChange={() => {}} placeholder="Enter details" />
        <BooleanInput subLabel="Read only" value={true} onChange={() => {}} />
      </SubWrapper>
      <SubWrapper childrenFor={componentType} title="Validations" canHide>
        <SettingNumberField subLabel="Min length" value={'2'} onChange={() => {}} />
        <SettingNumberField subLabel="Max length" value={'256'} onChange={() => {}} />
        <BooleanInput subLabel="Required" value={true} onChange={() => {}} />
      </SubWrapper>
      <SubWrapper childrenFor={componentType} title="Leading icon" canHide>
        <ImageInput subLabel="Asset" value={'Icon'} onChange={() => {}} />
        <BrandingColorInput
          subLabel="Tint color"
          value={'@onBackground'}
          onChange={() => {}}
          onSourceChange={() => {}}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper childrenFor={componentType} title="Trailing icon" canHide>
        <ImageInput subLabel="Asset" value={'Icon'} onChange={() => {}} />
        <BrandingColorInput
          subLabel="Tint color"
          value={'@onBackground'}
          onChange={() => {}}
          onSourceChange={() => {}}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper title="Options" canHide childrenFor={componentType}>
        <BooleanInput subLabel="Autofocus" value={true} onChange={() => {}} />
        <BooleanInput subLabel="Show clear fieid icon" value={true} onChange={() => {}} />
        <BooleanInput subLabel="Autocomplete" value={true} onChange={() => {}} />
        <SettingField
          subLabel="Keyboard type"
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          value={'Default'}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
