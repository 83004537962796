import React, { useContext, useEffect, useMemo } from 'react'

import {
  BooleanInput,
  CommonPicker,
  Icon,
  Menu,
  ValuesPicker,
  acceptDataTypesValues,
  dataTypes,
  getFieldIcon,
} from 'components'
import { CMSContext, ProgressContext } from 'context'
import { RightSidebarItem, SettingField, SettingTextField, SubWrapper } from 'partials/RightSidebar/components'
import { CMSCollectionProperty, recordStatus } from 'services/cms'
import { ValueType, firstLetterUpperCase } from 'utils'

interface Props {
  activeProperty: CMSCollectionProperty
  setActivePropertyId: React.Dispatch<React.SetStateAction<string>>
  properties: CMSCollectionProperty[]
  setProperties: React.Dispatch<React.SetStateAction<CMSCollectionProperty[]>>
}

export const FieldSettings: React.FC<Props> = ({ activeProperty, setActivePropertyId, properties, setProperties }) => {
  const { toast } = useContext(ProgressContext)
  const { dataObjects, rootCollections } = useContext(CMSContext)
  const { id, type, isRequired, isSystem, name, accept, helpText, collection, dataObject, acceptValues } =
    activeProperty
  const collectionsValues = useMemo(
    () =>
      rootCollections.map(el => ({
        value: el.name,
        label: el.name,
        iconName: getFieldIcon(ValueType.record),
      })),
    [rootCollections]
  )
  const dataObjectsValues = useMemo(
    () =>
      dataObjects.map(el => ({
        value: el.name,
        label: el.name,
        iconName: getFieldIcon(ValueType.record),
      })),
    [dataObjects]
  )
  const disabled = isSystem

  const updateField = (field: keyof CMSCollectionProperty, value: CMSCollectionProperty[keyof CMSCollectionProperty]) =>
    setProperties(properties => {
      const current = properties.find(el => el.id === id)
      if (current) {
        current[field] = value as never
      }
      return [...properties]
    })

  useEffect(() => {
    setProperties(properties => {
      const current = properties.find(el => el.id === id)
      if (current) {
        if (current.type !== ValueType.array && current.accept) {
          delete current.accept
        }
        if (current.type !== ValueType.keyValueMap && current.accept !== ValueType.keyValueMap && current.dataObject) {
          delete current.dataObject
        }
        if (current.type !== ValueType.record && current.accept !== ValueType.record && current.collection) {
          delete current.collection
        }
        if (
          current.type !== ValueType.string &&
          current.accept !== ValueType.string &&
          current.type !== ValueType.accessLevel &&
          current.accept !== ValueType.accessLevel &&
          current.acceptValues
        ) {
          delete current.acceptValues
        }
      }
      return [...properties]
    })
  }, [type, accept])

  useEffect(() => {
    if (!acceptValues?.length) {
      setProperties(properties => {
        const current = properties.find(el => el.id === id)
        if (current) {
          delete current.acceptValues
        }
        return [...properties]
      })
    }
  }, [acceptValues])

  const onNameChange = (value: string) => {
    if (value !== recordStatus && ![...properties].find(el => el.name === value)) {
      updateField('name', value)
    } else {
      toast(`Field ${value} already exists`)
    }
  }

  const sectionName = 'Field'

  return (
    <Menu
      right
      firstChild={
        <RightSidebarItem
          title={sectionName}
          hasCloseIcon
          onClose={() => setActivePropertyId('')}
          onBackClick={() => setActivePropertyId('')}
        >
          <SubWrapper childrenFor={sectionName}>
            <SettingTextField label="Name" value={name} onChange={onNameChange} disabled={disabled} />
            <SettingTextField
              label="Help text"
              value={helpText}
              onChange={value => updateField('helpText', value)}
              disabled={disabled}
            />
            <SettingField
              label="Data type"
              leftIcon={<Icon name={getFieldIcon(type)} />}
              value={firstLetterUpperCase(type)}
              disabled={disabled}
              optionsContainer={
                <CommonPicker
                  title="Data types"
                  value={type}
                  onChange={val => updateField('type', val)}
                  values={dataTypes}
                  close={() => {}}
                />
              }
            />
            {type === ValueType.record && (
              <SettingField
                label="Collection"
                value={collection}
                disabled={disabled}
                optionsContainer={
                  <CommonPicker
                    title="Collections"
                    value={collection}
                    onChange={val => updateField('collection', val)}
                    values={collectionsValues}
                    close={() => {}}
                  />
                }
              />
            )}
            {type === ValueType.keyValueMap && (
              <SettingField
                label="Data object"
                value={dataObjects.find(el => el.name === dataObject)?.name}
                disabled={disabled}
                optionsContainer={
                  <CommonPicker
                    title="Data objects"
                    value={dataObject}
                    onChange={val => updateField('dataObject', val)}
                    values={dataObjectsValues}
                    close={() => {}}
                  />
                }
              />
            )}
            {(type === ValueType.string || type === ValueType.accessLevel) && (
              <SettingField
                label="Accept values"
                value={acceptValues?.join(', ')}
                disabled={disabled}
                optionsContainer={
                  <ValuesPicker
                    value={acceptValues}
                    onChange={val => updateField('acceptValues', val)}
                    close={() => {}}
                  />
                }
              />
            )}
          </SubWrapper>
          <SubWrapper childrenFor={sectionName} title="Validations">
            <BooleanInput
              subLabel="Required"
              value={isRequired}
              onChange={val => updateField('isRequired', val)}
              disabled={disabled}
            />
            {type === ValueType.array && (
              <SettingField
                subLabel="Accept"
                value={accept ? firstLetterUpperCase(accept) : undefined}
                disabled={disabled}
                optionsContainer={
                  <CommonPicker
                    title="Data types"
                    value={accept}
                    onChange={val => updateField('accept', val)}
                    values={acceptDataTypesValues}
                    close={() => {}}
                  />
                }
              />
            )}
            {accept === ValueType.record && (
              <SettingField
                subLabel="Collection"
                value={collection}
                disabled={disabled}
                optionsContainer={
                  <CommonPicker
                    title="Collections"
                    value={collection}
                    onChange={val => updateField('collection', val)}
                    values={collectionsValues}
                    close={() => {}}
                  />
                }
              />
            )}
            {accept === ValueType.keyValueMap && (
              <SettingField
                subLabel="Data object"
                value={dataObjects.find(el => el.name === dataObject)?.name}
                disabled={disabled}
                optionsContainer={
                  <CommonPicker
                    title="Data objects"
                    value={dataObject}
                    onChange={val => updateField('dataObject', val)}
                    values={dataObjectsValues}
                    close={() => {}}
                  />
                }
              />
            )}
            {(accept === ValueType.string || accept === ValueType.accessLevel) && (
              <SettingField
                subLabel="Accept values"
                value={acceptValues?.join(', ')}
                disabled={disabled}
                optionsContainer={
                  <ValuesPicker
                    value={acceptValues}
                    onChange={val => updateField('acceptValues', val)}
                    close={() => {}}
                  />
                }
              />
            )}
          </SubWrapper>
        </RightSidebarItem>
      }
    />
  )
}
