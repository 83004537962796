import React, { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ProgressContext } from 'context'
import { ProjectsService } from 'services/projects'

export const Migrate: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Migrate | CodePlatform'
    startLoader()
    ProjectsService.migrate(id as string, '0P1mKhRwVE9vLMNS0RKW')
      .then(() => navigate(`/projects/${id}`))
      .catch(err => toast(err))
      .finally(() => stopLoader())
  }, [])

  return <></>
}
