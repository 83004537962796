import React, { useContext, useEffect, useState } from 'react'

import { BrandingColorInput, CommonPicker, Icon, ImageInput, Name } from 'components'
import { ProjectContext } from 'context'
import { useVariable } from 'hooks'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { ImageLayout, LocalVariable, Resource, Screen, ScreenComponent, getMediaResource } from 'utils'
import { Common, Id } from '../common'

const modeValues = Object.values(ImageLayout).map(el => ({ value: el, label: el }))

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
  listId?: string
}

export const ImageSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  screenConfig,
  allLocalVariables,
  listId,
}) => {
  const {
    state: {
      data: { collections, globalVariables },
      branding: {
        colorStyles,
        appName,
        icons: { iosIcon },
      },
      resources,
    },
    theme,
    language,
  } = useContext(ProjectContext)
  const { getVariable } = useVariable()
  const {
    componentType,
    name,
    image,
    imageColor,
    imageLayout,
    placeholderImage,
    placeholderImageColor,
    placeholderImageLayout,
  } = component

  const [{ name: imageName, url: imageUrl }, setImageResource] = useState({ name: '', url: '' })
  const [{ name: placeholderName, url: placeholderUrl }, setPlaceholderImageResource] = useState({ name: '', url: '' })

  useEffect(() => {
    getMediaResource(
      resources,
      getVariable(screenConfig, listId, 0),
      true,
      0,
      image,
      imageColor,
      theme,
      colorStyles
    ).then(setImageResource)
  }, [
    JSON.stringify(screenConfig),
    JSON.stringify(colorStyles),
    JSON.stringify(resources),
    JSON.stringify(collections),
    JSON.stringify(globalVariables),
    JSON.stringify(appName),
    iosIcon,
    theme,
    language,
    JSON.stringify(image),
    JSON.stringify(imageColor),
    listId,
  ])

  useEffect(() => {
    getMediaResource(
      resources,
      getVariable(screenConfig, listId, 0),
      true,
      0,
      placeholderImage,
      placeholderImageColor,
      theme,
      colorStyles
    ).then(setPlaceholderImageResource)
  }, [
    JSON.stringify(screenConfig),
    JSON.stringify(colorStyles),
    JSON.stringify(resources),
    JSON.stringify(collections),
    JSON.stringify(globalVariables),
    JSON.stringify(appName),
    iosIcon,
    theme,
    language,
    JSON.stringify(placeholderImage),
    JSON.stringify(placeholderImageColor),
    listId,
  ])

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <ImageInput
          label="Data"
          value={imageName || imageUrl}
          url={imageUrl}
          onChange={(value, resources) =>
            onComponentPropertiesChange([{ propName: 'image', value: { constant: { resourceId: value } }, resources }])
          }
          source={image?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'image', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingField
          subLabel="Mode"
          value={imageLayout}
          leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
          optionsContainer={
            <CommonPicker
              title="Modes"
              value={imageLayout}
              onChange={value => onComponentPropertiesChange([{ propName: 'imageLayout', value }])}
              values={modeValues}
              close={() => {}}
            />
          }
        />
        <BrandingColorInput
          subLabel="Tint color"
          value={imageColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'imageColor', value: { constant: value } }])}
          source={imageColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'imageColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper title="Placeholder" canHide childrenFor={componentType}>
        <ImageInput
          subLabel="Asset"
          value={placeholderName}
          url={placeholderUrl}
          onChange={(value, resources) =>
            onComponentPropertiesChange([
              { propName: 'placeholderImage', value: { constant: { resourceId: value } }, resources },
            ])
          }
          source={placeholderImage?.source}
          onSourceChange={value =>
            onComponentPropertiesChange([{ propName: 'placeholderImage', value: { source: value } }])
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        {placeholderUrl && (
          <>
            <SettingField
              subLabel="Mode"
              value={placeholderImageLayout}
              leftIcon={<Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />}
              optionsContainer={
                <CommonPicker
                  title="Modes"
                  value={placeholderImageLayout}
                  onChange={value => onComponentPropertiesChange([{ propName: 'placeholderImageLayout', value }])}
                  values={modeValues}
                  close={() => {}}
                />
              }
            />
            <BrandingColorInput
              subLabel="Tint color"
              value={placeholderImageColor?.constant}
              onChange={value =>
                onComponentPropertiesChange([{ propName: 'placeholderImageColor', value: { constant: value } }])
              }
              source={placeholderImageColor?.source}
              onSourceChange={value =>
                onComponentPropertiesChange([{ propName: 'placeholderImageColor', value: { source: value } }])
              }
              screenConfig={screenConfig}
              allLocalVariables={allLocalVariables}
            />
          </>
        )}
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
