import React from 'react'

import { Text, Type } from 'components'
import { SettingsLayout } from '../components'

export const Usage: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Usage</Text>
    <Text type={Type.SUB_TITLE}>Track current usage and limits</Text>
  </SettingsLayout>
)
