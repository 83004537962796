import { GetVariableValue } from 'hooks'
import {
  BackgroundGradient,
  BrandingColorStyles,
  BrandingColorType,
  BrandingThemeType,
  StringVariable,
  getColor,
} from 'utils'

export const setColors = async (
  element: HTMLDivElement,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue,
  textColor?: StringVariable,
  backgroundColor?: StringVariable,
  borderColor?: StringVariable,
  backgroundGradient?: BackgroundGradient
) => {
  if (textColor) {
    element.style.color = await getColor(textColor, colorStyles, theme, getVariableValue)
  }

  if (backgroundColor) {
    element.style.backgroundColor = await getColor(backgroundColor, colorStyles, theme, getVariableValue)
  }

  if (borderColor) {
    element.style.borderColor = await getColor(borderColor, colorStyles, theme, getVariableValue)
  }

  if (backgroundGradient) {
    const { direction, start, end } = backgroundGradient
    element.style.background = `linear-gradient(to ${direction === 'vertical' ? 'bottom' : 'right'}, ${
      colorStyles[start.slice(1) as BrandingColorType][BrandingThemeType.light]
    }, ${colorStyles[end.slice(1) as BrandingColorType][BrandingThemeType.light]})`
  }
}
