import React, { useEffect, useState } from 'react'

import { Transforms } from 'components'
import { LocalVariable, Screen, VariableSource, VariableSourceType, VariableTransform } from 'utils'

interface Props {
  value?: VariableTransform[]
  onChange: (val: VariableTransform[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const ActionTransforms: React.FC<Props> = ({ value, onChange, screenConfig, allLocalVariables }) => {
  const [currentVariable, setCurrentVariable] = useState<VariableSource | null>({
    type: VariableSourceType.collection,
    transforms: value,
  })

  useEffect(() => {
    setCurrentVariable({ type: VariableSourceType.collection, transforms: value })
  }, [value])

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(currentVariable?.transforms)) {
      onChange(currentVariable?.transforms || [])
    }
  }, [currentVariable?.transforms])

  return (
    <Transforms
      transforms={currentVariable?.transforms}
      setCurrentVariable={setCurrentVariable}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  )
}
