import { useEffect } from 'react'

import { ITemplate } from 'context/types'

export const useIcon = (ref: React.MutableRefObject<HTMLDivElement | null>, template?: ITemplate | null) => {
  useEffect(() => {
    if (template?.studioAdditionalIcon?.url) {
      fetch(template.studioAdditionalIcon.url)
        .then(res => res.text())
        .then(res => {
          const div = document.createElement('div')
          div.innerHTML = res
          const svg = div.querySelector('svg')
          if (svg && ref.current) {
            const firstChild = ref.current.firstChild
            if (firstChild) {
              ref.current.removeChild(firstChild)
            }
            ref.current.appendChild(svg)
          }
        })
    }
  }, [ref, template])
}
