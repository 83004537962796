import { FormControlLabel, Paper } from '@mui/material'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import { ThemeType, unreset } from 'theme'
import { CoordinateInput } from './components/CoordinateInput/CoordinateInput.styled'
import { RichTextWrapper } from './components/RichText/RichText.styled'
import { VariableInput } from './components/VariableInput/VariableInput.styled'

export const Field = styled(FormControlLabel)<{
  $isPlaceholder: boolean
  $smallWidth?: boolean
  $ai?: boolean
  $editByModal?: boolean
}>`
  margin: 30px 0 0 !important;
  width: 100%;
  max-width: ${props => props.$smallWidth && '576px'};
  box-sizing: border-box;
  align-items: flex-start !important;
  position: relative;
  & > span {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: ${props => props.theme.colors.darkText};
    margin-bottom: 10px;
  }
  & ${CoordinateInput} > input,
  & ${VariableInput} > input,
  & > input,
  & > select,
  & > textarea {
    width: 100%;
    font-family: ${props => props.theme.fonts.primary};
    resize: none;
    border-radius: 8px;
    padding: ${props => (props.$ai || props.$editByModal ? '9px 32px 9px 9px' : '9px')};
    font-size: 14px;
    line-height: 20px;
    box-sizing: border-box;
    border: 1px solid
      ${props => (props.theme.themeType === ThemeType.LIGHT ? props.theme.colors.borderColor : 'transparent')};
    outline: none;
    transition: border-color 300ms ease-out;
    background-color: ${props => props.theme.colors.secondaryButtonBg1};
    color: ${props => props.theme.colors.darkText};
    &::placeholder {
      color: ${props => props.theme.colors.grey1};
    }
    &:hover,
    &:focus-within {
      border-color: ${props => props.theme.colors.blue};
    }
    &:disabled {
      pointer-events: none;
      background: ${props => props.theme.colors.disabledBgColor};
      color: ${props => props.theme.colors.disabledTextColor};
      border-color: ${props => props.theme.colors.borderColor};
      &::placeholder {
        color: ${props => props.theme.colors.disabledTextColor};
      }
    }
  }
  & > select {
    height: 39px;
    color: ${props => props.$isPlaceholder && props.theme.colors.grey1};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.6rem center;
    background-size: 1em;
    background-color: transparent;
    & > option {
      color: ${props => props.theme.colors.darkText};
    }
  }
  & > textarea {
    &:last-of-type {
      padding: 0 9px !important;
    }
  }
  & > ${RichTextWrapper} {
    cursor: auto;
    width: 100%;
    border-radius: 8px;
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.colors.borderColor};
    transition: border-color 300ms ease-out;
    div[data-editor-block-type='image'] {
      img {
        max-width: 100%;
        height: auto;
      }
    }
    & div[role='toolbar'] {
      position: static;
      background: ${props => props.theme.colors.primaryBackground};
      border-radius: 9px 9px 0 0;
      margin: 0;
      overflow: visible;
      z-index: unset;
      & > div {
        width: 100%;
        & > div,
        & > button {
          display: flex;
          align-items: center;
          & svg {
            width: 20px;
          }
        }
      }
      & + div {
        ${unreset}
      }
    }
    &:hover,
    &:focus-within {
      border-color: ${props => props.theme.colors.blue};
    }
    &:disabled {
      pointer-events: none;
      background: ${props => props.theme.colors.disabledBgColor};
      color: ${props => props.theme.colors.disabledTextColor};
      border-color: ${props => props.theme.colors.borderColor};
    }
  }
`

export const HelpText = styled.div`
  font-weight: 400;
  color: ${props => props.theme.colors.grey1};
  margin: 5px 0;
`

export const MaxLength = styled.div`
  margin: 5px 0 -23px;
  width: 100%;
  font-size: 12px;
  line-height: 150%;
  text-align: right;
  color: ${props => props.theme.colors.grey1};
`

export const ErrorMessage = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.red};
  & svg {
    min-width: 16px;
    min-height: 16px;
    margin-right: 6px;
    & circle {
      fill: ${props => props.theme.colors.red};
    }
  }
`

export const AutocompleteWrapper = styled.div<{ folder?: boolean }>`
  width: 100%;
  position: relative;
  & > div > div > div {
    border: 1px solid ${props => props.theme.colors.darkBlue_100_lightBorder};
    transition: border-color 300ms ease-out;
    border-radius: 8px;
    padding: 5px 9px !important;
    min-height: 40px;
    &:hover,
    &:focus-within {
      border-color: ${props => props.theme.colors.blue};
    }
    & input {
      font-family: ${props => props.theme.fonts.primary};
      font-size: 14px;
      line-height: 150%;
      color: ${props => props.theme.colors.darkText};
      padding: 1.5px 30px 1.5px 1.5px !important;
      padding-left: ${props => props.folder && '35px !important'};
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
    & button {
      & > svg {
        & > path {
          fill: ${props => props.theme.colors.grey1};
        }
      }
    }
    & fieldset {
      border: none;
    }
  }
`

export const SimpleJsonInputWrapper = styled.div`
  width: 100%;
  position: relative;
  & > div {
    border: 1px solid ${props => props.theme.colors.darkBlue_100_lightBorder};
    transition: border-color 300ms ease-out;
    border-radius: 8px;
    &:hover,
    &:focus-within {
      border-color: ${props => props.theme.colors.blue};
    }
  }
`

export const SimpleObjectInputWrapper = SimpleJsonInputWrapper

export const CustomPaper = (props: any) => {
  const themeContext = useContext(ThemeContext)
  return (
    <Paper {...props} sx={{ bgcolor: themeContext.colors.selectBackground, color: themeContext.colors.darkText1 }} />
  )
}

export const ListOption = styled.li`
  background-color: transparent !important;
  &:hover {
    color: ${props => props.theme.colors.darkText}!important;
    background-color: ${props => props.theme.colors.settingField}!important;
  }
`

export const ThreeRows = styled.div`
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  max-width: 200px;
`

export const Nowrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
`
