import 'highlight.js/styles/atom-one-light.css'
import React, { useContext, useEffect, useState } from 'react'
import Highlight from 'react-highlight'
import { Link, useLocation } from 'react-router-dom'

import * as styled from './FileContent.styled'

import { DropDown, Icon, Name } from 'components'
import { File } from 'pages/Repository/pages'
import { ApplicationContext } from 'context'

interface Props {
  data: File
  flag: string
}

export const FileContent: React.FC<Props> = ({ data, flag }) => {
  const { application } = useContext(ApplicationContext)
  const { pathname } = useLocation()
  const [image, setImage] = useState('')

  useEffect(() => {
    if (data.type.includes('image')) {
      const b64Response = btoa(data.content)
      setImage(`data:${data.type};base64,${b64Response}`)
    } else {
      setImage('')
    }
  }, [data])

  const pathForCrumbs = pathname.replace(flag, '')
  const crumbs = pathForCrumbs.split('/')
  const commit = crumbs[0]?.slice(0, 7)
  const name = pathname.split('/').slice(-1)[0]

  return (
    <styled.Container>
      <styled.Name>{name}</styled.Name>
      <styled.DropDowns>
        <DropDown options={[{ text: 'Source' }]} defaultLabel="Source" width="120px" />
        <DropDown
          options={[{ text: 'master', icon: <Icon name={Name.REPOSITORY_BRANCH} /> }]}
          defaultLabel="master"
          width="120px"
        />
        <DropDown options={[{ text: commit }]} defaultLabel={commit} width="120px" />
      </styled.DropDowns>
      <styled.File>
        <styled.FileHead>
          <div>
            <Icon name={Name.REPOSITORY_FILE} />
            <styled.FileCrumbs>
              {crumbs.map((el, ind) => (
                <Link
                  key={el}
                  to={`${pathname
                    .split('/')
                    .slice(0, -(crumbs.length - 1 - ind))
                    .join('/')}${ind === 0 ? '/' : ''}`}
                >
                  {ind === 0 ? application?.name : `/ ${el}`}
                </Link>
              ))}
            </styled.FileCrumbs>
          </div>
          <span>...</span>
        </styled.FileHead>
        <styled.FileContent>
          {image ? (
            <styled.ImageWrapper>
              <img src={image} alt="img" />
            </styled.ImageWrapper>
          ) : (
            <>
              <styled.CountOfLines>
                {data.content.split(/\r\n|\r|\n/).map((el, ind) => (
                  <span key={el + ind}>{ind + 1}</span>
                ))}
              </styled.CountOfLines>
              <Highlight>{data.content}</Highlight>
            </>
          )}
        </styled.FileContent>
      </styled.File>
    </styled.Container>
  )
}
