import { progressIndicatorAttribute, progressPassedAttribute } from 'utils'

const currentSlide: { [key: string]: number } = {}

const slideClickEvent = 'slideClickEvent'
export const carouselHandler = (carousel: HTMLDivElement) => {
  const slides = Array.from(carousel.querySelectorAll(':scope > div')) as HTMLDivElement[]
  const progressParts = Array.from(
    carousel.querySelectorAll(`:scope > i > svg > rect[${progressIndicatorAttribute}]`)
  ) as SVGRectElement[]
  if (slides.length && progressParts.length) {
    slides.forEach((el, i) =>
      el.addEventListener(slideClickEvent, () => {
        currentSlide[carousel.id] = i
        slides.slice(0, i).forEach(el => (el.hidden = true))
        slides.slice(i + 1).forEach(el => (el.hidden = true))
        slides[i].removeAttribute('hidden')
        progressParts.slice(0, i).forEach(el => el.setAttribute(progressPassedAttribute, ''))
        progressParts.slice(i + 1).forEach(el => el.removeAttribute(progressPassedAttribute))
        progressParts[i].setAttribute(progressPassedAttribute, '')
      })
    )
    slides[currentSlide[carousel.id] || 0].dispatchEvent(new Event(slideClickEvent))
  }
}

export const moveSlide = (selectedComponent: HTMLDivElement, next?: boolean) => {
  const slides = Array.from(selectedComponent.querySelectorAll(':scope > div')) as HTMLDivElement[]
  const activeIndex = slides.findIndex(el => !el.hidden)
  slides[next ? activeIndex + 1 : activeIndex - 1]?.dispatchEvent(new Event(slideClickEvent))
}
