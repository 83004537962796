import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ThemeContext } from 'styled-components'

import { NavBody, NavItem } from 'components/Menu/Menu.styled'
import { PickerOverlay, PickerWrapper } from 'components/Modal/Modal.styled'
import * as styled from './Translations.styled'

import {
  Button,
  ButtonColor,
  CustomPicker,
  CustomSectionsWrapper,
  Icon,
  Menu,
  Name,
  NavHeadSearch,
  PWAPreview,
} from 'components'
import { ProjectContext } from 'context'
import { useScreenshotFromVB } from 'hooks'
import {
  Config,
  LocalizedContent,
  Resource,
  Screen,
  ScreenComponent,
  TabBarItem,
  detectEmptyValue,
  findAllScreenComponentsWichHasProp,
  findComponent,
  includesSearch,
} from 'utils'
import { ElementWithLocalizedContent } from './ElementWithLocalizedContent'

interface Props {
  close: () => void
}

export const Translations: React.FC<Props> = ({ close }) => {
  const themeContext = useContext(ThemeContext)
  const {
    state: { tabBars, screens },
    setState,
  } = useContext(ProjectContext)
  const [activeScreenId, setActiveScreenId] = useState(screens[0].id)
  const activeScreen = screens.find(el => el.id === activeScreenId)
  const [activeScreenPreview, setActiveScreenPreview] = useState('')
  const getScreenshotFromVB = useScreenshotFromVB()
  const [search, setSearch] = useState('')

  const elementsWithText = useMemo(
    () => (activeScreen ? findAllScreenComponentsWichHasProp(activeScreen, 'text') : []),
    [activeScreen]
  )

  const elementsWithPlaceholderText = useMemo(
    () => (activeScreen ? findAllScreenComponentsWichHasProp(activeScreen, 'placeholderText') : []),
    [activeScreen]
  )

  const screenWithScreenTitle = useMemo(
    () => (activeScreen?.screenTitle ? activeScreen : null),
    [activeScreen?.screenTitle]
  )

  const tabs = useMemo(
    () => tabBars.find(el => el.tabs.find(el => el.screen === activeScreen?.screenName))?.tabs || [],
    [tabBars, activeScreen?.screenName]
  )

  useEffect(() => {
    let abort = false
    if (activeScreen?.screenName) {
      setActiveScreenPreview('')
      getScreenshotFromVB(activeScreen.screenName).then(res => !abort && setActiveScreenPreview(res))
    }
    return () => {
      abort = true
    }
  }, [activeScreen?.screenName, getScreenshotFromVB])

  const onComponentPropertiesChange =
    (componentId: string) => (props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]) => {
      setState(state => {
        const stateCopy = JSON.parse(JSON.stringify(state)) as Config
        const screen = stateCopy.screens.find(el => el.id === activeScreen?.id)
        if (screen && componentId) {
          const component = findComponent(screen, 'id', componentId)
          if (component) {
            props.forEach(({ propName, value, resources }) => {
              if (detectEmptyValue(value)) {
                delete component[propName]
              } else {
                // @ts-ignore
                component[propName] = value
              }
              if (resources) {
                stateCopy.resources = resources
              }
            })
            return stateCopy
          }
        }
        return state
      })
    }

  const onScreenPropertiesChange = (props: { propName: keyof Screen; value: any }[]) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      const screen = stateCopy.screens.find(el => el.id === activeScreen?.id)
      if (screen) {
        props.forEach(({ propName, value }) => {
          if (detectEmptyValue(value)) {
            delete screen[propName]
          } else {
            // @ts-ignore
            screen[propName] = value
          }
        })
      }
      return stateCopy
    })
  }

  const onTabPropertiesChange =
    (tabScreen: string) => (props: { propName: keyof TabBarItem; value: any; resources?: Resource[] }[]) => {
      setState(state => {
        const stateCopy = JSON.parse(JSON.stringify(state)) as Config
        const tab = stateCopy.tabBars
          .find(el => el.tabs.find(el => el.screen === tabScreen))
          ?.tabs.find(el => el.screen === tabScreen)
        if (tab) {
          props.forEach(({ propName, value, resources }) => {
            if (detectEmptyValue(value)) {
              delete tab[propName]
            } else {
              tab[propName] = value
            }
            if (resources) {
              stateCopy.resources = resources
            }
          })
          return stateCopy
        }
        return state
      })
    }

  return (
    <PickerWrapper>
      <PickerOverlay onClick={close} />
      <Box maxWidth="1400px" width="calc(100vw - 40px)" zIndex="1">
        <CustomPicker
          title="Translations"
          close={close}
          optionsContainer={
            <>
              <Box height="calc(100vh - 140px)" display="flex" position="relative">
                <Menu
                  insidePicker
                  firstChild={
                    <>
                      <NavHeadSearch title="Screens" search={search} setSearch={setSearch} />
                      <NavBody>
                        {screens
                          .filter(el => includesSearch(el.screenName, search))
                          .map(el => (
                            <NavItem
                              key={el.screenName}
                              lvl={0}
                              active={activeScreen?.screenName === el.screenName}
                              onClick={() => setActiveScreenId(el.id)}
                              big
                              noArrowMode
                            >
                              <Icon name={Name.ADDITIONAL_SCREEN} />
                              <span>{el.screenName}</span>
                            </NavItem>
                          ))}
                      </NavBody>
                    </>
                  }
                />
                <Box key={activeScreen?.screenName} width="100%" p="40px 400px 40px 40px" overflow="auto">
                  <CustomSectionsWrapper>
                    {screenWithScreenTitle && (
                      <ElementWithLocalizedContent
                        title="Screen title"
                        value={screenWithScreenTitle.screenTitle as LocalizedContent}
                        onChange={value => onScreenPropertiesChange([{ propName: 'screenTitle', value }])}
                      />
                    )}
                    {elementsWithText.map(el => (
                      <ElementWithLocalizedContent
                        key={el.id}
                        title={`${el.name || el.componentType}`}
                        value={el.text as LocalizedContent}
                        onChange={value => onComponentPropertiesChange(el.id)([{ propName: 'text', value }])}
                      />
                    ))}
                    {elementsWithPlaceholderText.map(el => (
                      <ElementWithLocalizedContent
                        key={el.id}
                        title={`${el.name || el.componentType} (placeholder)`}
                        value={el.placeholderText as LocalizedContent}
                        onChange={value => onComponentPropertiesChange(el.id)([{ propName: 'placeholderText', value }])}
                      />
                    ))}
                    {tabs.map(el => (
                      <ElementWithLocalizedContent
                        key={el.screen}
                        title={`Tab for ${el.screen} screen`}
                        value={el.title as LocalizedContent}
                        onChange={value => onTabPropertiesChange(el.screen)([{ propName: 'title', value }])}
                      />
                    ))}
                  </CustomSectionsWrapper>
                  {activeScreenPreview && (
                    <styled.PreviewWrapper>
                      <PWAPreview>
                        <img src={activeScreenPreview} alt="preview" />
                      </PWAPreview>
                    </styled.PreviewWrapper>
                  )}
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-end"
                p="10px 16px 12px"
                borderTop={`1px solid ${themeContext.colors.darkBlue_100}`}
              >
                <Button color={ButtonColor.SECONDARY} onClick={close}>
                  Cancel
                </Button>
              </Box>
            </>
          }
        />
      </Box>
    </PickerWrapper>
  )
}
