import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ProgressContext } from 'context'
import { ApplicationId, Integration, IntegrationId, useProjectIntegrations } from 'hooks'
import { firestore } from 'services/firebase'
import { AppStore, Github, GooglePlay } from './components'

export const Integrations: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const { id, repositoryId } = useParams()
  const { appstoreIntegration, googleplayIntegration } = useProjectIntegrations(id as string)
  const [applicationIntegrations, setApplicationIntegrations] = useState<Integration[]>([])

  useEffect(() => {
    const unsubscribe = firestore.collection(`projects/${id}/applications/${repositoryId}/integrations`).onSnapshot({
      next: res => setApplicationIntegrations(res.docs.map(el => ({ id: el.id, ...el.data() })) as Integration[]),
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
    }
  }, [])

  const projectIntegration =
    repositoryId === ApplicationId.IOS
      ? appstoreIntegration
      : repositoryId === ApplicationId.ANDROID
      ? googleplayIntegration
      : undefined
  const integrations = projectIntegration ? [...applicationIntegrations, projectIntegration] : applicationIntegrations

  return (
    <>
      {integrations.map(el =>
        el.id === IntegrationId.github ? (
          <Github key={el.id} integration={el} />
        ) : el.id === IntegrationId.appstore ? (
          <AppStore key={el.id} integration={el} />
        ) : el.id === IntegrationId.googleplay ? (
          <GooglePlay key={el.id} integration={el} />
        ) : (
          <React.Fragment key={el.id} />
        )
      )}
    </>
  )
}
