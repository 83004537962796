import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'

import { CustomSection, Loader, NoContent } from 'components'
import { ProgressContext, WorkspaceContext } from 'context'
import { SubscriptionStatus } from 'hooks'
import { WorkspacesService } from 'services/workspaces'
import { getMoney } from 'utils'
import { PlanName, TableRow } from './components'

enum PlanType {
  PRO = 'Pro',
  FREE = 'Free',
}

interface ITableRow {
  title: string
  description?: string
  subtitle?: string
  status?: SubscriptionStatus
  price?: string
  subject?: string
  plan?: PlanType
  hasLink?: boolean
  projectId?: string
  priceId?: string
  link?: string
}

export const Plans: React.FC = () => {
  const { toast } = useContext(ProgressContext)
  const { workspace, projects } = useContext(WorkspaceContext)
  const [wait, setWait] = useState(true)
  const [subscriptions, setSubscriptions] = useState<any[]>([])

  useEffect(() => {
    if (workspace) {
      setWait(true)
      const abortController = new AbortController()
      Promise.all([
        WorkspacesService.getSubscriptions(workspace.id, workspace.billingAccountId, abortController),
        WorkspacesService.getProducts(workspace.id, workspace.billingAccountId, undefined, abortController),
      ])
        .then(res => {
          const subscriptions = res[0].data
          const ids = subscriptions.map((el: any) => el.plan.product).join('&ids=')
          const products = res[1].data.filter((el: any) => !subscriptions.find((a: any) => a.plan.product === el.id))
          return Promise.all([
            WorkspacesService.getProducts(workspace.id, workspace.billingAccountId, ids, abortController),
            ...products.map((el: any) =>
              WorkspacesService.getProductPrices(workspace.id, workspace.billingAccountId, el.id, abortController)
            ),
          ]).then(([res, ...rest]) => {
            const prices = rest
              .map((el: any) =>
                el.data.map((a: any) => {
                  const currency = el.headers.get('X-Currency')
                  a.currency = currency
                  a.unit_amount = a.currency_options[currency].unit_amount
                  return a
                })
              )
              .flat()
            products.forEach((el: any) => (el.price = prices.find((a: any) => a.id === el.default_price)))
            subscriptions.forEach(
              (el: any) => (el.plan.name = res.data.find((a: any) => a.id === el.plan.product)?.name || '-')
            )
            setSubscriptions([...subscriptions, ...products])
          })
        })
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => setWait(false))
      return () => {
        abortController.abort()
      }
    }
  }, [workspace?.id, workspace?.billingAccountId])

  const businessSubscriptions = useMemo(
    () =>
      subscriptions.filter(
        el =>
          !el.metadata.workspaceId && !el.metadata.projectId && !el.metadata.usageId && el.metadata.planType !== 'usage'
      ),
    [subscriptions]
  )
  const workspaceSubscriptions = useMemo(
    () =>
      subscriptions.filter(
        el =>
          (workspace?.id && el.metadata.workspaceId === workspace.id) ||
          el.metadata.usageId ||
          el.metadata.planType === 'usage'
      ),
    [subscriptions]
  )
  const projectSubscriptions = useMemo(() => subscriptions.filter(el => !!el.metadata.projectId), [subscriptions])
  const externalSubscriptions = useMemo(
    () => subscriptions.filter(el => el.customer && workspace && el.customer !== workspace.billingAccountId),
    [subscriptions]
  )
  const projectPlansNames = useMemo(
    () => projectSubscriptions.map(el => el.plan.name).filter((el, i, arr) => arr.indexOf(el) === i),
    [projectSubscriptions]
  )

  const TABLE: ITableRow[][] = workspace
    ? [
        [
          { title: 'Business plans', subtitle: 'Additional plans to help you grow your business' },
          ...businessSubscriptions.map(el => ({
            title: el.plan?.name || el.name,
            description: el.description || el.metadata.description,
            subtitle: el.current_period_end
              ? `Expires at ${new Date(el.current_period_end * 1000).toDateString()}`
              : '',
            status: el.status,
            price: el.plan
              ? getMoney(
                  el.plan.currency,
                  el.plan.amount,
                  el.plan.interval,
                  el.discount?.coupon?.percent_off,
                  el.discount?.coupon?.amount_off,
                  el.discount?.coupon?.currency
                )
              : getMoney(el.price.currency, el.price.unit_amount, el.price.recurring.interval),
            priceId: el.default_price,
            link: el.metadata.link,
          })),
        ],
        [
          {
            title: 'Workspace plans',
            subtitle: 'Summary your workspace',
            hasLink: true,
          },
          ...workspaceSubscriptions.map(el => ({
            title: el.plan?.name || el.name,
            description: el.description || el.metadata.description,
            subtitle:
              el.metadata.usageId || el.metadata.planType === 'usage'
                ? ''
                : `Editors: 1 Free, ${workspace.memberIds.length - 1} Paid`,
            status: el.status,
            price:
              el.metadata.usageId || el.metadata.planType === 'usage'
                ? 'pay-as-you-go'
                : getMoney(
                    el.plan.currency,
                    el.plan.amount,
                    el.plan.interval,
                    el.discount?.coupon?.percent_off,
                    el.discount?.coupon?.amount_off,
                    el.discount?.coupon?.currency
                  ),
            priceId: el.default_price,
            link: el.metadata.link,
          })),
        ],
        [
          { title: 'Project plans', subtitle: 'Summary of all app projects within your workspace' },
          ...projectPlansNames
            .map(el => [
              { subject: 'plan', plan: el, title: 'Plan details' },
              ...projects
                .filter(b => projectSubscriptions.find(a => a.metadata.projectId === b.id)?.plan.name === el)
                .map(el => {
                  const subscription = projectSubscriptions.find(a => a.metadata.projectId === el.id)
                  return subscription
                    ? {
                        title: el.name,
                        projectId: el.id,
                        description: subscription.description,
                        subtitle: `Expires at ${new Date(subscription?.current_period_end * 1000).toDateString()}`,
                        status: subscription.status,
                        price: getMoney(
                          subscription.plan.currency,
                          subscription.plan.amount,
                          subscription.plan.interval,
                          subscription.discount?.coupon?.percent_off,
                          subscription.discount?.coupon?.amount_off,
                          subscription.discount?.coupon?.currency
                        ),
                      }
                    : {
                        title: el.name,
                        projectId: el.id,
                      }
                }),
            ])
            .flat(),
        ],
        externalSubscriptions.length
          ? [
              { title: 'External billing account' },
              ...externalSubscriptions.map(el => ({
                title: el.plan.name,
                description: el.description,
                subtitle: `Expires at ${new Date(el.current_period_end * 1000).toDateString()}`,
                status: el.status,
                price: getMoney(
                  el.plan.currency,
                  el.plan.amount,
                  el.plan.interval,
                  el.discount?.coupon?.percent_off,
                  el.discount?.coupon?.amount_off,
                  el.discount?.coupon?.currency
                ),
              })),
            ]
          : [],
      ]
    : []

  return (
    <>
      {TABLE.map((section, sectionIndex) =>
        section.length ? (
          <CustomSection table key={sectionIndex}>
            {section.map((tableRow, index) => {
              switch (tableRow.subject) {
                case 'plan':
                  return (
                    <PlanName
                      key={`${sectionIndex}-${tableRow.title}-${index}`}
                      plan={tableRow.plan as PlanType}
                      planTitle={tableRow.title}
                    />
                  )
                default:
                  return (
                    <React.Fragment key={`${sectionIndex}-${tableRow.title}-${index}`}>
                      <TableRow
                        head={index === 0}
                        last={index === section.length - 1}
                        title={tableRow.title}
                        description={tableRow.description}
                        subtitle={tableRow.subtitle}
                        status={tableRow.status}
                        price={tableRow.price}
                        hasLink={tableRow.hasLink}
                        projectId={tableRow.projectId}
                        priceId={tableRow.priceId}
                        link={tableRow.link}
                      />
                      {section.length === 1 &&
                        (wait ? (
                          <Box height="310px" display="flex" justifyContent="center" alignItems="center">
                            <Loader />
                          </Box>
                        ) : (
                          <NoContent text="No plans yet." />
                        ))}
                    </React.Fragment>
                  )
              }
            })}
          </CustomSection>
        ) : (
          <React.Fragment key={sectionIndex} />
        )
      )}
    </>
  )
}
