import styled from 'styled-components'

export const Tag = styled.span`
  background: ${props => props.theme.colors.primaryBackground};
  border: 1px solid ${props => props.theme.colors.borderColor};
  border-radius: 4px;
  padding: 4px 10px;
`

export const Status = styled.div<{ active: boolean }>`
  & i {
    display: inline-block;
    margin-right: 7px;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${props => (props.active ? props.theme.colors.green : props.theme.colors.red)};
  }
`
