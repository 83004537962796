import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './AddCollection.styled'

import { Icon, Modal, Name, Text, Type } from 'components'
import { InputWrapper } from 'components/Modal/Modal.styled'
import { useInputAutoFocusRef } from 'hooks'
import { CMSCollection, rootFolderName } from 'services/cms'

interface Props {
  active: boolean
  close: () => void
  onAdd: (name: string, callback: () => void) => void
  subCollectionParentName?: string
  folder?: boolean
  currentFolder?: CMSCollection
}

export const AddCollection: React.FC<Props> = ({
  active,
  close,
  onAdd,
  subCollectionParentName,
  folder,
  currentFolder,
}) => {
  const [name, setName] = useState('')
  const themeContext = useContext(ThemeContext)
  const inputAutoFocusElement = useInputAutoFocusRef(active)
  const collectionTitle = folder ? 'Folder' : 'Collection'
  const [wait, setWait] = useState(false)

  const onButtonClick = () => {
    setWait(true)
    onAdd(name, () => {
      onClose()
      setWait(false)
    })
  }

  const onClose = () => {
    setName('')
    close()
  }

  return (
    <Modal
      active={active}
      onClose={onClose}
      title={`Create new ${collectionTitle.toLowerCase()}`}
      onButtonClick={onButtonClick}
      buttonDisabled={!name}
      buttonTitle={`Create ${collectionTitle.toLowerCase()}`}
      buttonLoading={wait}
    >
      <Box>
        <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
          {collectionTitle} name
        </Text>
        <InputWrapper inputEmpty={name === ''}>
          <input value={name} onChange={e => setName(e.target.value)} ref={inputAutoFocusElement} />
        </InputWrapper>
        {subCollectionParentName && (
          <styled.Path>
            <styled.IconWrapper>
              <Icon name={Name.ADDITIONAL_COLLECTION} />
            </styled.IconWrapper>
            <span>{subCollectionParentName.split('/').join(' / ')}</span>
            <b>/ {name}</b>
          </styled.Path>
        )}
        {folder && (
          <styled.Path>
            <styled.IconWrapper>
              <Icon name={Name.ADDITIONAL_HOME_FOLDER} />
            </styled.IconWrapper>
            <span>{currentFolder?.name.split('/').join(' / ') || rootFolderName}</span>
            <b>/ {name}</b>
          </styled.Path>
        )}
      </Box>
    </Modal>
  )
}
