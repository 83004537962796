import React from 'react'

import { Text, Type } from 'components'
import { RepositoriesList } from 'pages'
import { SettingsLayout } from '../components'

export const Repositories: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Repositories</Text>
    <Text type={Type.SUB_TITLE}>Source code & pipelines</Text>
    <RepositoriesList hidePreview />
  </SettingsLayout>
)
