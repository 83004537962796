import React from 'react'

import * as styled from './InputParameters.styled'

import { Icon, InputParameterPicker, Name, Rotation } from 'components'
import { SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { LocalVariable, Screen, ScreenParameter } from 'utils'

interface Props {
  childrenFor: string
  onChange: (val: ScreenParameter[]) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const InputParameters: React.FC<Props> = ({ childrenFor, onChange, screenConfig, allLocalVariables }) => {
  const inputParameters = screenConfig.inputParameters || []

  const onMove = (index: number, start: boolean) => {
    const inputParametersCopy = JSON.parse(JSON.stringify(inputParameters)) as ScreenParameter[]
    let inputParameterCopy: ScreenParameter | null = null
    if (start && index !== 0) {
      inputParameterCopy = inputParametersCopy[index - 1]
      inputParametersCopy[index - 1] = inputParametersCopy[index]
      inputParametersCopy[index] = inputParameterCopy
    } else if (!start && index !== inputParametersCopy.length - 1) {
      inputParameterCopy = inputParametersCopy[index + 1]
      inputParametersCopy[index + 1] = inputParametersCopy[index]
      inputParametersCopy[index] = inputParameterCopy
    }
    if (inputParameterCopy) {
      onChange(inputParametersCopy)
    }
  }

  const onInputParameterChange = (index: number, value: ScreenParameter) => {
    const inputParametersCopy = JSON.parse(JSON.stringify(inputParameters)) as ScreenParameter[]
    inputParametersCopy.splice(index, 1, value)
    onChange(inputParametersCopy)
  }

  const onRemove = (index: number) => {
    const inputParametersCopy = JSON.parse(JSON.stringify(inputParameters)) as ScreenParameter[]
    inputParametersCopy.splice(index, 1)
    onChange(inputParametersCopy)
  }

  return (
    <SubWrapper
      title="Input parameters"
      canHide={!!inputParameters.length}
      childrenFor={childrenFor}
      onAddContainer={
        <InputParameterPicker
          value={{}}
          onChange={value => onChange([...inputParameters, value])}
          close={() => {}}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      }
    >
      <styled.InputParameters>
        {inputParameters.map((el, i) => (
          <SettingField
            key={JSON.stringify(el)}
            noRadius
            value={el.parameter}
            valueDescription={el.collection ? `${el.type} from ${el.collection}` : el.type}
            actions={
              <>
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} rotation={Rotation.LEFT} onClick={() => onMove(i, false)} />
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} onClick={() => onMove(i, true)} />
                <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />
              </>
            }
            optionsContainer={
              <InputParameterPicker
                value={el}
                onChange={value => onInputParameterChange(i, value)}
                onRemove={() => onRemove(i)}
                close={() => {}}
                screenConfig={screenConfig}
                allLocalVariables={allLocalVariables}
              />
            }
          />
        ))}
      </styled.InputParameters>
    </SubWrapper>
  )
}
