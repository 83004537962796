import React, { useEffect, useRef, useState } from 'react'

import * as styled from './AIModal.styled'

import { AIModal, Button, ButtonVariant, DropDown, Icon, Name } from 'components'
import { useOnClickOutside, useParamsFromUrl } from 'hooks'

interface Props {
  setOuterOpenAI?: React.Dispatch<React.SetStateAction<boolean>>
  vb?: boolean
  cms?: boolean
  screen?: boolean
  input?: boolean
  whiteIcon?: boolean
}

export const AIBtn: React.FC<Props> = ({ setOuterOpenAI, vb, cms, screen, input, whiteIcon }) => {
  const [open, setOpen] = useState(false)
  const { conversationId, intent, setIntentToUrl } = useParamsFromUrl()
  const openAIByDefault = !!(conversationId || intent)
  const [openAI, setOpenAI] = useState(openAIByDefault)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))
  const onlyIcon = input || screen

  useEffect(() => {
    setOpenAI(openAIByDefault)
  }, [openAIByDefault])

  useEffect(() => {
    setOuterOpenAI?.(open || openAI)
  }, [open, openAI])

  const options = [
    {
      icon: <Icon name={Name.PICKERS_GENERATE_SCREENS} />,
      text: 'Generate screens',
      onClick: () => setIntentToUrl('generate-screen'),
    },
    {
      icon: <Icon name={Name.PICKERS_GENERATE_COMPONENTS} />,
      text: 'Generate components',
      onClick: () => setIntentToUrl('generate-component'),
    },
    {
      icon: <Icon name={Name.PICKERS_REFACTOR} />,
      text: 'Refactor',
      onClick: () => setIntentToUrl('refactor'),
    },
    {
      icon: <Icon name={Name.PICKERS_GENERATE_COLLECTION} />,
      text: 'Generate collection',
      onClick: () => setIntentToUrl('generate-collection'),
    },
    {
      icon: <Icon name={Name.PICKERS_IMPORT_DATA} />,
      text: 'Import data',
      onClick: () => setIntentToUrl('import-data'),
    },
    {
      icon: <Icon name={Name.PICKERS_GENERATE_CONTENT} />,
      text: 'Generate content',
      onClick: () => setIntentToUrl('generate-content'),
    },
    {
      icon: <Icon name={Name.PICKERS_QA} />,
      text: 'Explain this / Q&A',
      onClick: () => setIntentToUrl('explain'),
    },
  ].filter((el, i) =>
    vb
      ? [0, 2, 3, 4, 5, 6].includes(i)
      : cms
      ? [3, 4, 5, 6].includes(i)
      : screen
      ? [1, 2, 5, 6].includes(i)
      : input
      ? [5, 6].includes(i)
      : true
  )

  return (
    <>
      <div ref={ref}>
        <DropDown
          top={onlyIcon ? '20px' : '36px'}
          left={onlyIcon ? undefined : '0px'}
          right={onlyIcon ? '0px' : undefined}
          width="220px"
          options={options}
          outerOpenState={open}
          onClick={() => setOpen(open => !open)}
          labelElement={
            onlyIcon ? (
              <styled.AIIcon open={open} whiteIcon={whiteIcon}>
                <Icon name={Name.ADDITIONAL_AI_SMALL} onClick={() => setOpen(open => !open)} />{' '}
              </styled.AIIcon>
            ) : (
              <Button
                variant={ButtonVariant.TOP_PANEL_ACTION}
                leftIcon={<Icon name={Name.ADDITIONAL_AI} />}
                active={open || openAI}
                onClick={() => setOpen(open => !open)}
              >
                AI
              </Button>
            )
          }
        />
      </div>
      {!onlyIcon && <AIModal active={openAI} close={() => setOpenAI(false)} />}
    </>
  )
}
