import { Box } from '@mui/material'
import React, { useContext, useState } from 'react'

import * as styled from './TopPanel.styled'

import { ProjectContext, TopPanelContext } from 'context'
import { useTasks } from 'hooks'
import { StoreManager } from 'pages'
import { DeployBtn, Navigation, ProfileBtn, RunBtn } from './components'

interface Props {
  empty?: boolean
}

export const TopPanel: React.FC<Props> = ({ empty }) => {
  const { backButton, leftButtons, pageLeftButtons, pageCenterButtons, rightButtons, page, isMobile } =
    useContext(TopPanelContext)
  const {
    project: { name, logoUrl },
  } = useContext(ProjectContext)
  const { tasks, runningTasks, failedTasks, setupSteps } = useTasks()
  const [openStoreManager, setOpenStoreManager] = useState(false)

  return empty ? (
    <styled.Wrapper />
  ) : (
    <>
      <styled.Wrapper hasBackButton={!!backButton} isMobile={isMobile}>
        {isMobile ? (
          <>
            <styled.Buttons>
              <styled.Logo>
                <img src={logoUrl} alt="logo" />
              </styled.Logo>
              <styled.Name>{name}</styled.Name>
            </styled.Buttons>
            <styled.Buttons right>
              <RunBtn />
            </styled.Buttons>
          </>
        ) : (
          <>
            {backButton && <styled.BackButton>{backButton}</styled.BackButton>}
            <styled.Buttons>{pageLeftButtons || leftButtons}</styled.Buttons>
            <Box
              display="flex"
              justifyContent="center"
              position="relative"
              ml={!!pageCenterButtons ? '-109px' : !!backButton ? '-49px' : undefined}
            >
              {pageCenterButtons || <Navigation tasks={tasks} runningTasks={runningTasks} failedTasks={failedTasks} />}
            </Box>
            {rightButtons && <styled.Buttons right>{rightButtons}</styled.Buttons>}
            <styled.Buttons right hidden={!!rightButtons}>
              <ProfileBtn />
              <RunBtn />
              <DeployBtn setOpenStoreManager={setOpenStoreManager} tasks={tasks} setupSteps={setupSteps} />
            </styled.Buttons>
          </>
        )}
      </styled.Wrapper>
      {openStoreManager && <StoreManager close={() => setOpenStoreManager(false)} />}
      {page && <styled.Page>{page}</styled.Page>}
    </>
  )
}
