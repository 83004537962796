import React from 'react'
import { ISvg, Svg } from '../Svg'

const Firebase: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14.481 9.22137L12.4798 11.0832L10.6229 7.33763L11.5839 5.18335C11.8338 4.746 12.2236 4.751 12.4673 5.18335L14.481 9.22137Z"
      fill="#FFA000"
    />
    <path d="M10.6219 7.33716L12.4787 11.0828L5 18.0398L10.6219 7.33716Z" fill="#F57F17" />
    <path
      d="M16.6686 6.37524C17.026 6.03161 17.3958 6.14907 17.4902 6.63578L19.437 17.9476L12.9842 21.8213C12.7593 21.9463 12.1595 22 12.1595 22C12.1595 22 11.6134 21.935 11.4054 21.8126L5 18.0401L16.6686 6.37524Z"
      fill="#FFCA28"
    />
    <path
      d="M10.6212 7.33742L5 18.0401L7.50353 2.39845C7.596 1.91111 7.87341 1.86426 8.12083 2.29411L10.6212 7.33742Z"
      fill="#FFA000"
    />
  </Svg>
)

export default Firebase
