import React, { useContext, useEffect, useRef, useState } from 'react'

import * as styled from './Chat.styled'

import { Button, ButtonSize, Icon, Loader, LoaderVariant, Name } from 'components'
import { ProgressContext } from 'context'
import { useParamsFromUrl } from 'hooks'
import { Conversation, ConversationsService, Message, MessageBeforeSend } from 'services/conversations'
import firebase, { auth, firestore, readableError } from 'services/firebase'
import { ProjectsService } from 'services/projects'
import {
  BrandingColorType,
  generateFirestoreId,
  generateHexId,
  getCustomColors,
  onContentEditablePaste,
  Resource,
} from 'utils'
import {
  AppPlans,
  Auth,
  Buttons,
  ChatMessage,
  Colors,
  GenerateCollections,
  GenerateComponents,
  GenerateScreens,
  Icons,
  ImportData,
  RegionsSuggestions,
  Suggestions,
  Templates,
  UserMessage,
  WorkspacesSuggestions,
} from './components'

interface Props {
  onClose: () => void
  templateId: string | null
  setTemplateId: React.Dispatch<React.SetStateAction<string | null>>
  setName: React.Dispatch<React.SetStateAction<string | null>>
  icon: Resource | null
  setIcon: React.Dispatch<React.SetStateAction<Resource | null>>
  color: string | null
  setColor: React.Dispatch<React.SetStateAction<string | null>>
  setId: React.Dispatch<React.SetStateAction<string | null>>
  parentWindowStripeRedirect?: (url: string) => void
  adminMode?: boolean
  conversationIdSelectedByAdmin: string | null
}

export const Chat: React.FC<Props> = ({
  onClose,
  templateId,
  setTemplateId,
  setName,
  icon,
  setIcon,
  color,
  setColor,
  setId,
  parentWindowStripeRedirect,
  adminMode,
  conversationIdSelectedByAdmin,
}) => {
  const { toast } = useContext(ProgressContext)
  const { clearParams, setConversationIdToUrl, ...params } = useParamsFromUrl()
  const conversationId = adminMode ? conversationIdSelectedByAdmin : params.conversationId
  const messagesBox = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLDivElement>(null)
  const [sending, setSending] = useState(false)
  const [stopping, setStopping] = useState(false)
  const [messageText, setMessageText] = useState<string | null>(null)
  const [onClickActionButton, setOnClickActionButton] = useState<(() => void) | null>(null)
  const [conversation, setConversation] = useState<Conversation | null>(null)
  const [messages, setMessages] = useState<Message[]>([])
  const [messageToEdit, setMessageToEdit] = useState<Message | null>(null)
  const prevMessage = messages[messages.findIndex(el => el.id === messageToEdit?.id) + 1] as Message | undefined
  const nextMessage = messages[messages.findIndex(el => el.id === messageToEdit?.id) - 1] as Message | undefined
  const generating = prevMessage?.status === 'generating'
  const pending = prevMessage?.status === 'pending'
  const suggestions = prevMessage?.keyboard?.suggestions
  const showActionButton = prevMessage?.keyboard?.showActionButton
  const blockTyping = prevMessage?.keyboard?.blockTyping
  const placeholder = prevMessage?.keyboard?.placeholder
  const payload = prevMessage?.keyboard?.payload
  const widget = prevMessage?.keyboard?.widget
  const messageButtons = prevMessage?.buttons
  const context = conversation?.context
  const [user, setUser] = useState<firebase.User | null>(null)
  const [anonymousUser, setAnonymousUser] = useState<firebase.User | null>(null)
  const needMigrate = !adminMode && !!(user && anonymousUser && user.uid !== anonymousUser.uid)
  const sendMessageDisabled = adminMode || needMigrate || sending || generating || pending
  const submitDisabled = sendMessageDisabled || blockTyping || !messageText

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user)
      if (user?.isAnonymous) {
        setAnonymousUser(user)
      }
      if (!user) {
        auth.signInAnonymously().catch(err => toast(readableError(err.message)))
      }
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (user) {
      if (conversationId) {
        if (needMigrate) {
          anonymousUser
            .getIdToken()
            .then(anonymousUserIdToken => ConversationsService.migrate(conversationId, anonymousUserIdToken))
            .then(() => setAnonymousUser(null))
            .catch(err => toast(err))
        } else {
          const conversationUnsubscribe = firestore
            .collection('conversations')
            .doc(conversationId)
            .onSnapshot({
              next: res => {
                const resData = res.data()
                if (resData) {
                  const conversation = { id: res.id, ...resData } as Conversation
                  setConversation(conversation)
                  if (!adminMode && !conversation.geocoding) {
                    fetch('https://us-central1-codeplatform-dev.cloudfunctions.net/country')
                      .then(res => res.json())
                      .then(res => firestore.collection('conversations').doc(conversationId).update({ geocoding: res }))
                      .catch(err => toast(err))
                  }
                }
              },
              error: err => toast(err),
            })
          const messagesUnsubscribe = firestore
            .collection(`conversations/${conversationId}/messages`)
            .orderBy('createdAt', 'desc')
            .onSnapshot({
              next: res => setMessages(res.docs.map(el => ({ id: el.id, ...el.data() })) as Message[]),
              error: err => toast(err),
            })
          return () => {
            setConversation(null)
            setMessages([])
            conversationUnsubscribe()
            messagesUnsubscribe()
          }
        }
      } else if (!adminMode) {
        postConversation()
      }
    }
  }, [user, conversationId, needMigrate])

  useEffect(() => {
    setTemplateId(context?.templateId || null)
    setName(context?.name || null)
    setIcon(context?.icon || null)
    setColor(context?.color || null)
  }, [context?.templateId, context?.name, context?.icon?.id, context?.color])

  useEffect(() => {
    setId(conversation?.projectId || null)
  }, [conversation?.projectId])

  useEffect(() => {
    if (
      !adminMode &&
      conversation &&
      context &&
      context.templateId &&
      context.name &&
      context.projectIdSuggestions &&
      context.icon &&
      context.color &&
      context.regionId &&
      context.workspaceId &&
      context.checkoutSessionId &&
      !conversation.projectId
    ) {
      const customColors = getCustomColors(context.color, BrandingColorType.primary)
      const postProjectWithIdByIndex = (idIndex: number, customId?: string) =>
        ProjectsService.postProject(
          context.templateId,
          context.name,
          customId || context.projectIdSuggestions[idIndex],
          context.icon,
          customColors,
          context.regionId,
          context.workspaceId,
          context.checkoutSessionId
        )
          .then(res => {
            firestore
              .collection('conversations')
              .doc(conversation.id)
              .update({ projectId: res.data.id })
              .catch(err => toast(err))
          })
          .catch(err => {
            const badId =
              err.statusCode === 409 ||
              (err.statusCode === 422 && err.errors.length === 1 && err.errors[0].field === 'id')
            if (badId && idIndex < context.projectIdSuggestions.length - 1) {
              postProjectWithIdByIndex(idIndex + 1)
            } else if (badId) {
              postProjectWithIdByIndex(0, `cp-${generateHexId()}`)
            } else {
              toast(err)
            }
          })
      postProjectWithIdByIndex(0)
    }
  }, [
    context?.templateId,
    context?.name,
    context?.projectIdSuggestions,
    context?.icon?.id,
    context?.color,
    context?.regionId,
    context?.workspaceId,
    context?.checkoutSessionId,
    conversation?.projectId,
  ])

  useEffect(() => {
    if (messageToEdit && messageToEdit.body && messageToEdit.format === 'text') {
      setText(messageToEdit.body)
      return () => clear()
    }
  }, [messageToEdit])

  const postConversation = () => {
    const {
      intent,
      templateName,
      templateId,
      workspaceId,
      planPriceId,
      page,
      codeplatform_ga_session_id,
      userAgent,
      initialMessage,
    } = params
    ConversationsService.postConversation({
      intent,
      templateName,
      templateId,
      workspaceId,
      planPriceId,
      page,
      codeplatform_ga_session_id,
      userAgent,
      initialMessage,
    })
      .then(res => setConversationIdToUrl(res.data.id))
      .catch(err => toast(err))
  }

  const sendMessage = (messageBeforeSend: MessageBeforeSend) => {
    if (conversation && user && !sendMessageDisabled) {
      const message: Message = {
        id: generateFirestoreId(),
        authorId: user.uid,
        status: 'pending',
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        ...messageToEdit,
        ...messageBeforeSend,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
      if (!messageToEdit && messagesBox.current) {
        messagesBox.current.scrollTo(messagesBox.current.scrollWidth, messagesBox.current.scrollHeight)
      }
      setSending(true)
      firestore
        .collection(`conversations/${conversation.id}/messages`)
        .doc(message.id)
        .set(message)
        .then(() => {
          clear()
          if (nextMessage) {
            firestore
              .collection(`conversations/${conversation.id}/messages`)
              .doc(nextMessage.id)
              .update({ cmd: 'regenerate' })
              .catch(err => toast(err))
          }
        })
        .catch(err => toast(err))
        .finally(() => setSending(false))
    }
  }

  const stopGenerating = () => {
    if (conversation && prevMessage) {
      setStopping(true)
      firestore
        .collection(`conversations/${conversation.id}/messages`)
        .doc(prevMessage.id)
        .update({ cmd: 'stop' })
        .catch(err => toast(err))
        .finally(() => setStopping(false))
    }
  }

  const changePayload = (payload: any) => {
    if (conversation && prevMessage) {
      firestore
        .collection(`conversations/${conversation.id}/messages`)
        .doc(prevMessage.id)
        .update({ keyboard: { ...prevMessage.keyboard, payload } })
        .catch(err => toast(err))
    }
  }

  const use = onClickActionButton
    ? () => {
        onClickActionButton()
        if (conversation && prevMessage) {
          firestore
            .collection(`conversations/${conversation.id}/messages`)
            .doc(prevMessage.id)
            .update({ buttons: prevMessage.buttons?.filter(el => el.action !== 'use') })
            .catch(err => toast(err))
        }
      }
    : undefined

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => e.code === 'Enter' && !e.shiftKey && onSubmit(e)

  const onSubmit = (e: React.FormEvent<HTMLFormElement> | React.KeyboardEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!submitDisabled) {
      sendMessage({ body: messageText, format: 'text' })
    }
  }

  const setText = (text: string) => {
    if (inputRef.current) {
      inputRef.current.innerText = text
      setMessageText(text)
    }
  }

  const clear = () => {
    setText('')
    setOnClickActionButton(null)
    setMessageToEdit(null)
  }

  const widgetElement =
    widget === 'templates' ? (
      <Templates
        payload={payload}
        sendMessage={sendMessage}
        setOnClickActionButton={setOnClickActionButton}
        setTemplateId={setTemplateId}
        templateId={context?.templateId || templateId}
      />
    ) : widget === 'icons' ? (
      <Icons
        payload={payload}
        changePayload={changePayload}
        sendMessage={sendMessage}
        setOnClickActionButton={setOnClickActionButton}
        setIcon={setIcon}
        icon={context?.icon || icon}
      />
    ) : widget === 'colors' ? (
      <Colors
        payload={payload}
        changePayload={changePayload}
        sendMessage={sendMessage}
        setOnClickActionButton={setOnClickActionButton}
        setColor={setColor}
        color={context?.color || color}
      />
    ) : widget === 'regions' ? (
      <RegionsSuggestions payload={payload} sendMessage={sendMessage} />
    ) : widget === 'auth' ? (
      <Auth sendMessage={sendMessage} user={user} adminMode={adminMode} />
    ) : widget === 'workspaces' ? (
      <WorkspacesSuggestions sendMessage={sendMessage} />
    ) : widget === 'app-plans' ? (
      <AppPlans
        parentWindowStripeRedirect={parentWindowStripeRedirect}
        sendMessage={sendMessage}
        setOnClickActionButton={setOnClickActionButton}
        planPriceId={context?.planPriceId}
        workspaceId={context?.workspaceId}
      />
    ) : adminMode ? (
      <></>
    ) : widget === 'generate-screens' ? (
      <GenerateScreens payload={payload} />
    ) : widget === 'generate-components' ? (
      <GenerateComponents payload={payload} />
    ) : widget === 'refactor' ? (
      <></>
    ) : widget === 'generate-collection' ? (
      <GenerateCollections onClose={onClose} setOnClickActionButton={setOnClickActionButton} />
    ) : widget === 'import-data' ? (
      <ImportData />
    ) : widget === 'generate-content' ? (
      <></>
    ) : undefined

  const buttons = messageButtons?.length ? (
    <Buttons messageButtons={messageButtons} useDisabled={!use || sendMessageDisabled} use={use} />
  ) : undefined

  return conversation && user ? (
    <styled.Chat>
      <styled.MessagesBox ref={messagesBox}>
        {messages.map((el, i) =>
          el.authorId === user.uid ? (
            <UserMessage
              key={el.id}
              message={el}
              editMode={messageToEdit?.id === el.id}
              setEditMode={() => setMessageToEdit(el)}
            />
          ) : (
            <ChatMessage
              key={el.id + i}
              message={el}
              widgetElement={prevMessage?.id === el.id ? widgetElement : undefined}
              buttons={prevMessage?.id === el.id ? buttons : undefined}
            />
          )
        )}
      </styled.MessagesBox>
      <styled.InputBox>
        {messageToEdit && (
          <styled.EditMode>
            Edit message
            <Icon name={Name.PICKERS_CLOSE} onClick={() => setMessageToEdit(null)} />
          </styled.EditMode>
        )}
        {suggestions && <Suggestions suggestions={suggestions} sendMessage={sendMessage} />}
        {showActionButton ? (
          <Button
            type="button"
            size={ButtonSize.DEFAULT}
            disabled={!onClickActionButton || sendMessageDisabled}
            onClick={onClickActionButton || undefined}
          >
            {placeholder || 'Select an option above to proceed'}
          </Button>
        ) : (
          <styled.Form blockTyping={blockTyping} onSubmit={onSubmit}>
            <styled.Input>
              <div
                contentEditable={!blockTyping}
                onKeyDown={onKeyDown}
                onPaste={onContentEditablePaste}
                onInput={e => setMessageText((e.target as HTMLDivElement).innerText.trim())}
                placeholder={placeholder || 'Enter a prompt here'}
                ref={inputRef}
              />
              <styled.SendMessageButton
                type={generating ? 'button' : 'submit'}
                generating={generating}
                disabled={generating ? stopping : submitDisabled}
                onClick={generating ? () => stopGenerating() : undefined}
              >
                <Icon name={Name.ADDITIONAL_SEND_MESSAGE} width={24} height={24} />
              </styled.SendMessageButton>
            </styled.Input>
          </styled.Form>
        )}
      </styled.InputBox>
    </styled.Chat>
  ) : (
    <Loader variant={LoaderVariant.PACMAN} />
  )
}
