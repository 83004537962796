import { Box } from '@mui/material'
import React, { useRef, useState } from 'react'

import { CustomPicker } from 'components'
import { useOnClickOutside } from 'hooks'
import { IBackground, IDevice, IImage, IText } from 'pages/ScreenshotsStudio/utils'
import { AddComponents } from './AddComponents'
import { AddScreen } from './AddScreen'

interface Props {
  addToState: (state: IImage[] | IDevice[]) => void
  activeElement: IBackground | IImage | IDevice | IText | null
  close: () => void
  getLastScreen: () => { lastScreenX: number; lastScreenConfig: any[] }
}

export const AddMedia: React.FC<Props> = ({ addToState, activeElement, close, getLastScreen }) => {
  const [search, setSearch] = useState('')
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, close)

  return (
    <Box zIndex="10" width="366px" position="fixed" top="50px" left="70px" ref={ref}>
      <CustomPicker
        title="Screens"
        close={close}
        setSearch={setSearch}
        optionsContainer={
          <AddScreen addToState={addToState} close={close} getLastScreen={getLastScreen} search={search} />
        }
        componentsContainer={
          <AddComponents addToState={addToState} activeElement={activeElement} close={close} search={search} />
        }
      />
    </Box>
  )
}
