import React from 'react'
import { ISvg, Svg } from '../Svg'

const GoogleCloud: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M14.4554 8.86933L15.0319 8.87884L16.5984 7.36407L16.6744 6.72227C15.4318 5.65187 13.7914 5 11.9987 5C8.75322 5 6.01394 7.13536 5.20312 10.0278C5.37412 9.91253 5.73973 9.99901 5.73973 9.99901L8.86994 9.50134C8.86994 9.50134 9.03081 9.2438 9.11237 9.25658C9.47838 8.86774 9.92438 8.55713 10.4218 8.34466C10.9191 8.13219 11.4569 8.02254 12.0007 8.02274C12.9288 8.02464 13.7838 8.34174 14.4554 8.86633V8.86933Z"
      fill="#EA4335"
    />
    <path
      d="M18.7965 10.034C18.4328 8.73676 17.6833 7.5913 16.6717 6.72241L14.4536 8.86729C15.3423 9.56077 15.9124 10.6219 15.9124 11.8098V12.1884C16.9915 12.1884 17.8681 13.0379 17.8681 14.0795C17.8681 15.123 16.9895 15.9707 15.9124 15.9707H12.0009L11.6094 16.3514V18.6211L12.0009 18.9978H15.9124C17.2607 18.996 18.5533 18.4772 19.5067 17.5553C20.4602 16.6334 20.9967 15.3836 20.9986 14.0798C20.9966 12.4059 20.1267 10.9249 18.7965 10.034Z"
      fill="#4285F4"
    />
    <path
      d="M8.08874 18.9997H11.998V15.9701H8.08874C7.811 15.9702 7.53649 15.9125 7.28383 15.801L6.71938 15.9682L5.15287 17.483L5.01562 17.9943C5.89841 18.6455 6.97812 18.998 8.08846 18.9975L8.08874 18.9997Z"
      fill="#34A853"
    />
    <path
      d="M8.08623 9.16345C6.7379 9.16525 5.44532 9.68396 4.49188 10.6059C3.53843 11.5278 3.00193 12.7776 3 14.0814C3 15.6786 3.79113 17.0987 5.01537 17.9983L7.28329 15.8053C6.94001 15.6556 6.64867 15.4134 6.4442 15.1076C6.23972 14.8019 6.1308 14.4455 6.13049 14.0814C6.13049 13.0379 7.00908 12.1903 8.08623 12.1903C8.87933 12.1903 9.56106 12.6526 9.8693 13.3053L12.1372 11.1123C11.2072 9.92845 9.73824 9.16345 8.08651 9.16345H8.08623Z"
      fill="#FBBC05"
    />
  </Svg>
)

export default GoogleCloud
