import React from 'react'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { Integration, IntegrationGooglePlay, IntegrationStatus } from 'hooks'
import { ConnectGooglePlayButton } from './ConnectGooglePlayButton'
import { GooglePlayLink } from './GooglePlayLink'

interface Props {
  googleplayIntegration?: Integration
}

export const GooglePlayButton: React.FC<Props> = ({ googleplayIntegration }) =>
  googleplayIntegration?.status === IntegrationStatus.READY ? (
    <GooglePlayLink>
      <Button
        variant={ButtonVariant.LINK}
        color={ButtonColor.SECONDARY}
        leftIcon={<Icon name={Name.SIZE_24_PLAY_STORE} />}
      >
        {(googleplayIntegration?.data as IntegrationGooglePlay | undefined)?.teamName}
      </Button>
    </GooglePlayLink>
  ) : (
    <ConnectGooglePlayButton disabled={!googleplayIntegration} />
  )
