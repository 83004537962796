import React, { useContext, useEffect, useState } from 'react'

import * as styled from './AddMedia.styled'

import { Text, Type } from 'components'
import { ProjectContext } from 'context'
import { useScreenshotFromVB } from 'hooks'
import {
  Component,
  addBackground,
  addDevice,
  addImage,
  addText,
  backgroundWidth,
  containerHeight,
  marginWidth,
} from 'pages/ScreenshotsStudio/utils'
import { BrandingColorType, generateFirestoreId, includesSearch } from 'utils'
import { getScreensConfigs } from './configs'

interface Props {
  addToState: (state: any[]) => void
  close: () => void
  search: string
  getLastScreen: () => { lastScreenX: number; lastScreenConfig: any[] }
}

export const AddScreen: React.FC<Props> = ({ addToState, close, search, getLastScreen }) => {
  const [values, setValues] = useState<{ name: string; config: any[]; preview: string }[]>([])
  const {
    state: {
      branding: { colorStyles },
    },
    theme,
  } = useContext(ProjectContext)
  const getScreenshotFromVB = useScreenshotFromVB()

  const { lastScreenX, lastScreenConfig } = getLastScreen()
  const lastScreenConfigModified = lastScreenConfig.map(el => {
    if (el.hasOwnProperty('x')) {
      return { ...el, id: generateFirestoreId(), x: el.x + backgroundWidth + marginWidth }
    }
    return { ...el, id: generateFirestoreId() }
  })

  const getPreviewX = (x: number) => x - lastScreenX - backgroundWidth - marginWidth

  const getScreenshotPreview = async (config: any) => {
    const elements: SVGElement[] = []

    for (const el of config) {
      switch (el.type) {
        case Component.BACKGROUND:
          const backgroundContainer = await addBackground({
            ...el,
            background: el.colorName ? colorStyles[el.colorName as BrandingColorType][theme] : el.background,
          })
          elements.push(backgroundContainer)
          break
        case Component.TEXT:
          const textContainer = addText({
            ...el,
            x: getPreviewX(el.x),
            color: el.colorName ? colorStyles[el.colorName as BrandingColorType][theme] : el.color,
          })
          elements.push(textContainer)
          break
        case Component.IMAGE:
          const imageContainer = await addImage({ ...el, x: getPreviewX(el.x) })
          elements.push(imageContainer)
          break
        case Component.DEVICE:
          let image = ''
          if (el.screenshotName) {
            await getScreenshotFromVB(el.screenshotName).then(res => (image = res))
          }
          const deviceContainer = await addDevice({
            ...el,
            x: getPreviewX(el.x),
            color: el.colorName ? colorStyles[el.colorName as BrandingColorType][theme] : el.color,
            image: image || el.image,
          })
          elements.push(deviceContainer)
          break
      }
    }
    return elements.map(el => el.outerHTML).join('')
  }

  const getScreensAsync = async () => {
    const screens = getScreensConfigs(lastScreenX, lastScreenConfigModified)
    const values: { name: string; config: any[]; preview: string }[] = []
    for (const screen of screens) {
      values.push({ ...screen, preview: await getScreenshotPreview(screen.config) })
    }
    setValues(values)
  }

  useEffect(() => {
    getScreensAsync()
  }, [])

  const onClick = (config: any[]) => {
    addToState(config)
    close()
  }

  return (
    <styled.Screens>
      {!values.length && (
        <Text type={Type.BODY} fontSize="14px" textAlign="center" width="100%">
          Loading...
        </Text>
      )}
      {values
        .filter(screen => includesSearch(screen.name, search))
        .map(screen => (
          <styled.Screen key={screen.name} onClick={() => onClick(screen.config)}>
            <svg
              width="72"
              height="154"
              viewBox={`0 0 ${backgroundWidth} ${containerHeight}`}
              dangerouslySetInnerHTML={{ __html: screen.preview }}
            />
            <span>{screen.name}</span>
          </styled.Screen>
        ))}
    </styled.Screens>
  )
}
