import React from 'react'

import * as styled from '../../../Settings.styled'
import { Connected } from './index.styled'

import { AppStoreLink, ConnectAppStoreButton, Icon, Name, Unlink } from 'components'
import { Integration, IntegrationAppstore, IntegrationStatus } from 'hooks'

interface Props {
  integration: Integration
}

export const AppStore: React.FC<Props> = ({ integration }) => (
  <styled.Block>
    <styled.Title>App Store Connect Integration</styled.Title>
    <styled.Paragraph mb="25px">Connect to App Store to automate the release process.</styled.Paragraph>
    {integration.status === IntegrationStatus.READY ? (
      <>
        <styled.Paragraph mb="10px" grey1>
          Connected to:
        </styled.Paragraph>
        <Connected>
          <AppStoreLink>
            <Icon name={Name.SIZE_24_APP_STORE} />
            {(integration.data as IntegrationAppstore | undefined)?.teamName}
          </AppStoreLink>
          <Unlink integration={integration} />
        </Connected>
      </>
    ) : (
      <ConnectAppStoreButton />
    )}
  </styled.Block>
)
