import { Dispatch, SetStateAction } from 'react'

import { GetVariableValue } from 'hooks'
import {
  BrandingColorStyles,
  BrandingColorType,
  BrandingThemeType,
  Resource,
  TabBarItem,
  screenComponentClassName,
  setInnerModeEvent,
  urlToBase64,
} from 'utils'
import { BarComponentType, getMediaResource, getTextValue } from '../utils'

export const createScreenTabBar = (
  id: string,
  tabBarId: string,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  screen: HTMLDivElement,
  height: number,
  onClickComponent?: () => void,
  setHoveredId?: Dispatch<SetStateAction<string>>
) => {
  const screenTabBar = document.createElement('div')
  const fromVB = !!(onClickComponent && setHoveredId)
  if (fromVB) {
    screenTabBar.ondblclick = e => {
      e.stopPropagation()
      document.dispatchEvent(new Event(setInnerModeEvent))
    }
    screenTabBar.onmousedown = e => {
      e.stopPropagation()
      onClickComponent()
    }
    screenTabBar.onmouseover = e => {
      e.stopPropagation()
      setHoveredId(tabBarId)
    }
    screenTabBar.onmouseleave = e => {
      e.stopPropagation()
      setHoveredId('')
    }
  }
  screenTabBar.id = id
  screenTabBar.className = screenComponentClassName
  screenTabBar.style.position = 'fixed'
  screenTabBar.style.left = '0'
  screenTabBar.style.bottom = '0'
  screenTabBar.style.zIndex = '2'
  screenTabBar.style.background = colorStyles.background[theme]
  screenTabBar.style.width = '100%'
  screenTabBar.style.height = `${height}px`
  screenTabBar.style.padding = '5px 0'
  screenTabBar.style.boxSizing = 'border-box'
  screenTabBar.style.display = 'flex'
  screenTabBar.style.justifyContent = 'space-around'
  screenTabBar.style.alignItems = 'flex-start'
  screenTabBar.style.backgroundBlendMode = 'hard-light'
  screenTabBar.style.borderWidth = '0'
  screenTabBar.style.borderStyle = 'solid'
  screenTabBar.style.borderColor = colorStyles.surfaceVariant[theme]
  screenTabBar.style.borderTopWidth = '1px'
  screen.appendChild(screenTabBar)
  return screenTabBar
}

export const profieTabs = ['LoggedIn Profile', 'No Authorization Profile', 'Unauthorized Profile']

export const setTabs = async (
  screenName: string,
  tabs: TabBarItem[],
  screenTabBar: HTMLDivElement,
  fontFamily: string,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  getVariableValue: GetVariableValue,
  language: string,
  onClickComponent?: (tabScreen: string) => void,
  setHoveredId?: Dispatch<SetStateAction<string>>
  // setMovableTabComponent?: (config: TabBarItem) => void
) => {
  const isUnauthenticated = screenName === profieTabs[1]
  const isAnonymous = screenName === profieTabs[2]
  const isAuthorized = !isUnauthenticated && !isAnonymous
  for (const el of tabs.filter(
    el =>
      el.visible.constant ||
      (el.visible.source?.variableName === 'isAnonymous' && isAnonymous) ||
      (el.visible.source?.variableName === 'isAuthorized' && isAuthorized) ||
      (el.visible.source?.variableName === 'isUnauthenticated' && isUnauthenticated)
  )) {
    const fromVB = !!(
      onClickComponent &&
      //  && setMovableTabComponent
      setHoveredId
    )
    const { image, title, screen } = el
    const isActive = screen === screenName
    const tab = document.createElement('div')
    if (fromVB) {
      tab.ondblclick = e => {
        e.stopPropagation()
      }
      // let timer: NodeJS.Timeout | null = null
      tab.onmousedown = e => {
        e.stopPropagation()
        onClickComponent(screen)
        // timer = setTimeout(() => setMovableTabComponent(el), 400)
      }
      // tab.onmouseup = () => {
      //   if (timer) {
      //     clearInterval(timer)
      //   }
      // }
      tab.onmouseover = e => {
        e.stopPropagation()
        setHoveredId(screen)
      }
      tab.onmouseleave = e => {
        e.stopPropagation()
        setHoveredId('')
      }
    }
    tab.id = `${BarComponentType.tab}.${screen}.${screen}`
    tab.className = screenComponentClassName
    tab.style.position = 'relative'
    tab.style.display = 'flex'
    tab.style.flexDirection = 'column'
    tab.style.alignItems = 'center'
    tab.style.gap = '4px'
    tab.style.width = `calc(100% / ${tabs.length})`

    const width = 24
    const imageElement = document.createElement('img')
    imageElement.style.pointerEvents = 'none'
    imageElement.style.width = `${width}px`
    imageElement.style.height = `${width}px`
    imageElement.style.maxHeight = '100%'
    imageElement.style.objectFit = 'contain'
    const { url } = await getMediaResource(
      resources,
      getVariableValue,
      false,
      width * 3,
      image,
      { constant: isActive ? `@${BrandingColorType.primary}` : `@${BrandingColorType.neutral}` },
      theme,
      colorStyles
    )
    if (fromVB) {
      imageElement.setAttribute('src', url)
    } else {
      await urlToBase64(url, res => imageElement.setAttribute('src', res))
    }
    tab.appendChild(imageElement)

    const textElement = document.createElement('div')
    textElement.style.pointerEvents = 'none'
    textElement.style.fontFamily = fontFamily
    textElement.style.lineHeight = '12px'
    textElement.style.fontSize = '10px'
    textElement.style.textAlign = 'center'
    textElement.style.color = isActive ? colorStyles.primary[theme] : colorStyles.neutral[theme]
    textElement.innerHTML = await getTextValue(getVariableValue, language, title)
    tab.appendChild(textElement)

    screenTabBar.appendChild(tab)
  }
}
