import React, { useEffect, useState } from 'react'

import { Container, MaxWidthContainer } from '../index.styled'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { updateUserInfo, updateUserInfoEvent, useLastUpdate } from 'hooks'
import firebase, { auth } from 'services/firebase'
import { LoginMethods, MultiFactor, Profile } from './sections'

export const Account: React.FC = () => {
  const [user, setUser] = useState<firebase.User | null>(null)
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  JSON.stringify(lastUpdate)

  useLastUpdate(updateUserInfoEvent, () => {
    setUser(auth.currentUser)
    setLastUpdate(+new Date())
  })
  useEffect(() => {
    updateUserInfo()
  }, [])

  return (
    <Container>
      <MaxWidthContainer>
        <Text type={Type.TITLE}>Account settings</Text>
        <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
          Workspace members can create, edit, and delete projects. They can also access project data and invite other
          members.
        </Text>
        {user && (
          <CustomSectionsWrapper>
            <Profile user={user} />
            <LoginMethods user={user} />
            <MultiFactor user={user} />
          </CustomSectionsWrapper>
        )}
      </MaxWidthContainer>
    </Container>
  )
}
