import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import {
  CustomSection,
  CustomSectionSubTitle,
  CustomSectionTitle,
  Icon,
  Name,
  Switch,
  SwitchVariant,
  Text,
  Type,
} from 'components'

const providers = [
  {
    id: 0,
    title: 'Email',
    subTitle: 'Users get an email with a PIN to sign-in',
    iconName: Name.SECOND_ORDER_AUTHENTICATION_EMAIL,
    checked: false,
  },
  {
    id: 1,
    title: 'Allow Sign-in with Google',
    subTitle: 'Users must have entries in the user profile table',
    iconName: Name.SECOND_ORDER_AUTHENTICATION_GOOGLE,
    checked: true,
  },
  {
    id: 2,
    title: 'Allow Sign-in with Apple',
    subTitle: 'Users get an email with a PIN to sign-in',
    iconName: Name.SECOND_ORDER_AUTHENTICATION_APPLE,
    checked: true,
  },
  {
    id: 3,
    title: 'Allow Sign-in with Phone',
    subTitle: 'Users must have entries in the user profile table',
    iconName: Name.SECOND_ORDER_AUTHENTICATION_PHONE,
    checked: true,
  },
]

export const Providers: React.FC = () => {
  const themeContext = useContext(ThemeContext)

  return (
    <CustomSection style={{ padding: '25px' }}>
      <CustomSectionTitle mb="10px">Authentication Providers</CustomSectionTitle>
      <CustomSectionSubTitle>
        Accept payments in your app with Stripe integration. This also enables credit card, Google Pay, and Apple Pay.
        Setup is easy and only requires a few extra steps to get started.
      </CustomSectionSubTitle>
      <CustomSection style={{ padding: 0, margin: '20px 0 0 0' }}>
        {providers.map((el, i) => (
          <Box
            key={el.id}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            padding="20px 24px"
            borderTop={i !== 0 ? `1px solid ${themeContext.colors.darkBlue_100}` : ''}
          >
            <Box display="flex" columnGap="8px">
              <Icon name={el.iconName} />
              <Box>
                <Text type={Type.H3}>{el.title}</Text>
                <Text type={Type.BODY} as="p" color={themeContext.colors.grey1}>
                  {el.subTitle}
                </Text>
              </Box>
            </Box>
            <Switch checked={el.checked} onChange={() => {}} variant={SwitchVariant.BIG} />
          </Box>
        ))}
      </CustomSection>
    </CustomSection>
  )
}
