import styled from 'styled-components'

export const RichTextWrapper = styled.div``

export const RefFieldsWrapper = styled.div`
  gap: 6px;
  width: 100%;
  justify-content: flex-end;
  & > label {
    margin: 0 !important;
    width: auto;
    & > span {
      display: none;
    }
    & > div {
      height: auto;
      padding: 6px;
      & > span > div {
        right: -8px;
      }
    }
  }
`
