import React from 'react'
import { ISvg, Svg } from '../Svg'

const Release: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.19398 7.60885C8.97503 8.3899 10.2414 8.3899 11.0224 7.60885C11.8035 6.8278 11.8035 5.56147 11.0224 4.78042C10.2414 3.99938 8.97503 3.99938 8.19398 4.78042C7.41293 5.56147 7.41293 6.8278 8.19398 7.60885ZM10.3153 6.90174C9.92478 7.29227 9.29161 7.29227 8.90109 6.90174C8.51056 6.51122 8.51056 5.87806 8.90109 5.48753C9.29161 5.09701 9.92478 5.09701 10.3153 5.48753C10.7058 5.87806 10.7058 6.51122 10.3153 6.90174Z"
      fill="#212133"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16724 2.50733C7.52421 2.15037 8.00296 1.9416 8.50743 1.92292L11.9342 1.796C13.0962 1.75296 14.0499 2.7066 14.0068 3.86865L13.8799 7.2954C13.8612 7.79988 13.6525 8.27863 13.2955 8.63559L7.72259 14.2085C6.94154 14.9896 5.67521 14.9896 4.89416 14.2085L1.59433 10.9087C0.813283 10.1276 0.813283 8.86129 1.59433 8.08024L7.16724 2.50733ZM12.5884 7.92848L7.01548 13.5014C6.62496 13.8919 5.99179 13.8919 5.60127 13.5014L2.30144 10.2016C1.91091 9.81104 1.91091 9.17787 2.30144 8.78735L7.87435 3.21444C8.05283 3.03596 8.29221 2.93157 8.54444 2.92223L11.9712 2.79531C12.5522 2.77379 13.029 3.25061 13.0075 3.83164L12.8806 7.25839C12.8713 7.51063 12.7669 7.75 12.5884 7.92848Z"
      fill="#212133"
    />
  </Svg>
)
export default Release
