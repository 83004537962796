import React, { useContext } from 'react'

import { ProjectContext } from 'context'
import { Config, Screen, detectEmptyValue, getAllLocalVariables } from 'utils'
import { BrandingColorInput } from './BrandingColorInput'

interface Props {
  big?: boolean
  label?: string
  subLabel?: string
}

export const LaunchScreenBackgroundInput: React.FC<Props> = ({ big, label, subLabel }) => {
  const {
    state: { screens },
    setState,
  } = useContext(ProjectContext)
  const screenConfig = screens.find(el => el.screenName === 'Launch Screen') as Screen
  const value = screenConfig.backgroundColor.constant
  const source = screenConfig.backgroundColor.source
  const allLocalVariables = getAllLocalVariables(screenConfig, screenConfig)

  const onScreenPropertiesChange = (props: { propName: keyof Screen; value: any }[]) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      const screen = stateCopy.screens.find(el => el.screenName === 'Launch Screen')
      if (screen) {
        props.forEach(({ propName, value }) => {
          if (detectEmptyValue(value)) {
            delete screen[propName]
          } else {
            // @ts-ignore
            screen[propName] = value
          }
        })
      }
      return stateCopy
    })
  }

  return (
    <BrandingColorInput
      big={big}
      label={label}
      subLabel={subLabel}
      value={value}
      onChange={value => onScreenPropertiesChange([{ propName: 'backgroundColor', value: { constant: value } }])}
      source={source}
      onSourceChange={value => onScreenPropertiesChange([{ propName: 'backgroundColor', value: { source: value } }])}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
      withoutDelete
    />
  )
}
