import { Box } from '@mui/material'
import { memo, useEffect, useState } from 'react'

import { CMSCollectionRecord, ResourceTypes } from 'services/cms'
import { moveTopBottomInList } from 'utils'
import { ReferenceItem } from './ReferenceItem'

interface Props {
  values: CMSCollectionRecord[] | string[]
  onChange: (val: CMSCollectionRecord[] | string[]) => void
  remove: (val: CMSCollectionRecord | string) => void
  open: (id: string) => void
  resourceType?: ResourceTypes
}

export const ReferenceItems: React.FC<Props> = memo(({ values, onChange, remove, open, resourceType }) => {
  const [copy, setCopy] = useState(values)

  useEffect(() => {
    setCopy(values)
  }, [JSON.stringify(values)])

  const onMovableMouseDown = (e: any, item: any) => {
    const node = e.target.parentNode
    moveTopBottomInList(node, 10, item, copy, setCopy, onChange)
  }

  return copy.length ? (
    <Box width="100%" mb="20px">
      {copy.map(el => (
        <ReferenceItem
          key={resourceType ? (el as CMSCollectionRecord).id : el}
          value={el}
          remove={() => remove(el)}
          open={open}
          onMovableMouseDown={onMovableMouseDown}
          resourceType={resourceType}
        />
      ))}
    </Box>
  ) : (
    <></>
  )
})
