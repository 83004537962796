import React from 'react'
import { ISvg, Svg } from '../Svg'

const Landing: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.5418 14.1818C15.6364 13.4545 15.6364 12.7273 15.6364 12C15.6364 11.2727 15.6 10.5455 15.5418 9.81818H19.6873C20.1042 11.2428 20.1042 12.7572 19.6873 14.1818H15.5418ZM13.9127 8.36364C13.4545 5.52 12.5455 4 12 4C11.4545 4 10.5455 5.52 10.0873 8.36364H13.9127ZM10.0873 15.6364C10.5382 18.48 11.4545 20 12 20C12.5455 20 13.4545 18.48 13.9127 15.6364H10.0873ZM14.0873 9.81818H9.91273C9.85455 10.4945 9.81818 11.2145 9.81818 12C9.81818 12.7855 9.85455 13.5055 9.91273 14.1818H14.0873C14.1455 13.5055 14.1818 12.7855 14.1818 12C14.1818 11.2145 14.1818 10.4945 14.0873 9.81818ZM8.36364 12C8.36364 11.2727 8.36364 10.5455 8.45818 9.81818H4.31273C3.89576 11.2428 3.89576 12.7572 4.31273 14.1818H8.45818C8.4 13.4545 8.36364 12.7273 8.36364 12ZM8.62546 15.6364H4.88C5.38154 16.6132 6.0788 17.4763 6.92837 18.172C7.77794 18.8676 8.76163 19.381 9.81818 19.68C9.20887 18.4038 8.80628 17.0389 8.62546 15.6364ZM15.3745 15.6364C15.1937 17.0389 14.7911 18.4038 14.1818 19.68C15.2384 19.381 16.2221 18.8676 17.0716 18.172C17.9212 17.4763 18.6185 16.6132 19.12 15.6364H15.3745ZM15.3745 8.36364H19.12C18.6185 7.38681 17.9212 6.52369 17.0716 5.82802C16.2221 5.13235 15.2384 4.61902 14.1818 4.32C14.7911 5.59617 15.1937 6.96108 15.3745 8.36364ZM8.62546 8.36364C8.80628 6.96108 9.20887 5.59617 9.81818 4.32C8.76163 4.61902 7.77794 5.13235 6.92837 5.82802C6.0788 6.52369 5.38154 7.38681 4.88 8.36364H8.62546Z"
      fill="#737BF8"
    />
  </Svg>
)

export default Landing
