import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'

import * as styled from './Repository.styled'

import {
  AppStoreButton,
  Button,
  ButtonColor,
  ButtonVariant,
  GithubButton,
  GooglePlayButton,
  Icon,
  Name,
  Rotation,
  Text,
  Type,
} from 'components'
import { ApplicationContext, ProgressContext } from 'context'
import {
  Application,
  ApplicationId,
  Integration,
  IntegrationId,
  useAppStoreConnectionModal,
  useGithubConnectionModal,
  useProjectIntegrations,
} from 'hooks'
import { firestore } from 'services/firebase'
import { ProjectsService } from 'services/projects'
import { Navigation } from './components'

export const Repository: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id, repositoryId } = useParams()
  const { appstoreIntegration, googleplayIntegration } = useProjectIntegrations(id as string)
  const [application, setApplication] = useState<Application | null>(null)
  const [applicationIntegrations, setApplicationIntegrations] = useState<Integration[]>([])
  const github = applicationIntegrations.find(el => el.id === IntegrationId.github)
  const isIos = application?.id === ApplicationId.IOS
  const isAndroid = application?.id === ApplicationId.ANDROID
  const githubConnectionModal = useGithubConnectionModal(github, application?.name)
  const appStoreConnectionModal = useAppStoreConnectionModal(appstoreIntegration)

  useEffect(() => {
    if (application) {
      document.title = `${application.name} | CodePlatform`
    }
  }, [application])

  useEffect(() => {
    startLoader()
    ProjectsService.getProjectApplication(id as string, repositoryId as string)
      .then(res => setApplication(res.data))
      .catch(err => toast(err))
      .finally(() => stopLoader())
    const unsubscribe = firestore.collection(`projects/${id}/applications/${repositoryId}/integrations`).onSnapshot({
      next: res => setApplicationIntegrations(res.docs.map(el => ({ id: el.id, ...el.data() })) as Integration[]),
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return application ? (
    <>
      <styled.Container>
        <styled.Header>
          <styled.Left>
            <styled.AppName>
              <Link to={`/projects/${id}/repositories`}>
                <Button
                  variant={ButtonVariant.LINK}
                  color={ButtonColor.SECONDARY}
                  leftIcon={<Icon name={Name.RIGHT_SIDEBAR_ARROW_THIN_DOWN} rotation={Rotation.DOWN} />}
                />
              </Link>
              <Link to={`/projects/${id}/repositories/${repositoryId}/src`}>
                <Text type={Type.TITLE}>{application.name}</Text>
              </Link>
            </styled.AppName>
            <Box display="flex" alignItems="center" columnGap="15px">
              {github && <GithubButton github={github} />}
              {isIos && appstoreIntegration && <AppStoreButton appstoreIntegration={appstoreIntegration} />}
              {isAndroid && googleplayIntegration && <GooglePlayButton googleplayIntegration={googleplayIntegration} />}
            </Box>
          </styled.Left>
          {application.url && (
            <Button color={ButtonColor.SECONDARY} height={30} to={application.url}>
              Visit
            </Button>
          )}
        </styled.Header>
        <Navigation />
        {application && (
          <styled.Content>
            <ApplicationContext.Provider value={{ application }}>
              <Outlet />
            </ApplicationContext.Provider>
          </styled.Content>
        )}
      </styled.Container>
      {githubConnectionModal}
      {isIos && appStoreConnectionModal}
    </>
  ) : (
    <styled.Container />
  )
}
