import React, { useState } from 'react'

import { LocalVariable, Screen, ValueType, VariableSource } from 'utils'
import { CustomPicker } from '../CustomPicker'
import { Variable } from '../common'
import { Style, SwitchColor } from './components'

interface Props {
  close: () => void
  value?: string
  onChange: (val: string, color: string) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  color?: string
  onColorChange?: (val: string) => void
  onlySolid?: boolean
  withoutDelete?: boolean
}

export const ColorPicker: React.FC<Props> = ({
  close,
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  color,
  onColorChange,
  onlySolid,
  withoutDelete,
}) => {
  const [search, setSearch] = useState('')

  return (
    <CustomPicker
      title="Style"
      close={close}
      setSearch={setSearch}
      defaultTab={color ? 2 : source ? 1 : 0}
      optionsContainer={<Style value={value} onChange={onChange} close={close} search={search} />}
      variableContainer={
        onSourceChange && screenConfig && allLocalVariables ? (
          <Variable
            valueType={ValueType.color}
            value={source}
            onChange={onSourceChange}
            onRemove={withoutDelete ? undefined : () => onSourceChange()}
            close={() => {}}
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        ) : undefined
      }
      switchColorContainer={
        onColorChange ? <SwitchColor value={color} onChange={onColorChange} onlySolid={onlySolid} /> : undefined
      }
    />
  )
}
