import { ApplicationId, DeploymentType, ProjectRole, TaskType } from 'hooks'
import { BrandingColorStyles, ConfigData, Product, Resource, Screen, httpClient } from 'utils'

export const host = process.env.REACT_APP_API_URL

const postProject = (
  templateId: string,
  name: string,
  id: string,
  icon: Resource,
  customColors: BrandingColorStyles,
  regionId: string,
  workspaceId: string,
  checkoutSessionId: string
) => {
  const url = `${host}/projects`
  return httpClient.post(url, {
    templateId,
    name,
    id,
    icon,
    customColors,
    regionId,
    workspaceId,
    backend: 'FIREBASE',
    checkoutSessionId,
  })
}

const deleteProject = (id: string) => {
  const url = `${host}/projects/${id}`
  return httpClient.delete(url)
}

const updateProjectLogo = (id: string, logoUrl: string) => {
  const url = `${host}/projects/${id}/update-logo`
  return httpClient.put(url, { logoUrl })
}

const promote = (id: string, screenshots: string[]) => {
  const url = `${host}/projects/${id}/promote`
  return httpClient.post(url, { screenshots })
}

const addMember = (id: string, permissionId: string, role: ProjectRole) => {
  const url = `${host}/projects/${id}/add-member`
  return httpClient.post(url, { permissionId, role })
}

const getProjectApplication = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/applications/${id}`
  return httpClient.get(url)
}

const getProjectApplicationFiles = (projectId: string, id: string, path: string, abortController: AbortController) => {
  const url = `${host}/projects/${projectId}/applications/${id}/src${path}`
  return httpClient.get(url, { signal: abortController.signal })
}

const uploadResource = (id: string, formData: FormData, abortController?: AbortController) => {
  const url = `${host}/projects/${id}/resources`
  return httpClient.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    signal: abortController?.signal,
  })
}

const updateResource = (projectId: string, id: string, formData: FormData, abortController?: AbortController) => {
  const url = `${host}/projects/${projectId}/resources/${id}`
  return httpClient.patch(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    signal: abortController?.signal,
  })
}

const getCommits = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/applications/${id}/commits`
  return httpClient.get(url)
}

export interface TaskDto {
  type: TaskType
  deploymentType?: DeploymentType
  applicationId?: ApplicationId
}

const createTask = (id: string, dto: TaskDto) => {
  const url = `${host}/projects/${id}/tasks`
  return httpClient.post(url, dto)
}

const cancellTask = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/tasks/${id}/cancell`
  return httpClient.post(url)
}

const getSteps = (projectId: string, applicationId: string, id: string) => {
  const url = `${host}/projects/${projectId}/applications/${applicationId}/pipelines/${id}/steps`
  return httpClient.get(url)
}

const getLogs = (projectId: string, applicationId: string, pipelineId: string, id: string) => {
  const url = `${host}/projects/${projectId}/applications/${applicationId}/pipelines/${pipelineId}/steps/${id}/logs`
  return httpClient.get(url)
}

const getVariables = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/applications/${id}/variables`
  return httpClient.get(url)
}

const postVariable = (projectId: string, id: string, data: { key: string; value: string; secured: boolean }) => {
  const url = `${host}/projects/${projectId}/applications/${id}/variables`
  return httpClient.post(url, data)
}

const putVariable = (
  projectId: string,
  applicationId: string,
  id: string,
  data: { key: string; value: string; secured: boolean }
) => {
  const url = `${host}/projects/${projectId}/applications/${applicationId}/variables/${id}`
  return httpClient.put(url, data)
}

const deleteVariable = (projectId: string, applicationId: string, id: string) => {
  const url = `${host}/projects/${projectId}/applications/${applicationId}/variables/${id}`
  return httpClient.delete(url)
}

const getGithubToken = (projectId: string, id: string, code: string) => {
  const url = `${host}/projects/${projectId}/applications/${id}/integrations/github/auth`
  return httpClient.post(url, { code })
}

const createGithubIntegration = (
  projectId: string,
  id: string,
  githubToken: string,
  data: {
    installationId: number
    repositorySlug: string
  }
) => {
  const url = `${host}/projects/${projectId}/applications/${id}/integrations/github`
  return httpClient.put(url, data, {
    headers: {
      'x-github-token': githubToken,
    },
  })
}

const getGithubInstallations = (projectId: string, id: string, githubToken: string) => {
  const url = `${host}/projects/${projectId}/applications/${id}/integrations/github/installations`
  return httpClient.get(url, {
    headers: {
      'x-github-token': githubToken,
    },
  })
}

const getGithubRepositories = (projectId: string, applicationId: string, id: number, githubToken: string) => {
  const url = `${host}/projects/${projectId}/applications/${applicationId}/integrations/github/installations/${id}/repositories`
  return httpClient.get(url, {
    headers: {
      'x-github-token': githubToken,
    },
  })
}

const deleteApplicationIntegration = (projectId: string, applicationId: string, id: string) => {
  const url = `${host}/projects/${projectId}/applications/${applicationId}/integrations/${id}`
  return httpClient.delete(url)
}

const startAppstoreSession = (
  projectId: string,
  data: {
    username: string
    password: string
  }
) => {
  const url = `${host}/projects/${projectId}/integrations/appstore/auth`
  return httpClient.post(url, data)
}

const updateAppstoreSession = (projectId: string, token: string, otp: String) => {
  const url = `${host}/projects/${projectId}/integrations/appstore/auth`
  return httpClient.put(
    url,
    { otp },
    {
      headers: {
        'x-cp-appstore-token': token,
      },
    }
  )
}

const createAppstoreIntegration = (projectId: string, token: string, teamId: string) => {
  const url = `${host}/projects/${projectId}/integrations/appstore`
  return httpClient.put(url, { teamId }, { headers: { 'x-cp-appstore-token': token } })
}

const deleteIntegration = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/integrations/${id}`
  return httpClient.delete(url)
}

const getProduct = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/products/${id}`
  return httpClient.get(url)
}

const postProduct = (id: string, data: Product) => {
  const url = `${host}/projects/${id}/products`
  return httpClient.post(url, data)
}

const updateProduct = (projectId: string, id: string, data: Product) => {
  const url = `${host}/projects/${projectId}/products/${id}`
  return httpClient.patch(url, data)
}

const deleteProduct = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/products/${id}`
  return httpClient.delete(url)
}

const getReleaseNextVersion = (id: string) => {
  const url = `${host}/projects/${id}/releases/next-version`
  return httpClient.get(url)
}

const getPWAToken = (id: string) => {
  const url = `${host}/projects/${id}/pwa-token`
  return httpClient.get(url)
}

const getUsers = (id: string) => {
  const url = `${host}/projects/${id}/users`
  return httpClient.get(url)
}

const migrate = (id: string, templateId: string) => {
  const url = `${host}/projects/${id}/migrate`
  return httpClient.put(url, { templateId })
}

const suggestComonentStyles = (
  id: string,
  componentId: string,
  screen: Screen,
  data: ConfigData,
  abortController: AbortController
) => {
  const url = `${host}/projects/${id}/ai-tools/suggest-comonent-styles`
  return httpClient.post(url, { componentId, screen, data }, { signal: abortController.signal })
}

const refactorComponent = (
  id: string,
  componentId: string,
  screen: Screen,
  data: ConfigData,
  abortController: AbortController
) => {
  const url = `${host}/projects/${id}/ai-tools/refactor-component`
  return httpClient.post(url, { componentId, screen, data }, { signal: abortController.signal })
}

export const ProjectsService = {
  postProject,
  deleteProject,
  updateProjectLogo,
  promote,
  addMember,
  getProjectApplication,
  getProjectApplicationFiles,
  uploadResource,
  updateResource,
  getCommits,
  createTask,
  cancellTask,
  getSteps,
  getLogs,
  getVariables,
  postVariable,
  putVariable,
  deleteVariable,
  getGithubToken,
  createGithubIntegration,
  getGithubInstallations,
  getGithubRepositories,
  deleteApplicationIntegration,
  startAppstoreSession,
  updateAppstoreSession,
  createAppstoreIntegration,
  deleteIntegration,
  getProduct,
  postProduct,
  updateProduct,
  deleteProduct,
  getReleaseNextVersion,
  getPWAToken,
  getUsers,
  migrate,
  suggestComonentStyles,
  refactorComponent,
}
