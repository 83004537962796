import { GetVariableValue, collectionPrefix } from 'hooks'
import {
  AnyVariablTypeKey,
  Collection,
  CollectionDatasource,
  CollectionPermissions,
  CollectionProperty,
  TableQuery,
  TableQueryOrder,
  ValueType,
  VariableSource,
  httpClient,
} from 'utils'
import { host } from './projects'
import { UploadService } from './upload'

export interface CMSCollectionProperty extends CollectionProperty {
  id: string
  activeInTable?: boolean
}

export interface CMSCollection extends Collection {
  id: string
}

export interface CMSCollectionRecord {
  [key: string]: any
}

const postCollection = (id: string, name: string, subCollectionParentName?: string) => {
  const url = `${host}/projects/${id}/collections`
  return httpClient.post(url, { name, subCollectionParentName })
}

const deleteCollection = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/collections/${id}`
  return httpClient.delete(url)
}

const updateCollection = (
  projectId: string,
  id: string,
  properties: CollectionProperty[],
  datasource: CollectionDatasource,
  helpText?: string,
  titleField?: string,
  previewScreen?: string,
  permissions?: CollectionPermissions
) => {
  const url = `${host}/projects/${projectId}/collections/${id}`
  return httpClient.put(url, { properties, datasource, helpText, titleField, previewScreen, permissions })
}

const getRecords = async (
  projectId: string,
  id: string,
  language?: string,
  query?: TableQuery,
  ids?: string[],
  search?: string,
  limit?: number,
  getVariableValue?: GetVariableValue,
  onlyPublishedRecords?: boolean,
  abortController?: AbortController
) => {
  let searchParams = `?orderedBy=${query?.orderedBy || 'createdAt'}&order=${
    query?.order === TableQueryOrder.ascending ? 'asc' : 'desc'
  }`
  if (query?.filters) {
    for (const el of query.filters) {
      const filterValueKey = Object.keys(el.value)[0] as AnyVariablTypeKey
      let filterValue = el.value[filterValueKey] as any
      if (filterValueKey === AnyVariablTypeKey.textConstant && language) {
        filterValue = filterValue.locales[language]
      } else if (filterValueKey === AnyVariablTypeKey.source && getVariableValue) {
        filterValue = await getVariableValue(el.value.source as VariableSource)
      }
      if (filterValue !== undefined) {
        searchParams += `&${el.field}[${el.operator}]=${filterValue}`
      }
    }
  }
  if (ids) {
    searchParams += `&id[in]=${ids}`
  }
  if (search) {
    searchParams += `&search=${search}`
  }
  if (limit) {
    searchParams += `&limit=${limit}`
  }
  if (onlyPublishedRecords) {
    searchParams += `&onlyPublishedRecords=${onlyPublishedRecords}`
  }
  const url = `${host}/projects/${projectId}/records/${id}${searchParams}`
  return httpClient.get(url, { signal: abortController?.signal })
}

const postRecord = async (projectId: string, id: string, record: CMSCollectionRecord) => {
  const url = `${host}/projects/${projectId}/records/${id}`
  return httpClient.post(url, record)
}

const getRecord = (projectId: string, collectionId: string, id: string, onlyPublishedRecords?: boolean) => {
  const url = `${host}/projects/${projectId}/records/${collectionId}/${id}${
    onlyPublishedRecords ? `?onlyPublishedRecords=${onlyPublishedRecords}` : ''
  }`
  return httpClient.get(url)
}

const deleteAssetFields = (record: CMSCollectionRecord) => {
  delete record.resource
  record.url = null
  record.resourceType = null
  record.format = null
  record.width = null
  record.height = null
  record.bytes = null
  record.duration = null
  record.version = null
  record.signature = null
  record.error = null
  record.thumbnailUrl = null
  record.status = null
}

const getAssetRecord = async (projectId: string, record: CMSCollectionRecord) => {
  const { title, folder, resource } = record
  const url = await UploadService.uploadResource(projectId, resource)
  const [resourceType, format] = resource.type.split('/')
  return {
    title,
    folder,
    url,
    resourceType,
    format,
    width: null,
    height: null,
    bytes: null,
    duration: null,
    version: null,
    signature: null,
    error: null,
  }
}

const saveRecord = async (
  projectId: string,
  collectionId: string,
  id: string,
  initialRecord: CMSCollectionRecord,
  abortController?: AbortController
) => {
  let record = { ...initialRecord }
  if (collectionId === assetsId) {
    if (record.resource) {
      if (record.resource.name === oldFileName) {
        delete record.resource
      } else {
        record = await getAssetRecord(projectId, record)
      }
    } else {
      deleteAssetFields(record)
    }
  }
  const url = `${host}/projects/${projectId}/records/${collectionId}/${id}`
  return httpClient.put(url, record, { signal: abortController?.signal })
}

const deleteRecord = (projectId: string, collectionId: string, id: string) => {
  const url = `${host}/projects/${projectId}/records/${collectionId}/${id}`
  return httpClient.delete(url)
}

const publishRecord = async (projectId: string, collectionId: string, id: string, publish: boolean) => {
  const url = `${host}/projects/${projectId}/${publish ? 'publish' : 'unpublish'}/records/${collectionId}/${id}`
  return httpClient.put(url)
}

const postFolder = (id: string, name: string) => {
  const url = `${host}/projects/${id}/folders`
  return httpClient.post(url, { name })
}

const deleteFolder = (projectId: string, id: string) => {
  const url = `${host}/projects/${projectId}/folders/${id}`
  return httpClient.delete(url)
}

export const createAssetProperties: CollectionProperty[] = [
  {
    name: 'title',
    type: ValueType.string,
    position: 0,
  },
  {
    name: 'folder',
    type: ValueType.folder,
    position: 1,
  },
  {
    name: 'resource',
    type: ValueType.fileInput,
    position: 2,
    isRequired: true,
  },
]

export const rootFolderName = '~'
export const assetsId = 'assets'
export const oldFileName = 'oldFileName'
export const recordStatus = 'recordStatus'
export const reservedCollectionNames = [
  ...Object.values(ValueType).filter(
    el =>
      el !== ValueType.fileInput &&
      el !== ValueType.folder &&
      el !== ValueType.screenshots &&
      el !== ValueType.terms &&
      el !== ValueType.avatar &&
      el !== ValueType.appIcon &&
      el !== ValueType.coreColor &&
      el !== ValueType.launchScreenIcon &&
      el !== ValueType.launchScreenBackground &&
      el !== ValueType.variable
  ),
  'ISubCollection',
  'SubCollection',
  'Collection',
  'FieldValue',
  'Timestamp',
]

export const extractPath = (path: string) => {
  const decodedPath = decodeURI(path)
  let collectionId = ''
  let recordId = ''
  const parts = decodedPath.split('/')
  if (parts.length % 2) {
    collectionId = decodedPath
  } else {
    collectionId = parts.slice(0, -1).join('/')
    recordId = parts.slice(-1)[0]
  }
  return { collectionId, recordId }
}

export const extractRecord = (record: string) => extractPath(record.replace(collectionPrefix, ''))

export const collectionIdToFind = (name: string) =>
  name
    .split('/')
    .filter((el, i) => !(i % 2))
    .join('/')

export const getCollectionName = (name: string) => name.split('/').slice(-1)[0]

export const getParentCollectionName = (name: string) => name.split('/').slice(0, -2).join('/')

export const getParentFolderName = (name: string) => name.split('/').slice(0, -1).join('/')

export const isSubCollection = (collectionId: string) => collectionId.split('/').length > 1

export enum RecordStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  CHANGED = 'CHANGED',
}

export type ResourceTypes = ValueType.image | ValueType.video | ValueType.audio | ValueType.file

export const CMSService = {
  postCollection,
  deleteCollection,
  updateCollection,
  getRecords,
  postRecord,
  getRecord,
  saveRecord,
  deleteRecord,
  publishRecord,
  postFolder,
  deleteFolder,
}
