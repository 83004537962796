import { Box, Portal } from '@mui/material'
import { memo, useContext, useRef, useState } from 'react'

import * as styled from './RefField.styled'

import { FieldWrapper, Icon, Name, RefPicker } from 'components'
import { CMSContext } from 'context'
import { useOnClickOutside } from 'hooks'
import { CollectionRecord } from 'pages/CMS/pages'
import { CMSCollectionRecord } from 'services/cms'
import { generateFirestoreId } from 'utils'
import { FieldProps } from '../../types'
import { ReferenceItem, ReferenceItems } from './ReferenceItems'

interface RefFieldProps extends FieldProps {
  value: CMSCollectionRecord | CMSCollectionRecord[] | string | string[] | null
  onChange: (val: CMSCollectionRecord | CMSCollectionRecord[] | string | string[] | null) => void
}

export const RefField = memo((props: RefFieldProps) => {
  const { rootCollections, assets } = useContext(CMSContext)
  const [openMenu, setOpenMenu] = useState(false)
  const [openExisting, setOpenExisting] = useState(false)
  const [recordId, setRecordId] = useState('')
  const currentCollection = props.resourceType ? assets : rootCollections.find(el => el.id === props.collection)
  const pickerTitle = (props.resourceType || 'record') + `${props.multiple ? 's' : ''}`
  const value = !props.multiple ? (props.value as CMSCollectionRecord | string | null) : null
  const arrayValue = !!props.multiple ? (props.value as CMSCollectionRecord[] | string[] | null) || [] : []
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpenMenu(false))

  const onAddNewRef = (val: CMSCollectionRecord | string) => {
    if (props.multiple) {
      props.onChange([
        // @ts-ignore
        ...arrayValue.filter(el =>
          props.resourceType ? (el as CMSCollectionRecord).id !== (val as CMSCollectionRecord).id : el !== val
        ),
        val,
      ])
    } else {
      props.onChange(val)
    }
  }

  const onAddExistingRef = (val: CMSCollectionRecord | string) => {
    if (props.multiple) {
      // @ts-ignore
      const active = arrayValue.find(el =>
        props.resourceType ? (el as CMSCollectionRecord).id === (val as CMSCollectionRecord).id : el === val
      )
      if (active) {
        // @ts-ignore
        const value = arrayValue.filter(el =>
          props.resourceType ? (el as CMSCollectionRecord).id !== (active as CMSCollectionRecord).id : el !== active
        )
        props.onChange(value.length ? value : null)
      } else {
        props.onChange([...arrayValue, val])
      }
    } else {
      props.onChange(val)
      setOpenExisting(false)
    }
  }

  const remove = (val: CMSCollectionRecord | string) => {
    if (props.multiple) {
      // @ts-ignore
      const value = arrayValue.filter(el =>
        props.resourceType ? (el as CMSCollectionRecord).id !== (val as CMSCollectionRecord).id : el !== val
      )
      props.onChange(value.length ? value : null)
    } else {
      props.onChange(null)
    }
  }

  return (
    <>
      <FieldWrapper
        {...props}
        children={props => (
          <>
            {!value ? (
              <styled.RefFieldInput
                onClick={e => {
                  e.preventDefault()
                  setOpenMenu(!openMenu)
                }}
                ref={ref}
              >
                <span>
                  <Icon name={Name.RIGHT_SIDEBAR_ADD} />
                  Add {pickerTitle}
                  {openMenu && (
                    <Box>
                      <Box onClick={() => setOpenExisting(true)}>Add existing {pickerTitle}</Box>
                      <Box onClick={() => setRecordId(generateFirestoreId())}>Add new {pickerTitle}</Box>
                    </Box>
                  )}
                </span>
              </styled.RefFieldInput>
            ) : (
              <ReferenceItem
                value={value}
                remove={() => remove(value)}
                open={setRecordId}
                resourceType={props.resourceType}
              />
            )}
            {props.multiple && (
              <ReferenceItems
                values={arrayValue}
                onChange={props.onChange}
                remove={remove}
                open={setRecordId}
                resourceType={props.resourceType}
              />
            )}
          </>
        )}
      />
      {openExisting && currentCollection && (
        <RefPicker
          currentRef={currentCollection}
          selectedValues={props.multiple ? arrayValue : []}
          multiple={props.multiple}
          close={() => setOpenExisting(false)}
          onAddRef={onAddExistingRef}
          resourceType={props.resourceType}
          title={pickerTitle}
        />
      )}
      {recordId && currentCollection && (
        <Portal>
          <CollectionRecord
            recordId={recordId}
            collectionId={currentCollection.id}
            currentCollection={currentCollection}
            resourceType={props.resourceType}
            onAddRef={onAddNewRef}
            closeAddRef={() => setRecordId('')}
          />
        </Portal>
      )}
    </>
  )
})
