import React from 'react'
import { Outlet } from 'react-router-dom'

import {
  CustomSection,
  CustomSectionTitle,
  CustomSectionTitleAction,
  CustomSectionTitleWithActionWrapper,
  Icon,
  Name,
  ProductsTable,
} from 'components'

export const Products: React.FC = () => (
  <>
    <CustomSection>
      <CustomSectionTitleWithActionWrapper>
        <CustomSectionTitle>Products</CustomSectionTitle>
        <CustomSectionTitleAction to="products/add">
          <Icon name={Name.RIGHT_SIDEBAR_ADD} />
          Add product
        </CustomSectionTitleAction>
      </CustomSectionTitleWithActionWrapper>
      <ProductsTable />
    </CustomSection>
    <Outlet />
  </>
)
