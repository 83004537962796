import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 100%;
  max-height: 100vh;
  background: ${props => props.theme.colors.selectBackground};
  border-radius: 12px;
  pointer-events: auto;
  box-shadow: 0px 2px 8px 0px rgba(26, 24, 24, 0.18);
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 8px;
  height: 45px;
  & svg {
    min-width: 16px;
    cursor: pointer;
    & path {
      transition: 300ms ease-out;
      &[stroke] {
        stroke: ${props => props.theme.colors.grey1};
      }
    }
    &:hover {
      & path[stroke] {
        stroke: ${props => props.theme.colors.iconHover};
      }
    }
  }
`

export const Border = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.darkBlue_100};
  position: relative;
`

export const ActiveBorder = styled.span<{ left: number; width: number }>`
  position: absolute;
  top: 0;
  left: ${props => props.left}px;
  width: ${props => props.width}px;
  transition: left 0.3s ease;
  height: 1px;
  background-color: ${props => props.theme.colors.darkText4};
`

export const Tabs = styled.div`
  display: flex;
  column-gap: 8px;
`

export const Tab = styled.div<{ active?: boolean; alone?: boolean }>`
  cursor: ${props => !props.alone && 'pointer'};
  font-size: 14px;
  line-height: 150%;
  font-weight: ${props => props.theme.weights.medium};
  color: ${props => (props.active ? props.theme.colors.darkText4 : props.theme.colors.grey1)};
  border-radius: 8px;
  padding: 4px 8px;
  background-color: transparent;
  transition: ${props => props.theme.transition.secondary};
  & > span {
    color: ${props => props.theme.colors.grey1};
  }
  &:hover {
    background-color: ${props => !props.active && props.theme.colors.settingField};
  }
`

export const Search = styled.input<{ inputEmpty: boolean }>`
  font-family: ${props => props.theme.fonts.primary};
  margin: 8px;
  border-radius: 8px;
  background: ${props => props.theme.colors.settingField};
  width: calc(100% - 16px);
  box-sizing: border-box;
  padding: 8px 12px;
  transition: 0.4s background-color ease;
  font-size: 14px;
  line-height: 21px;
  color: ${props => props.theme.colors.darkText};
  border: none;
  outline: none;
  &::placeholder {
    color: ${props => props.theme.colors.grey1};
  }

  &:hover {
    background-color: ${props => props.theme.colors.settingFieldHover};
  }
  &:focus {
    background-color: ${props =>
      props.inputEmpty ? props.theme.colors.settingFieldHover : props.theme.colors.settingField};
  }
`

export const ButtonWrapper = styled.div`
  margin: 0 10px 10px;
  position: relative;
  & button {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: ${props => props.theme.colors.grey1};
  }
`

export const Arrow = styled.div`
  display: flex;
  align-items: center;
  & svg {
    min-height: 16px !important;
    min-width: 16px !important;
  }
`

export const ItemsHint = styled.div<{ pointer?: boolean }>`
  margin: 13px 13px 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: ${props => props.pointer && 'pointer'};
  &:hover {
    color: ${props => props.pointer && props.theme.colors.iconHover};
    ${Arrow} path {
      stroke: ${props => props.pointer && props.theme.colors.iconHover};
    }
  }
`

export const Items = styled.div<{ noSearchMode?: boolean; height?: string; padding?: string; multiple?: boolean }>`
  padding: ${props => props.padding || (props.multiple ? '0 9px 10px' : props.noSearchMode && '8px 0')};
  height: ${props => props.height || (!props.noSearchMode && '250px')};
  overflow-y: auto;
`

export const ItemsCategory = styled.div`
  margin: 0 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
`

export const ItemBigIcon = styled.div`
  & > * {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border-radius: 12px;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    background-color: ${props => props.theme.colors.primaryText};
    color: ${props => props.theme.colors.white};
  }
`

export const ItemLabelWithDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const ItemLabel = styled.div<{ color?: string; right?: boolean; warning?: boolean }>`
  font-weight: ${props => props.theme.weights.medium};
  font-size: 14px;
  line-height: 150%;
  width: ${props => !props.right && '100%'};
  overflow: ${props => !props.right && 'hidden'};
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.warning ? 2 : 1)};
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  color: ${props => props.color && props.theme.colors[props.color]};
  ${props =>
    props.warning &&
    css`
      &:hover {
        display: block;
      }
    `}
  & > span {
    color: #df5636;
  }
`

export const ItemDescription = styled.div`
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.theme.colors.grey};
`

export const Item = styled.div<{
  active?: boolean
  select?: boolean
  sectionName?: boolean
  warning?: boolean
  hasBigIcon?: boolean
  color?: string
  iconHover?: boolean
}>`
  position: relative;
  height: ${props => (props.hasBigIcon ? '60px' : !props.warning && '36px')};
  margin: 0 8px;
  padding: ${props => (props.hasBigIcon ? '0 5px' : props.warning ? '6px 10px 6px 60px' : '0 8px')};
  display: flex;
  border-radius: 8px;
  align-items: ${props => (props.warning ? 'flex-start' : 'center')};
  gap: 8px;
  background: ${props => props.active && props.theme.colors.settingField};
  margin-bottom: 2px;
  & > svg,
  & > img {
    margin-top: ${props => props.warning && '2px'};
    min-width: 16px;
    min-height: 16px;
  }
  ${props =>
    props.iconHover &&
    `
    & > svg {
      & * {
        &[fill] {
          fill: ${props.active ? props.theme.colors.darkText : props.theme.colors.darkText1};
        }
        &[stroke] {
          stroke: ${props.active ? props.theme.colors.darkText : props.theme.colors.darkText1};
        }
      }
    }
  `}
  ${props =>
    !props.sectionName &&
    css`
      ${Arrow} path {
        ${props.active && `stroke: ${props.theme.colors.iconHover};`}
      }
      &:hover {
        cursor: pointer;
        background: ${props.theme.colors.settingField};
        ${Arrow} path {
          stroke: ${props.theme.colors.iconHover};
        }
      }
    `}
  ${props =>
    props.sectionName &&
    css`
      ${ItemLabel} {
        font-size: 12px;
        line-height: 16px;
      }
    `}

  ${props =>
    props.select &&
    css`
      background: ${props.theme.colors.settingField};
      border-radius: 8px;
      margin: 6px 10px;
      &:hover {
        background: ${props.theme.colors.settingFieldHover};
      }
    `}
    ${ItemLabel} {
    color: ${props =>
      props.active
        ? props.theme.colors.darkText
        : props.color
        ? props.theme.colors[props.color]
        : props.theme.colors.darkText};
  }
  &:hover {
    ${ItemLabel} {
      color: ${props => props.color === 'darkText1' && props.theme.colors.darkText};
    }
    ${props =>
      props.iconHover &&
      `
    & > svg {
      & * {
        &[fill] {
          fill: ${props.theme.colors.darkText};
        }
        &[stroke] {
          stroke: ${props.theme.colors.darkText};
        }
      }
    }
  `}
  }
`

export const HoveredItem = styled.div<{ left?: number }>`
  overflow: hidden;
  cursor: auto;
  position: absolute;
  padding: 3px 3px 1px;
  top: 12px;
  left: ${props => props.left || -186}px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.borderColor};
  box-shadow: 0px 15px 20px -5px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  & > * {
    width: 173px;
  }
  & > img,
  & > svg {
    height: auto;
    border-radius: 1px;
  }
`
