import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CustomPicker, Variable } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { ProjectsService } from 'services/projects'
import { LocalVariable, Resource, Screen, ValueType, VariableSource, generateFirestoreId } from 'utils'
import { DragNDrop, ImageEditor, Options, Selects, categoriesOptions, kindOfImagesOptions } from './components'

interface Props {
  close: () => void
  url?: string
  name?: string
  onChange: (val: string, resources?: Resource[]) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  sfByDefault?: boolean
  withoutDelete?: boolean
}

export const ImagePicker: React.FC<Props> = ({
  close,
  url,
  name,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  sfByDefault,
  withoutDelete,
}) => {
  const { id } = useParams()
  const {
    state: { resources },
    setState,
  } = useContext(ProjectContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const [search, setSearch] = useState('')
  const [currentCategory, setCurrentCategory] = useState(categoriesOptions[4].value)
  const [currentKindOfImages, setCurrentKindOfImages] = useState(kindOfImagesOptions[sfByDefault ? 0 : 1].value)
  const [file, setFile] = useState<any>(null)
  const componentWillUnmount = useRef(false)

  useEffect(() => {
    return () => {
      componentWillUnmount.current = true
    }
  }, [])

  useEffect(() => {
    if (id && file) {
      const abortController = new AbortController()
      const formData = new FormData()
      formData.append('resource', file)
      startLoader()
      ProjectsService.uploadResource(id, formData, abortController)
        .then(res => {
          const resource = res.data as Resource
          onChange(resource.id, [...resources, resource])
        })
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => stopLoader())
      return () => {
        if (!componentWillUnmount.current) {
          abortController.abort()
        }
      }
    }
  }, [file])

  const onFileUpdate = (newUrl: string) => {
    const resourcesCopy = JSON.parse(JSON.stringify(resources)) as Resource[]
    const currentResource = resourcesCopy.find(resource => resource.url === url)
    if (currentResource) {
      currentResource.url = newUrl
    }
    setState(state => ({ ...state, resources: resourcesCopy }))
  }

  const onSFSymbolClick = (url: string, name: string) => {
    const resource = resources.find(el => el.url === url)
    if (resource) {
      onChange(resource.id)
    } else {
      fetch(url)
        .then(res => res.text())
        .then(res => {
          const div = document.createElement('div')
          div.innerHTML = res
          const svg = div.querySelector('svg')
          if (svg) {
            const viewBox = svg.getAttribute('viewBox')?.split(' ')
            if (viewBox) {
              const resource: Resource = {
                id: generateFirestoreId(),
                format: 'svg',
                bytes: 0,
                width: +viewBox[2],
                height: +viewBox[3],
                url,
                resourceType: 'image',
                createdAt: new Date().toISOString(),
                name: `${name}.svg`,
              }
              onChange(resource.id, [...resources, resource])
            }
          }
        })
        .catch(err => toast(err))
    }
  }

  return (
    <CustomPicker
      undoMode
      title="Asset"
      close={close}
      setSearch={setSearch}
      defaultTab={source ? 1 : 0}
      iconContainer={
        <>
          {url ? (
            <ImageEditor onFileChange={setFile} onFileUpdate={onFileUpdate} url={url} name={name} />
          ) : (
            <DragNDrop onFileChange={setFile} />
          )}
          <Selects
            currentKindOfImages={currentKindOfImages}
            setCurrentKindOfImages={setCurrentKindOfImages}
            currentCategory={currentCategory}
            setCurrentCategory={setCurrentCategory}
          />
        </>
      }
      optionsContainer={
        <Options
          onFileChange={setFile}
          onSFSymbolClick={onSFSymbolClick}
          onChange={onChange}
          currentKindOfImages={currentKindOfImages}
          currentCategory={currentCategory}
          search={search}
        />
      }
      variableContainer={
        onSourceChange && screenConfig && allLocalVariables ? (
          <Variable
            valueType={ValueType.image}
            value={source}
            onChange={onSourceChange}
            onRemove={withoutDelete ? undefined : () => onSourceChange()}
            close={() => {}}
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        ) : undefined
      }
    />
  )
}
