import styled from 'styled-components'

const Wrapper = styled.div`
  border-radius: 4px;
  border: 1.5px dashed ${props => props.theme.colors.grey1};
  & > * {
    position: absolute;
  }
  & > div {
    &:nth-child(1) {
      top: 2px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(2) {
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    &:nth-child(3) {
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:nth-child(4) {
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
`

export const MarginsWrapper = styled(Wrapper)`
  height: 150px;
  position: relative;
`

export const PaddingsWrapper = styled(Wrapper)`
  height: 80px;
  width: calc(100% - 80px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1.5px solid ${props => props.theme.colors.primaryText};
`

export const Title = styled.span`
  top: 3px;
  left: 5px;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  color: ${props => props.theme.colors.grey1};
`

export const Triangle = styled.div<{ bottom?: boolean }>`
  pointer-events: none;
  top: ${props => !props.bottom && 0};
  bottom: ${props => props.bottom && 0};
  left: 0;
  width: 100%;
  border-top: ${props => !props.bottom && `64px solid ${props.theme.colors.settingField}`};
  border-bottom: ${props => props.bottom && `64px solid ${props.theme.colors.settingField}`};
  border-left: 80px solid transparent;
  border-right: 80px solid transparent;
`

export const Center = styled(Wrapper)`
  height: 20px;
  width: calc(100% - 80px);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${props => props.theme.colors.settingField};
`
