import React, { useState } from 'react'

import * as styled from './ImageEditor.styled'

import { Button, ButtonColor, Modal, Text, Type, Icon, Name } from 'components'
import { Cropper } from './components'

interface Props {
  onFileChange: (file: File) => void
  onFileUpdate: (newUrl: string) => void
  url: string
  name?: string
}

export const ImageEditor: React.FC<Props> = ({ onFileChange, onFileUpdate, url, name }) => {
  const [crop, setCrop] = useState(false)
  const urlWithoutCrop = url
    .split('/')
    .filter(el => !el.includes('c_crop'))
    .filter(el => !el.includes('c_lpad'))
    .filter(el => !el.includes('c_scale'))
    .join('/')

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => e.target.files?.[0] && onFileChange(e.target.files[0])

  return (
    <styled.Background>
      <styled.ImageBox>
        <img src={url} alt={name} />
        <styled.ImageBoxHover>
          <styled.ButtonBox>
            <Button color={ButtonColor.PICKER}>
              <input type="file" accept="image/*" onChange={onChange} />
              Choose Image...
            </Button>
            <Button color={ButtonColor.PICKER} onClick={() => setCrop(true)}>
              Crop
            </Button>
            <Modal
              active={crop}
              onClose={() => setCrop(!crop)}
              noCloseIcon
              cancelButton={false}
              width="auto"
              parentHasTransform
              isMouseDown
              style={{ padding: '0px', transition: 'none' }}
            >
              <styled.ModalHeader>
                <Text type={Type.H3}>Crop Image</Text>
                <styled.IconWrapper>
                  <Icon name={Name.PICKERS_CLOSE} onClick={() => setCrop(false)} />
                </styled.IconWrapper>
              </styled.ModalHeader>
              <Cropper url={urlWithoutCrop} onFileUpdate={onFileUpdate} close={() => setCrop(false)} initUrl={url} />
            </Modal>
          </styled.ButtonBox>
        </styled.ImageBoxHover>
      </styled.ImageBox>
    </styled.Background>
  )
}
