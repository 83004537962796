import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { InputWrapper } from 'components/Modal/Modal.styled'

import { Button, CustomSection, Modal, Select, SelectArrowVariant, Text, Type } from 'components'
import { ProgressContext, ProjectContext, WorkspaceContext } from 'context'
import { Permission, ProjectRole, useInputAutoFocusRef } from 'hooks'
import firebase, { firestore } from 'services/firebase'
import { ProjectsService } from 'services/projects'
import { WorkspacesService } from 'services/workspaces'

interface IProps {
  children: React.ReactNode
  title: string
}

const RoleOption: React.FC<IProps> = ({ title, children }) => {
  const themeContext = useContext(ThemeContext)
  return (
    <div style={{ display: 'block' }}>
      <Text type={Type.H3} as="span">
        {title}
      </Text>
      <Text type={Type.BODY} as="p" color={themeContext.colors.grey1}>
        {children}
      </Text>
    </div>
  )
}

interface Props {
  projectPermissions?: Permission[]
  workspaceTeammates?: boolean
  getInvites?: (id: string) => void
}

export const AddNew: React.FC<Props> = ({ projectPermissions, workspaceTeammates, getInvites }) => {
  const themeContext = useContext(ThemeContext)
  const { workspace } = useContext(WorkspaceContext)
  const { id } = useParams()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { project } = useContext(ProjectContext)
  const [active, setActive] = useState(false)
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [wait, setWait] = useState(false)
  const [currentPermissionEmail, setCurrentPermissionEmail] = useState<string>('')
  const permissionId = permissions.find(permission => permission.email === currentPermissionEmail)?.id || ''
  const [role, setRole] = useState(ProjectRole.MEMBER)
  const inputAutoFocusElement = useInputAutoFocusRef(!!workspaceTeammates && active)

  useEffect(() => {
    if (!workspaceTeammates && projectPermissions) {
      if (active) {
        startLoader()
        const workspaceId = project.workspace.id
        const unsubscribe = (
          !!projectPermissions.length
            ? firestore.collection(`workspaces/${workspaceId}/permissions`).where(
                firebase.firestore.FieldPath.documentId(),
                'not-in',
                projectPermissions.map(el => el.id)
              )
            : firestore.collection(`workspaces/${workspaceId}/permissions`)
        ).onSnapshot({
          next: res => {
            setPermissions(res.docs.map(el => ({ id: el.id, ...el.data() })) as Permission[])
            stopLoader()
          },
          error: err => {
            toast(err)
            stopLoader()
          },
        })
        return () => {
          unsubscribe()
        }
      } else {
        setCurrentPermissionEmail('')
        setRole(ProjectRole.MEMBER)
      }
    }
  }, [active])

  const save = () => {
    setWait(true)
    startLoader()
    ProjectsService.addMember(id as string, permissionId, role)
      .then(() => setActive(false))
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setWait(false)
      })
  }

  const [email, setEmail] = useState('')

  const handleInvite = () => {
    if (workspaceTeammates && getInvites) {
      if (email && workspace) {
        startLoader()
        WorkspacesService.invite(workspace.id, email)
          .then(() => {
            setEmail('')
            getInvites(workspace.id)
          })
          .catch(err => toast(err))
          .finally(() => stopLoader())
      }
    }
    setActive(false)
  }

  return (
    <CustomSection
      style={{ margin: '40px 0 30px', padding: '15px 25px', backgroundColor: themeContext.colors.secondaryButtonBg }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <div>
          <Text type={Type.H3} as="h2" fontWeight={themeContext.weights.semibold}>
            Add teammate
          </Text>
          <Text type={Type.H3} as="p" fontWeight={themeContext.weights.normal} color={themeContext.colors.grey1}>
            {workspaceTeammates
              ? 'Send email to invite member to your workspace'
              : 'Choose a workspace member to grant access to. Please ensure that the member is added to the workspace beforehand.'}
          </Text>
        </div>
        <>
          <Button height={30} style={{ padding: '4.5px 15px' }} onClick={() => setActive(true)}>
            Add
          </Button>
          {!workspaceTeammates ? (
            <Modal
              active={active}
              noCloseIcon
              onClose={() => setActive(false)}
              title="Add new project member"
              onButtonClick={save}
              buttonTitle="Save"
              buttonLoading={wait}
              buttonDisabled={!permissionId}
              style={{ overflow: 'visible' }}
            >
              <Box display="flex" flexDirection="column" rowGap="30px" marginTop="5px">
                <Box display="flex" flexDirection="column" rowGap="15px">
                  <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
                    Workspace member
                  </Text>
                  <Select
                    options={permissions.map(permission => ({ value: permission.email }))}
                    onClick={setCurrentPermissionEmail}
                    initValue="Choose user"
                    arrowVariant={SelectArrowVariant.BIG_S24}
                    optionsWrapperStyle={{ borderRadius: '8px', padding: '10px 0' }}
                    eachOptionStyle={{ padding: '10px', margin: 0, borderRadius: 0 }}
                    labelStyle={{ justifyContent: 'space-between', height: '40px', padding: '0 10px' }}
                    width="100%"
                    top="45px"
                  />
                </Box>
                <Box display="flex" flexDirection="column" rowGap="15px">
                  <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
                    Role
                  </Text>
                  <Select
                    options={[
                      {
                        value: ProjectRole.MEMBER,
                        visibleElement: (
                          <RoleOption title="Member">Can push code, create and merge pull requests</RoleOption>
                        ),
                      },
                      {
                        value: ProjectRole.CONTENT_EDITOR,
                        visibleElement: (
                          <RoleOption title="Editor">
                            Lorem Ipsum is simply dummy text of the printing and typesetting
                          </RoleOption>
                        ),
                      },
                      {
                        value: ProjectRole.GUEST,
                        visibleElement: (
                          <RoleOption title="Guest">Can push code, create and merge pull requests</RoleOption>
                        ),
                      },
                      {
                        value: ProjectRole.ADMIN,
                        visibleElement: (
                          <RoleOption title="Admin">Lorem Ipsum is simply dummy text of the printing</RoleOption>
                        ),
                      },
                    ]}
                    disabled={!permissionId}
                    onClick={setRole}
                    arrowVariant={SelectArrowVariant.BIG_S24}
                    optionsWrapperStyle={{ borderRadius: '8px', padding: '10px 0' }}
                    eachOptionStyle={{ padding: '10px', margin: 0, borderRadius: 0 }}
                    labelStyle={{ justifyContent: 'space-between', height: '58px', padding: '0 10px' }}
                    width="100%"
                    top="65px"
                  />
                </Box>
              </Box>
            </Modal>
          ) : (
            <Modal
              active={active}
              noCloseIcon
              onClose={() => setActive(false)}
              title="Add teammate"
              onButtonClick={handleInvite}
              buttonTitle="Invite"
              buttonDisabled={!email || !email.includes('@')}
            >
              <Text type={Type.H3} as="span" fontWeight={themeContext.weights.medium}>
                Email
              </Text>
              <InputWrapper inputEmpty={email === ''}>
                <input value={email} onChange={e => setEmail(e.target.value)} ref={inputAutoFocusElement} />
              </InputWrapper>
            </Modal>
          )}
        </>
      </Box>
    </CustomSection>
  )
}
