import { useFormik } from 'formik'
import React, { useState } from 'react'

import { CustomSection, CustomSectionActions, CustomSectionTitle, InputProperty, inputs, validate } from 'components'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'id',
    type: ValueType.string,
    position: 0,
    title: 'Bundle ID / Package name',
    helpText:
      'The unique identifier for an application in the Apple Store and Google Play. The Bundle ID follows a reverse-domain name system.',
    smallWidth: true,
    disabled: true,
  },
  {
    name: 'name',
    type: ValueType.string,
    position: 1,
    title: 'Display Name',
    helpText: "The name of the app that is shown on the home screen of a device, under the app's icon",
    smallWidth: true,
    disabled: true,
  },
]

export const Applications: React.FC = () => {
  const [valuesBeforeEdit] = useState('')

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {},
  })

  const cancel = () => {}

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  return (
    <CustomSection showActions={showActions}>
      <CustomSectionTitle mb="-5px">Applications</CustomSectionTitle>
      {inputs(properties, formik)}
      {showActions && <CustomSectionActions cancel={cancel} onClick={formik.handleSubmit} />}
    </CustomSection>
  )
}
