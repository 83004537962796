import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'

import { CustomSection, CustomSectionActions, CustomSectionTitle, InputProperty, inputs, validate } from 'components'
import { Workspace } from 'hooks'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'name',
    type: ValueType.string,
    position: 0,
    isRequired: true,
    title: 'Name',
    helpText: 'Lorem Ipsum is simply dummy text',
    smallWidth: true,
  },
  {
    name: 'logoUrl',
    type: ValueType.avatar,
    position: 1,
    helpText: 'Lorem Ipsum is simply dummy text',
    smallWidth: true,
    logo: true,
  },
]

interface Props {
  workspace: Workspace
}

export const General: React.FC<Props> = ({ workspace }) => {
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const name = workspace?.name || ''
  const logoUrl = ''

  const formik = useFormik({
    initialValues: { name, logoUrl } as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {},
  })

  useEffect(() => {
    formik.setValues({ name, logoUrl })
    setValuesBeforeEdit(JSON.stringify({ name, logoUrl }))
  }, [name, logoUrl])

  const cancel = () => {
    const { name, logoUrl } = JSON.parse(valuesBeforeEdit)
    formik.setValues({ name, logoUrl })
  }

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  return (
    <CustomSection showActions={showActions}>
      <CustomSectionTitle mb="-5px">General</CustomSectionTitle>
      {inputs(properties, formik)}
      {showActions && <CustomSectionActions cancel={cancel} onClick={formik.handleSubmit} />}
    </CustomSection>
  )
}
