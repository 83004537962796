import { marked } from 'marked'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'
import { format } from 'timeago.js'

import { ProgressContext, ProjectContext } from 'context'
import { CMSCollectionRecord, CMSService } from 'services/cms'
import {
  Collection,
  CollectionProperty,
  CollectionVariable,
  LocalizedContent,
  Screen,
  ScreenComponent,
  StringVariable,
  TableQuery,
  ValueType,
  VariableSource,
  VariableSourceType,
  VariableTransform,
  VariableTransformTransform,
  findComponent,
  getTextValue,
} from 'utils'

export type GetVariable = (screenConfig: Screen, listId?: string, indexInList?: number) => GetVariableValue

export type GetVariableValue = (
  source: VariableSource,
  listParams?: {
    listId: string
    listItemContextKey: string
    limit?: number
  },
  localVariableName?: string,
  valueToSet?: any
) => Promise<string>

interface CollectionWithRecords extends Collection {
  records: CMSCollectionRecord[]
  limit?: number
}
let variables: {
  [key: string]: CollectionWithRecords | string
} = {}
let firebaseDatasources: { [key: string]: CMSCollectionRecord } = {}

export const clearVariables = () => {
  variables = {}
  firebaseDatasources = {}
}

export const useVariable = () => {
  const { id } = useParams()
  const {
    state: {
      data: { collections, globalVariables },
      branding: {
        appName,
        icons: { iosIcon },
      },
      resources,
    },
    language,
  } = useContext(ProjectContext)
  const { toast } = useContext(ProgressContext)
  const applicationName = appName.locales[language] || ''
  const applicationIcon = resources.find(el => el.id === iosIcon)?.url || ''

  const getVariable: GetVariable = (screenConfig, listId, indexInList) => {
    const getVariableValue: GetVariableValue = async (source, listParams, localVariableName, valueToSet) => {
      const { type, collection, fieldName, selector, query, transforms, variableName, componentName } = source
      const getVariableValue = getVariable(screenConfig, listId, indexInList)
      const screenId = screenConfig.id
      switch (type) {
        case VariableSourceType.globalVariable:
          const globalVariable = globalVariables.find(el => el.variableName === variableName)
          switch (variableName) {
            case 'currentUserId':
              return 'CAgla5YRTxbsi71r5VUaArj1d0C3'
            case 'applicationVersion':
              return 'v.1.0.1 (1)'
            case 'applicationName':
              return applicationName
            case 'applicationIcon':
              return applicationIcon
            case 'isAnonymous':
              return 'false'
            case 'isAuthorized':
              return 'false'
            case 'isUnauthenticated':
              return 'false'
            case 'accessLevels':
              if (globalVariable?.variable.source) {
                return getVariableValue(globalVariable.variable.source)
              }
              return ''
            case 'termsAndConditions':
              if (globalVariable?.variable.urlConstant) {
                return globalVariable?.variable.urlConstant
              }
              return ''
          }
          return ''
        case VariableSourceType.collection:
          if (collection) {
            const collectionId = await getCurrentCollectionId(getVariableValue, collection)
            const collectionData = collections.find(el => el.name === collection.name)
            if (collectionData) {
              const property = collectionData.properties.find(el => el.name === fieldName)
              const recordId = selector?.source ? await getVariableValue(selector.source) : selector?.constant
              if (listParams || (localVariableName && !(listId && localVariableName && indexInList !== undefined))) {
                const limit = listParams?.limit
                const name = listParams
                  ? screenId + listParams.listId + listParams.listItemContextKey
                  : screenId + localVariableName
                const variable = variables[name] as CollectionWithRecords | undefined
                if (!variable || (limit && variable.limit !== limit)) {
                  if (recordId) {
                    const firebaseDatasourceName = `${collectionId}/${recordId}`
                    const set = async (record: CMSCollectionRecord) => {
                      firebaseDatasources[firebaseDatasourceName] = record
                      if (fieldName && record[fieldName] && property) {
                        const subCollection =
                          property.type === ValueType.array &&
                          property.accept === ValueType.record &&
                          property.collection &&
                          collections.find(el => el.name === property.collection)
                        const assetsCollection =
                          (property.type === ValueType.image ||
                            property.type === ValueType.video ||
                            property.type === ValueType.audio ||
                            property.type === ValueType.file) &&
                          collections.find(el => el.name === 'assets')
                        if (subCollection) {
                          const recordsIds = record[fieldName].map(getRecordId)
                          if (recordsIds.length) {
                            const set = (records: CMSCollectionRecord[]) => {
                              variables[name] = {
                                ...subCollection,
                                records,
                                limit,
                              }
                            }
                            await getRecords(
                              getVariableValue,
                              set,
                              subCollection.name,
                              query,
                              recordsIds,
                              transforms,
                              limit
                            )
                          }
                        } else if (assetsCollection) {
                          variables[name] = {
                            ...assetsCollection,
                            records: [record[fieldName]],
                          }
                        }
                      } else {
                        variables[name] = {
                          ...collectionData,
                          records: [record],
                        }
                      }
                    }
                    if (!firebaseDatasources[firebaseDatasourceName]) {
                      await getRecord(set, collectionId, recordId)
                    } else {
                      await set(firebaseDatasources[firebaseDatasourceName])
                    }
                  } else if (localVariableName && !transforms) {
                    variables[name] = {
                      ...collectionData,
                      records: [{}],
                    }
                  } else {
                    const set = (records: CMSCollectionRecord[]) => {
                      variables[name] = {
                        ...collectionData,
                        records,
                        limit,
                      }
                    }
                    await getRecords(getVariableValue, set, collectionId, query, undefined, transforms, limit)
                  }
                }
                return String((variables[name] as CollectionWithRecords)?.records.length || 0)
              } else if (recordId && fieldName && property) {
                const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                const valueToReturn = await convertFieldToString(
                  getVariableValue,
                  record[fieldName],
                  property,
                  transforms
                )
                if (listId && localVariableName && indexInList !== undefined) {
                  const name = screenId + listId + localVariableName + indexInList
                  variables[name] = valueToReturn
                }
                return valueToReturn
              } else if (recordId && transforms) {
                const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                return convertRecordToString(getVariableValue, record, transforms)
              } else if (recordId) {
                if (collectionId === 'assets') {
                  const record = await getFirebaseDatasourceRecord(collectionId, recordId)
                  return JSON.stringify(record)
                } else {
                  return `${collectionPrefix}${collectionId}/${recordId}`
                }
              } else if (transforms) {
                return convertRecordToString(getVariableValue, {}, transforms)
              }
            }
          }
          return ''
        case VariableSourceType.localVariable:
          if (variableName) {
            let variable
            if (listId) {
              variable =
                variables[screenId + listId + variableName + indexInList] || variables[screenId + listId + variableName]
            } else {
              const name = screenId + variableName
              variable = variables[name]
            }
            const value = typeof variable === 'string' && variable
            const collectionData = typeof variable !== 'string' && variable
            if (value !== false) {
              return convertFieldToString(
                getVariableValue,
                value,
                { name: '', type: ValueType.string, position: 0 },
                transforms
              )
            } else if (collectionData !== false) {
              const { name, records } = collectionData
              const collectionId = name
              const record = records[indexInList || 0]
              const recordId = record.id as string | undefined
              if (recordId) {
                const firebaseDatasourceName = `${collectionId}/${recordId}`
                firebaseDatasources[firebaseDatasourceName] = record
              }
              return getVariableValue(
                {
                  type: VariableSourceType.collection,
                  collection: { name: collectionId },
                  selector: { constant: recordId },
                  fieldName,
                  query,
                  transforms,
                },
                listParams,
                localVariableName,
                valueToSet
              )
            }
          }
          return ''
        case VariableSourceType.component:
          if (componentName) {
            let value = ''
            const input: HTMLInputElement | null = document.querySelector(`[id*=".${componentName}."] > input`)
            const component = findComponent(screenConfig, 'name', componentName)
            const fieldValue = component && fieldName ? component[fieldName as keyof ScreenComponent] : undefined
            if (fieldValue && fieldName === 'text') {
              const text = fieldValue as LocalizedContent
              value = await getTextValue(getVariableValue, language, text)
            } else if (fieldValue && (fieldName === 'date' || fieldName === 'displayDate')) {
              const date = fieldValue as StringVariable
              value = date.source ? await getVariableValue(date.source) : date.constant || ''
            }
            if (input) {
              if (valueToSet !== undefined) {
                input.value = valueToSet
              }
              if (value !== input.value) {
                value = input.value
              }
            }
            const valueToReturn = await convertFieldToString(
              getVariableValue,
              value,
              { name: '', type: ValueType.string, position: 0 },
              transforms
            )
            if (localVariableName) {
              const name = screenId + localVariableName
              variables[name] = valueToReturn
            }
            return valueToReturn
          }
          return ''
        case VariableSourceType.contextVariable:
          if (localVariableName && valueToSet !== undefined) {
            const name = screenId + localVariableName
            variables[name] = valueToSet
          }
          return ''
        default:
          return ''
      }
    }
    return getVariableValue
  }

  const getFirebaseDatasourceRecord = async (collectionId: string, recordId: string) => {
    const firebaseDatasourceName = `${collectionId}/${recordId}`
    if (!firebaseDatasources[firebaseDatasourceName]) {
      const set = (record: CMSCollectionRecord) => {
        firebaseDatasources[firebaseDatasourceName] = record
      }
      await getRecord(set, collectionId, recordId)
    }
    return firebaseDatasources[firebaseDatasourceName]
  }

  const getRecord = (set: (record: CMSCollectionRecord) => void, collectionId: string, recordId: string) =>
    new Promise(resolve => {
      const setRecord = async (record: CMSCollectionRecord) => {
        await set(record)
        resolve(true)
      }
      CMSService.getRecord(id as string, collectionId, recordId, true)
        .then(res => setRecord(res.data || {}))
        .catch(err => {
          toast(err)
          setRecord({})
        })
    })

  const getRecords = (
    getVariableValue: GetVariableValue,
    set: (records: CMSCollectionRecord[]) => void,
    collectionId: string,
    query?: TableQuery,
    ids?: string[],
    transforms?: VariableTransform[],
    limit?: number
  ) =>
    new Promise(async resolve => {
      const setRecords = async (records: CMSCollectionRecord[]) => {
        await set(records)
        resolve(true)
      }
      const firstTransform = transforms?.find(el => el.transform === VariableTransformTransform.first)
      CMSService.getRecords(
        id as string,
        collectionId,
        language,
        query,
        ids,
        undefined,
        firstTransform ? 1 : limit,
        getVariableValue,
        true
      )
        .then(res => setRecords(res.data || []))
        .catch(err => {
          toast(err, true)
          setRecords([])
        })
    })

  const convertFieldToString = async (
    getVariableValue: GetVariableValue,
    value: any,
    property: CollectionProperty,
    transforms?: VariableTransform[]
  ) => {
    let valueToReturn = ''
    const { type, collection } = property
    const fieldTransform = transforms?.find(el => el.transform === VariableTransformTransform.field)
    const formatTimeIntervalTransform = transforms?.find(
      el => el.transform === VariableTransformTransform.formatTimeInterval
    )
    const compareNotEqualTransform = transforms?.find(el => el.transform === VariableTransformTransform.compareNotEqual)
    const compareEqualTransform = transforms?.find(el => el.transform === VariableTransformTransform.compareEqual)
    const boolNotTransform = transforms?.find(el => el.transform === VariableTransformTransform.boolNot)
    const existsTransform = transforms?.find(el => el.transform === VariableTransformTransform.exists)
    const isNotEmptyTransform = transforms?.find(el => el.transform === VariableTransformTransform.isNotEmpty)
    const formatTimeAgoTransform = transforms?.find(el => el.transform === VariableTransformTransform.formatTimeAgo)
    const trimWhitespacesAndNewlinesTransform = transforms?.find(
      el => el.transform === VariableTransformTransform.trimWhitespacesAndNewlines
    )
    const conditionalValueTransform = transforms?.find(
      el => el.transform === VariableTransformTransform.conditionalValue
    )
    if (value) {
      if (
        type === ValueType.accessLevel ||
        type === ValueType.string ||
        type === ValueType.number ||
        type === ValueType.boolean ||
        type === ValueType.url ||
        type === ValueType.json ||
        type === ValueType.date ||
        type === ValueType.dateTime
      ) {
        valueToReturn = value
      } else if (type === ValueType.richText) {
        valueToReturn = marked(value, { async: false }) as string
      } else if (type === ValueType.record && collection) {
        valueToReturn = value
        if (fieldTransform && fieldTransform.fieldName) {
          const id = getRecordId(valueToReturn)
          valueToReturn =
            fieldTransform.fieldName === 'id'
              ? id
              : await getVariableValue({
                  type: VariableSourceType.collection,
                  collection: { name: collection },
                  selector: { constant: id },
                  fieldName: fieldTransform.fieldName,
                })
        }
      } else if (
        type === ValueType.image ||
        type === ValueType.video ||
        type === ValueType.audio ||
        type === ValueType.file
      ) {
        if (fieldTransform && fieldTransform.fieldName) {
          valueToReturn = value[fieldTransform.fieldName]
        } else {
          valueToReturn = JSON.stringify(value)
        }
      } else if (type === ValueType.array || type === ValueType.coordinate || type === ValueType.keyValueMap) {
        valueToReturn = JSON.stringify(value)
      }
      if (formatTimeIntervalTransform && !isNaN(+valueToReturn)) {
        let time = ''
        const fullTime = new Date(+valueToReturn * 1000).toISOString().slice(11, 19)
        const hours = fullTime.slice(0, 2)
        time = hours === '00' ? fullTime.slice(3, 8) : fullTime
        valueToReturn = time.startsWith('0') ? time.slice(1) : time
      }
      if (formatTimeAgoTransform && value) {
        valueToReturn = format(value)
      }
    }
    if (trimWhitespacesAndNewlinesTransform) {
      valueToReturn = valueToReturn.trim()
    }
    if (compareNotEqualTransform && compareNotEqualTransform.value?.source) {
      valueToReturn =
        valueToReturn !== (await getVariableValue(compareNotEqualTransform.value.source)) ? 'true' : 'false'
    }
    if (compareEqualTransform) {
      if (compareEqualTransform.value?.source) {
        valueToReturn =
          valueToReturn === (await getVariableValue(compareEqualTransform.value.source)) ? 'true' : 'false'
      } else if (compareEqualTransform.value?.calendarStyleConstant) {
        valueToReturn = valueToReturn === compareEqualTransform.value.calendarStyleConstant ? 'true' : 'false'
      }
    }
    if (boolNotTransform) {
      valueToReturn = valueToReturn === 'false' ? 'true' : 'false'
    }
    if (existsTransform || isNotEmptyTransform) {
      valueToReturn = !!valueToReturn ? 'true' : 'false'
    }
    if (conditionalValueTransform) {
      if (conditionalValueTransform.value?.colorConstant && conditionalValueTransform.value2?.colorConstant) {
        const {
          value: { colorConstant: cC1 },
          value2: { colorConstant: cC2 },
        } = conditionalValueTransform
        valueToReturn = valueToReturn === 'true' ? cC1 : cC2
      } else if (conditionalValueTransform.value?.imageConstant && conditionalValueTransform.value2?.imageConstant) {
        const {
          value: {
            imageConstant: { resourceId: rId1 },
          },
          value2: {
            imageConstant: { resourceId: rId2 },
          },
        } = conditionalValueTransform
        valueToReturn = resources.find(el => el.id === (valueToReturn === 'true' ? rId1 : rId2))?.url || ''
      } else if (conditionalValueTransform.value?.source && conditionalValueTransform.value2?.source) {
        const {
          value: { source: s1 },
          value2: { source: s2 },
        } = conditionalValueTransform
        valueToReturn = await getVariableValue(valueToReturn === 'true' ? s1 : s2)
      }
    }
    return valueToReturn
  }

  const convertRecordToString = async (
    getVariableValue: GetVariableValue,
    record: CMSCollectionRecord,
    transforms: VariableTransform[]
  ) => {
    const getParticipantName = async (participantId: string, participantNameKeys: string[]) => {
      const fields: string[] = []
      for (const fieldName of participantNameKeys) {
        fields.push(
          await getVariableValue({
            type: VariableSourceType.collection,
            collection: { name: 'profiles' },
            selector: { constant: participantId },
            fieldName,
          })
        )
      }
      return fields
    }
    let valueToReturn = ''
    const conversationTitleTransform = transforms.find(
      el => el.transform === VariableTransformTransform.conversationTitle
    )
    const conversationImageTransform = transforms.find(
      el => el.transform === VariableTransformTransform.conversationImage
    )
    const existsTransform = transforms?.find(el => el.transform === VariableTransformTransform.exists)
    const isNotEmptyTransform = transforms?.find(el => el.transform === VariableTransformTransform.isNotEmpty)
    const identifierTransform = transforms.find(el => el.transform === VariableTransformTransform.identifier)
    const conditionalValueTransform = transforms?.find(
      el => el.transform === VariableTransformTransform.conditionalValue
    )
    if (conversationTitleTransform) {
      if (conversationTitleTransform.fieldName && record[conversationTitleTransform.fieldName]) {
        valueToReturn = record[conversationTitleTransform.fieldName]
      } else if (
        conversationTitleTransform.participantsKey &&
        record[conversationTitleTransform.participantsKey]?.length === 2
      ) {
        const currentUserId = await getVariableValue({
          type: VariableSourceType.globalVariable,
          variableName: 'currentUserId',
        })
        const participantId = record[conversationTitleTransform.participantsKey].find(
          (el: string) => el !== currentUserId
        )
        if (conversationTitleTransform.participantNameKeys) {
          valueToReturn = (
            await getParticipantName(participantId, conversationTitleTransform.participantNameKeys)
          ).join(' ')
        }
      }
    }
    if (conversationImageTransform) {
      if (conversationImageTransform.fieldName && record[conversationImageTransform.fieldName]?.url) {
        valueToReturn = record[conversationImageTransform.fieldName].url
      } else if (
        conversationImageTransform.participantsKey &&
        record[conversationImageTransform.participantsKey]?.length === 2
      ) {
        const currentUserId = await getVariableValue({
          type: VariableSourceType.globalVariable,
          variableName: 'currentUserId',
        })
        const participantId = record[conversationImageTransform.participantsKey].find(
          (el: string) => el !== currentUserId
        )
        if (conversationImageTransform.participantImageKey) {
          valueToReturn = await getVariableValue({
            type: VariableSourceType.collection,
            collection: { name: 'profiles' },
            selector: { constant: participantId },
            fieldName: conversationImageTransform.participantImageKey,
          })
          if (!valueToReturn && conversationImageTransform.participantNameKeys) {
            valueToReturn = createAvatar(
              (await getParticipantName(participantId, conversationImageTransform.participantNameKeys))
                .map(el => el[0] || '')
                .join('')
            )
          }
        }
      }
    }
    if (existsTransform || isNotEmptyTransform) {
      valueToReturn = record.id ? 'true' : 'false'
    }
    if (identifierTransform && record.id) {
      valueToReturn = record.id
    }
    if (conditionalValueTransform) {
      if (conditionalValueTransform.value?.colorConstant && conditionalValueTransform.value2?.colorConstant) {
        const {
          value: { colorConstant: cC1 },
          value2: { colorConstant: cC2 },
        } = conditionalValueTransform
        valueToReturn = valueToReturn === 'true' ? cC1 : cC2
      } else if (conditionalValueTransform.value?.imageConstant && conditionalValueTransform.value2?.imageConstant) {
        const {
          value: {
            imageConstant: { resourceId: rId1 },
          },
          value2: {
            imageConstant: { resourceId: rId2 },
          },
        } = conditionalValueTransform
        valueToReturn = resources.find(el => el.id === (valueToReturn === 'true' ? rId1 : rId2))?.url || ''
      } else if (conditionalValueTransform.value?.source && conditionalValueTransform.value2?.source) {
        const {
          value: { source: s1 },
          value2: { source: s2 },
        } = conditionalValueTransform
        valueToReturn = await getVariableValue(valueToReturn === 'true' ? s1 : s2)
      }
    }
    return valueToReturn
  }

  const removeOldLocalVariable = (screenConfig: Screen, listId: string, listItemContextKey: string) => {
    const screenId = screenConfig.id
    delete variables[screenId + listId + listItemContextKey]
  }

  return { getVariable, removeOldLocalVariable }
}

export const collectionPrefix = 'collection://'

export const removePrefix = (recordPath: string) => recordPath.replace(collectionPrefix, '')

export const getRecordId = (recordPath: string) => recordPath.split('/').slice(-1)[0]

const getCurrentCollectionId = async (getVariableValue: GetVariableValue, collection: CollectionVariable) => {
  let collectionId = collection.name
  if (collection.params) {
    for (const el of Object.keys(collection.params)) {
      collectionId = collectionId.replace(`{${el}}`, await getVariableValue(collection.params[el]))
    }
  }
  return collectionId
}

const createAvatar = (initials: string) => {
  const randomColor = () => '#' + (0x1000000 | (Math.random() * 0xffffff)).toString(16).substr(1, 6)
  const canvas = document.createElement('canvas')
  canvas.width = 100
  canvas.height = 100
  const context = canvas.getContext('2d')
  if (context) {
    context.fillStyle = randomColor()
    context.beginPath()
    context.ellipse(canvas.width / 2, canvas.height / 2, canvas.width / 2, canvas.height / 2, 0, 0, Math.PI * 2)
    context.fill()
    context.font = '60px serif'
    context.fillStyle = randomColor()
    context.textAlign = 'center'
    context.textBaseline = 'middle'
    context.fillText(initials, canvas.width / 2, canvas.height / 2)
  }
  return canvas.toDataURL()
}
