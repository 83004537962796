import styled, { css } from 'styled-components'

export const TableWrapper = styled.div<{ empty: boolean; hasStickyColumns?: boolean; big?: boolean; mt?: string }>`
  margin-top: ${props => props.mt || '30px'};
  border: 1px solid ${props => props.theme.colors.darkBlue_100_lightBorder};
  border-radius: ${props => (props.big ? 16 : 8)}px;
  min-width: fit-content;
  & table {
    position: relative;
    height: ${props => props.empty && '352px'};
  }
  & tbody tr:hover {
    cursor: pointer;
    background: ${props => props.theme.colors.darkBlue_50};
    & td {
      background: ${props => props.theme.colors.darkBlue_50};
    }
  }
  & th,
  & td {
    white-space: nowrap;
    color: ${props => props.theme.colors.darkText};
    border-bottom: 1px solid ${props => props.theme.colors.darkBlue_100_lightBorder};
    padding: ${props => (props.big ? '15px 20px' : '8px')};
    & input {
      float: left;
      margin-left: 12px;
    }
    ${props =>
      props.hasStickyColumns &&
      css`
        &:nth-last-child(2) {
          position: sticky;
          right: 24px;
          &:after {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            width: 30px;
            transform: translateX(-100%);
            box-shadow: inset -10px 0 8px -8px ${props.theme.colors.darkBlue_100_lightBorder};
            transition: ${props.theme.transition.primary};
            content: '';
            pointer-events: none;
          }
        }
        &:nth-last-child(1) {
          min-width: 38px;
          max-width: 38px;
          position: sticky;
          right: -30px;
        }
      `}
  }
  & th {
    background-color: ${props => props.theme.colors.secondaryButtonHoverBg};
    ${props =>
      props.hasStickyColumns &&
      css`
        &:nth-child(1),
        &:nth-child(2),
        &:nth-last-child(2),
        &:nth-last-child(1) {
          z-index: 2;
        }
      `}
  }
  & td {
    background-color: ${props => props.theme.colors.secondaryBackground};
  }
  & a {
    text-decoration: none;
    color: ${props => props.theme.colors.primaryText};
  }
  & thead {
    background: ${props => props.theme.colors.secondaryButtonHoverBg};
    & tr:first-child th {
      &:first-child {
        border-top-left-radius: ${props => (props.big ? 16 : 8) - 1}px;
      }
      &:last-child {
        border-top-right-radius: ${props => (props.big ? 16 : 8) - 1}px;
      }
    }
  }
  & tr:last-child td {
    border-bottom: none;
    &:first-child {
      border-bottom-left-radius: ${props => (props.big ? 16 : 8) - 1}px;
    }
    &:last-child {
      border-bottom-right-radius: ${props => (props.big ? 16 : 8) - 1}px;
    }
  }
`

export const ActionsIconTbodyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 12px;
  & svg {
    cursor: pointer;
    & circle {
      transition: 300ms ease-out;
    }
    &:hover circle {
      fill: ${props => props.theme.colors.iconHover};
    }
  }
`
