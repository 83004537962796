import { memo, ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './Menu.styled'

import { rightSidebarId } from 'hooks'
import { OpenClose } from './OpenClose'

interface Props {
  firstChild: ReactElement
  secondChild?: ReactElement
  right?: boolean
  bottom?: boolean
  isOpen?: (open: boolean) => void
  openByDefault?: boolean
  insidePicker?: boolean
}

export const Menu: React.FC<Props> = memo(
  ({ firstChild, secondChild, right, bottom, isOpen, openByDefault, insidePicker }) => {
    const ref = useRef<HTMLDivElement>(null)
    const secondChildRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(openByDefault !== undefined ? openByDefault : !bottom)
    const themeContext = useContext(ThemeContext)
    const left = !right && !bottom

    const onResizeMouseDown = ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
      document.onmousemove = e => {
        if (ref.current) {
          document.body.style.userSelect = 'none'
          document.body.style.cursor = bottom ? 'ns-resize' : 'ew-resize'
          currentTarget.style.borderColor = themeContext.colors.darkBlue_300
          if (bottom) {
            const newHeight = window.innerHeight - e.pageY
            if (newHeight >= 220 && newHeight <= 450) {
              ref.current.style.maxHeight = `${newHeight}px`
              ref.current.style.minHeight = `${newHeight}px`
            } else if (newHeight < 220) {
              ref.current.style.maxHeight = `${220}px`
              ref.current.style.minHeight = `${220}px`
            } else {
              ref.current.style.maxHeight = `${450}px`
              ref.current.style.minHeight = `${450}px`
            }
          } else {
            const newWidth = right ? window.innerWidth - e.pageX : e.pageX - 60
            if (newWidth >= 220 && newWidth <= 450) {
              ref.current.style.maxWidth = `${newWidth}px`
              ref.current.style.minWidth = `${newWidth}px`
            } else if (newWidth < 220) {
              ref.current.style.maxWidth = `${220}px`
              ref.current.style.minWidth = `${220}px`
            } else {
              ref.current.style.maxWidth = `${450}px`
              ref.current.style.minWidth = `${450}px`
            }
          }
        }
      }
      document.onmouseup = () => {
        document.body.style.removeProperty('user-select')
        document.body.style.removeProperty('cursor')
        currentTarget.style.removeProperty('border-color')
        document.onmousemove = () => {}
      }
    }

    const onDividerMouseDown = ({ currentTarget }: React.MouseEvent<HTMLDivElement>) => {
      document.onmousemove = (e: MouseEvent) => {
        if (secondChildRef.current) {
          document.body.style.userSelect = 'none'
          document.body.style.cursor = 'ns-resize'
          currentTarget.style.borderColor = themeContext.colors.darkBlue_300
          const maxHeight = window.innerHeight - 96
          const newHeight = window.innerHeight - e.pageY
          if (newHeight >= 38 && newHeight <= maxHeight) {
            secondChildRef.current.style.minHeight = `${newHeight}px`
            secondChildRef.current.style.maxHeight = `${newHeight}px`
          } else if (newHeight < 38) {
            secondChildRef.current.style.minHeight = `${38}px`
            secondChildRef.current.style.maxHeight = `${38}px`
          } else {
            secondChildRef.current.style.minHeight = `${maxHeight}px`
            secondChildRef.current.style.maxHeight = `${maxHeight}px`
          }
        }
      }

      document.onmouseup = () => {
        document.body.style.removeProperty('user-select')
        document.body.style.removeProperty('cursor')
        currentTarget.style.removeProperty('border-color')
        document.onmousemove = () => {}
        document.onmouseup = () => {}
      }
    }

    useEffect(() => {
      if (openByDefault === undefined && right && !open) {
        setOpen(true)
      }
    }, [right, firstChild])

    useEffect(() => {
      if (isOpen) {
        isOpen(open)
      }
    }, [open])

    useEffect(() => {
      setOpen(openByDefault !== undefined ? openByDefault : !bottom)
    }, [openByDefault, bottom])

    return (
      <styled.Container
        paddingRight={open && left}
        paddingTop={open && !!bottom}
        left={left}
        insidePicker={!!insidePicker}
      >
        {!insidePicker && open && (
          <styled.Resize onMouseDown={onResizeMouseDown} left={left} right={!!right} bottom={!!bottom} />
        )}
        {!insidePicker && <OpenClose open={open} setOpen={setOpen} left={left} right={!!right} bottom={!!bottom} />}
        <styled.Navigation open={open} ref={ref} right={!!right} bottom={!!bottom} insidePicker={!!insidePicker}>
          <styled.Child id={right ? rightSidebarId : undefined} right={right}>
            {firstChild}
          </styled.Child>
          {secondChild && (
            <>
              <styled.Divider onMouseDown={onDividerMouseDown} />
              <styled.Child second ref={secondChildRef}>
                {secondChild}
              </styled.Child>
            </>
          )}
        </styled.Navigation>
      </styled.Container>
    )
  }
)
