import React from 'react'
import { ISvg, Svg } from '../Svg'

const TemplateChoice: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <rect x="5.25" y="2.25" width="9.5" height="15.5" rx="2.25" stroke="#212133" strokeWidth="1.5" />
    <path d="M8 5H12" stroke="#212133" strokeWidth="1.5" strokeLinecap="round" />
  </Svg>
)

export default TemplateChoice
