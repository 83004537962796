import { useContext, useEffect, useRef, useState } from 'react'

import { Icon, Name, Recaptcha, recaptchaVerifierId } from 'components'
import { ProgressContext } from 'context'
import {
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getMultiFactorResolver,
} from 'firebase/auth'
import { updateUserInfo } from 'hooks'
import { auth, readableError, signInWithGithub, signInWithGoogle } from 'services/firebase'

const tooManyRequestsErrorCode = 'auth/too-many-requests'
const multiFactorAuthRequiredErrorCode = 'auth/multi-factor-auth-required'
const authErrorCode = 'auth/error-code:-47'

interface Props {
  scrollIntoView?: () => void
}

export const LogInForm: React.FC<Props> = ({ scrollIntoView }) => {
  const { startLoader, stopLoader } = useContext(ProgressContext)
  const [wait, setWait] = useState(false)
  const [emailForm, showEmailForm] = useState(false)
  const [resetPasswordForm, showResetPasswordForm] = useState(false)
  const [passwordForm, showPasswordForm] = useState(false)
  const [verificationId, setVerificationId] = useState('')
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(null)
  const [signUpForm, showSignUpForm] = useState(false)
  const [linkSent, setLinkSent] = useState(false)
  const [displayName, setDisplayName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [tooManyRequestsError, setToManyRequestsError] = useState(false)
  const [authError, setAuthError] = useState(false)
  const [error, setError] = useState('')
  const autofocusRef = useRef<HTMLInputElement>(null)

  const startLoading = () => {
    setWait(true)
    startLoader()
  }

  const stopLoading = () => {
    stopLoader()
    setWait(false)
  }

  const onCatch = (err: any) => {
    setToManyRequestsError(err.code === tooManyRequestsErrorCode)
    setAuthError(err.code === authErrorCode)
    setError(readableError(err.message))
  }

  const onLoginWithGithub = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    signInWithGithub().catch(err => onCatch(err))
  }

  const onLoginWithGoogle = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    signInWithGoogle().catch(err => onCatch(err))
  }

  const onLoginWithEmail = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (email && password) {
      startLoading()
      auth
        .signInWithEmailAndPassword(email, password)
        .catch(err => {
          if (err.code === multiFactorAuthRequiredErrorCode) {
            const resolver = getMultiFactorResolver(auth, err)
            setResolver(resolver)
            if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
              const phoneInfoOptions = {
                multiFactorHint: resolver.hints[0],
                session: resolver.session,
              }
              // @ts-ignore
              const phoneAuthProvider = new PhoneAuthProvider(auth)
              // @ts-ignore
              const recaptchaVerifier = new RecaptchaVerifier(recaptchaVerifierId, { size: 'invisible' }, auth)
              return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then(verificationId => {
                setVerificationId(verificationId)
                if (autofocusRef.current) {
                  autofocusRef.current.focus()
                }
              })
            }
          } else {
            onCatch(err)
          }
        })
        .finally(() => stopLoading())
    }
  }

  const checkCode = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (verificationId && resolver) {
      startLoading()
      const cred = PhoneAuthProvider.credential(verificationId, code)
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
      resolver
        .resolveSignIn(multiFactorAssertion)
        .catch(err => onCatch(err))
        .finally(() => stopLoading())
    }
  }

  const checkEmail = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    startLoading()
    auth
      .fetchSignInMethodsForEmail(email)
      .then(res => {
        if (res.length) {
          showPasswordForm(true)
        } else {
          showSignUpForm(true)
        }
        if (autofocusRef.current) {
          autofocusRef.current.focus()
        }
      })
      .catch(err => onCatch(err))
      .finally(() => stopLoading())
  }

  const onSignUp = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (email && password && displayName) {
      startLoading()
      auth
        .createUserWithEmailAndPassword(email, password)
        .then(res => res.user?.updateProfile({ displayName }))
        .then(() => updateUserInfo())
        .catch(err => onCatch(err))
        .finally(() => stopLoading())
    }
  }

  const sendLink = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    auth
      .sendPasswordResetEmail(email)
      .then(() => setLinkSent(true))
      .catch(err => onCatch(err))
  }

  const closeAll = (e?: React.MouseEvent<HTMLButtonElement>) => {
    e?.preventDefault()
    setDisplayName('')
    setEmail('')
    setPassword('')
    setCode('')
    showEmailForm(false)
    showResetPasswordForm(false)
    showPasswordForm(false)
    setVerificationId('')
    setResolver(null)
    showSignUpForm(false)
    setLinkSent(false)
  }

  useEffect(() => {
    setToManyRequestsError(false)
    setAuthError(false)
    setError('')
  }, [
    email,
    password,
    displayName,
    emailForm,
    resetPasswordForm,
    passwordForm,
    verificationId,
    resolver,
    signUpForm,
    linkSent,
  ])

  useEffect(() => {
    scrollIntoView?.()
  }, [error, emailForm, resetPasswordForm, passwordForm, verificationId, resolver, signUpForm, linkSent])

  const resetPasswordElement = <p onClick={() => showResetPasswordForm(true)}>Reset password</p>
  const forgotPasswordElement = <p onClick={() => showResetPasswordForm(true)}>Forgot password</p>
  const errorElement = (
    <section>
      {authError ? (
        <>
          Access is currently whitelisted. We're excited to onboard you! Reserve your session{' '}
          <a href="https://calendly.com/gurucan/codeplatform" target="_blank" rel="noreferrer">
            here
          </a>
          .
        </>
      ) : (
        error
      )}
    </section>
  )

  return (
    <>
      {linkSent ? (
        <>
          <h2>Link was sent to your email.</h2>
          <p>Check {email}</p>
          <div>
            <form>
              <div />
              <button name="secondary" onClick={closeAll}>
                Go back
              </button>
            </form>
          </div>
        </>
      ) : resetPasswordForm ? (
        <>
          <h2>
            <Back onClick={() => showResetPasswordForm(false)} />
            Enter an email to send link
          </h2>
          <p>We'll send password recovery link to your email.</p>
          <div>
            <form>
              {error && errorElement}
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                data-1p-ignore
              />
              <button name="secondary" disabled={!email || wait} onClick={sendLink} type="submit">
                Continue
              </button>
              {tooManyRequestsError && resetPasswordElement}
            </form>
          </div>
        </>
      ) : signUpForm ? (
        <>
          <h2>
            <Back onClick={() => showSignUpForm(false)} />
            Enter an email to get started
          </h2>
          <p>We'll check if you have an account and provide next steps.</p>
          <div>
            <form>
              {error && errorElement}
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                data-1p-ignore
                disabled
              />
              <input
                ref={autofocusRef}
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
                data-1p-ignore
              />
              <input
                placeholder="Full name"
                value={displayName}
                onChange={e => setDisplayName(e.target.value)}
                required
                data-1p-ignore
              />
              <article>
                By selecting Agree and continue below, I agree to Project Name’s <a href="/#">Terms of Service</a>,
                Payments <a href="/#">Terms of Service</a>, <a href="/#">Privacy Policy</a>, and{' '}
                <a href="/#">Nondiscrimination Policy</a>.
              </article>
              <button
                name="secondary"
                disabled={!email || !password || !displayName || wait}
                onClick={onSignUp}
                type="submit"
              >
                Agree and continue
              </button>
              {tooManyRequestsError && resetPasswordElement}
            </form>
          </div>
        </>
      ) : verificationId && resolver ? (
        <>
          <h2>
            <Back
              onClick={() => {
                setVerificationId('')
                setResolver(null)
              }}
            />
            Enter code
          </h2>
          <p>We'll check</p>
          <div>
            <form>
              {error && errorElement}
              <input
                ref={autofocusRef}
                type="text"
                placeholder="Enter code"
                value={code}
                onChange={e => setCode(e.target.value)}
                required
              />
              <button name="secondary" disabled={!code || wait} onClick={checkCode} type="submit">
                Continue
              </button>
              {tooManyRequestsError && resetPasswordElement}
            </form>
          </div>
        </>
      ) : passwordForm ? (
        <>
          <h2>
            <Back onClick={() => showPasswordForm(false)} />
            👋 Welcome back!
          </h2>
          <p>Enter your password to login.</p>
          <div>
            <form>
              {error && errorElement}
              <input
                ref={autofocusRef}
                type="password"
                placeholder="Password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                required
              />
              <button name="secondary" disabled={!password || wait} onClick={onLoginWithEmail} type="submit">
                Continue
              </button>
              {tooManyRequestsError ? resetPasswordElement : forgotPasswordElement}
            </form>
            {wait && <Recaptcha />}
          </div>
        </>
      ) : emailForm ? (
        <>
          <h2>
            <Back onClick={() => showEmailForm(false)} />
            Enter an email to get started
          </h2>
          <p>We'll check if you have an account and provide next steps.</p>
          <div>
            <form>
              {error && errorElement}
              <input
                type="email"
                placeholder="Email address"
                value={email}
                onChange={e => setEmail(e.target.value)}
                required
                data-1p-ignore
              />
              <button name="secondary" disabled={!email || wait} onClick={checkEmail} type="submit">
                Continue
              </button>
              {tooManyRequestsError && resetPasswordElement}
            </form>
          </div>
        </>
      ) : (
        <>
          {!scrollIntoView && (
            <>
              <h2>👋 Welcome</h2>
              <p>Select authentication provider to continue with</p>
            </>
          )}
          <div>
            <form>
              {error && errorElement}
              <button onClick={onLoginWithGithub}>
                <Icon name={Name.REPOSITORY_GITHUB} width={24} height={24} />
                Continue with Github
              </button>
              <button onClick={onLoginWithGoogle}>
                <Icon name={Name.SECOND_ORDER_GOOGLE} />
                Continue with Google
              </button>
              <button onClick={() => showEmailForm(true)}>
                <Icon name={Name.SECOND_ORDER_EMAIL} />
                Continue with Email
              </button>
              {tooManyRequestsError && resetPasswordElement}
            </form>
          </div>
        </>
      )}
    </>
  )
}

interface BackProps {
  onClick: () => void
}

const Back: React.FC<BackProps> = ({ onClick }) => (
  <figure onClick={onClick}>
    <span />
    <span />
    <div />
  </figure>
)
