import React, { useContext, useEffect, useRef, useState } from 'react'

import * as styled from './Options.styled'

import { ProgressContext, ProjectContext } from 'context'
import { getResources, getSFSymbols, getUnsplash } from 'services/images'
import { kindOfImagesOptions } from '../Selects'

const perPage = 9

interface Props {
  onFileChange: (file: any) => void
  onSFSymbolClick: (url: string, name: string) => void
  onChange: (val: string) => void
  currentKindOfImages: string
  currentCategory: string
  search: string
}

export const Options: React.FC<Props> = ({
  onFileChange,
  onSFSymbolClick,
  onChange,
  currentKindOfImages,
  currentCategory,
  search,
}) => {
  const { toast } = useContext(ProgressContext)
  const {
    state: { resources },
  } = useContext(ProjectContext)
  const iconsContainerRef = useRef<HTMLDivElement>(null)
  const loadMoreRef = useRef<HTMLDivElement>(null)
  const [values, setValues] = useState<{ url: string; name: string; id?: string }[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(1)
  const [localSearch, setLocalSearch] = useState(search)
  const [localLoading, setLocalLoading] = useState(true)
  const isSFSymbols = currentKindOfImages === kindOfImagesOptions[0].value
  const isResources = currentKindOfImages === kindOfImagesOptions[2].value

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setLocalSearch(search)
    }, 600)
    return () => clearTimeout(debounceFn)
  }, [search])

  useEffect(() => {
    setValues([])
    setPage(1)
  }, [currentKindOfImages, currentCategory, localSearch])

  useEffect(() => {
    const abortController = new AbortController()
    getValues(abortController)
    return () => {
      abortController.abort()
    }
  }, [currentKindOfImages, currentCategory, localSearch, page])

  useEffect(() => {
    const detectLoadMore = () => {
      if (iconsContainerRef.current && loadMoreRef.current) {
        const { y, height } = iconsContainerRef.current.getBoundingClientRect()
        if (loadMoreRef.current.getBoundingClientRect().y - 1 <= y + height) {
          setLocalLoading(true)
          setPage(page => page + 1)
        }
      }
    }
    iconsContainerRef.current?.addEventListener('scroll', detectLoadMore)
    return () => iconsContainerRef.current?.removeEventListener('scroll', detectLoadMore)
  }, [])

  const getValues = async (abortController: AbortController) => {
    setLocalLoading(true)
    const { values, total } = isSFSymbols
      ? getSFSymbols(currentCategory, localSearch, page, perPage)
      : isResources
      ? getResources(resources, localSearch, page, perPage)
      : await getUnsplash(currentCategory, localSearch, page, perPage, abortController)
    setValues(oldValues => (page === 1 ? values : [...oldValues, ...values]))
    setTotal(total)
    setLocalLoading(false)
  }

  const onUnsplashClick = (url: string, name: string) => {
    const format = new URLSearchParams(url).get('fm')
    onFileChange(null)
    fetch(url)
      .then(res => res.blob())
      .then(res => onFileChange(new File([res], `${name}.${format}`, { type: res.type })))
      .catch(err => toast(err))
  }

  return (
    <styled.Items ref={iconsContainerRef}>
      {values.map(el => (
        <styled.Item
          key={el.url}
          onClick={() =>
            isSFSymbols
              ? onSFSymbolClick(el.url, el.name)
              : isResources
              ? onChange(el.id as string)
              : onUnsplashClick(el.url, el.name)
          }
          isSFSymbols={isSFSymbols}
        >
          <styled.ItemImg isSFSymbols={isSFSymbols}>
            <img src={el.url} alt={el.name} loading="lazy" />
          </styled.ItemImg>
          <styled.ItemText>{el.name}</styled.ItemText>
        </styled.Item>
      ))}
      {localLoading ? (
        <styled.Hint>Loading...</styled.Hint>
      ) : !!total ? (
        total > page * perPage && <div ref={loadMoreRef} />
      ) : (
        <styled.Hint>No results</styled.Hint>
      )}
    </styled.Items>
  )
}
