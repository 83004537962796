import { Box } from '@mui/material'
import React, { useContext, useMemo } from 'react'

import { AnyVariableInput, CommonPicker, getFieldIcon, getValueType, Icon, Name } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SettingNumberField, SubWrapper } from 'partials/RightSidebar/components'
import { assetsId } from 'services/cms'
import {
  LocalVariable,
  Screen,
  ValueType,
  VariableSource,
  VariableTransform,
  VariableTransformResizeType,
  VariableTransformTransform,
} from 'utils'

const variableTransformResizeTypeValues = Object.values(VariableTransformResizeType).map(el => ({
  value: el,
  label: el,
}))

export const isTransformsNotValid = (transforms?: VariableTransform[]) =>
  !!transforms?.find(
    el =>
      !el.transform ||
      (el.transform === VariableTransformTransform.field && !el.fieldName) ||
      ((el.transform === VariableTransformTransform.conversationTitle ||
        el.transform === VariableTransformTransform.conversationImage) &&
        (!el.participantsKey || !el.fieldName || !el.participantNameKeys)) ||
      (el.transform === VariableTransformTransform.conversationImage && !el.participantImageKey) ||
      (el.transform === VariableTransformTransform.resize && (!el.resizeType || !el.size?.width || !el.size.height)) ||
      ((el.transform === VariableTransformTransform.compareEqual ||
        el.transform === VariableTransformTransform.compareNotEqual ||
        el.transform === VariableTransformTransform.compareGreater ||
        el.transform === VariableTransformTransform.compareGreaterOrEqual ||
        el.transform === VariableTransformTransform.compareLess ||
        el.transform === VariableTransformTransform.compareLessOrEqual) &&
        !el.value) ||
      (el.transform === VariableTransformTransform.conditionalValue && (!el.value || !el.value2))
  )

interface Props {
  valueType?: ValueType
  transforms?: VariableTransform[]
  collectionName?: string
  fieldName?: string
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Transforms: React.FC<Props> = ({
  valueType,
  transforms,
  collectionName,
  fieldName,
  setCurrentVariable,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    state: {
      data: { collections },
    },
  } = useContext(ProjectContext)
  const property = useMemo(
    () => collections.find(el => el.name === collectionName)?.properties.find(el => el.name === fieldName),
    [collections, collectionName, fieldName]
  )
  const isAsset = !!(
    property?.type === ValueType.image ||
    property?.type === ValueType.video ||
    property?.type === ValueType.audio ||
    property?.type === ValueType.file
  )
  const isRecord = !!property?.collection
  const hasFieldTransform = isAsset || isRecord
  const collectionFieldsValues = useMemo(
    () =>
      collections
        .find(
          el =>
            (isAsset && el.name === assetsId) ||
            (isRecord && el.name === property.collection) ||
            el.name === collectionName
        )
        ?.properties.map(el => ({
          value: el.name,
          label: el.name,
          iconName: getFieldIcon(el.type),
          type: el.type,
          accept: el.accept,
        })) || [],
    [collections, isAsset, isRecord, property, collectionName]
  )
  const participantFieldsValues = useMemo(
    () =>
      collections
        .find(el => el.name === 'profiles')
        ?.properties.map(el => ({
          value: el.name,
          label: el.name,
          iconName: getFieldIcon(el.type),
        })) || [],
    [collections]
  )
  const variableTransformValues = useMemo(
    () =>
      Object.values(VariableTransformTransform)
        .map(el => ({ value: el, label: el }))
        .filter(el => (!hasFieldTransform ? el.value !== VariableTransformTransform.field : true)),
    [hasFieldTransform]
  )

  const onAdd = () =>
    setCurrentVariable(currentVariable =>
      currentVariable
        ? { ...currentVariable, transforms: [...(currentVariable.transforms || []), {} as VariableTransform] }
        : null
    )

  const onRemove = (index: number) =>
    setCurrentVariable(currentVariable => {
      const copy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource | null
      copy?.transforms?.splice(index, 1)
      if (copy?.transforms && !copy.transforms.length) {
        delete copy.transforms
      }
      return copy
    })

  const onChange = (index: number, transform: VariableTransform) =>
    setCurrentVariable(currentVariable => {
      const copy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource | null
      copy?.transforms?.splice(index, 1, transform)
      return copy
    })

  return (
    <SubWrapper small noBorderBottom title="Transforms" onAdd={onAdd} canHide={!!transforms?.length} childrenFor="">
      {transforms && (
        <Box display="flex" flexDirection="column" gap="12px">
          {transforms.map((el, i) => (
            <RightSidebarItem key={`${el.transform}${i}`} small title="">
              <SubWrapper
                small
                title="TRANSFORM"
                childrenFor=""
                customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />}
              >
                <SettingField
                  insidePicker
                  subLabel="Transform"
                  value={el.transform}
                  small
                  optionsContainer={
                    <CommonPicker
                      title="Transforms"
                      value={el.transform}
                      onChange={value => onChange(i, { transform: value })}
                      values={variableTransformValues}
                      close={() => {}}
                    />
                  }
                />
                {(el.transform === VariableTransformTransform.field ||
                  el.transform === VariableTransformTransform.conversationTitle ||
                  el.transform === VariableTransformTransform.conversationImage) && (
                  <SettingField
                    insidePicker
                    subLabel="Field"
                    value={el.fieldName}
                    small
                    optionsContainer={
                      <CommonPicker
                        title="Fields"
                        value={el.fieldName}
                        onChange={value => onChange(i, { ...el, fieldName: value })}
                        values={collectionFieldsValues}
                        close={() => {}}
                      />
                    }
                  />
                )}
                {(el.transform === VariableTransformTransform.conversationTitle ||
                  el.transform === VariableTransformTransform.conversationImage) && (
                  <SettingField
                    insidePicker
                    subLabel="Participants key"
                    value={el.participantsKey}
                    small
                    optionsContainer={
                      <CommonPicker
                        title="Fields"
                        value={el.participantsKey}
                        onChange={value => onChange(i, { ...el, participantsKey: value })}
                        values={collectionFieldsValues}
                        close={() => {}}
                      />
                    }
                  />
                )}
                {el.transform === VariableTransformTransform.conversationImage && (
                  <SettingField
                    insidePicker
                    subLabel="Participant image key"
                    value={el.participantImageKey}
                    small
                    optionsContainer={
                      <CommonPicker
                        title="Fields"
                        value={el.participantImageKey}
                        onChange={value => onChange(i, { ...el, participantImageKey: value })}
                        values={participantFieldsValues}
                        close={() => {}}
                      />
                    }
                  />
                )}
                {(el.transform === VariableTransformTransform.conversationTitle ||
                  el.transform === VariableTransformTransform.conversationImage) && (
                  <SettingField
                    insidePicker
                    subLabel="Participant name keys"
                    value={el.participantNameKeys?.join(', ')}
                    small
                    optionsContainer={
                      <CommonPicker
                        title="Fields"
                        value={el.participantNameKeys}
                        onChange={value => onChange(i, { ...el, participantNameKeys: value })}
                        values={participantFieldsValues}
                        close={() => {}}
                        multiple
                      />
                    }
                  />
                )}
                {el.transform === VariableTransformTransform.resize && (
                  <>
                    <SettingField
                      insidePicker
                      subLabel="Resize type"
                      value={el.resizeType}
                      small
                      optionsContainer={
                        <CommonPicker
                          title="Resize types"
                          value={el.resizeType}
                          onChange={value => onChange(i, { ...el, resizeType: value })}
                          values={variableTransformResizeTypeValues}
                          close={() => {}}
                        />
                      }
                    />
                    <SettingNumberField
                      insidePicker
                      subLabel="Width"
                      value={el.size?.width}
                      onChange={value => onChange(i, { ...el, size: { ...el.size, width: value } })}
                      small
                      onlyNumber
                    />
                    <SettingNumberField
                      insidePicker
                      subLabel="Height"
                      value={el.size?.height}
                      onChange={value => onChange(i, { ...el, size: { ...el.size, height: value } })}
                      small
                      onlyNumber
                    />
                  </>
                )}
                {(el.transform === VariableTransformTransform.compareEqual ||
                  el.transform === VariableTransformTransform.compareNotEqual ||
                  el.transform === VariableTransformTransform.compareGreater ||
                  el.transform === VariableTransformTransform.compareGreaterOrEqual ||
                  el.transform === VariableTransformTransform.compareLess ||
                  el.transform === VariableTransformTransform.compareLessOrEqual) && (
                  <AnyVariableInput
                    valueType={getValueType(collectionFieldsValues, fieldName) || valueType}
                    anyVariableValue={el.value}
                    onChange={value => onChange(i, { ...el, value })}
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                )}
                {el.transform === VariableTransformTransform.conditionalValue && (
                  <>
                    <AnyVariableInput
                      valueType={getValueType(collectionFieldsValues, fieldName) || valueType}
                      anyVariableValue={el.value}
                      onChange={value => onChange(i, { ...el, value })}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                    <AnyVariableInput
                      valueType={getValueType(collectionFieldsValues, fieldName) || valueType}
                      anyVariableValue={el.value2}
                      onChange={value => onChange(i, { ...el, value2: value })}
                      screenConfig={screenConfig}
                      allLocalVariables={allLocalVariables}
                    />
                  </>
                )}
              </SubWrapper>
            </RightSidebarItem>
          ))}
        </Box>
      )}
    </SubWrapper>
  )
}
