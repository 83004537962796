import { useEffect } from 'react'

export const updateNavigationTreeEvent = 'updateNavigationTreeEvent'

export const updateNavigationTree = () => document.dispatchEvent(new Event(updateNavigationTreeEvent))

export const updateUserInfoEvent = 'updateUserInfoEvent'

export const updateUserInfo = () => document.dispatchEvent(new Event(updateUserInfoEvent))

export const useLastUpdate = (event: string, callback: () => void, dep?: any) => {
  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null
    const update = () => {
      if (timeout) {
        clearTimeout(timeout)
      }
      timeout = setTimeout(callback, 0)
    }
    document.addEventListener(event, update)
    return () => document.removeEventListener(event, update)
  }, [dep])
}
