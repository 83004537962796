import React from 'react'

import * as styled from './PublishedStatus.styled'

import { RecordStatus } from 'services/cms'

interface Props {
  status: RecordStatus
}

export const PublishedStatus: React.FC<Props> = ({ status }) => (
  <styled.Wrapper status={status}>{status}</styled.Wrapper>
)
