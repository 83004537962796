import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Task, TaskStatus, TaskType } from 'hooks'
import { ActivityItemSmall, ActivityItemType } from 'pages/Repository/components'
import firebase from 'services/firebase'
import { generateFirestoreId } from 'utils'

export const ImportData: React.FC = () => {
  const [task, setTask] = useState<Task>({
    id: generateFirestoreId(),
    name: 'Collecting the data',
    progress: 0,
    status: TaskStatus.IN_PROGRESS,
    type: TaskType.OTHER,
    createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
    updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
  })

  useEffect(() => {
    const timer = setTimeout(() => {
      setTask(task => ({
        ...task,
        progress: 100,
        status: TaskStatus.COMPLETED,
        updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      }))
    }, 10000)
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <Box sx={{ pointerEvents: 'none' }}>
      <ActivityItemSmall key={task.id} item={task} type={ActivityItemType.TASK} steps={task.steps} />
    </Box>
  )
}
