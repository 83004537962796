import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useState } from 'react'

import {
  CustomSection,
  CustomSectionActions,
  CustomSectionButton,
  CustomSectionTitle,
  InputProperty,
  inputs,
  validate,
} from 'components'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'type',
    type: ValueType.string,
    position: 0,
    helpText: 'The type of the cloud project.',
    smallWidth: true,
    disabled: true,
  },
  {
    name: 'location',
    type: ValueType.string,
    position: 1,
    title: 'Cloud resource location',
    helpText: "The location of your project's data and cloud resources.",
    smallWidth: true,
    disabled: true,
  },
  {
    name: 'project',
    type: ValueType.string,
    position: 2,
    title: 'Firebase project',
    helpText: 'The Firebase project of your app.',
    smallWidth: true,
    disabled: true,
  },
]

export const CloudProject: React.FC = () => {
  const [valuesBeforeEdit] = useState('')

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {},
  })

  const cancel = () => {}

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  return (
    <CustomSection showActions={showActions}>
      <CustomSectionTitle mb="-5px">Cloud Project</CustomSectionTitle>
      {inputs(properties, formik)}
      <Box mt="30px">
        <CustomSectionButton
          title="Ownership"
          subTitle="Request Google Cloud project ownership transfer to your account to really own your data."
          onClick={() => {}}
          buttonText="Request ownership transfer"
        />
      </Box>
      {showActions && <CustomSectionActions cancel={cancel} onClick={formik.handleSubmit} />}
    </CustomSection>
  )
}
