import { Box } from '@mui/material'
import React, { ReactElement, useEffect, useState } from 'react'

import {
  acceptDataTypesValues,
  Button,
  ButtonColor,
  CustomPicker,
  dataTypes,
  InputField,
  SelectStringValueField,
  VariableField,
} from 'components'
import { CollectionVariable, LocalVariable, Screen, ScreenParameter, ValueType, VariableSourceType } from 'utils'

const buttonPadding = { padding: '9.5px' }

interface Props {
  close: () => void
  value: ScreenParameter
  onChange: (val: ScreenParameter) => void
  onRemove?: () => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const InputParameterPicker: React.FC<Props> = ({
  close,
  value,
  onChange,
  onRemove,
  screenConfig,
  allLocalVariables,
}) => {
  const [subPicker, setSubPicker] = useState<ReactElement | null>(null)
  const [inputParameter, setInputParameter] = useState<ScreenParameter>(JSON.parse(JSON.stringify(value)))
  const {
    parameter,
    //  inout,
    type,
    accept,
    collection,
  } = inputParameter

  useEffect(() => {
    setInputParameter(JSON.parse(JSON.stringify(value)))
  }, [value])

  const onConfirm = () => {
    onChange(inputParameter)
    close()
  }

  const onDelete =
    value && onRemove
      ? () => {
          onRemove()
          close()
        }
      : undefined

  const confirmDisabled =
    !parameter ||
    (type === ValueType.array && !accept) ||
    ((type === ValueType.record || accept === ValueType.record) && !collection)

  return (
    <CustomPicker
      title="Input parameters"
      close={close}
      optionsContainer={
        <Box maxHeight="500px" overflow="auto">
          {subPicker || (
            <>
              <InputField
                label="Parameter"
                value={parameter}
                onChange={value => setInputParameter(inputParameter => ({ ...inputParameter, parameter: value }))}
                setSubPicker={setSubPicker}
              />
              <SelectStringValueField
                label="Type"
                value={type}
                onChange={value =>
                  setInputParameter(inputParameter => ({ ...inputParameter, type: value as ValueType }))
                }
                setSubPicker={setSubPicker}
                options={dataTypes.map(el => el.value)}
              />
              {type === ValueType.array && (
                <SelectStringValueField
                  label="Accept"
                  value={accept}
                  onChange={value =>
                    setInputParameter(inputParameter => ({ ...inputParameter, accept: value as ValueType }))
                  }
                  setSubPicker={setSubPicker}
                  options={acceptDataTypesValues.map(el => el.value)}
                />
              )}
              {(type === ValueType.record || accept === ValueType.record) && (
                <VariableField
                  label="Collection"
                  source={
                    collection ? { type: VariableSourceType.collection, collection: { name: collection } } : undefined
                  }
                  onSourceChange={value =>
                    value?.collection
                      ? setInputParameter(inputParameter => ({
                          ...inputParameter,
                          collection: (value.collection as CollectionVariable).name,
                        }))
                      : setInputParameter(inputParameter => {
                          const inputParameterCopy = JSON.parse(JSON.stringify(inputParameter))
                          delete inputParameterCopy.collection
                          return inputParameterCopy
                        })
                  }
                  setSubPicker={setSubPicker}
                  collectionMode
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              )}
              <Box
                padding="15px 10px"
                display="flex"
                justifyContent="space-between"
                gap="8px"
                flexWrap="wrap-reverse"
                width="100%"
              >
                {onDelete ? (
                  <Button style={buttonPadding} color={ButtonColor.DELETE} onClick={onDelete}>
                    Remove
                  </Button>
                ) : (
                  <span />
                )}
                <Box display="flex" gap="8px">
                  <Button style={buttonPadding} color={ButtonColor.SECONDARY} onClick={close}>
                    Cancel
                  </Button>
                  <Button style={buttonPadding} onClick={onConfirm} disabled={confirmDisabled}>
                    Confirm
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      }
    />
  )
}
