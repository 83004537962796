import Ajv from 'ajv'

import { Config, firstLetterUpperCase, schema } from 'utils'
const ajv = new Ajv({ allErrors: true })
require('ajv-keywords')(ajv)

export const isValidConfig = (state: Config) => {
  try {
    const valid = ajv.validate(schema, state)
    if (!valid) {
      if (ajv.errors) {
        console.log(ajv.errors)
        return ajv.errors.map(el => {
          const value = { screenId: '', componentId: '' }
          const path = el.instancePath.split('/').slice(1)
          let module = ''
          let screenName = ''
          let componentName = ''
          let fieldName = ''
          let item: any = null
          path.forEach((el, i) => {
            item = item ? item[el] : state[el as keyof Config]
            if (i === 0) {
              module = el
            } else if (i === 1 && module === 'screens') {
              const screen = item
              screenName = `${item.screenName || `Screen ${el}`}`
              if (screen.id) {
                value.screenId = screen.id
              }
            } else if (screenName && (el === 'components' || el === 'subComponents') && path[i + 1]) {
              const component = item[path[i + 1]]
              componentName = `component <span>"${component.name || component.componentType || 'item'}"</span>`
              if (component.id && value.screenId) {
                value.componentId = component.id
              }
            } else {
              if (isNaN(Number(+el))) {
                fieldName = `field <span>"${el}"</span>`
              } else {
                fieldName = ''
              }
            }
          })
          const message =
            el.message +
            (el.keyword === 'enum'
              ? ` (${el.params.allowedValues.join(', ')})`
              : el.keyword === 'uniqueItemProperties'
              ? componentName
                ? ' (id, name)'
                : ' (id, screenName)'
              : '')
          return {
            label: screenName || module || 'Config',
            insideLabel: firstLetterUpperCase(
              componentName && fieldName
                ? `${componentName} ${fieldName} ${message}`
                : componentName
                ? `${componentName} ${message}`
                : fieldName
                ? `${fieldName} ${message}`
                : message
            ),
            value: JSON.stringify(value),
          }
        })
      }
    }
  } catch (err) {
    console.log(err)
  }
  return []
}
