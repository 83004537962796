import styled from 'styled-components'

export const Content = styled.div`
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`

export const DeploymentsTable = styled.div`
  padding-top: 30px;
  & h2 {
    color: ${props => props.theme.colors.darkText};
    font-weight: 700;
    font-size: 22px;
    line-height: 150%;
  }
  & > div {
    margin-top: 15px;
  }
  & td {
    & > i {
      display: block;
      color: ${props => props.theme.colors.grey1};
    }
    & > a {
      display: flex;
      align-items: center;
      gap: 4px;
      color: ${props => props.theme.colors.primaryText};
      & svg {
        margin-top: 2px;
        & path {
          stroke: ${props => props.theme.colors.primaryText};
        }
      }
      &:hover {
        color: ${props => props.theme.colors.primaryTextHover};
        & svg path {
          stroke: ${props => props.theme.colors.primaryTextHover};
        }
      }
    }
  }
  & td,
  & th {
    max-width: unset;
  }
  & tr {
    & td {
      padding: 10px 8px;
    }
    & th:first-child,
    & td:first-child {
      padding-left: 20px;
    }
  }
`
