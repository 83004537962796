import React from 'react'
import { ISvg, Svg } from '../Svg'

const Ios: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M15.517 2C14.5183 2.0696 13.3511 2.71371 12.6709 3.55247C12.0504 4.31337 11.54 5.44352 11.739 6.54181C12.8301 6.57602 13.9576 5.91657 14.6108 5.06366C15.2219 4.26973 15.6844 3.14666 15.517 2Z"
      fill="#737BF8"
    />
    <path
      d="M19.4579 8.85411C18.4991 7.68087 17.1516 7 15.879 7C14.199 7 13.4884 7.78483 12.3211 7.78483C11.1176 7.78483 10.2033 7.00228 8.7504 7.00228C7.32328 7.00228 5.80366 7.85337 4.84015 9.30879C3.48561 11.3582 3.71741 15.2116 5.91254 18.4937C6.69811 19.6681 7.74708 20.9887 9.11919 21.0001C10.3403 21.0115 10.6845 20.2358 12.3387 20.2278C13.993 20.2187 14.3067 21.0104 15.5254 20.9978C16.8987 20.9875 18.0051 19.5241 18.7906 18.3497C19.3538 17.5078 19.5633 17.0839 20 16.1335C16.8238 14.9534 16.3145 10.546 19.4579 8.85411Z"
      fill="#737BF8"
    />
  </Svg>
)

export default Ios
