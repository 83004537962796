import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from '../CustomPicker.styled'

import { Button, ButtonColor, CustomPicker, Icon, Name } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { useScreenshotFromVB } from 'hooks'
import { ProjectsService } from 'services/projects'
import { Screen, includesSearch } from 'utils'

interface Props {
  value?: string
  onChange: (image: string, name: string) => void
  close: () => void
  noUpload?: boolean
}

export const ScreenPicker: React.FC<Props> = ({ value, onChange, close, noUpload }) => {
  const { id } = useParams()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const {
    state: { screens },
  } = useContext(ProjectContext)
  const [activeScreen, setActiveScreen] = useState<Screen | null>(null)
  const [activeScreenPreview, setActiveScreenPreview] = useState('')
  const getScreenshotFromVB = useScreenshotFromVB()
  const [search, setSearch] = useState('')

  useEffect(() => {
    let abort = false
    if (activeScreen) {
      setActiveScreenPreview('')
      getScreenshotFromVB(activeScreen.screenName).then(res => !abort && setActiveScreenPreview(res))
    }
    return () => {
      abort = true
    }
  }, [activeScreen?.screenName, getScreenshotFromVB])

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const image = ((e.target as HTMLInputElement).files as FileList)[0]
    if (id && image) {
      const formData = new FormData()
      formData.append('resource', image)
      startLoader()
      ProjectsService.uploadResource(id, formData)
        .then(res => {
          onChange(res.data.url, '')
          close()
        })
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }
  }

  const onClick = () => {
    if (activeScreen) {
      onChange('', activeScreen.screenName)
      close()
    }
  }

  return (
    <CustomPicker
      title="Screens"
      close={close}
      setSearch={setSearch}
      optionsContainer={
        <>
          {!noUpload && (
            <styled.ButtonWrapper>
              <Button
                color={ButtonColor.SECONDARY}
                input={<input onChange={uploadImage} type="file" accept="image/*" />}
              >
                Select file...
              </Button>
            </styled.ButtonWrapper>
          )}
          <styled.Items>
            {screens
              .filter(el => includesSearch(el.screenName, search))
              .map(el => (
                <styled.Item
                  key={el.screenName}
                  onClick={onClick}
                  onMouseOver={() => setActiveScreen(el)}
                  onMouseLeave={() => {
                    setActiveScreen(null)
                    setActiveScreenPreview('')
                  }}
                  active={el.screenName === value}
                >
                  <Icon name={Name.LEFT_SIDEBAR_PROJECT_SCREENSHOTS} />
                  <styled.ItemLabel>{el.screenName}</styled.ItemLabel>
                </styled.Item>
              ))}
            {activeScreen && activeScreenPreview && (
              <styled.HoveredItem>
                <img src={activeScreenPreview} alt="preview" />
              </styled.HoveredItem>
            )}
          </styled.Items>
        </>
      }
    />
  )
}
