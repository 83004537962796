import React from 'react'

interface Props {
  children: React.ReactNode
}

export const AppStoreLink: React.FC<Props> = ({ children }) => (
  <a href={`https://appstoreconnect.apple.com`} target="_blank" rel="noreferrer">
    {children}
  </a>
)
