import { Box } from '@mui/material'
import React, { useEffect } from 'react'

import * as styled from './Monetization.styled'

import { Text, Connections, Type } from 'components'
import { Cards, Paywalls, Products, Transactions } from './sections'

export const Monetization: React.FC = () => {
  useEffect(() => {
    document.title = 'Monetization | CodePlatform'
  }, [])

  return (
    <Box display="flex" justifyContent="space-between">
      <styled.Container>
        <styled.TitleWrapper>
          <Text type={Type.TITLE}>Monetization</Text>
          <Text type={Type.SUB_TITLE}>Your in-app & subscriptions analytics</Text>
        </styled.TitleWrapper>
        <styled.Content>
          <Connections appStore googlePlay />
          <Cards />
          <Transactions />
          <Products />
          <Paywalls />
        </styled.Content>
      </styled.Container>
    </Box>
  )
}
