import React from 'react'

import { CustomField, SubPicker } from 'components'
import { Actions } from 'partials/RightSidebar/settings/common'
import { Action, LocalVariable, Screen } from 'utils'

interface Props {
  label: string
  value?: Action[]
  onChange: (val: Action[]) => void
  setSubPicker: (
    value: React.SetStateAction<React.ReactElement<any, string | React.JSXElementConstructor<any>> | null>
  ) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const SubActions: React.FC<Props> = ({
  label,
  value,
  onChange,
  setSubPicker,
  screenConfig,
  allLocalVariables,
}) => (
  <CustomField
    label={label}
    value={value ? 'Sub actions' : undefined}
    onClick={() =>
      setSubPicker(() => (
        <SubPicker onBack={() => setSubPicker(null)}>
          <Actions
            childrenFor=""
            subActions={value}
            onChange={onChange}
            small
            noBorderBottom
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
          />
        </SubPicker>
      ))
    }
  />
)
