import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Text, Type } from 'components'
import { ProgressContext } from 'context'
import { Permission } from 'hooks'
import { TeammatesContent } from 'pages/Projects/pages/Teammates/TeammatesContent'
import { firestore } from 'services/firebase'
import { SettingsLayout } from '../components'
import { AddNew } from './components'

export const Teammates: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [permissions, setPermissions] = useState<Permission[]>([])
  const [wait, setWait] = useState(true)

  useEffect(() => {
    startLoader()
    const unsubscribe = firestore.collection(`projects/${id}/permissions`).onSnapshot({
      next: res => {
        setPermissions(res.docs.map(el => ({ id: el.id, ...el.data() })) as Permission[])
        stopLoader()
        setWait(false)
      },
      error: err => {
        toast(err)
        stopLoader()
      },
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <SettingsLayout>
      <Text type={Type.TITLE}>Teammates</Text>
      <Text type={Type.SUB_TITLE}>
        Teammates are people who have access to your workspace projects. They can create and edit apps. Workspace
        administration has access to billing and app deletion
      </Text>
      <AddNew projectPermissions={permissions} />
      {!wait && <TeammatesContent teammates={permissions} />}
    </SettingsLayout>
  )
}
