import React, { useContext, useEffect, useRef, useState } from 'react'
import AceEditor from 'react-ace'

import { Button, Menu } from 'components'
import { DeviceSettingsContext, ProgressContext, ProjectContext } from 'context'

export const SourceCode: React.FC = () => {
  const aceRef: React.LegacyRef<AceEditor> = useRef(null)
  const [open, setOpen] = useState(false)
  const { state, setState } = useContext(ProjectContext)
  const [stateForAceEditor, setStateForAceEditor] = useState(JSON.stringify(state, null, '\t'))
  const {
    selected: { tabScreen, tabBarId, subComponentId, componentId, screenId },
  } = useContext(DeviceSettingsContext)
  const { toast } = useContext(ProgressContext)
  const selectedId = tabScreen || tabBarId || subComponentId || componentId || screenId

  useEffect(() => {
    setStateForAceEditor(JSON.stringify(state, null, '\t'))
  }, [JSON.stringify(state), open])

  useEffect(() => {
    if (selectedId && open) {
      aceRef.current?.editor.find(selectedId)
    }
  }, [selectedId, open])

  useEffect(() => {
    aceRef.current?.editor.commands.addCommand({
      name: 'save',
      bindKey: { win: 'Ctrl-S', mac: 'Cmd-S' },
      exec: onSave,
    })
  }, [open, stateForAceEditor])

  const onSave = () => {
    try {
      setState(JSON.parse(stateForAceEditor))
    } catch (err) {
      toast(err)
    }
  }

  const stateChanged = JSON.stringify(state, null, '\t') !== stateForAceEditor

  return (
    <Menu
      bottom
      isOpen={setOpen}
      firstChild={
        <>
          <AceEditor
            ref={aceRef}
            value={stateForAceEditor}
            onChange={setStateForAceEditor}
            mode="json"
            width="100%"
            height="100%"
            style={{ position: 'absolute' }}
          />
          <Button
            style={{ position: 'absolute', top: '10px', right: '10px' }}
            onClick={onSave}
            disabled={!stateChanged}
          >
            Save
          </Button>
        </>
      }
    />
  )
}
