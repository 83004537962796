import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import ReactTimeago from 'react-timeago'

import * as styled from './FileManager.styled'

import { DropDown, Icon, Name, NoContent } from 'components'
import { Readme } from 'pages/Repository/components'
import { FileOrDirectory } from 'pages/Repository/pages'
import { About } from './components'

export interface Commit {
  hash: string
  message: string
  date: string
  author: {
    user: {
      display_name: string
    }
  }
}

interface Props {
  data: FileOrDirectory[]
  flag: string
  commits: Commit[]
  noContent: boolean
}

export const FileManager: React.FC<Props> = ({ data, flag, commits, noContent }) => {
  const { pathname } = useLocation()
  const readmeFile = data.find(el => el.path === 'README.md')

  const scrollToReadme = () => {
    if (readmeFile) {
      document.getElementById('readme')?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <>
      <styled.Container>
        <styled.TableWrapper>
          <styled.TableDropDowns>
            <DropDown
              options={[{ text: 'master', icon: <Icon name={Name.REPOSITORY_BRANCH} /> }]}
              defaultLabel="master"
              width="120px"
            />
            <DropDown
              options={[{ text: 'Download', icon: <Icon name={Name.ADDITIONAL_DOWNLOAD} /> }]}
              width="124px"
              defaultLabel="Code"
            />
          </styled.TableDropDowns>
          <styled.Table>
            <thead>
              <tr>
                {noContent ? (
                  <th />
                ) : commits[0] ? (
                  <>
                    <th align="left">
                      <div>
                        <b>{commits[0].author.user.display_name}:</b>
                        {commits[0].message}
                      </div>
                    </th>
                    <th align="right">
                      {`${commits[0].hash.slice(0, 7)} on ${new Date(commits[0].date).toDateString()}`}
                    </th>
                    <th align="right">
                      <span>
                        <Icon name={Name.REPOSITORY_TIME} />
                        <b>{commits.length}</b> {commits.length === 1 ? 'commit' : 'commits'}
                      </span>
                    </th>
                  </>
                ) : (
                  <>
                    <th />
                    <th />
                    <th />
                  </>
                )}
              </tr>
            </thead>
            {noContent ? (
              <NoContent isTable text="No content yet." />
            ) : (
              <tbody style={{ height: !!data.length ? 'auto' : '310px' }}>
                {data.map((el, i) => (
                  <React.Fragment key={el.path}>
                    {i === 0 && el.path.split('/').length > 1 && (
                      <tr>
                        <td>
                          <Link to={`${pathname.split('/').slice(0, -1).join('/')}`}>..</Link>
                        </td>
                        <td />
                        <td />
                      </tr>
                    )}
                    <tr>
                      <td>
                        <Link to={`${flag}/${el.commit.hash}/${el.path}`}>
                          {el.type === 'commit_file' ? (
                            <Icon name={Name.REPOSITORY_FILE_COLORED} width={24} height={24} />
                          ) : (
                            <Icon name={Name.REPOSITORY_FOLDER_COLORED} width={24} height={24} />
                          )}
                          {el.path.split('/').slice(-1)}
                        </Link>
                      </td>
                      <td>
                        <Link to={`${flag}/${el.commit.hash}/${el.path}`}>
                          <div>{commits.find(c => c.hash === el.commit.hash)?.message.replace(/[\r\n]/gm, '')}</div>
                        </Link>
                      </td>
                      <td align="right">
                        <Link to={`${flag}/${el.commit.hash}/${el.path}`}>
                          <ReactTimeago date={commits.find(c => c.hash === el.commit.hash)?.date || ''} />
                        </Link>
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </styled.Table>
          {readmeFile && <Readme hash={readmeFile.commit.hash} />}
        </styled.TableWrapper>
        <About scrollToReadme={scrollToReadme} />
      </styled.Container>
    </>
  )
}
