import { ReactElement, createContext } from 'react'

import { Application, Project, Workspace } from 'hooks'
import { CMSCollection } from 'services/cms'
import { ThemeType } from 'theme'
import { BrandingThemeType, Config, DataObject, ScreenComponent, devicesForPicker } from 'utils'
import { ISelected } from './types'

export const ProgressContext = createContext({
  startLoader: () => {},
  stopLoader: () => {},
  toast: (err: any, detect403StatusCode?: boolean) => {},
  getErrorMessage: (err: any) => '',
})

export const LayoutContext = createContext({
  theme: ThemeType.LIGHT,
  setTheme: (theme: ThemeType) => {},
})

export const TopPanelContext = createContext({
  backButton: null as ReactElement | null,
  setBackButton: (val: ReactElement | null) => {},
  leftButtons: null as ReactElement | null,
  setLeftButtons: (val: ReactElement | null) => {},
  pageLeftButtons: null as ReactElement | null,
  setPageLeftButtons: (val: ReactElement | null) => {},
  pageCenterButtons: null as ReactElement | null,
  setPageCenterButtons: (val: ReactElement | null) => {},
  rightButtons: null as ReactElement | null,
  setRightButtons: (val: ReactElement | null) => {},
  page: null as ReactElement | null,
  setPage: (val: ReactElement | null) => {},
  isMobile: false,
})

export const WorkspaceContext = createContext({
  workspace: null as Workspace | null,
  workspaces: [] as Workspace[],
  projects: [] as Project[],
  setWaitProjects: (val: boolean) => {},
  waitProjects: true,
})

export const ApplicationContext = createContext({
  application: null as Application | null,
})

export const CMSContext = createContext({
  dataObjects: [] as DataObject[],
  allCollections: [] as CMSCollection[],
  rootCollections: [] as CMSCollection[],
  assets: null as CMSCollection | null,
  folders: [] as CMSCollection[],
})

export const MovableComponentsContext = createContext({
  movableComponent: null as ScreenComponent | null,
  setMovableComponent: (config: ScreenComponent | null) => {},
  movableTabBarComponent: false,
  setMovableTabBarComponent: (config: boolean) => {},
  movableTopBarComponent: false,
  setMovableTopBarComponent: (config: boolean) => {},
  // movableTabComponent: null as TabBarItem | null,
  // setMovableTabComponent: (config: TabBarItem | null) => {},
})

export const selectedDefaultState: ISelected = {
  screenId: '',
  innerModeComponentId: '',
  componentId: '',
  subComponentId: '',
  appIcon: false,
  topBarId: '',
  tabBarId: '',
  tabScreen: '',
}
export const DeviceSettingsContext = createContext({
  compression: 1,
  device: devicesForPicker[0].value,
  selected: selectedDefaultState,
  setSelected: (val: ISelected | ((prev: ISelected) => ISelected)) => {},
})

export const ProjectContext = createContext({
  project: {} as Project,
  state: {} as Config,
  setState: (val: Config | ((prev: Config) => Config)) => {},
  undo: () => {},
  redo: () => {},
  language: '',
  setLanguage: (val: string | ((prev: string) => string)) => {},
  theme: BrandingThemeType.light,
  setTheme: (val: BrandingThemeType | ((prev: BrandingThemeType) => BrandingThemeType)) => {},
})
