import React from 'react'
import { Link } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'
import { connectAppStoreFlag } from 'hooks'

export const ConnectAppStoreButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => (
  <Link
    to={`?${connectAppStoreFlag}=true`}
    style={{
      textDecoration: 'none',
      pointerEvents: disabled ? 'none' : 'all',
    }}
  >
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.SIZE_24_APP_STORE} />}
      disabled={disabled}
    >
      Link to App Store Connect
    </Button>
  </Link>
)
