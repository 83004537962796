import React, { useContext, useMemo } from 'react'

import * as styled from '../../../CustomPicker.styled'

import { Icon, Name, SubPicker, getFieldIcon } from 'components'
import { ProjectContext } from 'context'
import {
  ComponentType,
  LocalVariable,
  Screen,
  ValueType,
  VariableSource,
  VariableSourceType,
  findAllScreenComponentsByType,
  getVariableCollectionName,
  includesSearch,
} from 'utils'

interface Props {
  value?: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  newSource: VariableSource
  setNewSource: (val: React.SetStateAction<VariableSource | null>) => void
  dataMode?: boolean
  collectionMode?: boolean
  recordMode?: boolean
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const SelectField: React.FC<Props> = ({
  value,
  setCurrentVariable,
  newSource,
  setNewSource,
  dataMode,
  collectionMode,
  recordMode,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    state: {
      data: { collections, globalVariables },
    },
  } = useContext(ProjectContext)

  const componentsFields = useMemo(
    () => [
      ...findAllScreenComponentsByType(screenConfig, ComponentType.textInput),
      ...findAllScreenComponentsByType(screenConfig, ComponentType.datePicker),
      ...findAllScreenComponentsByType(screenConfig, ComponentType.calendar),
    ],

    [screenConfig]
  )

  const collectionFields = !collectionMode
    ? collections
        .find(el => el.name === getVariableCollectionName(newSource, allLocalVariables))
        ?.properties.filter(el =>
          dataMode || recordMode
            ? el.type === ValueType.array && el.accept === ValueType.record
            : el.type !== ValueType.record && !(el.type === ValueType.array && el.accept === ValueType.record)
        )
    : undefined

  const localVariable = allLocalVariables.find(el => el.name === newSource?.variableName)

  return (
    <SubPicker
      label={
        newSource.type === VariableSourceType.globalVariable
          ? 'Global'
          : newSource.type === VariableSourceType.component
          ? 'Components'
          : newSource.type === VariableSourceType.collection
          ? newSource.collection?.name
          : newSource.type === VariableSourceType.localVariable
          ? newSource.variableName
          : ''
      }
      onBack={() => setNewSource(null)}
      ItemsContent={({ search }) => (
        <>
          {(dataMode || collectionMode || recordMode) && newSource.type === VariableSourceType.collection && (
            <styled.Item
              onClick={() => setCurrentVariable(newSource)}
              active={
                value?.type === VariableSourceType.collection &&
                value.collection?.name === newSource.collection?.name &&
                !value.fieldName
              }
              color="darkText1"
              iconHover
            >
              <Icon name={Name.ADDITIONAL_COLLECTION} />
              <styled.ItemLabel>{newSource.collection?.name}</styled.ItemLabel>
            </styled.Item>
          )}
          {!dataMode && newSource.type === VariableSourceType.localVariable && (
            <styled.Item
              onClick={() => setCurrentVariable(newSource)}
              active={
                value?.type === VariableSourceType.localVariable &&
                value.variableName === newSource.variableName &&
                !value.fieldName
              }
              color="darkText1"
              iconHover
            >
              <Icon
                name={
                  localVariable?.type
                    ? getFieldIcon(localVariable.type)
                    : localVariable?.variable?.source?.type === VariableSourceType.collection
                    ? Name.ADDITIONAL_COLLECTION
                    : Name.RIGHT_SIDEBAR_DEFAULT_ICON
                }
              />
              <styled.ItemLabel>{newSource.variableName}</styled.ItemLabel>
            </styled.Item>
          )}
          {newSource.type === VariableSourceType.globalVariable
            ? globalVariables
                .filter(el => includesSearch(el.variableName, search))
                .map(el => (
                  <styled.Item
                    key={el.id}
                    onClick={() => setCurrentVariable({ ...newSource, variableName: el.variableName })}
                    active={value?.type === VariableSourceType.globalVariable && value.variableName === el.variableName}
                    color="darkText1"
                    iconHover
                  >
                    <Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />
                    <styled.ItemLabel>{el.variableName}</styled.ItemLabel>
                  </styled.Item>
                ))
            : newSource.type === VariableSourceType.component
            ? componentsFields
                .filter(el => includesSearch(el.name, search))
                .map(el => (
                  <styled.Item
                    key={el.id}
                    onClick={() =>
                      setCurrentVariable({
                        ...newSource,
                        componentName: el.name,
                        fieldName: el.componentType === ComponentType.textInput ? 'text' : 'date',
                      })
                    }
                    active={value?.type === VariableSourceType.component && value.componentName === el.name}
                    color="darkText1"
                    iconHover
                  >
                    <Icon name={Name.RIGHT_SIDEBAR_DEFAULT_ICON} />
                    <styled.ItemLabel>{el.name}</styled.ItemLabel>
                  </styled.Item>
                ))
            : collectionFields
                ?.filter(el => includesSearch(el.name, search))
                .map(el => (
                  <styled.Item
                    key={el.name}
                    onClick={() => setCurrentVariable({ ...newSource, fieldName: el.name })}
                    active={
                      value?.type === VariableSourceType.collection
                        ? value.fieldName === el.name && value.collection?.name === newSource.collection?.name
                        : value?.type === VariableSourceType.localVariable
                        ? value.fieldName === el.name && value.variableName === newSource.variableName
                        : false
                    }
                    color="darkText1"
                    iconHover
                  >
                    <Icon name={getFieldIcon(el.type)} />
                    <styled.ItemLabel>
                      {dataMode
                        ? `${
                            newSource?.type === VariableSourceType.collection
                              ? newSource.collection?.name
                              : newSource.type === VariableSourceType.localVariable
                              ? newSource.variableName
                              : ''
                          }.${el.name}`
                        : el.name}
                    </styled.ItemLabel>
                  </styled.Item>
                ))}
        </>
      )}
    />
  )
}
