import React from 'react'
import { useParams } from 'react-router-dom'

import { CustomSection, CustomSectionButtons, CustomSectionSubTitle, CustomSectionTitle } from 'components'
import { useAppStoreConnectionModal, useProjectIntegrations } from 'hooks'
import { AppStoreButton, GooglePlayButton } from './components'

interface Props {
  appStore?: boolean
  googlePlay?: boolean
}

export const Connections: React.FC<Props> = ({ appStore, googlePlay }) => {
  const { id } = useParams()
  const { appstoreIntegration, googleplayIntegration } = useProjectIntegrations(id as string)
  const appStoreConnectionModal = useAppStoreConnectionModal(appstoreIntegration)

  return (
    <CustomSection style={{ paddingBottom: '37px' }}>
      <CustomSectionTitle>Connections</CustomSectionTitle>
      <CustomSectionSubTitle>Autorize CodePlatform to use your App Store and Google play account</CustomSectionSubTitle>
      <CustomSectionSubTitle secondary>
        Click on the '<b>Link to App Store Connect</b>' button to create a new connection.Need help? Contact our Connect
        support team at <a href="/#">connect@codeplatform.com</a>
      </CustomSectionSubTitle>
      <CustomSectionButtons>
        {appStore && <AppStoreButton appstoreIntegration={appstoreIntegration} />}
        {googlePlay && <GooglePlayButton googleplayIntegration={googleplayIntegration} />}
      </CustomSectionButtons>
      {appStore && appStoreConnectionModal}
    </CustomSection>
  )
}
