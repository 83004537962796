import { useContext, useEffect, useRef } from 'react'

import * as styled from './ComponentPreview.styled'

import { DeviceSettingsContext, ProjectContext } from 'context'
import { useVariable } from 'hooks'
import { Screen, ScreenComponent, devices, getPx, getShadow, listItemName, setComponents } from 'utils'

interface Props {
  screenConfig: Screen
  componentConfig: ScreenComponent
  listId?: string
}

export const ComponentPreview: React.FC<Props> = ({ screenConfig, componentConfig, listId }) => {
  const ref = useRef<HTMLDivElement>(null)
  const { device, compression } = useContext(DeviceSettingsContext)
  const {
    state: {
      data: { collections, globalVariables },
      branding: {
        colorStyles,
        typography: { iosDefaultFontFamily: fontFamily, fontStyles },
        appName,
        icons: { iosIcon },
      },
      resources,
      products,
    },
    language,
    theme,
  } = useContext(ProjectContext)
  const { getVariable } = useVariable()

  useEffect(() => {
    // @ts-ignore
    const { width, safeAreaTop, safeAreaBottom } = devices[device]
    const component = JSON.parse(JSON.stringify(componentConfig)) as ScreenComponent
    delete component.margins
    const shadow = getShadow()
    const parent = document.createElement('div')
    shadow.appendChild(parent)
    parent.style.width = `${width}px`
    setComponents(
      [component.name === listItemName ? { ...component, listId, indexInList: 0 } : component],
      parent,
      fontFamily,
      fontStyles,
      colorStyles,
      theme,
      resources,
      products,
      1,
      false,
      screenConfig,
      language,
      getPx(false, false, safeAreaTop, safeAreaBottom),
      getVariable(screenConfig, listId, 0),
      getVariable,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      true
    )
      .then(() => {
        const element = parent.querySelector('div')
        if (ref.current && element) {
          const { width, height } = element.getBoundingClientRect()
          const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
          svg.setAttribute('viewBox', `0 0 ${width} ${height}`)
          svg.style.width = `${width * compression}px`
          svg.style.maxWidth = '100%'
          const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
          foreignObject.setAttribute('width', '100%')
          foreignObject.setAttribute('height', '100%')
          foreignObject.appendChild(element)
          svg.appendChild(foreignObject)
          ref.current.innerHTML = svg.outerHTML
        }
      })
      .finally(() => shadow.parentNode?.removeChild(shadow))
  }, [
    JSON.stringify(componentConfig),
    JSON.stringify(screenConfig),
    fontFamily,
    JSON.stringify(fontStyles),
    JSON.stringify(colorStyles),
    JSON.stringify(resources),
    JSON.stringify(products),
    JSON.stringify(collections),
    JSON.stringify(globalVariables),
    JSON.stringify(appName),
    iosIcon,
    theme,
    device,
    language,
    compression,
  ])

  return <styled.Wrapper ref={ref} />
}
