import { Box } from '@mui/material'
import { memo, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import * as styled from './Navigation.styled'

import { DropDown, Icon, Name } from 'components'
import { ProjectContext } from 'context'
import { Task } from 'hooks'
import { routes } from 'routes'
import { ProjectSetupModal, Tasks, WarningsAndErrors } from './components'

interface Props {
  tasks: Task[]
  runningTasks: Task[]
  failedTasks: Task[]
}

export const Navigation: React.FC<Props> = memo(({ tasks, runningTasks, failedTasks }) => {
  const { id } = useParams()
  const { pathname } = useLocation()
  const {
    project: { name, logoUrl, status },
  } = useContext(ProjectContext)

  const replacedPath = (path: string) => path.replace(':id', id as string)

  const section =
    routes.find(
      route => route?.name && !route.index && pathname.startsWith(replacedPath(route.path)) && route.path !== '/'
    ) ||
    routes.find(route => route.index && pathname.startsWith(replacedPath(route.path))) ||
    routes.find(route => pathname === route.path)

  const subSection = section?.children?.find(route => pathname === replacedPath(route.path))
  const showSectionMenu =
    (section?.innerRoutes && pathname !== replacedPath(section.path)) ||
    !!(subSection?.name && pathname === replacedPath(subSection?.path))

  return (
    <>
      <styled.Navigation>
        <Box display="flex" alignItems="center" gap="4px">
          <DropDown
            options={[
              { text: name, icon: <Icon name={Name.PICKERS_APP_NAME} />, isProjectName: true },
              { text: 'Back to Projects', to: '/', icon: <Icon name={Name.PICKERS_BACK_TO_PROJECTS} /> },
            ]}
            top="-16px"
            left="-15px"
            width="240px"
            labelElement={
              <styled.NavItem clickable>
                <img width={22} height={22} src={logoUrl} alt="logo" />
                {name}
              </styled.NavItem>
            }
          />
          {showSectionMenu ? (
            <DropDown
              options={[
                { text: section?.name || '', isProjectName: true },
                {
                  text: `Back to ${section?.name}`,
                  to: section?.path.replace(':id', id as string),
                },
              ]}
              top="-16px"
              left="-7px"
              width="190px"
              labelElement={<styled.NavItem clickable={showSectionMenu}>/ {section?.name}</styled.NavItem>}
            />
          ) : (
            <styled.NavItem>/ {section?.name}</styled.NavItem>
          )}
        </Box>
        <Box display="flex" alignItems="center" gap="6px">
          <Tasks tasks={tasks} runningTasks={runningTasks} />
          <ProjectSetupModal id={id as string} status={status} />
        </Box>
      </styled.Navigation>
      <styled.WarningsAndErrorsBox>
        <WarningsAndErrors failedTasks={failedTasks} />
      </styled.WarningsAndErrorsBox>
    </>
  )
})
