import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from './ProductsTable.styled'

import { NoContent, TableWrapper } from 'components'
import { ProgressContext } from 'context'
import { firestore } from 'services/firebase'
import { Product, ProductStatus } from 'utils'

const columns: { name: string; field: keyof Product; tags?: boolean; status?: boolean; tag?: boolean }[] = [
  { name: 'Product name', field: 'name' },
  { name: 'Type', field: 'type' },
  { name: 'Access levels', field: 'accessLevels', tags: true },
  { name: 'Status', field: 'status', status: true },
  { name: 'Tier', field: 'tier' },
  { name: 'ID', field: 'id', tag: true },
]

interface Props {
  fromStoreManager?: boolean
}

export const ProductsTable: React.FC<Props> = ({ fromStoreManager }) => {
  const navigate = useNavigate()
  const { toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [products, setProducts] = useState<Product[]>([])

  useEffect(() => {
    const unsubscribe = firestore.collection(`projects/${id}/products`).onSnapshot({
      next: res => setProducts(res.docs.map(el => ({ id: el.id, ...el.data() })) as Product[]),
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <TableWrapper empty={!products.length} mt={fromStoreManager ? '15px' : undefined}>
      <Table>
        <TableHead>
          <TableRow>
            {columns.map(el => (
              <TableCell key={el.name}>{el.name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        {!!products.length ? (
          <TableBody>
            {products.map(item => (
              <TableRow key={item.id} onClick={() => !fromStoreManager && navigate(`products/${item.id}`)}>
                {columns.map(el => (
                  <TableCell key={el.field}>
                    {el.tags ? (
                      <Box display="flex" flexDirection="column" alignItems="flex-start" gap="4px">
                        {(item[el.field] as string[]).map((el: string) => (
                          <styled.Tag key={el}>{el}</styled.Tag>
                        ))}
                      </Box>
                    ) : el.tag ? (
                      <styled.Tag>{item[el.field]}</styled.Tag>
                    ) : el.status ? (
                      <styled.Status active={item[el.field] === ProductStatus.APPROVED}>
                        <i />
                        {item[el.field]}
                      </styled.Status>
                    ) : (
                      item[el.field]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <NoContent isTable text="Empty" />
        )}
      </Table>
    </TableWrapper>
  )
}
