import React from 'react'

import * as general from 'pages/Projects/pages/index.styled'

import { rightSidebarId } from 'hooks'

export const SettingsLayout: React.FC<{
  children?: React.ReactNode
  maxWidth?: string
  style?: React.CSSProperties
  height?: string
}> = ({ children, maxWidth, style, height }) => (
  <general.Container
    style={{ width: '100%', minWidth: '0px', height: height || 'calc(100vh - 60px)' }}
    id={rightSidebarId}
  >
    <general.MaxWidthContainer style={Object.assign({ maxWidth: `${maxWidth || '980px'}` }, style)}>
      {children}
    </general.MaxWidthContainer>
  </general.Container>
)
