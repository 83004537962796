import { GetVariableValue } from 'hooks'
import { BooleanVariable, StringVariable } from 'utils'

export const showMoreButtonHandler = async (
  text: HTMLDivElement,
  getVariableValue: GetVariableValue,
  numberOfLines?: StringVariable,
  showMoreTextButton?: BooleanVariable
) => {
  const textElement = text.querySelector('div')
  if (textElement) {
    const numberOfLinesValue = numberOfLines?.source
      ? await getVariableValue(numberOfLines.source)
      : numberOfLines?.constant
    if (numberOfLinesValue) {
      textElement.style.display = '-webkit-box'
      textElement.style.webkitBoxOrient = 'vertical'
      textElement.style.overflow = 'hidden'
      const oldH = textElement.getBoundingClientRect().height
      textElement.style.webkitLineClamp = String(+numberOfLinesValue)
      const showMoreTextButtonValue = showMoreTextButton?.source
        ? (await getVariableValue(showMoreTextButton.source)) === 'true'
        : !!showMoreTextButton?.constant
      if (showMoreTextButtonValue) {
        const newH = textElement.getBoundingClientRect().height
        if (oldH > newH) {
          const showMoreElement = document.createElement('div')
          showMoreElement.style.pointerEvents = 'none'
          showMoreElement.style.position = 'absolute'
          showMoreElement.style.bottom = '0'
          showMoreElement.style.right = '0'
          showMoreElement.style.fontStyle = 'normal'
          showMoreElement.style.fontWeight = '600'
          showMoreElement.style.paddingLeft = '30px'
          showMoreElement.innerHTML = 'MORE'
          // to fix
          showMoreElement.style.background = 'linear-gradient(270deg, #ffffff 80%, transparent 100%)'
          text.appendChild(showMoreElement)
        }
      }
    }
  }
}
