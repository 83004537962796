import React, { useContext } from 'react'

import { getFieldIcon, IPickerItem } from 'components'
import { ProjectContext } from 'context'
import { getVariableCollectionName, LocalVariable, Screen, VariableSource } from 'utils'
import { Filters } from './Filters'
import { Sort } from './Sort'

interface Props {
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Query: React.FC<Props> = ({ currentVariable, setCurrentVariable, screenConfig, allLocalVariables }) => {
  const {
    state: {
      data: { collections },
    },
  } = useContext(ProjectContext)

  const currentVariableCollectionFieldsValues: IPickerItem[] =
    collections
      .find(el => el.name === getVariableCollectionName(currentVariable, allLocalVariables))
      ?.properties.map(el => ({
        value: el.name,
        label: el.name,
        iconName: getFieldIcon(el.type),
        type: el.type,
      })) || []

  return (
    <>
      <Filters
        currentVariable={currentVariable}
        setCurrentVariable={setCurrentVariable}
        currentVariableCollectionFieldsValues={currentVariableCollectionFieldsValues}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
      <Sort
        currentVariable={currentVariable}
        setCurrentVariable={setCurrentVariable}
        currentVariableCollectionFieldsValues={currentVariableCollectionFieldsValues}
      />
    </>
  )
}
