import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { RemoveContent } from 'components'
import { ProgressContext } from 'context'
import { Workspace } from 'hooks'
import { firestore } from 'services/firebase'
import { WorkspacesService } from 'services/workspaces'

export const useProjectPlan = (
  workspaceId?: string,
  id?: string,
  onChangePlanAlertClean?: () => void,
  redirectToAnotherPage?: boolean,
  parentWindowStripeRedirect?: (url: string) => void
) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const navigate = useNavigate()
  const [wait, setWait] = useState(true)
  const [activeSubscription, setActiveSubscription] = useState<any>()
  const [plans, setPlans] = useState<any[]>([])
  const [priceIdToChange, setPriceIdToChange] = useState('')
  const [lastRefresh, setLastRefresh] = useState(+new Date())

  useEffect(() => {
    setWait(true)
    setActiveSubscription(null)
    setPlans([])
    setPriceIdToChange('')
    if (workspaceId && id) {
      const abortController = new AbortController()
      startLoader()
      Promise.all([
        WorkspacesService.getProjectPlans(id, abortController),
        id === '-'
          ? undefined
          : WorkspacesService.getProjectSubscriptions(id, abortController)
              .then(res => setActiveSubscription(res.data))
              .catch(err => err.statusCode !== 404 && !abortController.signal.aborted && toast(err)),
        workspaceId === '-' ? undefined : firestore.collection('workspaces').doc(workspaceId).get(),
      ])
        .then(res => {
          const plans = res[0].data.sort((a: any, b: any) => +a.metadata.weight - +b.metadata.weight)
          const workspace = res[2] ? (res[2].data() as Workspace | undefined) : { billingAccountId: '-' }
          if (workspace) {
            return Promise.all(
              plans.map((el: any) =>
                WorkspacesService.getProductPrices(workspaceId, workspace.billingAccountId, el.id, abortController)
              )
            ).then(res => {
              res.forEach(
                (el, i) =>
                  (plans[i].prices = el.data.map((a: any) => {
                    const currency = el.headers.get('X-Currency')
                    a.currency = currency
                    a.unit_amount = a.currency_options[currency].unit_amount
                    return a
                  }))
              )
              setPlans(plans)
              setWait(false)
            })
          } else {
            throw new Error('Workspace not found!')
          }
        })
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => stopLoader())
      return () => {
        abortController.abort()
      }
    }
  }, [workspaceId, id, lastRefresh])

  const onPlanChange = wait
    ? undefined
    : (priceId: string, callback?: () => void) => {
        if (workspaceId && id) {
          const anotherPageUrl = `/workspaces/${workspaceId}/billing/projects/${id}/plans`
          if (activeSubscription && !priceIdToChange) {
            setPriceIdToChange(priceId)
            return
          }
          startLoader()
          ;(activeSubscription
            ? WorkspacesService.updateProjectSubscription(id, activeSubscription.id, priceId)
            : WorkspacesService.postProjectSubscription(
                id,
                workspaceId,
                priceId,
                redirectToAnotherPage ? `${window.location.origin}${anotherPageUrl}` : window.location.href
              )
          )
            .then(res =>
              activeSubscription
                ? redirectToAnotherPage
                  ? navigate(anotherPageUrl)
                  : setLastRefresh(+new Date())
                : parentWindowStripeRedirect
                ? parentWindowStripeRedirect(res.data.url)
                : window.location.replace(res.data.url)
            )
            .catch(err => toast(err))
            .finally(() => {
              callback?.()
              stopLoader()
            })
        }
      }

  const ChangePlanAlert = activeSubscription ? (
    <RemoveContent
      toRemove={priceIdToChange}
      title="Change plan"
      text={
        priceIdToChange &&
        `You are about to change the plan from ${
          plans.find((el: any) => el.prices.find((a: any) => a.id === activeSubscription.plan.id))?.metadata.title
        } to ${plans.find((el: any) => el.prices.find((a: any) => a.id === priceIdToChange))?.metadata.title}`
      }
      clean={() => {
        onChangePlanAlertClean?.()
        setPriceIdToChange('')
      }}
      remove={callback => onPlanChange?.(priceIdToChange, callback)}
      blue
    />
  ) : null

  return { wait, activeSubscription, plans, onPlanChange, ChangePlanAlert }
}
