import React from 'react'

import * as styled from './Actions.styled'

import { ActionPicker, Icon, Name, Rotation } from 'components'
import { SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { Action, ActionType, getVariableName, LocalVariable, Screen, ScreenComponent } from 'utils'

interface Props {
  childrenFor: string
  component?: ScreenComponent
  subActions?: Action[]
  onChange: (val: Action[]) => void
  small?: boolean
  noBorderBottom?: boolean
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const Actions: React.FC<Props> = ({
  childrenFor,
  component,
  subActions,
  onChange,
  small,
  noBorderBottom,
  screenConfig,
  allLocalVariables,
}) => {
  const actions = subActions || (component ? component.actions : screenConfig.onScreenVisitActions) || []

  const onMove = (index: number, start: boolean) => {
    const actionsCopy = JSON.parse(JSON.stringify(actions)) as Action[]
    let actionCopy: Action | null = null
    if (start && index !== 0) {
      actionCopy = actionsCopy[index - 1]
      actionsCopy[index - 1] = actionsCopy[index]
      actionsCopy[index] = actionCopy
    } else if (!start && index !== actionsCopy.length - 1) {
      actionCopy = actionsCopy[index + 1]
      actionsCopy[index + 1] = actionsCopy[index]
      actionsCopy[index] = actionCopy
    }
    if (actionCopy) {
      onChange(actionsCopy)
    }
  }

  const onActionChange = (index: number, value: Action) => {
    const actionsCopy = JSON.parse(JSON.stringify(actions)) as Action[]
    actionsCopy.splice(index, 1, value)
    onChange(actionsCopy)
  }

  const onRemove = (index: number) => {
    const actionsCopy = JSON.parse(JSON.stringify(actions)) as Action[]
    actionsCopy.splice(index, 1)
    onChange(actionsCopy)
  }

  return (
    <SubWrapper
      title={subActions ? 'Sub actions' : component ? 'Actions' : screenConfig ? 'On screen visit actions' : ''}
      canHide={!!actions.length}
      childrenFor={childrenFor}
      small={small}
      noBorderBottom={noBorderBottom}
      onAddContainer={
        <ActionPicker
          value={{}}
          onChange={value => onChange([...actions, value])}
          close={() => {}}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      }
    >
      {!subActions && <styled.ActionsLabel>{component ? 'On tap' : 'On screen visit'}</styled.ActionsLabel>}
      <styled.Actions>
        {actions.map((el, i) => (
          <SettingField
            key={JSON.stringify(el)}
            noRadius
            value={el.actionType}
            valueDescription={
              el.actionType === ActionType.checkAuthentication
                ? `${el.options?.defaultTarget} or ${el.options?.successTarget}`
                : el.actionType === ActionType.navigate || el.actionType === ActionType.show
                ? `${el.options?.defaultTarget}`
                : el.actionType === ActionType.verifyAccess
                ? `${el.options?.defaultTarget} ${el.variable?.accessLevelConstant}`
                : el.actionType === ActionType.subscribe
                ? `${el.variable?.source ? getVariableName(el.variable.source) : undefined} ${el.variableContextKey}`
                : el.actionType === ActionType.createRecord
                ? `${el.collection?.name}`
                : el.actionType === ActionType.updateRecord
                ? `${el.record ? getVariableName(el.record) : undefined}`
                : el.actionType === ActionType.deleteRecord
                ? `${el.record ? getVariableName(el.record) : undefined}`
                : el.actionType === ActionType.openWeb
                ? `${el.options?.link?.constant}`
                : el.actionType === ActionType.openLink
                ? `${el.options?.link?.source ? getVariableName(el.options.link.source) : undefined}`
                : el.actionType === ActionType.openEmail
                ? `${el.options?.targetEmail}`
                : el.actionType === ActionType.scrollToStart
                ? `${el.componentName}`
                : el.actionType === ActionType.setValue
                ? `${el.valueTarget ? getVariableName(el.valueTarget) : undefined}`
                : ''
            }
            actions={
              <>
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} rotation={Rotation.LEFT} onClick={() => onMove(i, false)} />
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} onClick={() => onMove(i, true)} />
                <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />
              </>
            }
            optionsContainer={
              <ActionPicker
                value={el}
                onChange={value => onActionChange(i, value)}
                onRemove={() => onRemove(i)}
                close={() => {}}
                screenConfig={screenConfig}
                allLocalVariables={allLocalVariables}
              />
            }
          />
        ))}
      </styled.Actions>
    </SubWrapper>
  )
}
