import React from 'react'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { SettingsLayout } from '../components'
import { Applications, CloudProject, DangerZone, Project } from './sections'

export const General: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>General</Text>
    <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
      The product page for this app version will be published on the App Store, Google Play, and App Landing page with
      the assets and metadata below.
    </Text>
    <CustomSectionsWrapper>
      <Project />
      <Applications />
      <CloudProject />
      <DangerZone />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
