import pluralize from 'pluralize'
import { useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from 'components/Menu/Menu.styled'

import { Icon, Name, RemoveContent } from 'components'
import { ProgressContext } from 'context'
import {
  CMSCollection,
  CMSService,
  assetsId,
  collectionIdToFind,
  getCollectionName,
  getParentCollectionName,
  reservedCollectionNames,
} from 'services/cms'
import { AddCollection } from '../AddCollection'
import { Branch, CollectionsItem } from './CollectionsItem'

interface Props {
  allCollections: CMSCollection[]
  currentCollection?: CMSCollection
  setIsSchemaPage: React.Dispatch<React.SetStateAction<boolean>>
}

export const CollectionsItems: React.FC<Props> = ({ allCollections, currentCollection, setIsSchemaPage }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [addCollection, setAddCollection] = useState(false)
  const [subCollectionParentName, setSubCollectionParentName] = useState<string>()
  const [toRemove, setToRemove] = useState<CMSCollection | null>(null)
  const collections = useMemo(
    () => allCollections.filter(el => el.id !== assetsId).map(el => ({ ...el, name: collectionIdToFind(el.name) })),
    [allCollections]
  )

  const getCollectionsTree = (lvl: number, root?: string): Branch[] =>
    collections
      .filter(el => el.name.split('/').length === lvl + 1 && (root ? el.name.startsWith(`${root}/`) : true))
      .map(
        el =>
          ({
            id: el.id,
            name: getCollectionName(el.name),
            isSystem: !!el.isSystem,
            children: getCollectionsTree(lvl + 1, el.name),
          } as Branch)
      )

  const collectionsTree = useMemo(() => getCollectionsTree(0), [collections])

  const openRecords = (cId: string) => {
    setIsSchemaPage(false)
    navigate(`/projects/${id}/collections/${cId}`)
  }

  const openSchema = (cId: string) => {
    navigate(`/projects/${id}/collections/${cId}`)
    setIsSchemaPage(true)
  }

  const postCollection = (name: string, callback: () => void) => {
    if (name) {
      const plural = pluralize.plural(name).toLowerCase()
      const singular = pluralize.singular(name).toLowerCase()
      if (
        [...reservedCollectionNames, ...allCollections.map(el => el.name)].find(el =>
          [plural, singular, `${subCollectionParentName}/${plural}`, `${subCollectionParentName}/${singular}`].includes(
            el.toLowerCase()
          )
        )
      ) {
        toast(`You cannot create collection named ${name}`)
        callback()
      } else {
        startLoader()
        CMSService.postCollection(id as string, plural, subCollectionParentName)
          .then(res => {
            navigate(`/projects/${id}/collections/${res.data.name}`)
            setIsSchemaPage(true)
          })
          .catch(err => toast(err))
          .finally(() => {
            callback()
            stopLoader()
          })
      }
    }
  }

  const remove = (callback: () => void) => {
    if (toRemove) {
      startLoader()
      CMSService.deleteCollection(id as string, toRemove.id)
        .then(() => {
          if (currentCollection?.id === toRemove.id) {
            setIsSchemaPage(false)
            const parentCollectionName = getParentCollectionName(currentCollection.id)
            navigate(
              parentCollectionName
                ? `/projects/${id}/collections/${parentCollectionName}`
                : `/projects/${id}/collections`
            )
          }
        })
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
        })
    }
  }

  const closeAddCollection = () => {
    setAddCollection(false)
    setSubCollectionParentName(undefined)
  }

  return (
    <>
      <styled.NavHead>
        <styled.Title>Collections</styled.Title>
        <Icon name={Name.RIGHT_SIDEBAR_ADD} onClick={() => setAddCollection(true)} />
      </styled.NavHead>
      <styled.NavBody>
        {collectionsTree.map(el => (
          <CollectionsItem
            key={el.id}
            collection={el}
            currentCollection={currentCollection}
            openRecords={openRecords}
            openSchema={openSchema}
            setToRemove={setToRemove}
            setSubCollectionParentName={setSubCollectionParentName}
            lvl={0}
          />
        ))}
      </styled.NavBody>
      <AddCollection
        active={addCollection || !!subCollectionParentName}
        close={closeAddCollection}
        onAdd={postCollection}
        subCollectionParentName={subCollectionParentName}
      />
      <RemoveContent
        toRemove={toRemove}
        title="Delete collection"
        text="You are about to delete the collection <b>{name}</b>. To make sure, enter the collection name below"
        clean={() => setToRemove(null)}
        remove={remove}
        nameConfirmation
      />
    </>
  )
}
