import { memo, useContext, useEffect, useRef, useState } from 'react'

import * as styled from 'components/Menu/Menu.styled'

import { Icon, Name, Rotation } from 'components'
import { MovableComponentsContext } from 'context'
import { getComponentSmallIconByType, getComponentTypeById, isParentComponent } from 'utils'

export interface Chapter {
  element: HTMLElement
  name: string
  folder?: boolean
  screen?: boolean
  defaultOpen?: boolean
  children: Chapter[] | []
  active?: boolean
  parentScreen?: HTMLElement
}

const getName = (chapterName: string, search: string) => {
  let name = chapterName
  try {
    const regExp = new RegExp(search, 'gi')
    const mathes = chapterName.match(regExp)
    mathes?.map(el => (name = chapterName.replace(el, `<b>${el}</b>`)))
  } catch {}
  return name
}

interface Props {
  chapter: Chapter
  search: string
  lvl: number
  timer: NodeJS.Timeout | null
  setTimer: React.Dispatch<React.SetStateAction<NodeJS.Timeout | null>>
}

export const NavNode = memo(
  ({ chapter, search, lvl, timer, setTimer }: Props) => {
    const ref = useRef<HTMLLIElement>(null)
    const [open, setOpen] = useState(!!chapter.defaultOpen)
    const {
      movableComponent,
      // movableTabComponent
    } = useContext(MovableComponentsContext)
    const [y, setY] = useState(0)
    const [isMouseOver, setIsMouseOver] = useState(false)
    const getPointerEvents = () =>
      window.getComputedStyle(chapter.element).getPropertyValue('pointer-events') !== 'none'

    const onDblclick = () => {
      if (getPointerEvents()) {
        chapter.element.dispatchEvent(new Event('dblclick'))
      }
    }

    const onMouseUp = () => {
      if (timer) {
        clearInterval(timer)
      }
      if (getPointerEvents()) {
        chapter.element.dispatchEvent(new Event('mouseup'))
        ;(chapter.parentScreen || chapter.element).scrollIntoView({
          block: 'center',
          behavior: 'smooth',
        })
      }
      document.removeEventListener('mouseup', onMouseUp)
    }

    const onMouseDown = () => {
      if (getPointerEvents()) {
        chapter.element.dispatchEvent(new Event('mousedown'))
      }
      document.addEventListener('mouseup', onMouseUp)
    }

    const onMouseMove = (e: any) => {
      if (timer) {
        clearInterval(timer)
      }
      if (getPointerEvents()) {
        e.target.style.removeProperty('transform')
        if (!movableComponent) {
          chapter.element.dispatchEvent(new Event('mousemove'))
        } else {
          if (!chapter.element.id.includes(movableComponent.id)) {
            const { height, y } = e.target.getBoundingClientRect()
            const center = y + height / 2
            chapter.element.dispatchEvent(new CustomEvent('mousemove', { bubbles: true, detail: e.pageY >= center }))
            if (isParentComponent(getComponentTypeById(chapter.element.id))) {
              setTimer(
                setTimeout(() => {
                  chapter.element.dispatchEvent(new Event('mousemove', { bubbles: true }))
                  setOpen(true)
                }, 600)
              )
            }
          } else {
            const newY = e.movementY + y
            setY(newY)
            e.target.style.transform = `translateY(${newY / 3}px)`
          }
        }
      }
    }

    const onMouseOver = () => {
      if (timer) {
        clearInterval(timer)
      }
      if (getPointerEvents()) {
        setIsMouseOver(true)
        chapter.element.dispatchEvent(new Event('mouseover'))
        if (movableComponent && !isParentComponent(getComponentTypeById(chapter.element.id))) {
          setOpen(true)
        }
      }
    }

    const onMouseLeave = (e: any) => {
      if (timer) {
        clearInterval(timer)
      }
      if (getPointerEvents()) {
        setIsMouseOver(false)
        if (!chapter.screen) {
          chapter.element.dispatchEvent(new Event('mouseleave'))
        } else {
          if (open) {
            const { height, y } = e.target.getBoundingClientRect()
            const center = y + height / 2
            if (e.pageY < center) {
              chapter.element.dispatchEvent(new Event('mouseleave'))
            }
          } else {
            chapter.element.dispatchEvent(new Event('mouseleave'))
          }
        }
      }
    }

    const openClickHandler = () => {
      const newOpenState = !open
      setOpen(newOpenState)
      chapter.defaultOpen = newOpenState
    }

    useEffect(() => {
      setOpen(!!chapter.defaultOpen)
    }, [chapter.defaultOpen])

    useEffect(() => {
      document.removeEventListener('mouseup', onMouseUp)
    }, [])

    useEffect(() => {
      if (
        !movableComponent &&
        //  !movableTabComponent &&
        ref.current?.style.transform
      ) {
        ref.current?.style.removeProperty('transform')
      }
    }, [
      movableComponent,
      //  movableTabComponent
    ])

    useEffect(() => {
      if (
        chapter.active &&
        !(movableComponent && chapter.element.id.endsWith(movableComponent.id)) &&
        // !(movableTabComponent && chapter.element.id.endsWith(movableTabComponent.screen)) &&
        !isMouseOver
      ) {
        ref.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
      }
    }, [chapter.active])

    return (
      <>
        <styled.NavItem
          ref={ref}
          active={!!chapter.active}
          notHover={!!movableComponent}
          onDoubleClick={onDblclick}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          lvl={lvl}
          movable
        >
          {!!chapter.children.length ? (
            <styled.Arrow>
              <Icon
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
                onClick={openClickHandler}
                name={Name.ADDITIONAL_NAVIGATION_TREE_ARROW}
                rotation={open ? Rotation.DOWN : undefined}
              />
            </styled.Arrow>
          ) : (
            <styled.Space />
          )}
          {chapter.screen ? (
            <Icon name={Name.ADDITIONAL_SCREEN} />
          ) : chapter.element.id ? (
            getComponentSmallIconByType(getComponentTypeById(chapter.element.id))
          ) : open ? (
            <Icon name={Name.ADDITIONAL_NAVIGATION_TREE_FOLDER_OPEN} />
          ) : (
            <Icon name={Name.ADDITIONAL_NAVIGATION_TREE_FOLDER} />
          )}
          <span dangerouslySetInnerHTML={{ __html: getName(chapter.name, search) }} />
        </styled.NavItem>
        {open &&
          chapter.children.map((el, i) => (
            <NavNode
              key={el.name + el.element.id + i}
              chapter={el}
              search={search}
              lvl={lvl + 1}
              timer={timer}
              setTimer={setTimer}
            />
          ))}
      </>
    )
  },
  // @ts-ignore
  (prevProps: Props, nextProps: Props) => {
    if (prevProps.chapter.defaultOpen && !nextProps.chapter.defaultOpen) {
      nextProps.chapter.defaultOpen = true
    }
  }
)
