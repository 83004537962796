import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Crumbs, Icon, InputProperty, Name, RightForm, inputs, validate } from 'components'
import { ProgressContext, WorkspaceContext } from 'context'
import { CMSCollectionRecord } from 'services/cms'
import { WorkspacesService } from 'services/workspaces'
import { ValueType } from 'utils'

const properties: InputProperty[] = [
  'email',
  'name',
  'phone',
  'city',
  'country',
  'line1',
  'line2',
  'postalCode',
  'state',
  'taxId',
].map((el, i) => ({
  name: el,
  type: ValueType.string,
  position: i,
  isRequired: el === 'name',
}))

export const BillingAccount: React.FC = () => {
  const navigate = useNavigate()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { billingAccountId } = useParams()
  const { workspace } = useContext(WorkspaceContext)
  const workspaceId = workspace?.id as string
  const [wait, setWait] = useState(true)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)

  const setForm = async () => {
    const billingAccount: CMSCollectionRecord = {}
    setWait(true)
    if (billingAccountId) {
      try {
        startLoader()
        const res = await WorkspacesService.getBillingAccount(workspaceId, billingAccountId)
        billingAccount[properties[0].name] = res.data[properties[0].name] || null
        billingAccount[properties[1].name] = res.data[properties[1].name] || null
        billingAccount[properties[2].name] = res.data[properties[2].name] || null
        billingAccount[properties[3].name] = res.data.address?.[properties[3].name] || null
        billingAccount[properties[4].name] = res.data.address?.[properties[4].name] || null
        billingAccount[properties[5].name] = res.data.address?.[properties[5].name] || null
        billingAccount[properties[6].name] = res.data.address?.[properties[6].name] || null
        billingAccount[properties[7].name] = res.data.address?.[properties[7].name] || null
        billingAccount[properties[8].name] = res.data.tax_ids?.data[0]?.value || null
      } catch (err) {
        toast(err)
      } finally {
        stopLoader()
      }
    } else {
      properties.forEach(property => {
        billingAccount[property.name] = null
      })
    }
    setValuesBeforeEdit(JSON.stringify(billingAccount))
    formik.setValues({ ...billingAccount })
    setWait(false)
  }

  useEffect(() => {
    setForm()
  }, [])

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (billingAccount: any) => validate(billingAccount, properties),
    onSubmit: billingAccount => {
      setSaveLoading(true)
      startLoader()
      ;(billingAccountId
        ? WorkspacesService.updateBillingAccount(workspaceId, billingAccountId, billingAccount)
        : WorkspacesService.postBillingAccount(workspaceId, billingAccount)
      )
        .then(res => {
          const billingAccountId = res.data.id
          setValuesBeforeEdit(JSON.stringify(billingAccount))
          setTimeout(() => {
            navigate(`/workspaces/${workspaceId}/billing/${billingAccountId}`)
          }, 0)
        })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setSaveLoading(false)
        })
    },
  })

  const showConfirmWhen = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)
  const closePath = `/workspaces/${workspaceId}/billing`

  return (
    <RightForm
      showConfirmWhen={showConfirmWhen}
      onClose={() => navigate(closePath)}
      title={
        <>
          <Crumbs
            firstCrumbTitle="Billing"
            secondCrumbTitle={`${billingAccountId ? 'Edit' : 'Add'} Billing Account`}
            icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
            to={closePath}
            small
          />
          <Button onClick={formik.submitForm} disabled={!showConfirmWhen || saveLoading} loading={saveLoading}>
            Save
          </Button>
        </>
      }
      form={wait ? <></> : <form onSubmit={formik.handleSubmit}>{inputs(properties, formik)}</form>}
    />
  )
}
