import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from './CollectionRecord.styled'

import {
  Button,
  Crumbs,
  DropDown,
  Icon,
  Name,
  RemoveContent,
  RightForm,
  Text,
  Type,
  getField,
  inputs,
  validate,
} from 'components'
import { CMSContext, ProgressContext } from 'context'
import { collectionPrefix } from 'hooks'
import { PublishedStatus } from 'pages/CMS/components'
import {
  CMSCollection,
  CMSCollectionRecord,
  CMSService,
  RecordStatus,
  ResourceTypes,
  createAssetProperties,
  getCollectionName,
  oldFileName,
  rootFolderName,
} from 'services/cms'

export const refreshRecordsEvent = 'refreshRecordsEvent'
export const refreshRecords = () => document.dispatchEvent(new Event(refreshRecordsEvent))

interface Props {
  recordId: string
  collectionId: string
  currentCollection: CMSCollection
  currentFolder?: CMSCollection
  resourceType?: ResourceTypes
  onAddRef?: (val: any) => void
  closeAddRef?: () => void
}

export const CollectionRecord: React.FC<Props> = ({
  recordId,
  collectionId,
  currentCollection,
  currentFolder,
  resourceType,
  onAddRef,
  closeAddRef,
}) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { dataObjects, folders, assets } = useContext(CMSContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [wait, setWait] = useState(true)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const [status, setStatus] = useState<RecordStatus>()
  const [lastUpdate, setLastUpdate] = useState(+new Date())
  const [localLoading, setLocalLoading] = useState(false)
  const [publishLoading, setPublishLoading] = useState(false)
  const wasChanged = useRef(false)
  const [toRemove, setToRemove] = useState(false)
  const assetRealProperties = assets?.properties || []
  const properties = currentFolder || resourceType ? createAssetProperties : currentCollection.properties

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (record: any) => validate(record, properties, dataObjects),
    onSubmit: () => publishRecord(true),
  })

  useEffect(() => {
    if (!onAddRef) {
      return () => {
        if (wasChanged.current) {
          refreshRecords()
        }
      }
    }
  }, [])

  useEffect(() => {
    setWait(true)
    startLoader()
    CMSService.getRecord(id as string, collectionId, recordId)
      .then(res => setFormikValues(res.data))
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setWait(false)
      })
  }, [collectionId, recordId, lastUpdate])

  const setFormikValues = (data: CMSCollectionRecord) => {
    const record: CMSCollectionRecord = {}
    if (currentFolder || resourceType) {
      assetRealProperties.forEach(property => {
        record[property.name] = data[property.name]
      })
      if (record.url && record.resourceType) {
        record.resource = {
          url: record.url,
          name: oldFileName,
          size: record.bytes,
          type: `${record.resourceType}/${record.format}`,
          thumbnailUrl: record.thumbnailUrl,
        }
      } else {
        record.resource = null
      }
      if (!record.folder) {
        record.folder = currentFolder?.name || rootFolderName
      }
    } else {
      properties.forEach(property => {
        record[property.name] =
          data[property.name] || getField(property.type)?.getDefaultValue?.(dataObjects, property.dataObject) || null
      })
    }
    setStatus(data.recordStatus)
    setValuesBeforeEdit(JSON.stringify(record) + record.resource?.name)
    formik.setValues(record)
  }

  const publishRecord = (publish: boolean) => {
    setPublishLoading(true)
    startLoader()
    CMSService.publishRecord(id as string, collectionId, recordId, publish)
      .then(res => {
        setStatus(res.data.recordStatus)
        if (onAddRef) {
          document.dispatchEvent(new Event(`ReferenceItem/${collectionId}/${recordId}`))
        } else {
          wasChanged.current = true
        }
      })
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setPublishLoading(false)
      })
  }

  const dublicateRecord = () => {
    setLocalLoading(true)
    startLoader()
    const record = formik.values
    const recordCopy = JSON.parse(JSON.stringify(record))
    if (currentFolder || resourceType) {
      delete recordCopy.resource
    }
    CMSService.postRecord(id as string, collectionId, recordCopy)
      .then(res => {
        wasChanged.current = true
        navigate(
          currentFolder
            ? `/projects/${id}/collections/${collectionId}/${res.data.id}?folder=${currentFolder.id}`
            : `/projects/${id}/collections/${collectionId}/${res.data.id}`
        )
      })
      .catch(err => toast(err))
      .finally(() => {
        stopLoader()
        setLocalLoading(false)
      })
  }

  const deleteRecord = (callback: () => void) => {
    if (toRemove) {
      setLocalLoading(true)
      startLoader()
      CMSService.deleteRecord(id as string, collectionId, recordId)
        .then(() => {
          wasChanged.current = true
          setLastUpdate(+new Date())
        })
        .catch(err => toast(err))
        .finally(() => {
          callback()
          stopLoader()
          setLocalLoading(false)
        })
    }
  }

  const showConfirmWhen =
    !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values) + formik.values.resource?.name
  const hasProperties = !!properties.length
  const assetFolder = formik.values.folder
  const closePath = currentFolder
    ? `/projects/${id}/collections/${collectionId}?folder=${
        folders.find(el => el.name === assetFolder)?.id || currentFolder.id
      }`
    : `/projects/${id}/collections/${collectionId}`

  useEffect(() => {
    if (showConfirmWhen) {
      const abortController = new AbortController()
      const record = formik.values
      setLocalLoading(true)
      startLoader()
      CMSService.saveRecord(id as string, collectionId, recordId, record, abortController)
        .then(res => {
          setFormikValues(res.data)
          if (onAddRef) {
            delete res.data.recordStatus
            onAddRef(resourceType ? res.data : `${collectionPrefix}${collectionId}/${recordId}`)
          } else {
            wasChanged.current = true
          }
        })
        .catch(err => !abortController.signal.aborted && toast(err))
        .finally(() => {
          stopLoader()
          setLocalLoading(false)
        })
      return () => {
        abortController.abort()
      }
    }
  }, [formik.values])

  const publishOption = {
    icon: <Icon name={Name.GLOBAL_FILE_TRANSPARENT} />,
    text:
      status === RecordStatus.PUBLISHED ? 'Unpublish' : status === RecordStatus.CHANGED ? 'Publish changes' : 'Publish',
    onClick: () => publishRecord(status !== RecordStatus.PUBLISHED),
  }
  const dublicateOption = {
    icon: <Icon name={Name.RIGHT_SIDEBAR_COPY} />,
    text: 'Dublicate',
    onClick: () => dublicateRecord(),
  }
  const deleteOption = {
    icon: <Icon name={Name.PICKERS_DELETE} />,
    text: status === RecordStatus.CHANGED ? 'Discard' : 'Delete',
    onClick: () => setToRemove(true),
    red: true,
  }

  return (
    <>
      <RightForm
        showConfirmWhen={showConfirmWhen}
        onClose={() => (closeAddRef ? closeAddRef() : navigate(closePath))}
        title={
          wait ? (
            <></>
          ) : (
            <>
              <Box display="flex" alignItems="center" gap="10px">
                {onAddRef ? (
                  <Crumbs
                    firstCrumbTitle="Edit record"
                    onFirstCrumbClick={closeAddRef}
                    secondCrumbTitle={`Add ${resourceType || 'record'}`}
                    small
                  />
                ) : (
                  <Crumbs
                    firstCrumbTitle={getCollectionName(currentCollection.name)}
                    secondCrumbTitle={`${status ? 'Edit' : 'Add'} ${currentFolder ? 'asset' : 'record'}`}
                    icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
                    to={closePath}
                    small
                  />
                )}
                {status && <PublishedStatus status={status} />}
              </Box>
              {status && (
                <Box display="flex" gap="10px">
                  <Button
                    disabled={status === RecordStatus.PUBLISHED || localLoading || publishLoading}
                    onClick={formik.submitForm}
                    loading={publishLoading}
                  >
                    {status === RecordStatus.CHANGED ? 'Publish changes' : 'Publish'}
                  </Button>
                  <styled.ActionsWrapper>
                    <DropDown
                      options={onAddRef ? [publishOption] : [publishOption, dublicateOption, deleteOption]}
                      top="25px"
                      labelElement={<Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />}
                    />
                  </styled.ActionsWrapper>
                </Box>
              )}
            </>
          )
        }
        form={
          wait ? (
            <></>
          ) : hasProperties ? (
            <>
              {currentCollection.helpText && <Text type={Type.SUB_TITLE}>{currentCollection.helpText}</Text>}
              <form onSubmit={formik.handleSubmit}>{inputs(properties, formik, resourceType)}</form>
            </>
          ) : (
            <Box mt="40px">
              <Text type={Type.BODY}>No fields for this collection yet</Text>
            </Box>
          )
        }
      />
      <RemoveContent
        toRemove={toRemove}
        title={`${status === RecordStatus.CHANGED ? 'Discard' : 'Delete'} record`}
        text={`Are you sure? This action <b>can not be undone</b>. Data will be ${
          status === RecordStatus.CHANGED ? 'discarded' : 'deleted'
        } immediately`}
        clean={() => setToRemove(false)}
        remove={deleteRecord}
      />
    </>
  )
}
