import React from 'react'
import { ISvg, Svg } from '../Svg'

const ReloadPage: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 17 16" fill="none" {...props}>
    <path
      d="M3.01468 3.76943L2.76271 2.70613C2.74236 2.62076 2.70539 2.54023 2.65392 2.46915C2.60245 2.39806 2.53748 2.33781 2.46273 2.29184C2.38798 2.24586 2.3049 2.21506 2.21824 2.20119C2.13158 2.18732 2.04304 2.19066 1.95767 2.21101C1.8723 2.23136 1.79177 2.26832 1.72069 2.31979C1.64961 2.37126 1.58936 2.43623 1.54338 2.51099C1.4974 2.58574 1.4666 2.66882 1.45273 2.75548C1.43886 2.84214 1.4422 2.93068 1.46255 3.01605L2.12775 5.76502C2.16958 5.936 2.27723 6.08354 2.42728 6.17555C2.57734 6.26757 2.75765 6.29661 2.92901 6.25636L5.6805 5.59872C6.54727 5.38707 6.23231 4.08691 5.36554 4.29857L4.04271 4.61857C4.74449 3.73264 5.69413 3.07568 6.77063 2.73141C7.84713 2.38714 9.00175 2.37114 10.0874 2.68545C11.173 2.99976 12.1405 3.63015 12.8665 4.4963C13.5926 5.36244 14.0444 6.42513 14.1643 7.54896C14.2374 8.46109 15.6181 8.31243 15.4922 7.40534C15.1066 3.79211 12.0326 1.10109 8.11452 1.13888C5.81657 1.36565 4.14601 2.35337 3.01468 3.76943ZM11.3095 10.3811C10.4074 10.5675 10.7325 11.9257 11.6194 11.6812L12.9422 11.3612C12.2421 12.2504 11.2926 12.9104 10.2152 13.2568C9.13781 13.6032 7.98157 13.6203 6.89442 13.3059C5.80727 12.9915 4.83864 12.3599 4.11248 11.4919C3.38632 10.6239 2.93565 9.55896 2.81814 8.43337C2.81368 8.34248 2.7907 8.25346 2.75059 8.17178C2.71048 8.09009 2.6541 8.01747 2.58491 7.95837C2.51572 7.89926 2.43517 7.85493 2.34823 7.82808C2.26128 7.80123 2.16976 7.79244 2.07929 7.80224C1.98882 7.81204 1.90131 7.84024 1.82213 7.88509C1.74295 7.92994 1.67378 7.9905 1.61885 8.06305C1.56393 8.13561 1.52441 8.21862 1.50273 8.307C1.48105 8.39538 1.47767 8.48726 1.49279 8.57699C1.69332 10.4296 2.62015 12.1271 4.07015 13.2975C5.52015 14.4679 7.37502 15.0157 9.22822 14.8208C10.1518 14.7214 11.0464 14.4394 11.86 13.9913C12.6736 13.5432 13.3901 12.9378 13.9677 12.2104L14.2248 13.2762C14.3885 14.2085 15.797 13.8709 15.5199 12.9663L14.8622 10.2148C14.7866 9.89731 14.4944 9.68061 14.1114 9.71085L11.3095 10.3811Z"
      fill="#212133"
    />
  </Svg>
)

export default ReloadPage
