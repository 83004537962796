import React, { ReactElement, useEffect, useState } from 'react'

import { Actions, OptionsContainer } from '../../SettingField.styled'
import * as styled from './NumberField.styled'

import { Icon, Name, NumberPicker, Rotation } from 'components'
import { getVariableName, LocalVariable, Screen, VariableSource } from 'utils'

interface Props {
  value?: string
  onChange: (val: string) => void
  source?: VariableSource
  onSourceChange?: (val?: VariableSource) => void
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
  leftIcon?: ReactElement
  units?: string
  onlyNumber?: boolean
  active?: boolean
  setActive?: React.Dispatch<React.SetStateAction<boolean>>
  setOptionsContainer: React.Dispatch<React.SetStateAction<((top?: number, width?: number) => ReactElement) | null>>
  optionsContainerOffsetTop?: number
  specialWidth?: string
  fullWidth?: boolean
  hasMax?: boolean
  sync?: boolean
  insidePicker?: boolean
}

export const NumberField: React.FC<Props> = ({
  value,
  onChange,
  source,
  onSourceChange,
  screenConfig,
  allLocalVariables,
  leftIcon,
  units,
  onlyNumber,
  active,
  setActive,
  setOptionsContainer,
  optionsContainerOffsetTop,
  specialWidth,
  fullWidth,
  hasMax,
  sync,
  insidePicker,
}) => {
  const val = source ? getVariableName(source) : value || ''
  const [currentValue, setCurrentValue] = useState(val)

  useEffect(() => {
    if (currentValue !== val) {
      setCurrentValue(val)
    }
  }, [val])

  useEffect(() => {
    if (currentValue !== val) {
      if (sync) {
        onChange(currentValue)
      } else {
        const debounceFn = setTimeout(() => {
          onChange(currentValue)
        }, 600)
        return () => clearInterval(debounceFn)
      }
    }
  }, [currentValue])

  const onArrowMouseDown = (increase: boolean) => {
    setCurrentValue(currentValue => String(+currentValue + (increase ? 1 : -1)))
    const timer = setInterval(() => {
      setCurrentValue(currentValue => String(+currentValue + (increase ? 1 : -1)))
    }, 300)
    document.onmouseup = () => {
      clearInterval(timer)
      document.onmouseup = () => {}
    }
  }

  const onClick = () => {
    setActive?.(true)
    if (!onlyNumber) {
      setOptionsContainer(() => (top?: number, width?: number) => (
        <OptionsContainer top={(top || 0) - (optionsContainerOffsetTop || 0)} width={width} insidePicker={insidePicker}>
          <NumberPicker
            key={Math.random()}
            close={() => {
              setActive?.(false)
              setOptionsContainer(null)
            }}
            value={value}
            onChange={onChange}
            source={source}
            onSourceChange={onSourceChange}
            screenConfig={screenConfig}
            allLocalVariables={allLocalVariables}
            hasMax={hasMax}
          />
        </OptionsContainer>
      ))
    }
  }

  const onArrowClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    setActive?.(true)
    setOptionsContainer(null)
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCurrentValue(value === '' || isNaN(+value) ? '' : String(+value))
    setOptionsContainer(null)
  }

  const hideArrows = isNaN(+currentValue)

  return (
    <styled.NumberInput
      leftIcon={!!leftIcon}
      specialWidth={specialWidth}
      fullWidth={!!fullWidth}
      hideArrows={hideArrows}
      onClick={onClick}
      active={active}
    >
      {leftIcon}
      <input placeholder="auto" value={currentValue} onChange={onInputChange} />
      {units && <styled.Units isZero={value === ''}>{units}</styled.Units>}
      {!specialWidth &&
        (hideArrows ? (
          <Actions onClick={e => e.stopPropagation()}>
            <Icon
              name={Name.RIGHT_SIDEBAR_DELETE}
              onClick={() => {
                onChange('')
                onSourceChange?.()
              }}
            />
          </Actions>
        ) : (
          <styled.NumberInputIcons leftIcon={!!leftIcon}>
            <Icon
              name={Name.RIGHT_SIDEBAR_SMALL_ARROW_THIN_DOWN}
              rotation={Rotation.LEFT}
              onMouseDown={() => onArrowMouseDown(true)}
              onClick={onArrowClick}
            />
            <Icon
              name={Name.RIGHT_SIDEBAR_SMALL_ARROW_THIN_DOWN}
              onMouseDown={() => onArrowMouseDown(false)}
              onClick={onArrowClick}
            />
          </styled.NumberInputIcons>
        ))}
    </styled.NumberInput>
  )
}
