import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { PickerOverlay, PickerWrapper } from 'components/Modal/Modal.styled'
import * as styled from './RefPicker.styled'

import { Button, ButtonColor, CustomPicker, isFiltersNotValid, NoContent, Text, Type } from 'components'
import { ReferenceItem } from 'components/FieldTypes/components/RefField/ReferenceItems'
import { ProgressContext, ProjectContext } from 'context'
import { collectionPrefix } from 'hooks'
import { CMSCollection, CMSCollectionRecord, CMSService, ResourceTypes } from 'services/cms'
import { TableQueryFilterOperand, ValueType, VariableSource, VariableSourceType } from 'utils'
import { CustomSearch } from './components'

interface Props {
  currentRef: CMSCollection
  onAddRef: (val: any) => void
  close: () => void
  resourceType?: ResourceTypes
  selectedValues: any[]
  multiple?: boolean
  title: string
}

export const RefPicker: React.FC<Props> = ({
  currentRef,
  onAddRef,
  close,
  resourceType,
  selectedValues,
  multiple,
  title,
}) => {
  const { language } = useContext(ProjectContext)
  const themeContext = useContext(ThemeContext)
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const [loading, setLoading] = useState(true)
  const [values, setValues] = useState<CMSCollectionRecord[]>([])
  const [search, setSearch] = useState('')
  const [currentVariable, setCurrentVariable] = useState<VariableSource>({
    type: VariableSourceType.collection,
    query: {
      filters:
        resourceType && resourceType !== ValueType.file
          ? [
              {
                field: 'resourceType',
                operator: TableQueryFilterOperand.equal,
                value: { textConstant: { key: 'key', locales: { [language]: resourceType } } },
                default: true,
              },
            ]
          : [],
    },
  })

  useEffect(() => {
    if (!isFiltersNotValid(currentVariable.query?.filters)) {
      const abortController = new AbortController()
      setLoading(true)
      CMSService.getRecords(
        id as string,
        currentRef.id,
        language,
        currentVariable.query,
        undefined,
        search,
        undefined,
        undefined,
        undefined,
        abortController
      )
        .then(res => setValues(res.data))
        .catch(err => !abortController.signal.aborted && toast(err, true))
        .finally(() => !abortController.signal.aborted && setLoading(false))
      return () => {
        abortController.abort()
      }
    }
  }, [currentVariable.query, search])

  return (
    <PickerWrapper>
      <PickerOverlay onClick={close} />
      <Box width="700px" zIndex="1">
        <CustomPicker
          title={`Add existing ${title}`}
          close={close}
          optionsContainer={
            <>
              <CustomSearch
                search={search}
                setSearch={setSearch}
                currentVariable={currentVariable}
                setCurrentVariable={setCurrentVariable}
                properties={currentRef.properties}
                wide
              />
              <styled.Items referenceMode={!resourceType}>
                {loading ? (
                  <Text type={Type.BODY} fontSize="14px" textAlign="center" width="100%">
                    Loading...
                  </Text>
                ) : !values.length ? (
                  <NoContent
                    text={`Your ${resourceType ? 'folder' : 'collection'} is empty. Get started by adding a new ${
                      resourceType ? 'asset' : 'record'
                    }.`}
                  />
                ) : (
                  values.map(el => {
                    const value = resourceType ? el : `${collectionPrefix}${currentRef.id}/${el.id}`
                    return (
                      <ReferenceItem
                        key={el.id}
                        active={
                          !!selectedValues.find(selected =>
                            resourceType ? selected.id === el.id : selected.includes(el.id)
                          )
                        }
                        value={value}
                        onClick={() => onAddRef(value)}
                        resourceType={resourceType}
                        record={el}
                      />
                    )
                  })
                )}
              </styled.Items>
              {multiple && (
                <Box
                  display="flex"
                  gap="16px"
                  justifyContent="flex-end"
                  p="10px 16px 12px"
                  borderTop={`1px solid ${themeContext.colors.darkBlue_100}`}
                >
                  <Button color={ButtonColor.SECONDARY} onClick={close}>
                    Cancel
                  </Button>
                  <Button onClick={close}>Select {title}</Button>
                </Box>
              )}
            </>
          }
        />
      </Box>
    </PickerWrapper>
  )
}
