import { Box } from '@mui/material'
import { memo } from 'react'

import * as styled from './ReferenceItem.styled'

import { DropDown, Icon, Name } from 'components'
import { CMSCollectionRecord, ResourceTypes } from 'services/cms'
import { Asset, Record } from './components'

interface Props {
  value: CMSCollectionRecord | string
  resourceType?: ResourceTypes
  onMovableMouseDown?: (e: React.MouseEvent<HTMLElement, MouseEvent>, item: any) => void
  remove?: () => void
  open?: (id: string) => void
  onClick?: () => void
  active?: boolean
  record?: CMSCollectionRecord
}

export const ReferenceItem: React.FC<Props> = memo(
  ({ value, resourceType, onMovableMouseDown, remove, open, onClick, active, record }) => {
    const dropDown = remove && (
      <div onClick={e => e.stopPropagation()}>
        <DropDown
          options={[
            {
              icon: <Icon name={Name.PICKERS_DELETE} />,
              text: 'Remove',
              onClick: remove,
              red: true,
            },
          ]}
          top="25px"
          labelElement={
            <styled.ShowMoreActions>
              <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
            </styled.ShowMoreActions>
          }
        />
      </div>
    )

    const move = onMovableMouseDown && (
      <styled.Move onMouseDown={e => onMovableMouseDown(e, value)} onClick={e => e.stopPropagation()}>
        <Icon width={32} height={32} name={Name.ADDITIONAL_MOVABLE} />
      </styled.Move>
    )

    return (
      <styled.Item
        asset={!!resourceType}
        small={!!onClick}
        hasMovable={!!onMovableMouseDown}
        active={!!active}
        onClick={
          open ? () => open(resourceType ? (value as CMSCollectionRecord).id : value.split('/').slice(-1)[0]) : onClick
        }
      >
        {move}
        <Box width="100%">
          {resourceType ? (
            <Asset asset={value as CMSCollectionRecord} dropDown={dropDown} record={record} />
          ) : (
            <Record value={value as string} dropDown={dropDown} record={record} />
          )}
        </Box>
      </styled.Item>
    )
  }
)
