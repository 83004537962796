import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import * as styled from './AppCard.styled'

import { DropDown, Icon, Image, Name, Plan, Text, Type } from 'components'
import { Project } from 'hooks'

interface Props {
  project?: Project
  setToRemove?: React.Dispatch<React.SetStateAction<Project | null>>
}

export const AppCard: React.FC<Props> = ({ project, setToRemove }) => {
  const themeContext = useContext(ThemeContext)
  const navigate = useNavigate()
  const { workspaceId } = useParams()

  const onLinkClick = () => {
    if (project) {
      navigate(`/projects/${project.id}`)
    }
  }
  const onOptionClick = () => {
    if (setToRemove && project) {
      setToRemove(project)
    }
  }

  const date = project?.createdAt?.seconds ? new Date(project.createdAt.seconds * 1000) : null
  const formattedTime = date
    ? date.toLocaleString('en-US', { month: 'long' }) + ' ' + date.getDate() + ', ' + date.getFullYear()
    : null

  return (
    <styled.Container load={!project}>
      <styled.Link onClick={onLinkClick}>
        {project && (
          <styled.CardBox>
            <styled.PlanWrapper onClick={e => e.stopPropagation()}>
              <Plan to={`/workspaces/${workspaceId}/billing/projects/${project.id}/plans`} plan={project.plan}>
                {project.plan}
              </Plan>
            </styled.PlanWrapper>
            <Image
              src={project.logoUrl}
              borderRadius="16px"
              height="90px"
              width="90px"
              style={{ marginBottom: '16px' }}
            />
            <Text
              type={Type.H3}
              as="span"
              fontWeight={themeContext.weights.normal}
              styles={{
                marginBottom: '3px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '200px',
              }}
            >
              {project.name}
            </Text>
            <Text color={themeContext.colors.grey1} type={Type.BODY}>
              {project && formattedTime}
            </Text>
            <styled.MoreIconBox onClick={e => e.stopPropagation()}>
              <DropDown
                options={[{ text: 'Delete Project', onClick: onOptionClick, red: true }]}
                width="122px"
                right="10px"
                labelElement={
                  <styled.RotateIconBox>
                    <Icon name={Name.RIGHT_SIDEBAR_MORE} color={themeContext.colors.grey1} />
                  </styled.RotateIconBox>
                }
              />
            </styled.MoreIconBox>
          </styled.CardBox>
        )}
      </styled.Link>
    </styled.Container>
  )
}
