import { Box } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { Picker } from './components/Picker'
import { PickerContextWrapper } from './context'

export * from './hooks/useColorPicker'

const height = 135

export const SwitchColor = ({ value, onChange, onlySolid = false, withoutOpacity = false, preview = <></> }) => {
  const contRef = useRef(null)
  const [x, setX] = useState(0)
  const [width, setWidth] = useState(0)
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (value !== localValue) {
      const debounceFn = setTimeout(() => {
        onChange(localValue)
      }, 10)
      return () => clearInterval(debounceFn)
    }
  }, [localValue])

  useEffect(() => {
    setTimeout(() => {
      if (contRef.current) {
        const { x, width } = contRef.current.getBoundingClientRect()
        setX(x)
        setWidth(width)
      }
    }, 0)
  }, [contRef.current])

  return (
    <Box padding="10px 10px 20px">
      <div ref={contRef}>
        {!!width && (
          <PickerContextWrapper
            offsetLeft={x}
            value={localValue}
            onChange={value => setLocalValue(value)}
            squareSize={width}
            squareHeight={height}
          >
            <Picker onlySolid={onlySolid} withoutOpacity={withoutOpacity} preview={preview} />
          </PickerContextWrapper>
        )}
      </div>
    </Box>
  )
}
