import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, ButtonColor, CoreColorInput, CoreFontInput, Icon, Name } from 'components'
import { ScreenshotsStudio } from 'pages'
import { ProjectContext, TopPanelContext } from 'context'
import { RightSidebarItem, SettingField, SettingProject, SubWrapper } from 'partials/RightSidebar/components'
import { BrandingColorType, Platform } from 'utils'
import { Id } from '../common'

export const ProjectName: React.FC = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    state: {
      ios: { bundleId },
      localization: { languages },
    },
    project: { name, logoUrl },
  } = useContext(ProjectContext)
  const { setBackButton, setPage } = useContext(TopPanelContext)
  const [openScreenshots, setOpenScreenshots] = useState(false)

  useEffect(() => {
    if (openScreenshots) {
      setBackButton(
        <Button
          color={ButtonColor.TERTIARY}
          leftIcon={<Icon name={Name.ADDITIONAL_BACK} width={20} height={20} />}
          onClick={() => setOpenScreenshots(false)}
        >
          Back
        </Button>
      )
      setPage(<ScreenshotsStudio />)
      return () => {
        setBackButton(null)
        setPage(null)
      }
    }
  }, [openScreenshots])

  const sectionName = ''

  return (
    <RightSidebarItem title={sectionName}>
      <SettingProject name={name} logoUrl={logoUrl} />
      <SubWrapper childrenFor={sectionName}>
        <Id value={bundleId} />
      </SubWrapper>
      <SubWrapper title="Colors" childrenFor={sectionName} customIcon={<Icon name={Name.RIGHT_SIDEBAR_EDIT} />}>
        {[
          BrandingColorType.primary,
          BrandingColorType.secondary,
          BrandingColorType.tertiary,
          BrandingColorType.neutral,
        ].map(el => (
          <CoreColorInput key={el} colorType={el} />
        ))}
      </SubWrapper>
      <SubWrapper title="Fonts" childrenFor={sectionName} customIcon={<Icon name={Name.RIGHT_SIDEBAR_EDIT} />}>
        {[Platform.Android, Platform.iOS].map(el => (
          <CoreFontInput key={el} fontType={el} />
        ))}
      </SubWrapper>
      <SubWrapper
        title="Translations"
        childrenFor={sectionName}
        customIcon={
          <Icon name={Name.RIGHT_SIDEBAR_EDIT} onClick={() => navigate(`/projects/${id}/settings/languages`)} />
        }
      >
        <Box display="flex" gap="8px" flexWrap="wrap" marginTop="-10px !important">
          {languages.map(el => (
            <SettingField key={el} value={el} />
          ))}
        </Box>
      </SubWrapper>
      <SubWrapper
        title="Screenshots"
        childrenFor={sectionName}
        customIcon={<Icon name={Name.RIGHT_SIDEBAR_EDIT} onClick={() => setOpenScreenshots(true)} />}
      >
        <Icon name={Name.ADDITIONAL_SCREENSHOTS_PREVIEW} />
      </SubWrapper>
    </RightSidebarItem>
  )
}
