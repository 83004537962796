import { useCallback, useContext } from 'react'

import { ProjectContext } from 'context'
import { getImageByConfigs, Screen } from 'utils'
import { useVariable } from './useVariable'

export const useScreenshotFromVB = () => {
  const { state, language, theme } = useContext(ProjectContext)
  const { getVariable } = useVariable()

  const getScreenshotFromVB = useCallback(
    (screen: string | Screen) => {
      const screenConfig = typeof screen === 'string' ? state.screens.find(el => el.screenName === screen) : screen
      return screenConfig
        ? getImageByConfigs(screenConfig, state, getVariable, language, theme)
        : (new Promise(resolve => resolve('')) as Promise<string>)
    },
    [JSON.stringify(state), language, theme]
  )

  return getScreenshotFromVB
}
