import { listEmptyName, listItemName, listItemNameCopy } from 'utils'

const currentEmptyMode: { [key: string]: boolean } = {}

export const listHandler = (list: HTMLDivElement) => setEmptyMode(list, currentEmptyMode[list.id])

export const setEmptyMode = (selectedComponent: HTMLDivElement, set?: boolean) => {
  const listEmpty = selectedComponent.querySelector(`:scope > [id*="${listEmptyName}"]`) as HTMLDivElement | null
  if (listEmpty) {
    const listItems = Array.from(
      selectedComponent.querySelectorAll(`:scope > [id*="${listItemName}"], :scope > [id*="${listItemNameCopy}"]`)
    ) as HTMLDivElement[]
    if (set) {
      listItems.forEach(el => (set ? (el.hidden = true) : el.removeAttribute('hidden')))
      listEmpty.removeAttribute('hidden')
      currentEmptyMode[selectedComponent.id] = true
    } else {
      listItems.forEach(el => (set ? (el.hidden = true) : el.removeAttribute('hidden')))
      listEmpty.hidden = true
      delete currentEmptyMode[selectedComponent.id]
    }
  }
}
