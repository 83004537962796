import { memo, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as styled from './Asset.styled'

import { Icon, Name } from 'components'
import { PublishedStatus } from 'pages/CMS/components'
import { ProgressContext } from 'context'
import { CMSCollectionRecord, CMSService, assetsId } from 'services/cms'
import { Preview } from './Preview'

interface Props {
  asset: CMSCollectionRecord
  dropDown?: JSX.Element
  record?: CMSCollectionRecord
}

export const Asset: React.FC<Props> = memo(({ asset, dropDown, record: realRecord }) => {
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const [record, setRecord] = useState(realRecord)

  useEffect(() => {
    if (id && !realRecord) {
      const getRecord = () =>
        CMSService.getRecord(id, assetsId, asset.id)
          .then(res => setRecord(res.data))
          .catch(err => toast(err))
      getRecord()
      const refreshEventName = `ReferenceItem/${assetsId}/${asset.id}`
      document.addEventListener(refreshEventName, getRecord)
      return () => document.removeEventListener(refreshEventName, getRecord)
    }
  }, [asset])

  return (
    <>
      <styled.AssetHead>
        {record ? <PublishedStatus status={record.recordStatus} /> : <div />}
        {dropDown}
      </styled.AssetHead>
      <styled.AssetBody>
        <styled.AssetPreview>{record && <Preview asset={record} />}</styled.AssetPreview>
        <styled.AssetName>{record && <span>{record.title || record.resourceType}</span>}</styled.AssetName>
        <styled.AssetId>
          <styled.AssetInfo>
            <Icon name={Name.REPOSITORY_FOLDER} />
            {record && <span>{record.folder}</span>}
          </styled.AssetInfo>
        </styled.AssetId>
        <styled.AssetOptions>
          <styled.AssetInfo>
            <Icon name={Name.REPOSITORY_FILE} />
            {record && <span>.{record.format}</span>}
          </styled.AssetInfo>
        </styled.AssetOptions>
      </styled.AssetBody>
    </>
  )
})
