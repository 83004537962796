import React from 'react'
import { ISvg, Svg } from '../Svg'

const Api: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 18 18" fill="none" {...props}>
    <path d="M6 4L3 9L6 14" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" />
    <path d="M12 14L15 9L12 4" stroke="#7F899E" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)
export default Api
