import { Box } from '@mui/material'
import { memo } from 'react'

import { Button, ButtonColor, ButtonSize, Icon, Name } from 'components'
import { CoordinateConstant } from 'utils'
import { FieldProps } from '../../types'
import { CoordinateItem } from './CoordinateItem'

interface CoordinateInputProps extends FieldProps {
  value: CoordinateConstant | CoordinateConstant[] | null
  onChange: (val: CoordinateConstant | CoordinateConstant[] | null) => void
}

export const CoordinateInput = memo((props: CoordinateInputProps) => {
  const value = !props.multiple ? (props.value as CoordinateConstant | null) : null
  const arrayValue = !!props.multiple ? (props.value as CoordinateConstant[] | null) || [] : []

  const onChange = (val: CoordinateConstant | null, i: number) => {
    const copy = JSON.parse(JSON.stringify(arrayValue))
    copy[i] = val || {}
    props.onChange(copy)
  }

  const onRemove = (e: any, i: number) => {
    e.preventDefault()
    const copy = JSON.parse(JSON.stringify(arrayValue))
    copy.splice(i, 1)
    props.onChange(copy.length ? copy : null)
  }

  return (
    <>
      {props.multiple ? (
        <Box display="flex" flexDirection="column" gap="10px" width="100%">
          {arrayValue.map((el, i) => (
            <CoordinateItem key={i} value={el} onChange={val => onChange(val, i)} onRemove={e => onRemove(e, i)} />
          ))}
          <Button
            type="button"
            size={ButtonSize.DEFAULT}
            color={ButtonColor.SECONDARY}
            leftIcon={<Icon name={Name.RIGHT_SIDEBAR_ADD} />}
            onClick={() => props.onChange([...arrayValue, {}])}
          >
            Add new cooridnate
          </Button>
        </Box>
      ) : (
        <CoordinateItem value={value} onChange={props.onChange} />
      )}
    </>
  )
})
