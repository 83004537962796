import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import styled, { css } from 'styled-components'
import { ThemeType } from 'theme'

export const CustomSectionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  & > div {
    margin: 0;
  }
`

export const CustomSection = styled.div<{ table?: boolean; showActions?: boolean }>`
  position: relative;
  overflow: ${props => !props.table && 'hidden'};
  margin: 40px 0 50px;
  border: 1px solid ${props => props.theme.colors.borderColorToLightBorder};
  border-radius: 16px;
  padding: ${props => (props.showActions ? '25px 25px 107px' : props.table ? '25px 25px 0' : '25px 25px 50px')};
`

export const CustomSectionTitleWithActionWrapper = styled.div<{ mb?: string; mt?: string }>`
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb || '25px'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > h2 {
    margin-bottom: 0;
  }
`

export const CustomSectionTitle = styled.h2<{ mb?: string; mt?: string }>`
  margin-top: ${props => props.mt};
  margin-bottom: ${props => props.mb || '25px'};
  color: ${props => props.theme.colors.darkText};
  font-weight: ${props => props.theme.weights.bold};
  font-size: 18px;
  line-height: 27px;
`

const CustomTitleAction = css`
  color: ${props => props.theme.colors.primaryText};
  text-decoration: none;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  transition: ${props => props.theme.transition.primary};
  & svg {
    margin-right: 10px;
    & path {
      transition: ${props => props.theme.transition.primary};
      stroke: ${props => props.theme.colors.primaryText};
    }
  }
  &:hover {
    color: ${props => props.theme.colors.primaryTextHover};
    & svg path {
      stroke: ${props => props.theme.colors.primaryTextHover};
    }
  }
`

export const CustomSectionTitleAction = styled(Link)`
  ${CustomTitleAction}
`

export const CustomSectionTitleHashLink = styled(HashLink)`
  ${CustomTitleAction}
`

export const CustomSectionSubTitle = styled.p<{
  secondary?: boolean
  maxWidth?: string
  pb?: string
  spaceBetween?: boolean
  mt?: string
}>`
  display: ${props => props.spaceBetween && 'flex'};
  justify-content: ${props => props.spaceBetween && 'space-between'};
  align-items: ${props => props.spaceBetween && 'center'};
  margin-top: ${props => props.mt};
  max-width: ${props => props.maxWidth || '890px'};
  padding-bottom: ${props => props.pb || '10px'};
  color: ${props => (props.secondary ? props.theme.colors.grey1 : props.theme.colors.darkText)};
  font-size: 14px;
  line-height: 21px;
  & b {
    font-weight: 500;
  }
  & a {
    transition: ${props => props.theme.transition.primary};
    color: ${props => props.theme.colors.primaryText};

    &:hover {
      color: ${props => props.theme.colors.primaryTextHover};
    }
  }
`

export const CustomSectionButtons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 20px;
  & a {
    text-decoration: none;
    & > button {
      background-color: transparent;
      ${props =>
        props.theme.themeType === ThemeType.DARK &&
        `
      border: 1px solid ${props.theme.colors.darkText1};
      color: ${props.theme.colors.darkText1};
      &:hover {
        border: 1px solid ${props.theme.colors.white};
        background-color: transparent;
        color: ${props.theme.colors.darkText};
      }
    `}
      &:disabled {
        & svg > path {
          fill: ${props => props.theme.colors.disabledTextColor};
        }
      }
    }
  }
`

export const Actions = styled.div`
  left: 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  padding: 13px 25px;
  border-top: 1px solid ${props => props.theme.colors.borderColor};
  background: ${props => props.theme.colors.primaryBackground};
`
