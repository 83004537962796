import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import * as styled from 'pages/CMS/pages/CollectionSchema/components/Fields/Fields.styled'

import { getFieldIcon, Icon } from 'components'
import { ProjectContext } from 'context'
import { Collection, Config } from 'utils'
import { collections } from './constants'

interface Props {
  onClose: () => void
  setOnClickActionButton: React.Dispatch<React.SetStateAction<(() => void) | null>>
}

export const GenerateCollections: React.FC<Props> = ({ onClose, setOnClickActionButton }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { setState } = useContext(ProjectContext)
  const [selected, setSelected] = useState<Collection | null>(null)

  useEffect(() => {
    setSelected(collections[0])
  }, [])

  const addCollection = (collection: Collection) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      stateCopy.data.collections = [...stateCopy.data.collections.filter(a => a.name !== collection.name), collection]
      return stateCopy
    })
    onClose()
    navigate(`/projects/${id}/collections/${collection.name}`)
  }

  useEffect(() => {
    setOnClickActionButton(() => (selected ? () => addCollection(selected) : null))
  }, [selected])

  return selected ? (
    <Box sx={{ pointerEvents: 'none' }}>
      {selected.properties.map(el => (
        <styled.Property key={el.name}>
          <Box width="100%">
            <Icon name={getFieldIcon(el.type)} width={22} height={22} />
            <styled.PropertyName>{el.name}</styled.PropertyName>
            {el.type}
            {el.accept && ` of ${el.accept}s`}
          </Box>
          {el.isRequired && <styled.DopInfo>Required</styled.DopInfo>}
          {el.isSystem && <styled.DopInfo>system field</styled.DopInfo>}
        </styled.Property>
      ))}
    </Box>
  ) : (
    <></>
  )
}
