import React from 'react'
import { ISvg, Svg } from '../Svg'

const RunPreview: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 20 20" fill="none" {...props}>
    <path
      d="M15.9326 11.977C17.5002 11.1251 17.5002 8.87486 15.9326 8.02302L7.82427 3.61705C6.32501 2.80237 4.5 3.88773 4.5 5.59403L4.5 14.406C4.5 16.1123 6.32501 17.1976 7.82427 16.3829L15.9326 11.977Z"
      stroke="#212133"
      strokeWidth="1.5"
    />
  </Svg>
)

export default RunPreview
