import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import * as styled from './TableRow.styled'

import { Button, ButtonColor, DropDown, Icon, Name, Plan, Text, Type } from 'components'
import { ProgressContext, WorkspaceContext } from 'context'
import { SubscriptionStatus } from 'hooks'
import { WorkspacesService } from 'services/workspaces'

interface Props {
  head: boolean
  last: boolean
  title: string
  description?: string
  subtitle?: string
  status?: SubscriptionStatus
  price?: string
  hasLink?: boolean
  projectId?: string
  priceId?: string
  link?: string
}

export const TableRow: React.FC<Props> = ({
  head,
  last,
  title,
  description,
  subtitle,
  status,
  price,
  hasLink,
  projectId,
  priceId,
  link,
}) => {
  const navigate = useNavigate()
  const themeContext = useContext(ThemeContext)
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { workspace } = useContext(WorkspaceContext)

  const boxTitleColor =
    status === SubscriptionStatus.ACTIVE
      ? 'Green'
      : status === SubscriptionStatus.CANCELED
      ? 'Red'
      : status === SubscriptionStatus.UNPAID
      ? 'Yellow'
      : 'Blue'

  const getPlan = () => {
    if (workspace && priceId) {
      startLoader()
      WorkspacesService.postWorkspaceSubscription(workspace.id, priceId, window.location.href)
        .then(res => window.location.replace(res.data.url))
        .catch(err => toast(err))
        .finally(() => stopLoader())
    }
  }

  return head ? (
    <Box lineHeight="150%" padding="0 0 20px" borderBottom={`1px solid ${themeContext.colors.darkBlue_100}`}>
      <Box display="flex" gap="10px" alignItems="center">
        <Text type={Type.H2}>{title}</Text>
        {hasLink && workspace && (
          <Plan to="plans" plan={workspace.plan}>
            Workspace {workspace.plan}
          </Plan>
        )}
      </Box>
      {subtitle && (
        <Text
          type={Type.H3}
          as="p"
          fontWeight={themeContext.weights.normal}
          color={themeContext.colors.grey1}
          styles={{ marginTop: '5px' }}
        >
          {subtitle}
        </Text>
      )}
    </Box>
  ) : (
    <Box
      lineHeight="150%"
      display="flex"
      alignItems="center"
      gap="20px"
      padding="20px 0"
      borderBottom={last ? undefined : `1px solid ${themeContext.colors.darkBlue_100}`}
    >
      <Box width="100%">
        <Box display="flex" alignItems="center" columnGap="10px" marginBottom="5px">
          <Text type={Type.H3} fontWeight={themeContext.weights.semibold}>
            {title}
          </Text>
          {status && <styled.BoxTitle color={boxTitleColor}>{status}</styled.BoxTitle>}
        </Box>
        <Text type={Type.H3} as="p" fontWeight={themeContext.weights.normal} color={themeContext.colors.grey1}>
          {description}
        </Text>
        <Text type={Type.H3} as="p" fontWeight={themeContext.weights.normal} color={themeContext.colors.grey1}>
          {subtitle}
        </Text>
      </Box>
      <Box display="flex" alignItems="center" columnGap="20px">
        {!link && (
          <span style={{ fontSize: '12px', color: themeContext.colors.darkText1, whiteSpace: 'nowrap' }}>{price}</span>
        )}
        {link ? (
          <Button color={ButtonColor.SECONDARY} to={link}>
            Contact sales
          </Button>
        ) : priceId ? (
          <Button onClick={getPlan}>Get Plan</Button>
        ) : (
          <DropDown
            options={[
              {
                icon: <Icon name={Name.GLOBAL_FILE_TRANSPARENT} />,
                text: 'Upgrade',
                onClick: () => navigate(projectId ? `projects/${projectId}/plans` : 'plans'),
              },
              {
                icon: <Icon name={Name.PICKERS_DELETE} />,
                text: 'Cancel',
                onClick: () => {},
                red: true,
              },
            ]}
            top="25px"
            labelElement={
              <Box
                width="30px"
                height="30px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                bgcolor={themeContext.colors.darkBlue_50}
                borderRadius="8px"
                style={{ cursor: 'pointer' }}
              >
                <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
              </Box>
            }
          />
        )}
      </Box>
    </Box>
  )
}
