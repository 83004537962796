import styled from 'styled-components'

export const Switches = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid ${props => props.theme.colors.borderColor};
  display: flex;
  flex-direction: column;
  gap: 15px;
`

export const Hint = styled.div`
  position: absolute;
  left: calc(576px + 24px);
  bottom: 0;
  height: 41px;
  display: inline-flex;
  align-items: center;
  gap: 15px;
  & > span {
    font-size: 14px;
    line-height: 21px;
    color: ${props => props.theme.colors.darkText};
    display: flex;
    align-items: center;
    gap: 6px;
    & svg {
      width: 20px;
      height: 20px;
      & path {
        stroke: ${props => props.theme.colors.primaryText};
      }
    }
  }
  @media (max-width: 1354px) {
    margin-top: 10px;
    position: static;
  }
`
