import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'

import {
  CustomSection,
  CustomSectionSubTitle,
  CustomSectionTitle,
  CustomSectionTitleWithActionWrapper,
  InputProperty,
  Switch,
  SwitchVariant,
  inputs,
  validate,
} from 'components'
import { ValueType } from 'utils'

export const CustomCredentials: React.FC = () => {
  const [valuesBeforeEdit] = useState('')

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {},
  })

  const properties = useMemo(
    (): InputProperty[] => [
      {
        name: 'APPETIZE_API_TOKEN',
        type: ValueType.variable,
        position: 0,
        isRequired: true,
        helpText: 'Publishable key',
        smallWidth: true,
      },
    ],
    []
  )

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  useEffect(() => {
    if (showActions) {
      formik.handleSubmit()
    }
  }, [showActions])

  return (
    <CustomSection style={{ paddingBottom: '25px' }}>
      <CustomSectionTitleWithActionWrapper mb="10px">
        <CustomSectionTitle>Custom Credentials</CustomSectionTitle>
        <Switch checked onChange={() => {}} variant={SwitchVariant.BIG} />
      </CustomSectionTitleWithActionWrapper>
      <CustomSectionSubTitle>API token to gain programmatic access to Appetize.io</CustomSectionSubTitle>
      {inputs(properties, formik)}
    </CustomSection>
  )
}
