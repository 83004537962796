import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { ThemeContext } from 'styled-components'

import {
  CustomSection,
  CustomSectionActions,
  CustomSectionSubTitle,
  CustomSectionTitle,
  InputProperty,
  Switch,
  SwitchVariant,
  Text,
  Type,
  inputs,
  validate,
} from 'components'
import { ValueType } from 'utils'

const limits = [
  {
    id: 0,
    title: 'Allow Sign-in from specific domains',
    subTitle: 'Users get an email with a PIN to sign-in',
    checked: true,
  },
  {
    id: 1,
    title: 'Allow Sign-in if invited ',
    subTitle: 'Users must have entries in the user profile table',
    checked: false,
  },
]

const properties: InputProperty[] = [
  {
    name: 'domains',
    type: ValueType.array,
    accept: ValueType.string,
    position: 0,
    hideLabel: true,
  },
]

export const Limits: React.FC = () => {
  const themeContext = useContext(ThemeContext)
  const [valuesBeforeEdit] = useState('')

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (data: any) => validate(data, properties),
    onSubmit: data => {},
  })

  const cancel = () => {}

  const showActions = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)

  return (
    <CustomSection showActions={showActions} style={{ padding: '25px' }}>
      <CustomSectionTitle mb="10px">Access Limits</CustomSectionTitle>
      <CustomSectionSubTitle>
        Accept payments in your app with Stripe integration. This also enables credit card, Google Pay, and Apple Pay.
        Setup is easy and only requires a few extra steps to get started.
      </CustomSectionSubTitle>
      <CustomSection style={{ padding: 0, margin: '20px 0 0 0' }}>
        {limits.map((el, i) => (
          <Box
            key={el.id}
            padding="20px 24px"
            borderTop={i !== 0 ? `1px solid ${themeContext.colors.darkBlue_100}` : ''}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <Text type={Type.H3}>{el.title}</Text>
                <Text type={Type.BODY} as="p" color={themeContext.colors.grey1}>
                  {el.subTitle}
                </Text>
              </Box>
              <Switch checked={el.checked} onChange={() => {}} variant={SwitchVariant.BIG} />
            </Box>
            {el.checked && <Box mt="-15px">{inputs(properties, formik)}</Box>}
          </Box>
        ))}
      </CustomSection>
      {showActions && <CustomSectionActions cancel={cancel} onClick={formik.handleSubmit} />}
    </CustomSection>
  )
}
