import { Box } from '@mui/material'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { Menu } from 'components'
import { CMSContext, ProgressContext, ProjectContext } from 'context'
import { clearVariables } from 'hooks'
import { TopPanelCMS } from 'partials'
import {
  CMSCollection,
  assetsId,
  collectionIdToFind,
  extractPath,
  getCollectionName,
  rootFolderName,
} from 'services/cms'
import { firestore } from 'services/firebase'
import { CollectionsItems, FoldersItems } from './components'
import { CollectionRecords, CollectionSchema } from './pages'

export const CMS: React.FC = () => {
  const themeContext = useContext(ThemeContext)
  const { toast } = useContext(ProgressContext)
  const {
    state: {
      // to check
      data: { dataObjects = [], collections },
    },
  } = useContext(ProjectContext)
  const navigate = useNavigate()
  const { id } = useParams()
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const folderId = searchParams.get('folder')

  const [isSchemaPage, setIsSchemaPage] = useState(false)
  const [folders, setFolders] = useState<CMSCollection[]>([])

  const { collectionId, recordId } = useMemo(() => extractPath(pathname.split('/').slice(4).join('/')), [pathname])

  const { allCollections, rootCollections, assets } = useMemo(() => {
    const cmsCollections = collections.map(el => ({ id: el.name, ...el }))
    return {
      allCollections: cmsCollections,
      rootCollections: cmsCollections.filter(el => el.id !== assetsId && el.id.split('/').length === 1),
      assets: cmsCollections.find(el => el.id === assetsId) || null,
    }
  }, [collections])

  const { currentCollection, currentFolder } = useMemo(() => {
    const folder = folders.find(el => el.id === folderId)
    return folder && assets
      ? {
          currentCollection: { ...assets, name: getCollectionName(folder.name) },
          currentFolder: folder,
        }
      : !folderId
      ? {
          currentCollection: allCollections.find(el => collectionIdToFind(el.id) === collectionIdToFind(collectionId)),
        }
      : {}
  }, [folderId, folders, assets, allCollections, collectionId])

  useEffect(() => {
    document.title = 'Collections | CodePlatform'
    const unsubscribe = firestore.collection(`projects/${id}/folders`).onSnapshot({
      next: res => {
        const foldersRes = res.docs.map(el => ({ id: el.id, ...el.data() })) as CMSCollection[]
        setFolders([{ id: rootFolderName, name: rootFolderName, isSystem: true } as CMSCollection, ...foldersRes])
      },
      error: err => toast(err),
    })
    return () => {
      unsubscribe()
      clearVariables()
    }
  }, [])

  useEffect(() => {
    if (!collectionId && rootCollections.length) {
      navigate(`/projects/${id}/collections/${rootCollections[0].id}`, { replace: true })
    }
  }, [collectionId, rootCollections])

  return (
    <>
      <TopPanelCMS
        isSchemaPage={isSchemaPage}
        setIsSchemaPage={setIsSchemaPage}
        collectionId={collectionId}
        recordId={recordId}
        currentCollection={currentCollection}
        currentFolder={currentFolder}
      />
      <Box display="flex" justifyContent="space-between" bgcolor={themeContext.colors.secondaryBackground}>
        <Menu
          firstChild={
            <CollectionsItems
              allCollections={allCollections}
              currentCollection={currentCollection}
              setIsSchemaPage={setIsSchemaPage}
            />
          }
          secondChild={
            <FoldersItems folders={folders} currentFolder={currentFolder} setIsSchemaPage={setIsSchemaPage} />
          }
        />
        {currentCollection && (
          <CMSContext.Provider
            key={currentCollection.id + currentFolder?.id}
            value={{ dataObjects, allCollections, rootCollections, assets, folders }}
          >
            {isSchemaPage ? (
              <CollectionSchema
                collectionId={collectionId}
                currentCollection={currentCollection}
                setIsSchemaPage={setIsSchemaPage}
              />
            ) : (
              <CollectionRecords
                collectionId={collectionId}
                recordId={recordId}
                currentCollection={currentCollection}
                currentFolder={currentFolder}
              />
            )}
          </CMSContext.Provider>
        )}
      </Box>
    </>
  )
}
