import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import { Loader, LoaderVariant } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { clearVariables, useHistoryState, useProject } from 'hooks'
import { TopPanel } from 'partials'
import { firestore } from 'services/firebase'
import {
  getLanguageFromStorage,
  getLanguagesController,
  getThemeFromStorage,
  setLanguagesController,
  themeKey,
} from 'utils'
import { MobileMode } from './components'

interface Props {
  isMobile: boolean
}

export const FetchProjectWrapper: React.FC<Props> = ({ isMobile }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const themeContext = useContext(ThemeContext)
  const { id } = useParams()
  const { project, projectWait } = useProject(id as string)
  const [state, setState, undo, redo] = useHistoryState({})
  const [isSnapshot, setIsSnapshot] = useState(false)
  const [configWait, setConfigWait] = useState(true)
  const configRef = firestore.collection(`projects/${id}/configurations`).doc('main')
  const [language, setLanguage] = useState(getLanguageFromStorage(id as string))
  const [theme, setTheme] = useState(getThemeFromStorage())

  useEffect(() => {
    if (state.localization) {
      const { languages, primaryLanguage } = state.localization
      if (!languages.includes(language)) {
        setLanguage(primaryLanguage)
      }
    }
  }, [state.localization])

  useEffect(() => {
    const controller = getLanguagesController()
    controller[id as string] = language
    setLanguagesController(controller)
  }, [language])

  useEffect(() => {
    localStorage.setItem(themeKey, theme)
  }, [theme])

  useEffect(() => {
    startLoader()
    const unsubscribe = configRef.onSnapshot({
      next: res => {
        stopLoader()
        const resData = res.data()
        if (resData) {
          setIsSnapshot(true)
          setState(JSON.parse(resData.data))
          setTimeout(() => setIsSnapshot(false), 0)
          setConfigWait(false)
        } else {
          toast('Main config not found!')
        }
      },
      error: err => {
        stopLoader()
        toast(err)
      },
    })
    return () => {
      unsubscribe()
      clearVariables()
    }
  }, [])

  useEffect(() => {
    if (!isSnapshot && !configWait) {
      const timer = setTimeout(() => {
        startLoader()
        configRef
          .update({ data: JSON.stringify(state) })
          .catch(err => toast(err))
          .finally(() => stopLoader())
      }, 0)
      return () => clearTimeout(timer)
    }
  }, [JSON.stringify(state)])

  return projectWait || configWait ? (
    <>
      <TopPanel empty />
      <Box height="100%" bgcolor={themeContext.colors.secondaryBackground}>
        <Loader variant={LoaderVariant.PACMAN} />
      </Box>
    </>
  ) : (
    <ProjectContext.Provider value={{ project, state, setState, undo, redo, language, setLanguage, theme, setTheme }}>
      <TopPanel />
      {isMobile ? <MobileMode /> : <Outlet />}
    </ProjectContext.Provider>
  )
}
