import { Box } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'

import * as styled from './IconContainer.styled'

import { Image, Text, Type } from 'components'
import { DeviceSettingsContext, ProjectContext, selectedDefaultState } from 'context'
import { useVariable } from 'hooks'
import { getColor, Screen } from 'utils'

export const IconContainer: React.FC = () => {
  const {
    selected: { appIcon },
    setSelected,
  } = useContext(DeviceSettingsContext)
  const {
    state: {
      data: { collections, globalVariables },
      branding: {
        colorStyles,
        appName,
        icons: { iosIcon },
      },
      resources,
      screens,
    },
    language,
    theme,
  } = useContext(ProjectContext)
  const themeContext = useContext(ThemeContext)
  const { getVariable } = useVariable()
  const screenConfig = screens.find(el => el.screenName === 'Launch Screen') as Screen
  const { backgroundColor } = screenConfig
  const [color, setColor] = useState('')

  const findResourceUrlById = (resourceId: string) => resources.find(el => el.id === resourceId)?.url || ''

  useEffect(() => {
    getColor(backgroundColor, colorStyles, theme, getVariable(screenConfig)).then(setColor)
  }, [
    JSON.stringify(screenConfig),
    JSON.stringify(colorStyles),
    JSON.stringify(resources),
    JSON.stringify(collections),
    JSON.stringify(globalVariables),
    JSON.stringify(appName),
    iosIcon,
    theme,
    language,
    JSON.stringify(backgroundColor),
  ])

  return (
    <Box ml="60px">
      <Text
        type={Type.BODY}
        fontWeight={themeContext.weights.medium}
        color={themeContext.colors.grey1}
        mb="10px"
        fontSize="14px"
      >
        App icon
      </Text>
      <styled.Container active={appIcon}>
        <styled.IconContainer
          onClick={e => {
            e.stopPropagation()
            setSelected({ ...selectedDefaultState, appIcon: true })
          }}
          color={color}
        >
          <Box display="flex" justifyContent="center" alignItems="center" height="120px" width="120px">
            <Image src={findResourceUrlById(iosIcon)} />
          </Box>
        </styled.IconContainer>
      </styled.Container>
    </Box>
  )
}
