import React from 'react'
import { ISvg, Svg } from '../Svg'

const Twilo: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <circle cx="12" cy="12" r="8" stroke="#CF272D" strokeWidth="2" />
    <circle cx="9.5" cy="9.5" r="1.5" fill="#CF272D" />
    <circle cx="14.5" cy="9.5" r="1.5" fill="#CF272D" />
    <circle cx="9.5" cy="14.5" r="1.5" fill="#CF272D" />
    <circle cx="14.5" cy="14.5" r="1.5" fill="#CF272D" />
  </Svg>
)

export default Twilo
