import React from 'react'

import { CustomSectionsWrapper, Text, Type } from 'components'
import { SettingsLayout } from '../components'
import { AppIcon, LaunchScreen, PrimaryColor } from './sections'

export const Design: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Design</Text>
    <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
      The product page for this app version will be published on the App Store, Google Play, and App Landing page with
      the assets and metadata below.
    </Text>
    <CustomSectionsWrapper>
      <AppIcon />
      <PrimaryColor />
      <LaunchScreen />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
