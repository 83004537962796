import { TopPanelContext } from 'context'
import { ReactElement, useState } from 'react'

interface Props {
  children: React.ReactNode
  isMobile: boolean
}

export const TopPanelProvider: React.FC<Props> = ({ children, isMobile }) => {
  const [backButton, setBackButton] = useState<ReactElement | null>(null)
  const [leftButtons, setLeftButtons] = useState<ReactElement | null>(null)
  const [pageLeftButtons, setPageLeftButtons] = useState<ReactElement | null>(null)
  const [pageCenterButtons, setPageCenterButtons] = useState<ReactElement | null>(null)
  const [rightButtons, setRightButtons] = useState<ReactElement | null>(null)
  const [page, setPage] = useState<ReactElement | null>(null)

  return (
    <TopPanelContext.Provider
      value={{
        backButton,
        setBackButton,
        leftButtons,
        setLeftButtons,
        pageLeftButtons,
        setPageLeftButtons,
        pageCenterButtons,
        setPageCenterButtons,
        rightButtons,
        setRightButtons,
        page,
        setPage,
        isMobile,
      }}
    >
      {children}
    </TopPanelContext.Provider>
  )
}
