import { Box } from '@mui/material'
import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  Crumbs,
  CustomSectionSubTitle,
  CustomSectionTitle,
  CustomSectionTitleAction,
  CustomSectionTitleWithActionWrapper,
  Icon,
  InputProperty,
  Name,
  ProductsTable,
  RightForm,
  inputs,
  validate,
} from 'components'
import { ProgressContext } from 'context'
import { CMSCollectionRecord } from 'services/cms'
import { firestore } from 'services/firebase'
import { ValueType } from 'utils'
import { TermsTable } from './components'

export const properties: InputProperty[] = [
  {
    name: 'name',
    type: ValueType.string,
    position: 0,
    isRequired: true,
    helpText: "The name of your app as it will appear on the app stores. This can't be longer than 30 characters.",
    ai: true,
  },
  {
    name: 'language',
    type: ValueType.string,
    position: 1,
    isRequired: true,
    title: 'Primary Language',
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
    optionsToSelect: [{ value: 'English (US)', label: 'English (US)' }],
  },
  {
    name: 'primaryCategory',
    type: ValueType.string,
    position: 2,
    isRequired: true,
    title: 'Primary category',
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
    optionsToSelect: [{ value: 'Photo & Video', label: 'Photo & Video' }],
  },
  {
    name: 'secondaryCategory',
    type: ValueType.string,
    position: 3,
    isRequired: true,
    title: 'Secondary category',
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
    optionsToSelect: [{ value: 'Entertainment', label: 'Entertainment' }],
  },
  {
    name: 'screenshots',
    type: ValueType.screenshots,
    position: 4,
    title: 'App Screenshots',
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
  },
  {
    name: 'text',
    type: ValueType.string,
    position: 5,
    isRequired: true,
    title: 'Promotional Text',
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
    ai: true,
  },
  {
    name: 'description',
    type: ValueType.string,
    position: 6,
    isRequired: true,
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
    ai: true,
  },
  {
    name: 'keywords',
    type: ValueType.string,
    position: 7,
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
    ai: true,
  },
  {
    name: 'copyright',
    type: ValueType.string,
    position: 8,
    isRequired: true,
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
  },
  {
    name: 'support_url',
    type: ValueType.url,
    position: 9,
    isRequired: true,
    title: 'Support URL',
    editByModal: true,
  },
  {
    name: 'privacy_policy_url',
    type: ValueType.url,
    position: 10,
    isRequired: true,
    title: 'Privacy Policy URL',
    editByModal: true,
  },
  {
    name: 'marketing_url',
    type: ValueType.url,
    position: 11,
    isRequired: true,
    title: 'Marketing URL',
    editByModal: true,
  },
]

export interface StoremanagerConfig {
  name: string
  language: string
  primaryCategory: string
  secondaryCategory: string
  screenshots: string[]
  text: string
  description: string
  keywords: string
  copyright: string
  support_url: string
  privacy_policy_url: string
  marketing_url: string
  terms_policies: string
}

interface Props {
  close: () => void
}

export const StoreManager: React.FC<Props> = ({ close }) => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [wait, setWait] = useState(true)
  const [isSnapshot, setIsSnapshot] = useState(false)
  const ref = firestore.collection(`projects/${id}/configurations`).doc('storemanager')

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (config: any) => validate(config, properties),
    onSubmit: config => {
      startLoader()
      ref
        .update({ data: JSON.stringify(config) })
        .catch(err => toast(err))
        .finally(() => stopLoader())
    },
  })

  useEffect(() => {
    startLoader()
    const unsubscribe = ref.onSnapshot({
      next: res => {
        const resData = res.data()
        if (resData) {
          const data = JSON.parse(resData.data)
          const config: CMSCollectionRecord = {}
          properties.forEach(property => {
            config[property.name] = data[property.name] || null
          })
          setIsSnapshot(true)
          formik.setValues({ ...config })
          setTimeout(() => setIsSnapshot(false), 0)
          stopLoader()
          setWait(false)
        }
      },
      error: err => {
        toast(err)
        stopLoader()
      },
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (!isSnapshot && !wait) {
      const timer = setTimeout(() => {
        formik.handleSubmit()
      }, 0)
      return () => clearTimeout(timer)
    }
  }, [JSON.stringify(formik.values)])

  return !wait ? (
    <RightForm
      showConfirmWhen={false}
      onClose={close}
      title={
        <>
          <Crumbs
            firstCrumbTitle="Publish to App Store & Google Play"
            onFirstCrumbClick={close}
            icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
            small
          />
          <Button onClick={() => {}}>Send to review</Button>
        </>
      }
      form={
        wait ? (
          <></>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <CustomSectionTitle mt="20px" mb="5px">
              Binaries
            </CustomSectionTitle>
            <CustomSectionSubTitle pb="0px">
              Lorem Ipsum is simply dummy text of the printing and typesetting industry.
            </CustomSectionSubTitle>
            <CustomSectionTitle mt="40px" mb="5px">
              App information
            </CustomSectionTitle>
            <CustomSectionSubTitle pb="0px">
              The product page for this app version will be published on the App Store, Google Play, and App Landing
              page with the assets and metadata below.
            </CustomSectionSubTitle>
            {inputs(properties.slice(0, 9), formik)}
            <CustomSectionTitle mt="40px" mb="5px">
              App privacy & support
            </CustomSectionTitle>
            <CustomSectionSubTitle pb="0px">
              The product page for this app version will be published on the App Store, Google Play, and App Landing
              page with the assets and metadata below.{' '}
              <a href="/#" style={{ textDecoration: 'none' }}>
                Manage on CodePlatform
              </a>
            </CustomSectionSubTitle>
            <CustomSectionSubTitle spaceBetween mt="30px">
              <b>Terms & Policies</b>
              <CustomSectionTitleAction to={`/projects/${id}/collections/terms`} onClick={close}>
                Edit
              </CustomSectionTitleAction>
            </CustomSectionSubTitle>
            <Box mt="-5px">
              <CustomSectionSubTitle secondary pb="0px">
                If localized app information isn’t available in an app stores country or region, the information from
                your primary language will be used instead.
              </CustomSectionSubTitle>
              <TermsTable />
            </Box>
            {inputs(properties.slice(9), formik)}
            <CustomSectionTitleWithActionWrapper mt="40px" mb="5px">
              <CustomSectionTitle>Products</CustomSectionTitle>
              <CustomSectionTitleAction to={`/projects/${id}/monetization`} onClick={close}>
                Edit
              </CustomSectionTitleAction>
            </CustomSectionTitleWithActionWrapper>
            <CustomSectionSubTitle pb="0px">
              The product page for this app version will be published on the App Store, Google Play, and App Landing
              page with the assets and metadata below.
            </CustomSectionSubTitle>
            <ProductsTable fromStoreManager />
          </form>
        )
      }
    />
  ) : (
    <></>
  )
}
