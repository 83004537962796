import React, { useContext, useEffect, useState } from 'react'

import { ProjectContext } from 'context'
import { useVariable } from 'hooks'
import {
  ComponentAlignment,
  ComponentType,
  Config,
  ImageLayout,
  InnerLaunchScreenIcon,
  Resource,
  Screen,
  ScreenComponent,
  detectEmptyValue,
  generateFirestoreId,
  getAllLocalVariables,
  getMediaResource,
} from 'utils'
import { ImageInput } from './ImageInput'

const brandIconComponent: ScreenComponent = {
  componentType: ComponentType.image,
  componentAlignment: ComponentAlignment.bottom,
  id: generateFirestoreId(),
  imageLayout: ImageLayout.center,
  height: { constant: '24' },
  name: 'image.brandIcon',
}

interface Props {
  big?: boolean
  label?: string
  subLabel?: string
  iconName: InnerLaunchScreenIcon
}

export const LaunchScreenIconInput: React.FC<Props> = ({ big, label, subLabel, iconName }) => {
  const {
    state: {
      data: { collections, globalVariables },
      branding: {
        colorStyles,
        appName,
        icons: { iosIcon },
      },
      resources,
      screens,
    },
    setState,
    language,
    theme,
  } = useContext(ProjectContext)
  const screenConfig = screens.find(el => el.screenName === 'Launch Screen') as Screen
  const subComponents = screenConfig.components[0].subComponents
  const component = (
    subComponents ? (iconName === InnerLaunchScreenIcon.splashIcon ? subComponents[0] : subComponents[1] || {}) : {}
  ) as ScreenComponent
  const { id, image, imageColor } = component
  const { getVariable } = useVariable()
  const [{ name: imageName, url: imageUrl }, setImageResource] = useState({ name: '', url: '' })
  const allLocalVariables = getAllLocalVariables(screenConfig, component)

  useEffect(() => {
    getMediaResource(resources, getVariable(screenConfig), true, 0, image, imageColor, theme, colorStyles).then(
      setImageResource
    )
  }, [
    JSON.stringify(screenConfig),
    JSON.stringify(colorStyles),
    JSON.stringify(resources),
    JSON.stringify(collections),
    JSON.stringify(globalVariables),
    JSON.stringify(appName),
    iosIcon,
    theme,
    language,
    JSON.stringify(image),
    JSON.stringify(imageColor),
  ])

  const onComponentPropertiesChange = (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => {
    setState(state => {
      const stateCopy = JSON.parse(JSON.stringify(state)) as Config
      const subComponents = stateCopy.screens.find(el => el.screenName === 'Launch Screen')?.components[0].subComponents
      if (subComponents) {
        const component =
          iconName === InnerLaunchScreenIcon.splashIcon ? subComponents[0] : subComponents[1] || brandIconComponent
        props.forEach(({ propName, value, resources }) => {
          if (detectEmptyValue(value)) {
            delete component[propName]
          } else {
            // @ts-ignore
            component[propName] = value
          }
          if (resources) {
            stateCopy.resources = resources
          }
          if (!id) {
            subComponents.push(component)
          } else if (!component[propName] && iconName === InnerLaunchScreenIcon.brandLogo) {
            subComponents.splice(1)
          }
        })
      }
      return stateCopy
    })
  }

  return (
    <ImageInput
      big={big}
      label={label}
      subLabel={subLabel}
      value={imageName || imageUrl}
      url={imageUrl}
      onChange={(value, resources) =>
        onComponentPropertiesChange([{ propName: 'image', value: { constant: { resourceId: value } }, resources }])
      }
      source={image?.source}
      onSourceChange={value => onComponentPropertiesChange([{ propName: 'image', value: { source: value } }])}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
      withoutDelete={iconName === InnerLaunchScreenIcon.splashIcon}
    />
  )
}
