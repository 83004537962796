import { Box } from '@mui/material'
import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

import * as styled from './DropDown.styled'

import { Icon, Name, Rotation } from 'components'
import { useOnClickOutside } from 'hooks'

export interface Option {
  text: string
  icon?: ReactElement
  to?: string
  divider?: boolean
  onClick?: () => void
  isProjectName?: boolean
  color?: string
  style?: React.CSSProperties
  red?: boolean
}

interface IDropDown {
  children?: React.ReactNode
  options?: Option[]
  outerOpenState?: boolean
  labelElement?: ReactElement
  defaultLabel?: string
  width?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
  onClick?: any
  topChildren?: boolean
  lightBorder?: boolean
  style?: React.CSSProperties
  wrapperStyle?: React.CSSProperties
  checkIfOpen?: (val: boolean) => void
  scrollIntoView?: boolean
}

export const DropDown: React.FC<IDropDown> = ({
  children,
  outerOpenState,
  labelElement,
  defaultLabel,
  options,
  onClick,
  width,
  top,
  right,
  bottom,
  left,
  topChildren = false,
  lightBorder,
  style,
  wrapperStyle,
  checkIfOpen,
  scrollIntoView,
}) => {
  const themeContext = useContext(ThemeContext)
  const [open, setOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOnClickOutside(ref, () => setOpen(false))

  useEffect(() => {
    checkIfOpen?.(open)
    if (open && scrollIntoView) {
      ref.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [open])

  useEffect(() => {
    if (outerOpenState !== undefined) {
      setOpen(outerOpenState)
    }
  }, [outerOpenState])

  const hasOnClick = outerOpenState === undefined

  const handleOptionClick = (val: string, onOptionClick?: any) => {
    if (onClick) {
      onClick(val)
    }
    if (onOptionClick) {
      onOptionClick()
    }
    setOpen(false)
  }

  return (
    <styled.Wrapper ref={ref} style={wrapperStyle}>
      {labelElement && <Box onClick={hasOnClick ? () => setOpen(open => !open) : undefined}>{labelElement}</Box>}
      {defaultLabel && (
        <styled.Label onClick={hasOnClick ? () => setOpen(open => !open) : undefined}>
          <span>{defaultLabel}</span>
          <Icon rotation={open ? Rotation.LEFT : undefined} name={Name.REPOSITORY_DROPDOWN_ARROW} />
        </styled.Label>
      )}
      {open && (
        <styled.OptionsWrapper
          width={width}
          top={top || '40px'}
          right={right || '0'}
          bottom={bottom}
          left={left}
          style={style}
          lightBorder={lightBorder}
        >
          {topChildren && children}
          {options?.map((option, index) => {
            if (option.red) {
              option.color = themeContext.colors.logOut
            }
            return (
              <React.Fragment key={`${option.text}-${index}`}>
                <styled.Option
                  onClick={() => handleOptionClick(option.text, option.onClick)}
                  hasOwnDropDown={false}
                  isProjectName={option.isProjectName}
                  isLink={!!option.to}
                  style={option.style}
                  color={option.color}
                >
                  {option.to ? (
                    <Link to={option.to}>
                      {option.icon}
                      {option.text}
                    </Link>
                  ) : (
                    <div>
                      {option.icon}
                      <span>{option.text}</span>
                    </div>
                  )}
                </styled.Option>
                {option.divider && <styled.Divider optionsDivider lower width={width} />}
              </React.Fragment>
            )
          })}
          {!topChildren && children}
        </styled.OptionsWrapper>
      )}
    </styled.Wrapper>
  )
}
