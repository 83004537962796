import { useEffect, useState } from 'react'

export const pickerId = 'picker'
export const scrollContainerId = 'scrollContainer'
export const rightSidebarId = 'rightSidebar'
export const hoverHintLabelId = 'hoverHintLabel'
export const selectedHintLabelId = 'selectedHintLabel'

export const useOptionsContainerPosition = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
  open: boolean,
  insidePicker?: boolean,
  openStylePickerFromScreen?: boolean
) => {
  const [top, setTop] = useState<number>()
  const [width, setWidth] = useState<number>()
  const [left, setLeft] = useState<number>()

  const setOptionsContainerPosition = (openStylePickerFromScreen?: boolean) => {
    const fieldWrapper = ref.current
    if (fieldWrapper) {
      const { bottom, width, height } = fieldWrapper.getBoundingClientRect()
      if (openStylePickerFromScreen) {
        const selectedHintLabel = document.getElementById(selectedHintLabelId)
        if (selectedHintLabel) {
          const { bottom, right } = selectedHintLabel.getBoundingClientRect()
          setTop(bottom)
          setLeft(right)
        }
      } else if (insidePicker) {
        setTop(height)
        setLeft(undefined)
      } else {
        setTop(bottom)
        setLeft(undefined)
      }
      setWidth(width)
    }
  }

  const scrollToPicker = (scrollArea: HTMLElement | null) => {
    setTimeout(() => {
      const picker = document.getElementById(pickerId)
      if (scrollArea && picker) {
        const scrollAreaBottom = scrollArea.getBoundingClientRect().bottom
        const pickerBottom = picker.getBoundingClientRect().bottom
        if (pickerBottom > scrollAreaBottom) {
          scrollArea.style.scrollBehavior = 'smooth'
          scrollArea.scrollTop = scrollArea.scrollTop + pickerBottom - scrollAreaBottom
          scrollArea.style.removeProperty('scroll-behavior')
        }
      }
    })
  }

  useEffect(() => {
    if (open) {
      setOptionsContainerPosition()
      if (!insidePicker) {
        const rightSidebar = document.getElementById(rightSidebarId)
        scrollToPicker(rightSidebar)
        const event = () => setOptionsContainerPosition()
        rightSidebar?.addEventListener('scroll', event)
        return () => rightSidebar?.removeEventListener('scroll', event)
      }
    }
  }, [open])

  useEffect(() => {
    if (openStylePickerFromScreen) {
      setOptionsContainerPosition(true)
      const scrollContainer = document.getElementById(scrollContainerId)
      scrollToPicker(scrollContainer)
      const event = () => setOptionsContainerPosition(true)
      scrollContainer?.addEventListener('scroll', event)
      return () => scrollContainer?.removeEventListener('scroll', event)
    }
  }, [openStylePickerFromScreen])

  useEffect(() => {
    if (!open && !openStylePickerFromScreen) {
      setTop(undefined)
      setWidth(undefined)
      setLeft(undefined)
    }
  }, [open, openStylePickerFromScreen])

  return [top, width, left]
}
