import React from 'react'
import { Link } from 'react-router-dom'

import { Button, ButtonColor, ButtonVariant, Icon, Name } from 'components'

export const connectGooglePlayFlag = 'connect-googleplay'

export const ConnectGooglePlayButton: React.FC<{ disabled?: boolean }> = ({ disabled }) => (
  <Link
    to={`?${connectGooglePlayFlag}=true`}
    style={{
      textDecoration: 'none',
      pointerEvents: disabled ? 'none' : 'all',
    }}
  >
    <Button
      variant={ButtonVariant.LINK}
      color={ButtonColor.SECONDARY}
      leftIcon={<Icon name={Name.SIZE_24_PLAY_STORE} />}
      disabled={disabled}
    >
      Link to Google Play Connect
    </Button>
  </Link>
)
