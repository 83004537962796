import React from 'react'
import { ISvg, Svg } from '../Svg'

const Android: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 18C2 12.4772 6.47715 8 12 8C17.5228 8 22 12.4772 22 18H2ZM10 13.5C10 14.3284 9.32843 15 8.5 15C7.67157 15 7 14.3284 7 13.5C7 12.6716 7.67157 12 8.5 12C9.32843 12 10 12.6716 10 13.5ZM15.5 15C16.3284 15 17 14.3284 17 13.5C17 12.6716 16.3284 12 15.5 12C14.6716 12 14 12.6716 14 13.5C14 14.3284 14.6716 15 15.5 15Z"
      fill={props.stroke ? props.stroke : '#A4C639'}
    />
    <path
      d="M3.11022 7.00035L5.84312 11.9533"
      stroke={props.stroke ? props.stroke : '#A4C639'}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M20.8429 7.00035L18.11 11.9533"
      stroke={props.stroke ? props.stroke : '#A4C639'}
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </Svg>
)

export default Android
