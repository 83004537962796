import React, { memo, useEffect, useMemo } from 'react'

import { CommonPicker, Menu, ScreenPicker } from 'components'
import { RightSidebarItem, SettingField, SettingTextField, SubWrapper } from 'partials/RightSidebar/components'
import { Id } from 'partials/RightSidebar/settings/common/Id'
import { CMSCollectionProperty } from 'services/cms'
import { CollectionPermissions, CollectionRule, firstLetterUpperCase } from 'utils'

const rulesValues = Object.values([CollectionRule.Anyone, CollectionRule.Owner, CollectionRule['No-one']]).map(el => ({
  value: el,
  label: el,
}))

interface Props {
  collectionId: string
  helpText?: string
  setHelpText: React.Dispatch<React.SetStateAction<string | undefined>>
  titleField?: string
  setTitleField: React.Dispatch<React.SetStateAction<string | undefined>>
  previewScreen?: string
  setPreviewScreen: React.Dispatch<React.SetStateAction<string | undefined>>
  permissions?: CollectionPermissions
  setPermissions: React.Dispatch<React.SetStateAction<CollectionPermissions | undefined>>
  propertiesForTitleField: CMSCollectionProperty[]
}

export const About: React.FC<Props> = memo(
  ({
    collectionId,
    helpText,
    setHelpText,
    titleField,
    setTitleField,
    previewScreen,
    setPreviewScreen,
    permissions,
    setPermissions,
    propertiesForTitleField,
  }) => {
    const fieldsValues = useMemo(
      () => propertiesForTitleField.map(el => ({ value: el.name, label: el.name })),
      [propertiesForTitleField]
    )

    useEffect(() => {
      if (titleField && !propertiesForTitleField.find(el => el.name === titleField)) {
        setTitleField('')
      }
    }, [titleField, propertiesForTitleField])

    const sectionName = 'Schema'

    return (
      <Menu
        right
        firstChild={
          <RightSidebarItem title={sectionName}>
            <SubWrapper childrenFor={sectionName}>
              <Id value={collectionId} />
              <SettingTextField label="Help text" value={helpText} onChange={setHelpText} />
            </SubWrapper>
            <SubWrapper childrenFor={sectionName} title="Options">
              <SettingField
                subLabel="Title field"
                value={titleField}
                optionsContainer={
                  <CommonPicker
                    title="Fields"
                    value={titleField}
                    onChange={setTitleField}
                    values={fieldsValues}
                    close={() => {}}
                  />
                }
              />
              <SettingField
                subLabel="Preview screen"
                value={previewScreen}
                optionsContainer={
                  <ScreenPicker
                    onChange={(image, name) => setPreviewScreen(name)}
                    value={previewScreen}
                    close={() => {}}
                    noUpload
                  />
                }
              />
            </SubWrapper>
            <SubWrapper childrenFor={sectionName} title="Permissions">
              {(['list', 'get', 'create', 'update', 'delete'] as (keyof CollectionPermissions)[]).map(el => (
                <SettingField
                  key={el}
                  subLabel={firstLetterUpperCase(el)}
                  value={permissions?.[el]}
                  disabled={permissions?.[el] === CollectionRule.Custom}
                  optionsContainer={
                    <CommonPicker
                      title="Rules"
                      value={permissions?.[el]}
                      onChange={value => setPermissions(permissions => ({ ...permissions, [el]: value }))}
                      values={rulesValues}
                      close={() => {}}
                    />
                  }
                />
              ))}
            </SubWrapper>
          </RightSidebarItem>
        }
      />
    )
  }
)
