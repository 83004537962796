import React, { useContext, useEffect, useState } from 'react'

import {
  BrandingColorInput,
  BrandingFontInput,
  ComponentPreview,
  ComponentStylePicker,
  ElementWithLocalizedContent,
  ImageInput,
} from 'components'
import { ProjectContext } from 'context'
import { useVariable } from 'hooks'
import {
  RightSidebarItem,
  SettingField,
  SettingImageAlignment,
  SettingTextAlignment,
  SettingTextField,
  SubWrapper,
} from 'partials/RightSidebar/components'
import {
  LocalVariable,
  Resource,
  Screen,
  ScreenComponent,
  getAppTextAlignment,
  getCSSTextAlignment,
  getMediaResource,
  getTextareaValue,
} from 'utils'
import { Common, Id } from '../common'

interface Props {
  component: ScreenComponent
  onComponentPropertiesChange: (
    props: { propName: keyof ScreenComponent; value: any; resources?: Resource[] }[]
  ) => void
  onComponentStyleChange: (config: ScreenComponent) => void
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
  listId?: string
}

export const ButtonSetting: React.FC<Props> = ({
  component,
  onComponentPropertiesChange,
  onComponentStyleChange,
  screenConfig,
  allLocalVariables,
  listId,
}) => {
  const {
    state: {
      data: { collections, globalVariables },
      branding: {
        colorStyles,
        appName,
        icons: { iosIcon },
      },
      resources,
    },
    theme,
    language,
  } = useContext(ProjectContext)
  const { getVariable } = useVariable()
  const { id, componentType, name, text, font, textAlignment, textColor, image, imageColor, imageLocation } = component

  const { key, value } = getTextareaValue(language, text)
  const [{ name: imageName, url: imageUrl }, setImageResource] = useState({ name: '', url: '' })

  useEffect(() => {
    getMediaResource(
      resources,
      getVariable(screenConfig, listId, 0),
      true,
      0,
      image,
      imageColor,
      theme,
      colorStyles
    ).then(setImageResource)
  }, [
    JSON.stringify(screenConfig),
    JSON.stringify(colorStyles),
    JSON.stringify(resources),
    JSON.stringify(collections),
    JSON.stringify(globalVariables),
    JSON.stringify(appName),
    iosIcon,
    theme,
    language,
    JSON.stringify(image),
    JSON.stringify(imageColor),
    listId,
  ])

  return (
    <RightSidebarItem title={componentType} hasCloseIcon>
      <SubWrapper childrenFor={componentType}>
        <Id value={name || componentType} />
        <SettingTextField
          key={language}
          label="Text"
          value={value}
          values={text?.values || []}
          limit={30}
          onChange={(value, values) =>
            onComponentPropertiesChange([
              { propName: 'text', value: { key, locales: { ...text?.locales, [language]: value }, values } },
            ])
          }
          translation={
            text ? (
              <ElementWithLocalizedContent
                title={`${name || componentType}`}
                value={text}
                onChange={value => onComponentPropertiesChange([{ propName: 'text', value }])}
              />
            ) : undefined
          }
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingField
          label="Style"
          leftIcon={<ComponentPreview screenConfig={screenConfig} componentConfig={component} listId={listId} />}
          canOpenStylePickerFromScreen
          optionsContainer={
            <ComponentStylePicker
              componentId={id}
              screenConfig={screenConfig}
              onChange={onComponentStyleChange}
              close={() => {}}
              listId={listId}
            />
          }
        />
      </SubWrapper>
      <SubWrapper title="Font" canHide childrenFor={componentType}>
        <BrandingFontInput
          value={font}
          onChange={value => onComponentPropertiesChange([{ propName: 'font', value }])}
        />
        <SettingTextAlignment
          subLabel="Alignment"
          value={getCSSTextAlignment(textAlignment)}
          onChange={value =>
            onComponentPropertiesChange([{ propName: 'textAlignment', value: getAppTextAlignment(value) }])
          }
        />
        <BrandingColorInput
          subLabel="Text color"
          value={textColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { constant: value } }])}
          source={textColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'textColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
      </SubWrapper>
      <SubWrapper title="Icon" canHide childrenFor={componentType}>
        <ImageInput
          subLabel="Asset"
          value={imageName || imageUrl}
          url={imageUrl}
          onChange={(value, resources) =>
            onComponentPropertiesChange([{ propName: 'image', value: { constant: { resourceId: value } }, resources }])
          }
          source={image?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'image', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
          sfByDefault
        />
        <BrandingColorInput
          subLabel="Tint color"
          value={imageColor?.constant}
          onChange={value => onComponentPropertiesChange([{ propName: 'imageColor', value: { constant: value } }])}
          source={imageColor?.source}
          onSourceChange={value => onComponentPropertiesChange([{ propName: 'imageColor', value: { source: value } }])}
          screenConfig={screenConfig}
          allLocalVariables={allLocalVariables}
        />
        <SettingImageAlignment
          subLabel="Alignment"
          value={imageLocation}
          onChange={value => onComponentPropertiesChange([{ propName: 'imageLocation', value }])}
        />
      </SubWrapper>
      <Common
        childrenFor={componentType}
        component={component}
        onComponentPropertiesChange={onComponentPropertiesChange}
        screenConfig={screenConfig}
        allLocalVariables={allLocalVariables}
      />
    </RightSidebarItem>
  )
}
