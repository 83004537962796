import { Autocomplete, Box, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { Container, FieldWrapper, Label, SubLabel } from '../SettingField.styled'
import * as styled from './SettingArrayField.styled'

interface Props {
  label?: string
  subLabel?: string
  value?: string[]
  onChange: (val?: string[]) => void
  placeholder?: string
  small?: boolean
  disabled?: boolean
}

export const SettingArrayField: React.FC<Props> = ({
  label,
  subLabel,
  value,
  onChange,
  placeholder,
  small,
  disabled,
}) => {
  const [active, setActive] = useState(false)
  const [localValue, setLocalValue] = useState(value)

  useEffect(() => {
    if (JSON.stringify(value) !== JSON.stringify(localValue)) {
      const debounceFn = setTimeout(() => {
        onChange(localValue)
      }, 600)
      return () => clearInterval(debounceFn)
    }
  }, [localValue])

  return (
    <Container withLabel={!!label} small={!!small} disabled={disabled}>
      {label && <Label>{label}</Label>}
      <FieldWrapper>
        {subLabel && <SubLabel>{subLabel}</SubLabel>}
        <Box width={subLabel ? 'calc(65% - 8px)' : '100%'}>
          <styled.AutocompleteWrapper active={active}>
            <Autocomplete
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              size="small"
              fullWidth
              multiple
              freeSolo
              options={[]}
              value={localValue || []}
              onChange={(e, v) => setLocalValue(v as string[])}
              renderInput={params => <TextField {...params} placeholder={placeholder || 'Type here and hit enter'} />}
            />
          </styled.AutocompleteWrapper>
        </Box>
      </FieldWrapper>
    </Container>
  )
}
