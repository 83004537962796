import { Box } from '@mui/material'
import { singular } from 'pluralize'
import { memo, useEffect, useState } from 'react'

import * as styled from 'components/Menu/Menu.styled'

import { DropDown, Icon, Name, Rotation } from 'components'
import { CMSCollection, getCollectionName } from 'services/cms'

export interface Branch extends CMSCollection {
  children: Branch[]
}

interface Props {
  collection: Branch
  currentCollection?: CMSCollection
  openSchema: (id: string) => void
  openRecords: (id: string) => void
  setToRemove: React.Dispatch<React.SetStateAction<CMSCollection | null>>
  setSubCollectionParentName: (id: string) => void
  lvl: number
}

export const CollectionsItem: React.FC<Props> = memo(
  ({ collection, currentCollection, openRecords, openSchema, setToRemove, setSubCollectionParentName, lvl }) => {
    const [open, setOpen] = useState(false)

    useEffect(() => {
      if (currentCollection && currentCollection.name.startsWith(`${collection.id}/`)) {
        setOpen(true)
      }
    }, [currentCollection])

    const options = [
      {
        icon: <Icon name={Name.PICKERS_EDIT_RECORDS} />,
        text: 'View/edit records',
        onClick: () => openRecords(collection.id),
      },
      {
        icon: <Icon name={Name.PICKERS_EDIT_SCHEMA} />,
        text: 'Edit fileds',
        onClick: () => openSchema(collection.id),
      },
      {
        icon: <Icon name={Name.ADDITIONAL_PLUS} />,
        text: 'Start collection',
        onClick: () => setSubCollectionParentName(`${collection.id}/{${singular(getCollectionName(collection.id))}Id}`),
      },
      {
        icon: <Icon name={Name.PICKERS_API_DOCUMENTATION} />,
        text: 'API documentation',
      },
      {
        icon: <Icon name={Name.PICKERS_DELETE} />,
        text: 'Delete collection',
        onClick: () => setToRemove(collection),
        red: true,
      },
    ].filter((el, i) => (collection.isSystem ? i !== 4 : true))

    return (
      <>
        <styled.NavItem
          active={currentCollection?.id === collection.id}
          onClick={() => openRecords(collection.id)}
          hasActions
          lvl={lvl}
          big
        >
          {!!collection.children.length ? (
            <styled.Arrow>
              <Icon
                onClick={e => {
                  e.stopPropagation()
                  setOpen(open => !open)
                }}
                name={Name.ADDITIONAL_NAVIGATION_TREE_ARROW}
                rotation={open ? Rotation.DOWN : undefined}
              />
            </styled.Arrow>
          ) : (
            <styled.Space />
          )}
          <Icon name={Name.ADDITIONAL_COLLECTION} />
          <span>{collection.name}</span>
          <Box position="absolute" right="4px" onClick={e => e.stopPropagation()}>
            <DropDown
              options={options}
              top="15px"
              width="196px"
              right="-5px"
              scrollIntoView
              labelElement={
                <styled.ShowMoreActions>
                  <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} width={14} />
                </styled.ShowMoreActions>
              }
            />
          </Box>
        </styled.NavItem>
        {open &&
          collection.children.map(el => (
            <CollectionsItem
              key={el.id}
              collection={el}
              currentCollection={currentCollection}
              openSchema={openSchema}
              openRecords={openRecords}
              setToRemove={setToRemove}
              setSubCollectionParentName={setSubCollectionParentName}
              lvl={lvl + 1}
            />
          ))}
      </>
    )
  }
)
