import React from 'react'

import { ISvg, Svg } from '../Svg'

const BuildFailedSmallDarkTheme: React.FC<ISvg> = props => (
  <Svg viewBox="0 0 22 22" fill="none" {...props}>
    <circle cx="11" cy="11" r="9" fill="white" />
    <path d="M14 8L8 14" stroke="#DE3030" strokeWidth="2" strokeLinecap="round" />
    <path d="M8 8L14 14" stroke="#DE3030" strokeWidth="2" strokeLinecap="round" />
  </Svg>
)

export default BuildFailedSmallDarkTheme
