import React, { memo, useContext, useEffect, useState } from 'react'

import { AIBtn, Button, ButtonVariant, Icon, Name } from 'components'
import { TopPanelContext } from 'context'
import { AddMedia } from './components'

export const TopPanelStudio: React.FC = memo(() => {
  const { setLeftButtons } = useContext(TopPanelContext)
  const [openMedia, setOpenMedia] = useState(false)
  const [outerOpenAI, setOuterOpenAI] = useState(false)

  useEffect(() => {
    setLeftButtons(
      <>
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_POINTER} />}
          active={!openMedia && !outerOpenAI}
        >
          Select
        </Button>
        <Button
          variant={ButtonVariant.TOP_PANEL_ACTION}
          leftIcon={<Icon name={Name.ADDITIONAL_ADD} />}
          active={openMedia && !outerOpenAI}
          onClick={() => setOpenMedia(open => !open)}
        >
          Add
        </Button>
        {openMedia && <AddMedia close={() => setOpenMedia(false)} />}
        <AIBtn setOuterOpenAI={setOuterOpenAI} vb />
      </>
    )
    return () => setLeftButtons(null)
  }, [openMedia, outerOpenAI])

  return <></>
})
