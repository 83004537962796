import React, { useContext } from 'react'

import {
  BooleanInput,
  BrandingColorInput,
  CoordinateInput,
  DateInput,
  IPickerItem,
  SourceInput,
  StringInput,
} from 'components'
import { ProjectContext } from 'context'
import { SettingNumberField } from 'partials/RightSidebar/components'
import { AnyVariable, LocalVariable, Screen, ValueType } from 'utils'

export const getValueType = (collectionFieldsValues: IPickerItem[], fieldName?: string) => {
  const field = collectionFieldsValues.find(a => a.value === fieldName)
  if (field) {
    return field.type === ValueType.array ? field.accept : field.type
  }
}

interface Props {
  valueType?: ValueType
  anyVariableValue?: AnyVariable
  onChange: (val?: AnyVariable) => void
  disabled?: boolean
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
}

export const AnyVariableInput: React.FC<Props> = ({
  valueType,
  anyVariableValue,
  onChange,
  disabled,
  screenConfig,
  allLocalVariables,
}) => {
  const { language } = useContext(ProjectContext)

  return valueType === ValueType.boolean ? (
    <BooleanInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.booleanConstant}
      onChange={value => onChange(value !== undefined ? { booleanConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.number ? (
    <SettingNumberField
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.numberConstant}
      onChange={value => onChange(value ? { numberConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.date ? (
    <DateInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.dateConstant}
      onChange={value => onChange(value ? { dateConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.dateTime ? (
    <DateInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.dateTimeConstant}
      onChange={value => onChange(value ? { dateTimeConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.color ? (
    <BrandingColorInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.colorConstant}
      onChange={value => onChange(value ? { colorConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.coordinate ? (
    <CoordinateInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.coordinateConstant}
      onChange={value => onChange(value ? { coordinateConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.string ? (
    <StringInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.textConstant?.locales[language]}
      onChange={value =>
        onChange(
          value
            ? {
                textConstant: {
                  key: 'key',
                  locales: { ...anyVariableValue?.textConstant?.locales, [language]: value },
                },
              }
            : undefined
        )
      }
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.url ? (
    <StringInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.urlConstant}
      onChange={value => onChange(value ? { urlConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType === ValueType.accessLevel ? (
    <StringInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      value={anyVariableValue?.accessLevelConstant}
      onChange={value => onChange(value ? { accessLevelConstant: value } : undefined)}
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : valueType ? (
    <SourceInput
      insidePicker
      small
      disabled={disabled}
      subLabel="Value"
      source={anyVariableValue?.source}
      onSourceChange={value => onChange(value ? { source: value } : undefined)}
      screenConfig={screenConfig}
      allLocalVariables={allLocalVariables}
    />
  ) : (
    <></>
  )
}
