import React from 'react'

interface Props {
  children: React.ReactNode
}

export const GooglePlayLink: React.FC<Props> = ({ children }) => (
  <a href={`https://play.google.com/`} target="_blank" rel="noreferrer">
    {children}
  </a>
)
