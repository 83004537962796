import { Box } from '@mui/material'
import React, { useEffect, useRef } from 'react'

import * as styled from './Modal.styled'

import { Button, ButtonColor, ButtonSize, Icon, Name, Text, Type } from 'components'

interface Props {
  active: boolean
  children?: React.ReactNode
  hasLeftPanel?: boolean
  noCloseIcon?: boolean
  onClose: () => void
  onButtonClick?: React.MouseEventHandler<HTMLElement>
  title?: string
  cancelButton?: boolean
  buttonTitle?: string
  buttonDisabled?: boolean
  buttonLoading?: boolean
  deleteButton?: boolean
  width?: string
  parentHasTransform?: boolean
  isMouseDown?: boolean
  style?: React.CSSProperties
}

export const Modal: React.FC<Props> = ({
  active,
  children,
  hasLeftPanel,
  noCloseIcon,
  onClose,
  title,
  onButtonClick,
  cancelButton = true,
  buttonTitle,
  buttonDisabled,
  buttonLoading,
  deleteButton,
  width,
  parentHasTransform,
  isMouseDown,
  style,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const onKeyDown = (e: Event) => (e as KeyboardEvent).code === 'Escape' && onClose()
    if (active) {
      document.addEventListener('keydown', onKeyDown)
    }
    return () => document.removeEventListener('keydown', onKeyDown)
  }, [active])

  useEffect(() => {
    if (parentHasTransform && ref.current) {
      const viewportOffset = ref.current.getBoundingClientRect()
      const top = viewportOffset.top
      const left = viewportOffset.left
      ref.current.style.transform = `translate(-${left}px, -${top}px)`
    }
  }, [ref.current?.getBoundingClientRect().top, ref.current?.getBoundingClientRect().left])

  return (
    <styled.Overlay
      active={active}
      onClick={!isMouseDown ? onClose : undefined}
      onMouseDown={isMouseDown ? onClose : undefined}
      ref={ref}
    >
      {hasLeftPanel ? (
        <styled.Modal
          active={active}
          onClick={e => !isMouseDown && e.stopPropagation()}
          onMouseDown={e => isMouseDown && e.stopPropagation()}
          hasLeftPanel
        >
          <styled.WithLeftPanelWrapper>{active && children}</styled.WithLeftPanelWrapper>
        </styled.Modal>
      ) : (
        <styled.Modal
          active={active}
          onClick={e => !isMouseDown && e.stopPropagation()}
          onMouseDown={e => isMouseDown && e.stopPropagation()}
          width={width}
          style={style}
        >
          {title && (
            <Box marginBottom="10px">
              <Text type={Type.H1} fontWeight="700" as="span">
                {title}
              </Text>
            </Box>
          )}
          {!noCloseIcon && (
            <styled.CloseIconBox>
              <Icon name={Name.SIZE_24_CLOSE} onClick={onClose} />
            </styled.CloseIconBox>
          )}
          {active && children}
          {(cancelButton || !!(buttonTitle && onButtonClick)) && (
            <Box mt="30px" display="flex" justifyContent="flex-end" alignItems="center" gap="15px">
              {cancelButton && (
                <Button
                  size={ButtonSize.DEFAULT}
                  color={ButtonColor.SECONDARY}
                  onClick={onClose}
                  type="button"
                  style={{ minWidth: '90px' }}
                >
                  Cancel
                </Button>
              )}
              {!!(buttonTitle && onButtonClick) && (
                <Button
                  size={ButtonSize.DEFAULT}
                  color={deleteButton ? ButtonColor.DELETE : ButtonColor.PRIMARY}
                  disabled={buttonDisabled || buttonLoading}
                  onClick={onButtonClick}
                  type="button"
                  loading={buttonLoading}
                  style={{ minWidth: '90px' }}
                >
                  {buttonTitle}
                </Button>
              )}
            </Box>
          )}
        </styled.Modal>
      )}
    </styled.Overlay>
  )
}
