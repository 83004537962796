import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'

import { ActionsIconTbodyWrapper, Avatar, DropDown, Icon, Name, NoContent, TableWrapper } from 'components'
import { InviteStatus } from 'hooks'
import { firstLetterUpperCase } from 'utils'

interface Props {
  teammates: any[]
}

export const TeammatesContent: React.FC<Props> = ({ teammates }) => {
  const themeContext = useContext(ThemeContext)

  return (
    <TableWrapper empty={!teammates.length} big>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{teammates.length} teammates</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Status</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        {!!teammates.length ? (
          <TableBody>
            {teammates.map(el => (
              <TableRow key={el.id}>
                <TableCell>
                  <Box display="flex" gap="10px" alignItems="center">
                    <Avatar width={30} value={el.userPhotoUrl} />
                    {el.status ? 'New user' : el.userName}
                  </Box>
                </TableCell>
                <TableCell>{el.email}</TableCell>
                <TableCell>
                  {el.role && (
                    <span
                      style={{
                        padding: '6px 10px',
                        borderRadius: '8px',
                        border: `1px solid ${themeContext.colors.darkBlue_100}`,
                        background: themeContext.colors.tableRoleBg,
                      }}
                    >
                      {firstLetterUpperCase(el.role.toLowerCase().replaceAll('_', ' '))}
                    </span>
                  )}
                </TableCell>
                <TableCell style={{ color: el.status === InviteStatus.PENDING && themeContext.colors.grey1 }}>
                  {el.status ? 'Pending...' : 'Active'}
                </TableCell>
                <TableCell>
                  <ActionsIconTbodyWrapper>
                    <div>
                      <DropDown
                        options={[
                          {
                            icon: <Icon name={Name.PICKERS_DELETE} />,
                            text: 'Delete member',
                            onClick: () => {},
                            red: true,
                          },
                        ]}
                        top="-38px"
                        labelElement={
                          <Box display="flex" alignItems="center">
                            <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
                          </Box>
                        }
                      />
                    </div>
                  </ActionsIconTbodyWrapper>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        ) : (
          <NoContent isTable text="Empty" />
        )}
      </Table>
    </TableWrapper>
  )
}
