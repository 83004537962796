import { Box } from '@mui/material'
import React from 'react'

import { CommonPicker, IPickerItem, Icon, Name } from 'components'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { TableQuery, TableQueryOrder, VariableSource } from 'utils'

const tableQueryOrdersValues = Object.values(TableQueryOrder).map(el => ({ value: el, label: el }))

interface Props {
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  currentVariableCollectionFieldsValues: IPickerItem[]
}

export const Sort: React.FC<Props> = ({
  currentVariable,
  setCurrentVariable,
  currentVariableCollectionFieldsValues,
}) => {
  const { query } = currentVariable

  const onAdd = () =>
    setCurrentVariable(currentVariable =>
      currentVariable
        ? { ...currentVariable, query: { ...currentVariable.query, order: TableQueryOrder.descending } }
        : null
    )

  const onRemove = () =>
    setCurrentVariable(currentVariable => {
      const copy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource | null
      if (copy?.query && (!copy.query.filters || !copy.query.filters.length)) {
        delete copy.query
      } else if (copy?.query) {
        delete copy.query.order
        delete copy.query.orderedBy
      }
      return copy
    })

  const onChange = (field: keyof TableQuery, value: any) =>
    setCurrentVariable(currentVariable =>
      currentVariable ? { ...currentVariable, query: { ...currentVariable.query, [field]: value } } : null
    )

  return (
    <SubWrapper
      small
      noBorderBottom
      title="Sort"
      onAdd={!query?.order ? onAdd : undefined}
      canHide={!!query?.order}
      childrenFor=""
    >
      {query?.order && (
        <Box display="flex" flexDirection="column" gap="12px">
          <RightSidebarItem small title="">
            <SubWrapper
              small
              title="SORT"
              childrenFor=""
              customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={onRemove} />}
            >
              <SettingField
                insidePicker
                subLabel="Order"
                value={query?.order}
                small
                optionsContainer={
                  <CommonPicker
                    title="Orders"
                    value={query?.order}
                    onChange={value => onChange('order', value)}
                    values={tableQueryOrdersValues}
                    close={() => {}}
                  />
                }
              />
              <SettingField
                insidePicker
                subLabel="Ordered by"
                value={query?.orderedBy}
                small
                optionsContainer={
                  <CommonPicker
                    title="Fields"
                    value={query?.orderedBy}
                    onChange={value => onChange('orderedBy', value)}
                    values={currentVariableCollectionFieldsValues}
                    close={() => {}}
                  />
                }
              />
            </SubWrapper>
          </RightSidebarItem>
        </Box>
      )}
    </SubWrapper>
  )
}
