import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Button, Crumbs, Icon, InputProperty, Name, RightForm, inputs, validate } from 'components'
import { ProgressContext } from 'context'
import { CMSCollectionRecord } from 'services/cms'
import { ProjectsService } from 'services/projects'
import { ProductType, ValueType } from 'utils'

const properties: InputProperty[] = [
  {
    name: 'name',
    type: ValueType.string,
    position: 0,
    isRequired: true,
    title: 'Product name',
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
  },
  {
    name: 'description',
    type: ValueType.string,
    position: 1,
    isRequired: true,
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
  },
  {
    name: 'type',
    type: ValueType.string,
    position: 2,
    isRequired: true,
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
    optionsToSelect: Object.values(ProductType).map(el => ({ value: el, label: el })),
  },
  {
    name: 'duration',
    type: ValueType.string,
    position: 3,
    isRequired: true,
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
    optionsToSelect: [
      { value: 'P1W', label: '1 Week' },
      { value: 'P1M', label: '1 Month' },
      { value: 'P2M', label: '2 Months' },
      { value: 'P3M', label: '3 Months' },
      { value: 'P6M', label: '6 Months' },
      { value: 'P1Y', label: '1 Year' },
    ],
  },
  {
    name: 'trialDuration',
    type: ValueType.string,
    position: 4,
    title: 'Trial duration',
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
    optionsToSelect: [
      { value: 'P3D', label: '3 Days' },
      { value: 'P1W', label: '1 Week' },
      { value: 'P2W', label: '2 Weeks' },
      { value: 'P1M', label: '1 Month' },
      { value: 'P2M', label: '2 Months' },
      { value: 'P3M', label: '3 Months' },
      { value: 'P6M', label: '6 Months' },
      { value: 'P1Y', label: '1 Year' },
    ],
  },
  {
    name: 'isAutoRenewable',
    type: ValueType.boolean,
    position: 5,
    title: 'Is auto renewable',
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
  },
  {
    name: 'isConsumable',
    type: ValueType.boolean,
    position: 6,
    title: 'Is consumable',
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
  },
  {
    name: 'tier',
    type: ValueType.string,
    position: 7,
    isRequired: true,
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
    optionsToSelect: [
      { value: '$0.99', label: '$0.99' },
      { value: '$11.99', label: '$11.99' },
      { value: '$99.99', label: '$99.99' },
    ],
  },
  {
    name: 'accessLevels',
    type: ValueType.array,
    accept: ValueType.accessLevel,
    position: 8,
    isRequired: true,
    acceptValues: ['premium'],
    title: 'Access levels',
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
  },
  {
    name: 'id',
    type: ValueType.string,
    position: 9,
    isRequired: true,
    title: 'ID',
    helpText: 'Connect your repository to manage the source code. Every push will trigger assigned CI/CD pipeline.',
  },
]

export const Product: React.FC = () => {
  const navigate = useNavigate()
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id, productId } = useParams()
  const [wait, setWait] = useState(true)
  const [valuesBeforeEdit, setValuesBeforeEdit] = useState('')
  const [saveLoading, setSaveLoading] = useState(false)

  const setForm = async () => {
    const product: CMSCollectionRecord = {}
    setWait(true)
    if (productId) {
      try {
        startLoader()
        const res = await ProjectsService.getProduct(id as string, productId)
        properties.forEach(property => {
          product[property.name] = res.data[property.name] || null
        })
      } catch (err) {
        toast(err)
      } finally {
        stopLoader()
      }
    } else {
      properties.forEach(property => {
        product[property.name] = null
      })
    }
    setValuesBeforeEdit(JSON.stringify(product))
    formik.setValues({ ...product })
    setWait(false)
  }

  useEffect(() => {
    setForm()
  }, [])

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    validate: (product: any) => validate(product, properties),
    onSubmit: product => {
      setSaveLoading(true)
      startLoader()
      ;(productId
        ? ProjectsService.updateProduct(id as string, productId, product)
        : ProjectsService.postProduct(id as string, product)
      )
        .then(res => {
          const productId = res.data.id
          setValuesBeforeEdit(JSON.stringify(product))
          setTimeout(() => {
            navigate(`/projects/${id}/monetization/products/${productId}`)
          }, 0)
        })
        .catch(err => toast(err))
        .finally(() => {
          stopLoader()
          setSaveLoading(false)
        })
    },
  })

  const showConfirmWhen = !!valuesBeforeEdit && valuesBeforeEdit !== JSON.stringify(formik.values)
  const closePath = `/projects/${id}/monetization`

  return (
    <RightForm
      showConfirmWhen={showConfirmWhen}
      onClose={() => navigate(closePath)}
      title={
        <>
          <Crumbs
            firstCrumbTitle="Products"
            secondCrumbTitle={`${productId ? 'Edit' : 'Add'} product`}
            icon={<Icon name={Name.RIGHT_SIDEBAR_CLOSE} />}
            to={closePath}
            small
          />
          <Button onClick={formik.submitForm} disabled={!showConfirmWhen || saveLoading} loading={saveLoading}>
            Save
          </Button>
        </>
      }
      form={wait ? <></> : <form onSubmit={formik.handleSubmit}>{inputs(properties, formik)}</form>}
    />
  )
}
