import { GetVariable } from 'hooks'
import {
  BrandingThemeType,
  Config,
  Screen,
  ValueType,
  VariableSourceType,
  VariableTransformTransform,
  base64Roboto,
  base64SF,
  convertSvgStringToImage,
  createAlertScreen,
  createScreen,
  createScreenTabBar,
  createScreenTopBar,
  getColor,
  getPx,
  getShadow,
  i14new,
  screenHeight,
  screenWidth,
  setComponents,
  setTabs,
  tabBarHeight,
  topBarHeight,
} from 'utils'

export const getBackScreenConfig = (screenName: string, state: Config) => {
  if (
    screenName === 'Push Notifications System Alert' ||
    screenName === 'Camera System Alert' ||
    screenName === 'Gallery System Alert'
  ) {
    return state.screens.find(el => el.screenName === 'Push Notifications Permissions')
  } else if (screenName === 'Idfa Tracking SystemAlert') {
    return state.screens.find(el => el.screenName === 'Idfa Tracking Permissions')
  }
}

export const getImageByConfigs = async (
  screenConfig: Screen,
  state: Config,
  getVariable: GetVariable,
  language: string,
  theme: BrandingThemeType
) => {
  const {
    screenName,
    backgroundColor,
    components,
    topBar,
    showTopBar,
    showBackButton,
    screenTitle,
    inputParameters,
    localVariables,
  } = screenConfig
  const {
    branding: {
      colorStyles,
      typography: { iosDefaultFontFamily: fontFamily, fontStyles },
    },
    tabBars,
    resources,
    products,
  } = state
  const { safeAreaTop, safeAreaBottom } = i14new
  const tabBar = tabBars.find(el => el.tabs.find(el => el.screen === screenName))
  const backScreenConfig = getBackScreenConfig(screenName, state)

  const shadow = getShadow()
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  shadow.appendChild(svg)
  svg.setAttribute('viewBox', `0 0 ${screenWidth} ${screenHeight}`)
  svg.setAttribute('width', String(screenWidth))
  svg.setAttribute('height', String(screenHeight))
  const screenWrapper = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
  svg.appendChild(screenWrapper)
  screenWrapper.setAttribute('width', String(screenWidth))
  screenWrapper.setAttribute('height', String(screenHeight))

  try {
    const getVariableValue = getVariable(screenConfig)
    const showTopBarValue = showTopBar?.source
      ? (await getVariableValue(showTopBar.source)) === 'true'
      : !!showTopBar?.constant
    const getPxValue = getPx(!!tabBar, showTopBarValue, safeAreaTop, safeAreaBottom)
    if (inputParameters) {
      for (const el of inputParameters) {
        if (el.type === ValueType.record && el.collection) {
          await getVariableValue(
            {
              collection: { name: el.collection },
              type: VariableSourceType.collection,
              transforms: [{ transform: VariableTransformTransform.first }],
            },
            undefined,
            el.parameter
          )
        } else if (el.type === ValueType.string) {
          await getVariableValue({ type: VariableSourceType.contextVariable }, undefined, el.parameter, '')
        }
      }
    }
    if (localVariables) {
      for (const el of localVariables) {
        if (el.variable?.source) {
          await getVariableValue(el.variable.source, undefined, el.name)
        } else if (el.variable?.calendarStyleConstant) {
          await getVariableValue(
            { type: VariableSourceType.contextVariable },
            undefined,
            el.name,
            el.variable.calendarStyleConstant
          )
        } else if (el.type === ValueType.record && el.collection) {
          await getVariableValue(
            { collection: { name: el.collection }, type: VariableSourceType.collection },
            undefined,
            el.name
          )
        }
      }
    }
    const screenBackgroundColor = await getColor(backgroundColor, colorStyles, theme, getVariableValue)
    const screen = createScreen('', screenBackgroundColor, screenWrapper)
    if (showTopBarValue) {
      await createScreenTopBar(
        '',
        '',
        topBarHeight + safeAreaTop,
        screen,
        fontFamily,
        fontStyles,
        colorStyles,
        theme,
        resources,
        products,
        1,
        screenConfig,
        language,
        getPxValue,
        getVariableValue,
        getVariable,
        topBar,
        showBackButton,
        screenTitle
      )
    }
    if (components.length) {
      await setComponents(
        components,
        screen,
        fontFamily,
        fontStyles,
        colorStyles,
        theme,
        resources,
        products,
        1,
        true,
        screenConfig,
        language,
        getPxValue,
        getVariableValue,
        getVariable
      )
    }
    if (tabBar) {
      const screenTabBar = createScreenTabBar('', '', colorStyles, theme, screen, tabBarHeight + safeAreaBottom)
      await setTabs(
        screenName,
        tabBar.tabs,
        screenTabBar,
        fontFamily,
        colorStyles,
        theme,
        resources,
        getVariableValue,
        language
      )
    }
    if (backScreenConfig) {
      createAlertScreen(
        screen,
        screenWrapper,
        await getImageByConfigs(backScreenConfig, state, getVariable, language, theme)
      )
    }

    const style = document.createElement('style')
    svg.appendChild(style)
    style.innerHTML = '*::-webkit-scrollbar{display: none;} *[hidden]{display: none !important;}'
    svg.innerHTML = (fontFamily === 'Roboto' ? base64Roboto : base64SF) + svg.innerHTML

    const serializer = new XMLSerializer()
    const source = serializer.serializeToString(svg)
    const url = convertSvgStringToImage(source)
    return url
  } catch {
    return ''
  } finally {
    shadow.parentNode?.removeChild(shadow)
  }
}
