import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { ImageInput } from 'components'
import { ProgressContext, ProjectContext } from 'context'
import { ProjectsService } from 'services/projects'
import { Resource } from 'utils'

interface Props {
  big?: boolean
  label?: string
  subLabel?: string
}

export const AppIconInput: React.FC<Props> = ({ big, label, subLabel }) => {
  const { id } = useParams()
  const { toast } = useContext(ProgressContext)
  const {
    state: {
      branding: {
        icons: { iosIcon },
      },
      resources,
    },
    setState,
  } = useContext(ProjectContext)
  const stateResources = resources
  const iconResource = stateResources.find(el => el.id === iosIcon)

  const onChange = (value?: string, resources?: Resource[]) => {
    const logoUrl = (resources || stateResources).find(el => el.id === value)?.url
    if (id && logoUrl) {
      ProjectsService.updateProjectLogo(id, logoUrl).catch(err => toast(err))
    }
    setState(state => ({
      ...state,
      branding: {
        ...state.branding,
        icons: { iosIcon: value as string, androidIcon: value as string },
      },
      resources: resources || stateResources,
    }))
  }

  return (
    <ImageInput
      big={big}
      label={label}
      subLabel={subLabel}
      value={iconResource?.name || iconResource?.url}
      url={iconResource?.url}
      onChange={onChange}
      withoutDelete
    />
  )
}
