import { useFormik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { CustomSection, CustomSectionTitle, CustomSectionsWrapper, InputProperty, Text, Type, inputs } from 'components'
import { ProgressContext } from 'context'
import { firestore } from 'services/firebase'
import { ValueType } from 'utils'
import { SettingsLayout } from '../components'

export const properties: InputProperty[] = [
  {
    name: 'screenshots',
    type: ValueType.screenshots,
    position: 0,
    title: 'App Screenshots',
    helpText:
      'If localized app information isn’t available in an app stores country or region, the information from your primary language will be used instead. Learn more',
  },
]

export const Screenshots: React.FC = () => {
  const { startLoader, stopLoader, toast } = useContext(ProgressContext)
  const { id } = useParams()
  const [wait, setWait] = useState(true)
  const ref = firestore.collection(`projects/${id}/configurations`).doc('storemanager')

  const formik = useFormik({
    initialValues: {} as any,
    enableReinitialize: true,
    onSubmit: () => {},
  })

  useEffect(() => {
    startLoader()
    const unsubscribe = ref.onSnapshot({
      next: res => {
        const resData = res.data()
        if (resData) {
          const data = JSON.parse(resData.data)
          formik.setValues({ screenshots: data.screenshots || null })
          stopLoader()
          setWait(false)
        }
      },
      error: err => {
        toast(err)
        stopLoader()
      },
    })
    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <SettingsLayout>
      <Text type={Type.TITLE}>Screenshots</Text>
      <Text type={Type.SUB_TITLE} styles={{ marginBottom: '40px' }}>
        Panoramic studio-quality screenshots for iOS and Android apps.
      </Text>
      {wait ? (
        <></>
      ) : (
        <CustomSectionsWrapper>
          <CustomSection>
            <CustomSectionTitle mb="-5px">App Screenshots</CustomSectionTitle>
            {inputs(properties, formik)}
          </CustomSection>
        </CustomSectionsWrapper>
      )}
    </SettingsLayout>
  )
}
