import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Icon, Name, Rotation } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { IBackground, IDevice, IImage, IText, resizeContainer } from '../../utils'

interface Props {
  activeElement: IBackground | IImage | IDevice | IText | null
  addScreen: () => void
  deleteScreen: () => void
  backgrounds: IBackground[]
  setActiveElement: (el: IBackground) => void
}

export const ScreenshotSettings: React.FC<Props> = ({
  activeElement,
  addScreen,
  backgrounds,
  setActiveElement,
  deleteScreen,
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const {
    state: {
      localization: { languages },
    },
  } = useContext(ProjectContext)
  const setActive = (background: IBackground) => {
    resizeContainer.parentNode?.removeChild(resizeContainer)
    document.getElementById(background.id)?.scrollIntoView({ behavior: 'smooth' })
    setActiveElement(background)
  }

  const sectionName = 'Settings'

  return (
    <RightSidebarItem title={sectionName} canHide>
      <SubWrapper title="Screenshots" onAdd={addScreen} childrenFor={sectionName} canHide>
        {backgrounds.map((background, index) => (
          <SettingField
            key={background.id}
            value={'Screenshot ' + (index + 1)}
            active={activeElement?.id === background.id}
            onClick={() => setActive(background)}
            actions={
              <>
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} rotation={Rotation.LEFT} />
                <Icon name={Name.RIGHT_SIDEBAR_MOVE_UP} />
                {index === backgrounds.length - 1 ? (
                  <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={deleteScreen} />
                ) : (
                  <Icon name={Name.RIGHT_SIDEBAR_SHOW_MORE} />
                )}
              </>
            }
          />
        ))}
      </SubWrapper>
      <SubWrapper
        title="Translations"
        childrenFor={sectionName}
        customIcon={
          <Icon name={Name.RIGHT_SIDEBAR_EDIT} onClick={() => navigate(`/projects/${id}/settings/languages`)} />
        }
      >
        <Box display="flex" gap="8px" flexWrap="wrap" marginTop="-10px !important">
          {languages.map(el => (
            <SettingField key={el} value={el} />
          ))}
        </Box>
      </SubWrapper>
    </RightSidebarItem>
  )
}
