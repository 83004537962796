import { Box } from '@mui/material'
import React, { useContext, useMemo } from 'react'

import * as styled from '../../../CustomPicker.styled'

import { ProjectContext } from 'context'
import { includesSearch } from 'utils'
import { colorStylesSections } from './constants'
import { TwoColorsSvg } from '../ColorsSvg'

interface Props {
  value?: string
  onChange: (val: string, color: string) => void
  search: string
  close: () => void
}

export const Style: React.FC<Props> = ({ value, onChange, search, close }) => {
  const {
    state: {
      branding: { colorStyles },
    },
  } = useContext(ProjectContext)

  const sections = useMemo(
    () =>
      colorStylesSections.map(el => ({
        ...el,
        values: el.values.map(el => ({
          value: `@${el}`,
          label: el,
          img: <TwoColorsSvg left={colorStyles[el].dark} right={colorStyles[el].light} />,
          color: colorStyles[el].light,
        })),
      })),
    [colorStyles]
  )

  const onClick = (value: string, color: string) => {
    onChange(value, color)
    close()
  }

  return (
    <styled.Items>
      {sections
        .filter(el => !!el.values.filter(el => includesSearch(el.label, search)).length)
        .map(el => (
          <Box marginBottom="5px" key={el.name}>
            <styled.Item sectionName color="grey4">
              <styled.ItemLabel>{el.name}</styled.ItemLabel>
            </styled.Item>
            {el.values
              .filter(el => includesSearch(el.label, search))
              .map(el => (
                <styled.Item
                  key={el.label}
                  active={el.value === value}
                  onClick={() => onClick(el.value, el.color)}
                  color="darkText1"
                >
                  {el.img}
                  <styled.ItemLabel>{el.label}</styled.ItemLabel>
                </styled.Item>
              ))}
          </Box>
        ))}
    </styled.Items>
  )
}
