import { Box } from '@mui/material'
import React, { useContext } from 'react'

import { AnyVariableInput, CommonPicker, IPickerItem, Icon, Name, getValueType } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import { RecordStatus, recordStatus } from 'services/cms'
import { AnyVariable, LocalVariable, Screen, TableQueryFilter, TableQueryFilterOperand, VariableSource } from 'utils'

const tableQueryFilterOperandsValues = Object.values(TableQueryFilterOperand).map(el => ({ value: el, label: el }))
const recordStatusValues = Object.values(RecordStatus).map(el => ({ label: el, value: el }))

export const isFiltersNotValid = (filters?: TableQueryFilter[]) =>
  !!filters?.find(el => !el.field || !el.operator || !el.value)

interface Props {
  currentVariable: VariableSource
  setCurrentVariable: (val: React.SetStateAction<VariableSource | null>) => void
  currentVariableCollectionFieldsValues: IPickerItem[]
  screenConfig?: Screen
  allLocalVariables?: LocalVariable[]
}

export const Filters: React.FC<Props> = ({
  currentVariable,
  setCurrentVariable,
  currentVariableCollectionFieldsValues,
  screenConfig,
  allLocalVariables,
}) => {
  const { language } = useContext(ProjectContext)
  const { query } = currentVariable

  const onAdd = () =>
    currentVariableCollectionFieldsValues.length &&
    setCurrentVariable(currentVariable =>
      currentVariable
        ? {
            ...currentVariable,
            query: {
              ...currentVariable.query,
              filters: [...(currentVariable.query?.filters || []), {} as TableQueryFilter],
            },
          }
        : null
    )

  const onRemove = (index: number) =>
    setCurrentVariable(currentVariable => {
      const copy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource | null
      copy?.query?.filters?.splice(index, 1)
      if (copy?.query?.filters && !copy.query.filters.length) {
        delete copy.query.filters
      }
      if (copy?.query && !copy.query.filters && !copy.query.order && !copy.query.orderedBy) {
        delete copy.query
      }
      return copy
    })

  const onChange = (
    index: number,
    filter: TableQueryFilter | { field?: string; operator?: TableQueryFilterOperand; value?: AnyVariable }
  ) =>
    setCurrentVariable(currentVariable => {
      const copy = JSON.parse(JSON.stringify(currentVariable)) as VariableSource | null
      copy?.query?.filters?.splice(index, 1, filter as TableQueryFilter)
      return copy
    })

  return (
    <SubWrapper small noBorderBottom title="Filters" onAdd={onAdd} canHide={!!query?.filters?.length} childrenFor="">
      {query?.filters && (
        <Box display="flex" flexDirection="column" gap="12px">
          {query.filters.map((el, i) => (
            <RightSidebarItem key={`${el.field}${i}`} small title="">
              <SubWrapper
                small
                title="FILTER"
                childrenFor=""
                customIcon={
                  el.default ? undefined : <Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />
                }
              >
                <SettingField
                  insidePicker
                  subLabel="Field"
                  value={el.field}
                  small
                  disabled={el.default}
                  optionsContainer={
                    <CommonPicker
                      title="Fields"
                      value={el.field}
                      onChange={value => onChange(i, { field: value })}
                      values={currentVariableCollectionFieldsValues}
                      close={() => {}}
                    />
                  }
                />
                <SettingField
                  insidePicker
                  subLabel="Operator"
                  value={el.operator}
                  small
                  disabled={el.default}
                  optionsContainer={
                    <CommonPicker
                      title="Operators"
                      value={el.operator}
                      onChange={value => onChange(i, { ...el, operator: value })}
                      values={tableQueryFilterOperandsValues}
                      close={() => {}}
                    />
                  }
                />
                {el.field === recordStatus ? (
                  <SettingField
                    insidePicker
                    subLabel="Value"
                    value={el.value?.textConstant?.locales[language]}
                    small
                    disabled={el.default}
                    optionsContainer={
                      <CommonPicker
                        title="Values"
                        value={el.value?.textConstant?.locales[language]}
                        onChange={value =>
                          onChange(i, {
                            ...el,
                            value: {
                              textConstant: {
                                key: 'key',
                                locales: { ...el.value?.textConstant?.locales, [language]: value },
                              },
                            },
                          })
                        }
                        values={recordStatusValues}
                        close={() => {}}
                      />
                    }
                  />
                ) : (
                  <AnyVariableInput
                    valueType={getValueType(currentVariableCollectionFieldsValues, el.field)}
                    anyVariableValue={el.value}
                    onChange={value => onChange(i, { ...el, value })}
                    disabled={el.default}
                    screenConfig={screenConfig}
                    allLocalVariables={allLocalVariables}
                  />
                )}
              </SubWrapper>
            </RightSidebarItem>
          ))}
        </Box>
      )}
    </SubWrapper>
  )
}
