import React from 'react'

import { Connections, CustomSectionsWrapper, Notification, NotificationColor, Text, Type } from 'components'
import { Links, SettingsLayout } from '../components'

export const GooglePlay: React.FC = () => (
  <SettingsLayout>
    <Text type={Type.TITLE}>Google Play</Text>
    <Text type={Type.SUB_TITLE}>
      The product page for this app version will be published on the App Store, Google Play, and App Landing page with
      the assets and metadata below.
    </Text>
    <Links />
    <CustomSectionsWrapper>
      <Notification
        color={NotificationColor.RED}
        open
        inside
        title="2-Factor verification required"
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip"
        onFix={() => {}}
      />
      <Connections googlePlay />
    </CustomSectionsWrapper>
  </SettingsLayout>
)
