import { Box } from '@mui/material'
import React, { useContext, useMemo } from 'react'

import { AnyVariableInput, CommonPicker, getFieldIcon, getValueType, Icon, Name } from 'components'
import { ProjectContext } from 'context'
import { RightSidebarItem, SettingField, SubWrapper } from 'partials/RightSidebar/components'
import {
  Action,
  CollectionVariable,
  getVariableCollectionName,
  LocalVariable,
  RecordModification,
  Screen,
  VariableSource,
} from 'utils'

interface Props {
  collection?: CollectionVariable
  record?: VariableSource
  recordModifications?: RecordModification[]
  setAction: React.Dispatch<React.SetStateAction<Action>>
  screenConfig: Screen
  allLocalVariables: LocalVariable[]
}

export const RecordModifications: React.FC<Props> = ({
  collection,
  record,
  recordModifications,
  setAction,
  screenConfig,
  allLocalVariables,
}) => {
  const {
    state: {
      data: { collections },
    },
  } = useContext(ProjectContext)

  const collectionName =
    (collection ? collection.name : undefined) ||
    (record ? getVariableCollectionName(record, allLocalVariables) : undefined)

  const collectionFieldsValues = useMemo(
    () =>
      collections
        .find(el => el.name === collectionName)
        ?.properties?.map(el => ({
          value: el.name,
          label: el.name,
          iconName: getFieldIcon(el.type),
          type: el.type,
          accept: el.accept,
        })) || [],
    [collections, collectionName]
  )

  const onAdd = () =>
    setAction(action => ({
      ...action,
      recordModifications: [...(action.recordModifications || []), { type: 'set' }],
    }))

  const onRemove = (index: number) =>
    setAction(action => {
      const copy = JSON.parse(JSON.stringify(action)) as Action
      copy.recordModifications?.splice(index, 1)
      if (copy.recordModifications && !copy.recordModifications.length) {
        delete copy.recordModifications
      }
      return copy
    })

  const onChange = (index: number, recordModification: RecordModification) => {
    setAction(action => {
      const copy = JSON.parse(JSON.stringify(action)) as Action
      copy?.recordModifications?.splice(index, 1, recordModification)
      return copy
    })
  }

  return (
    <SubWrapper
      small
      noBorderBottom
      title="Record modifications"
      onAdd={onAdd}
      canHide={!!recordModifications?.length}
      childrenFor=""
    >
      {recordModifications && (
        <Box display="flex" flexDirection="column" gap="12px">
          {recordModifications.map((el, i) => (
            <RightSidebarItem key={`${el.fieldName}${i}`} small title="">
              <SubWrapper
                small
                title="MODIFICATION"
                childrenFor=""
                customIcon={<Icon name={Name.RIGHT_SIDEBAR_DELETE} onClick={() => onRemove(i)} />}
              >
                <SettingField
                  insidePicker
                  subLabel="Field"
                  value={el.fieldName}
                  small
                  optionsContainer={
                    <CommonPicker
                      title="Fields"
                      value={el.fieldName}
                      onChange={value => onChange(i, { fieldName: value })}
                      values={collectionFieldsValues}
                      close={() => {}}
                    />
                  }
                />
                <AnyVariableInput
                  valueType={getValueType(collectionFieldsValues, el.fieldName)}
                  anyVariableValue={el.value}
                  onChange={value => onChange(i, { ...el, value })}
                  screenConfig={screenConfig}
                  allLocalVariables={allLocalVariables}
                />
              </SubWrapper>
            </RightSidebarItem>
          ))}
        </Box>
      )}
    </SubWrapper>
  )
}
